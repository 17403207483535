import { useContext } from "react"
import AppContext from "../partials/AppContext"

const Input = (props) => {
    const {systemLang} = useContext(AppContext)
    let showSpanHint = ''
    if (props.showSpanHint) {
        const spanStyle = {
            position: 'absolute' ,top: 25 ,color: 'gray'
        }
        systemLang === 'ar' ? spanStyle.left = 15 : spanStyle.right = 15
        showSpanHint = <span style={spanStyle}> {props.showSpanHint} </span>
    }

    if (props.readOnly) {
        return (
            <div style={{position: 'relative'}}>
                <input className={`rounded-box ${props.isAr ? 'ar' : ''}`}
                    placeholder={props.placeholder ? props.placeholder : ''}
                    value={props.value ? props.value : ''}
                    type={props.type ? props.type : 'text'}
                    readOnly={true}/>
                {showSpanHint}
            </div>
        )
    }

    return (
        <div style={{position: 'relative' ,width: '100%'}}>
            <input className={`rounded-box ${props.isAr ? 'ar' : ''} ${props.className ? props.className : ''}`}
                style={{...props?.style}} autoComplete={props.autoComplete ? props.autoComplete : 'on'}
                placeholder={props.placeholder ? props.placeholder : ''}
                value={props.value ? props.value : ''}
                type={props.type ? props.type : 'text'}
                onChange={props.onChange}/>
            {showSpanHint}
        </div>
    )
}

export default Input