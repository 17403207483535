const words = {
    Branch: "Branch",
    BrowseFiles: "Browse Files",
    BranchCoverTitle: "Cover Image (optional)",
    BranchCoverMsg: "cover image here",
    BranchName: "Branch Name",
    Branches: "Branches",
    Bar: "Bar",
    BookInfo: "Booking information",
    Barman: "Barman",
    Brand: "Brand",
    Booking: "Booking",
    BookingAt: "booking at",
    BookedSuccessfully: "Booked Successfully",
    BookDate: "Book a date",
    BookingType: "Booking Type",
    BroughtBy: "brought by",
    By: "By",
    CantUndoAction: "Please note you can’t undo this action",
    CreatedSuccessfully: "created successfully",
    City: "City",
    CameraIPs: "Camera IP`s",
    CameraIP: "Camera IP`s",
    CreationDate: "Creation Date",
    Classes: "Classes",
    Change: "Change",
    Cancel: "Cancel",
    ChangeIP: "Change IP",
    ChangeBar: "Change Bar",
    ChangeLanguage: "Change Language",
    ChangePhoto: "Change Photo",
    ChangeType: "Change Type",
    ChangeName: "Change Name",
    ChangePhone: "Change Phone",
    ChangeWebsite: "Change Website",
    Camera: "Camera",
    ChangeMaleLockers: "Change Male Lockers",
    ChangeFemaleLockers: "Change Female Lockers",
    CloseTime: "Close Time",
    ComingSoon: "Coming Soon",
    Coach: "Coach",
    CheckOutSuccess: "checked out successfully",
    ConfirmEmployeeCheckout: "Are you sure you want to check out this employee? This action can`t be undone",
    CheckoutAlert: "Checkout Alert",
    CheckInSuccess: "checked in successfully",
    ContractInfo: "Contract Information",
    CommissionEgp: "Commission (EGP)",
    Commission: "Commission",
    Confirm: "Confirm",
    Category: "Category",
    Calories: "Calories",
    CAL: "CAL",
    CanceledSuccessfully: "canceled successfully",
    CurrMemInGym: "Current Members in GYM",
    CameThrough: "Came Through",
    Cost: "Cost",
    CM: "CM",
    Checkout: "Checkout",
    ChangePassword: "Change Password",
    ConfirmPassword: "Confirm Password",
    ChangePlan: "Change Plan",
    ChangedTopaySuccessfully: "changed to pay successfully",
    ChangedToleftoverSuccessfully: "changed to leftover successfully",
    CancelOffer: "Cancel Offer",
    Class: "Class",
    ClassDetails: "Class Details",
    ClassTipMsg: "Tip: schedule the class early so people have the time to register",
    ClassTimes: "Class Times",
    CheckChosenMembers: "please check the chosen member(s)",
    CommingSoon: "Comming Soon",
    Clients: "Clients",
    Client: "Client",
    ClientsEarnings: "Clients Earnings",
    BarEarnings: "Bar Earnings",
    CashType: "From/To",
    CashIn: "Cash In",
    CashOut: "Cash Out",
    ClientsStatistics: "Clients Statistics",
    BarStatistics: "Bar Statistics",
    ChangeNameEn: "Change English Name",
    ChangeNameAr: "Change Arabic Name",
    ChangeAddress: "Change Address",
    ChangeDescriptionEnglish: "Change English Description",
    ChangeDescriptionArabic: "Change Arabic Description",
    ChangeLocation: "Change Location On Map",
    BranchDetails: "Branch Details",
}

export default words