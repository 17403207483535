const words = {
    KG: "كيلوجرام",
    LockersNo: "أرقام الخزائن",
    Language: "اللغة",
    Lockers: "الخزائن",
    LeavingTime: "وقت الإنصراف",
    LastMaintenance: "آخر صيانة",
    LogOut: "تسجيل الخروج",
    LowQntAlert: "تنبيه بأقل كمية",
    Leave: "إنصرف",
    LeaveTime: "وقت الإنصراف",
    Locker: "الخزانة",
    LockerNumber: "رقم الخزانة",
    Leaving: "الإنصراف",
    LogoFirst: "إختر اللوجو أولا",
    LeftoverOrder: "طلبية آجل",
    LeftoverOrders: "الطلبيات الآجلة",
    Leftover: "آجل",
    Manage: "إدارة",
    Male: "ذكر",
    Mixed: "مختلط",
    MainInfo: "معلومات رئيسية",
    MaleLockers: "خزائن الرجال",
    Minutes: "دقائق",
    Monday: "الإثنين",
    Mon: "الإثنين",
    Month: "شهر",
    March: "مارس",
    May: "مايو",
    MaintenanceRecUpl: "صورة إيصال الصيانة يرفع هنا",
    MaintenanceDate: "تاريخ الصيانة",
    Maintenance: "الصيانة",
    Main: "رئيسية",
    More: "أكثر",
    Members: "أعضاء",
    Membership: "العضوية",
    Member: "العضو",
    MemberID: "رقم العضو",
    MemberFirst: "إختر عضو أولا",
    MemberCheckoutConfirm: "هل أنت متأكد أنك تريد تسجيل خروج هذا العضو",
    MaxMemInGymReached: "لقد وصلت إلى الحد الأقصى لعدد الأعضاء في الجيم",
    Name: "الإسم",
    NoCancel: "لا إلغاء",
    NoWorkHrsYet: "لا يوجد ساعات عمل بعد",
    NationalID: "رقم الهوية",
    November: "نوفمبر",
    NewEquipment: "معدات جديدة",
    NumberOnly: "رقم فقط",
    NoNotifications: "لا إشعارات حتي الآن",
    NameEnglish: "الإسم بالإنجليزية",
    NameArabic: "الإسم بالعربية",
    NoClose: "لا إغلاق",
    NewMember: "عضو جديد",
    NewMembers: "أعضاء جدد",
    Next: "التالي",
    NewPassword: "كلمة مرور جديدة",
    NextBiling: "فاتورتك القادمة في",
    NoOfDays: "عدد الأيام",
    NoOfInvitations: "عدد الدعوات",
    NumberOfMembers: "عدد الأعضاء",
    NoMemberForClass: "لا يوجد عضو في هذا الفصل",
    Message: "الرسالة",
    LockerInfo: "معلومات الخزائن",
    MonthOverview: "مراجعة الشهر الآخير",
    NetRevenue: "صافي الربح",
    LastUpdateTime: "آخر تحديث",
    Number: "العدد",
    MapLocation: "الموقع علي الخريطة",
    Longitude: "خط الطول",
    Latitude: "خط العرض",
    MemberNotAttend: "هذا العضر غير حاضر الآن",
    MaleWorkingHours: "ساعات عمل الرجال",
    MixWorkingHours: "ساعات عمل المختلط",
    MainBoard: "اللوحة الرئيسية",
    MapUrl: "رابط خريطة جوجل للفرع",
}

export default words