import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import ModalButtons from "../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../FormShared/ModalHeader"
import FieldError from "../../../../FormShared/FieldError"
import AppContext from "../../../../partials/AppContext"
import SearchBox from "../../../../Shared/SearchBox"

const styles = {
    divBtn: {
        color: '#E03F3F',
        width: 120
    }
}

const AddMember = ({cancel ,reloadData ,classId}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/training-classes/members`
    const [disabled ,setDisabled] = useState(false)
    const [members ,setMembers] = useState([])
    const [selectedMember ,setSelectedMember] = useState({id: undefined ,name: translate('MemberID')})
    const [memberId ,setMemberId] = useState('')
    const [chosenMembers ,setChosenMembers] = useState([])
    const [error ,setError] = useState('')

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()

        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/all?classId=${classId}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setMembers(response?.data)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })

        return () => axiosSource.cancel()
    } ,[gymToken ,clearUserData ,alert ,entityUrl ,setMembers])

    const addToChosenMembers = () => {
        if (selectedMember?.id) {
            const existsBefore = chosenMembers.filter(m => m?.id === selectedMember?.id)?.[0]
            if (!existsBefore?.id) {
                setChosenMembers([...chosenMembers ,{name: selectedMember?.name ,id: selectedMember?.id}])
            }
            setSelectedMember({id: undefined ,name: translate('MemberID')})
        }
    }
    const addMember = () => {
        const ids = [...chosenMembers.map(chosenMM => chosenMM.id)]
        if (selectedMember?.id) ids.push(selectedMember?.id)
        console.log(ids)
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/${classId}` ,{members: ids})
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) {
                alert.error(error?.response?.data?.message)
                setError(error?.response?.data?.message + ` ,${translate("CheckChosenMembers")}`)
            }
            if (error?.response?.status === 401) clearUserData()
        })
        // only send to server
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddMember")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section pb-1">
                            {
                                chosenMembers?.map((mm ,index) =>
                                    <div key={index} className="flex-row rel-position jus-con-space-btwn center-align-items center-align-cont">
                                        <span>{index + 1}- { mm?.name }</span>
                                        <i className="fa fa-times clickable red-text" onClick={() => {
                                            setChosenMembers([...chosenMembers.filter(chosenMM => chosenMM.id !== mm.id)])
                                        }}></i>
                                    </div>
                                )
                            }
                            {
                                selectedMember.id ?
                                <div className="flex-row rel-position jus-con-space-btwn center-align-cont center-align-items">
                                    <span> #{ selectedMember?.id } ,{ selectedMember?.name } </span>
                                    <div className="flex-col flex-gap-10 center-align-items">
                                        <i className="fa fa-check-circle clickable green-color"
                                            onClick={addToChosenMembers}>
                                        </i>
                                        <span>|</span>
                                        <i className="fa fa-times-circle clickable red-text" onClick={() => {
                                            setSelectedMember({id: undefined ,name: translate('MemberID')})
                                        }}></i>
                                    </div>
                                </div>
                                : null
                            }
                            <SearchBox
                                extraClass="custom-search-box"
                                extraParentClass="custom-parent-search-box"
                                searchTrigger={() => {
                                    if (memberId === '') {
                                        setSelectedMember({id: undefined ,name: translate('MemberID')})
                                        setError('')
                                    } else {
                                        const member = [...members.filter(member => `${member?.uid}` === `${memberId}` )]?.[0]
                                        setSelectedMember({name: member?.name ,id: member?.id})
                                        if (!member) {
                                            setError(translate("MemberFirst"))
                                        }
                                    }
                                }}
                                setKeyword={value => {
                                    if (!isNaN(value)) setMemberId(value)
                                }}
                                keyword={memberId}
                                placeholder={translate("MemberID")}/>
                            {error ? <FieldError message={error}/> : ''}
                            <div
                                className="clickable" style={styles.divBtn}
                                onClick={addToChosenMembers}>
                                + Add another
                            </div>
                        </div>
                        <div className="col-md-12">
                            <ModalButtons
                                disabled={disabled || chosenMembers.length < 1}
                                cancel={cancel}
                                confirm={addMember}
                                confirmLabel={translate("AddMember")} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddMember