import { Line } from 'react-chartjs-2'

const options = {
    scales: {
        y: {
            beginAtZero: true
        }
    }
}

const TowVectorChart = ({
    labelOne = "Label" ,
    labelTow = "Label" ,
    dataOne ,
    dataTow ,
    colorOne = 'rgb(255, 99, 132)',
    colorTow = 'rgba(224, 63, 63)',
    labels
}) => {
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: labelOne,
                data: dataOne,
                fill: false,
                backgroundColor: colorOne,
                borderColor: colorOne,
                tension: 0.3
            },
            {
                label: labelTow,
                data: dataTow,
                fill: false,
                backgroundColor: colorTow,
                borderColor: colorTow,
                tension: 0.3
            }
        ]
    }
    return (
        <Line data={chartData} options={options} />
    )
}

export default TowVectorChart