import { useContext } from "react"
import AppContext from "../../partials/AppContext"

import ModalHeader from '../../FormShared/ModalHeader'
import Settings from '../BranchSettings/IndexComponent'

const BranchSettings = ({cancel ,branch}) => {
    const {translate} = useContext(AppContext)

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain manage-branch-setting-popup">
                    <ModalHeader title={`${translate('Manage')} ${branch.name ? branch.name : translate('Branch')}`} cancel={cancel}/>
                    <div className="crud-in-modal manage-popup-cintainer">
                        <div className="section manage-popup-section">
                            <Settings/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BranchSettings