import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import DropDown from "../../../FormShared/DropDown"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import Delete from "./Delete"
import Row from "./Row"
import Add from "./Add"
import Edit from "./Edit"

const Index = ({data ,setData ,visible}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/safe/gym-expenses`
    const [deleteAction ,setDeleteAction] = useState({id: undefined ,name: ''})
    const [addAction ,setAddAction] = useState(false)
    const [editAction ,setEditAction] = useState(false)
    const expenses = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword
    const [selectedFilters ,setSelectedFilters] = useState({
        month: {name: translate('All') ,value: ''},
        year: {name: translate('All') ,value: ''},
        category: {name: translate('All') ,id: ''},
        subCategory: {name: translate('All') ,id: ''}
    })
    const [categories ,setCategories] = useState([])
    const [subCategories ,setSubCategories] = useState([])

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const getQuery = () => {
        let query = `keyword=${keyword}`
        query += `&limit=${dataPerPage}`
        query += `&page=${currentPage}`
        query += `&month=${selectedFilters?.month?.value}`
        query += `&year=${selectedFilters?.year?.value}`
        query += `&category=${selectedFilters?.category?.id}`
        query += `&sub_category=${selectedFilters?.subCategory?.id}`

        return query
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?${getQuery()}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate("Item")}`,
                list: responseData,
                meta: meta
            })
            executeScroll()

        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        if (categories.length <= 0) {
            Axios
            .setAuthToken(gymToken)
            .get(`${entityUrl}/categories` ,{cancelToken: axiosSource.token})
            .then(response => setCategories([{name: translate("All") ,id: ''} ,...response?.data?.data]))
            .catch(err => {
                const error = {...err}
                if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
                if (error?.response?.status === 401) clearUserData()
            })
        }
        return () => axiosSource.cancel()
    },[alert ,gymToken ,clearUserData ,entityUrl ,dataPerPage ,currentPage ,selectedFilters])

    const printClick = () => {
        return Config.printByToken(`${entityUrl}/print?${getQuery()}` ,true ,gymToken)
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const changeMonth = (month) => {
        setSelectedFilters({...selectedFilters ,month: {name: month.name ,value: month.value}})
    }

    const changeYear = (year) => {
        setSelectedFilters({...selectedFilters ,year: {name: year.name ,value: year.value}})
    }

    const changeCategory = (category) => {
        setSelectedFilters({
            ...selectedFilters,
            category: {name: category.name ,id: category.id},
            subCategory: {name: translate('SubCategory') ,id: ''}
        })
        setSubCategories([{name: translate("All") ,id: ''} ,...category.sub_categories])
    }

    const changeSubCategory = (subCategory) => {
        setSelectedFilters({
            ...selectedFilters,
            subCategory: {name: subCategory.name ,id: subCategory.id}
        })
    }

    const cancelModal = () => {
        setDeleteAction({id: undefined ,name: ''})
        setAddAction(false)
        setEditAction(false)
    }

    const reloadForDelete = () => {
        if (expenses.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header flex-gap-0">
                    <SearchBox
                        extraParentClass="full-width"
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <div className="flex-row full-width jus-con-space-btwn center-align-items center-align-cont">
                        <div className="flex-row flex-gap-10">
                            <DropDown
                                placeholder={translate('Month')}
                                selectedName={selectedFilters?.month?.name}
                                textColorBlack={selectedFilters?.month?.name !== translate('All')}
                                list={Config.monthsNameValue.map(m => {return {...m ,name: translate(m.name)}})}
                                itemName="name"
                                select={changeMonth} />
                            <DropDown
                                placeholder={translate('Year')}
                                selectedName={selectedFilters?.year?.name}
                                textColorBlack={selectedFilters?.year?.name !== translate('All')}
                                list={Config.yearsNameValue().map(y => {return {...y ,name: translate(y.name)}})}
                                itemName="name"
                                select={changeYear} />
                            <DropDown
                                placeholder={translate('Category')}
                                selectedName={selectedFilters?.category?.name}
                                textColorBlack={selectedFilters?.category?.name !== translate('All')}
                                list={categories}
                                itemName="name"
                                select={changeCategory} />
                            <DropDown
                                placeholder={translate('SubCategory')}
                                selectedName={selectedFilters?.subCategory?.name}
                                textColorBlack={selectedFilters?.subCategory?.name !== translate('All')}
                                list={subCategories}
                                itemName="name"
                                select={changeSubCategory} />
                        </div>
                        <button className="btn btn-light" onClick={() => {
                            cancelModal()
                            setAddAction(true)
                        }}> {translate("AddExpenses")} </button>
                    </div>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Receipt')},
                            {value: translate('Date')},
                            {value: translate('Category')},
                            {value: translate('SubCategory')},
                            {value: translate('AddedBy')},
                            {value: translate('Cost')},
                            {value: translate('Actions')}
                        ]}/>
                        <tbody>
                            {
                                expenses?.map((row ,index) => <Row
                                    key={index}
                                    row={row}
                                    deleteAction={() => {
                                        cancelModal()
                                        setDeleteAction({
                                            id: row?.id,
                                            name: translate('GymExpenses') + ': '+ row?.cost + ', ' + translate('AddedBy') + ': ' + row?.added_by
                                        })
                                    }}
                                    editAction={() => {
                                        cancelModal()
                                        setEditAction(row?.id)
                                    }}
                                />)
                            }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                </div>
                {
                    deleteAction?.id ?
                    <Delete
                        entityUrl={`${entityUrl}/${deleteAction?.id}`}
                        cancel={cancelModal}
                        loadData={reloadForDelete}
                        name={deleteAction?.name}
                    />
                    : ''
                }
                {
                    addAction ?
                    <Add
                        entityUrl={entityUrl}
                        loadData={reloadForAdd}
                        cancel={cancelModal}
                        categories={categories}/>
                    : ''
                }
                {
                    editAction ?
                    <Edit
                        entityUrl={entityUrl}
                        loadData={reloadForAdd}
                        cancel={cancelModal}
                        categories={categories}
                        id={editAction}/>
                    : ''
                }
            </div>
        </div>
    )
}

export default Index