const words = {
    Role: "Role",
    RoleName: "Role`s Name",
    RolePermissions: "Role Permissions",
    Roles: "Roles",
    RowDeleted: "Row deleted successfully",
    Receipt: "Receipt",
    RefillSuccessfully: "refill successfully",
    RefillDrink: "Refill Drink",
    RefillFood: "Refill Food",
    RefillSupplement: "Refill Supplement",
    RequiredField: "Required Field",
    RenewalMember: "Renewal Member",
    RefundedSuccess: "refunded successfully",
    RefundOrder: "Refund Order",
    RefundPartial: "Refund Partial",
    RefundAll: "Refund All",
    RefundOrderItems: "Refund order item(s)",
    RefundDelete: "Refund/Delete",
    RegistrationDate: "Registration Date",
    StoredSuccessfully: "stored successfully",
    SurePermanentlyDelete: "Are you sure you want to permanently delete this",
    Subscriptions: "Subscriptions",
    Sessions: "Sessions",
    SaveChanges: "Save Changes",
    Supplement: "Supplement",
    StartNo: "Start No.",
    Settings: "Settings",
    Saturday: "Saturday",
    Sunday: "Sunday",
    Sat: "Sat",
    Sun: "Sun",
    SelectDay: "Select Day",
    Salesman: "Salesman",
    SelectEmpFirst: "Please select employee first",
    SalaryEgp: "Salary (EGP)",
    September: "September",
    Salary: "Salary",
    Status: "Status",
    Suggestion: "Suggestion",
    SendUs: "Send Us",
    Search: "Search",
    SuccessAlert: "Success Alert",
    Safe: "Safe",
    Stock: "Stock",
    Supplements: "Supplements",
    Size: "Size",
    SellingDetails: "Selling Details",
    SellingPrice: "Selling Price",
    SurePermanentlyDeleteGuest: "Are you sure you want to permanently delete this guest",
    Subscription: "Subscription",
    SearchFullMemberID: "Search by Full Member ID",
    SurePermanentlyCancelBooking: "Are you sure you want to permanently cancel this booking ",
    SubscDaysLeft: "days left till end of subscription",
    SureToTakeLockerKey: "Make sure to take the locker's key",
    SalesmanFirst: "Select salesman first",
    Session: "Session",
    SubscInfo: "Subscription Information",
    StartDateReq: "Start date required",
    SureToUnfreeze: "Are you sure you want to unfreeze this member",
    StartDate: "Start Date",
    SureMakeLeftoverOrdersPaid: "Are you sure you want to to make leftover orders paid for",
    Security: "Security",
    SocialMedia: "Social media",
    SelectOrderFirst: "Select order(s) first",
    SelectedOrderPaid: "selected orders paid successfully",
    SupplementRevenue: "Supplement Revenue",
    Statistics: "Statistics",
    Starts: "Starts",
    Subject: "Subject",
    SubscriptionStatus: "Subscription Status",
    Show: "Show",
    Salaries: "Salaries",
    SubCategory: "Sub-Category",
    StockExpensens: "Stock Expensens",
    Reason: "Reason",
    SafeBalance: "Safe Balance",
    RevenueStatistics: "Revenue Statistics",
    SelectTime: "Select Time",
    SoldQty: "Sold Qnt",
    RedirectionInSeconds: "You`ll be redirected in seconds",
    Skip: "Skip",
}

export default words