import { useContext, useState } from "react"
import {useAlert} from 'react-alert'
import axios from "../../../Axios"
import Config from '../../../Config'
import './form.css'
import ModalHeader from '../../FormShared/ModalHeader'
import FieldError from '../../FormShared/FieldError'
import Input from '../../FormShared/Input'
import ModalButtons from '../../FormShared/ModalButtons'
import RolePermittedEntities from './RolePermittedEntities'
import AppContext from "../../partials/AppContext"

const AddRole = ({cancel ,reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/roles`

    const [errors ,setErrors] = useState({name_en: '', name_ar: '', permissions: ''})
    const [item ,setItem] = useState({name_ar: '' ,name_en: '' ,permissions: []})
    const [disabled ,setDisabled] = useState(false)

    const changeNameEn = (e) => {
        setItem({...item ,name_en: e.target.value})
        setErrors({...errors ,name_en: ''})
    }

    const changeNameAr = (e) => {
        setItem({...item ,name_ar: e.target.value})
        setErrors({...errors ,name_ar: ''})
    }

    const storeItem = () => {
        setDisabled(true)
        axios
        .setAuthToken(gymToken)
        .post(entityUrl ,item)
        .then(response => {
            setDisabled(false)
            cancel()
            alert.success(`${translate('Role')} "${item.name_en}" ${translate('storedSuccessfully')}`)
            reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,response = error.response.data
            if (response.errors) setErrors(response.errors)
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="add-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddRole")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section pb-1">
                            <div className="row">
                                <div className="col-sm-6 pl-0">
                                    <Input placeholder={translate("RoleName")} value={item.name_en} onChange={changeNameEn}/>
                                    {errors.name_en ? <FieldError message={errors.name_en}/> : ''}
                                </div>
                                <div className="col-sm-6 pr-0">
                                    <Input placeholder={translate("ArRoleName")} isAr={true} value={item.name_ar} onChange={changeNameAr}/>
                                    {errors.name_ar ? <FieldError message={errors.name_ar}/> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="toggle-section mb-4">
                            <div className="input-label mb-2">
                                <label htmlFor="branch-adding">{translate('RolePermissions')}</label>
                                {errors.permissions ? <FieldError message={errors.permissions}/> : ''}
                            </div>
                            <RolePermittedEntities
                                itemPermissions={item.permissions}
                                cancel={cancel}
                                updateItemPermission={(permissions) => setItem({...item ,permissions})}/>
                        </div>
                        <ModalButtons disabled={disabled} cancel={cancel} confirm={storeItem} confirmLabel={translate("AddRole")} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddRole