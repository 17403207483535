import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import AppContext from "../../partials/AppContext"
import axios from '../../../Axios'
import Config from '../../../Config'
import ModalHeader from '../../FormShared/ModalHeader'
import ModalButtons from '../../FormShared/ModalButtons'
import SerachBox from "../../Shared/SearchBox"
import DropDown from "../../FormShared/DropDown"

const AttendanceIn = ({cancel ,loadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [employeesIdNumbers ,setEmployeesIdNumbers] = useState([])
    const [idNumber ,setIdNumber] = useState('')
    const [currentEmployee ,setCurrentEmployee] = useState({id: undefined ,id_number: '' ,name: ''})
    const [loadedEmployees ,setLoadedEmployees] = useState([])
    const [btnDisabled ,setBtnDisabled] = useState(false)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(gymToken)
        .get(`${Config.apiBaseUrl}/gym-dashboard-services/employees/getAll` ,{cancelToken: axiosSource.token})
        .then(response => {
            setEmployeesIdNumbers(response?.data?.employees)
            setLoadedEmployees(response?.data?.employees)
        })
        .catch(err => {
            const error = {...err}
            if (error.message) alert.error(error.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setEmployeesIdNumbers ,setLoadedEmployees])

    const addAttendance = () => {
        if (!currentEmployee.id) {
            alert.error(translate('selectEmpFirst'))
            return
        }
        setBtnDisabled(true)
        axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/employees/attendance/store` ,{employee_id: currentEmployee.id})
        .then(response => {
            setBtnDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate('checkInSuccess')}`)
            cancel()
            loadData()
        })
        .catch(err => {
            const error = {...err}
            setBtnDisabled(false)
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }
    
    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddAttendance")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section">
                            <div className="row">
                                <div className="col-sm-12 pad0">
                                    <SerachBox
                                        placeholder={translate("IDNumber")}
                                        keyword={idNumber}
                                        setKeyword={value => {
                                            if (!isNaN(value)) setIdNumber(value)
                                        }}
                                        extraClass="small-search"
                                        searchTrigger={() => {
                                            if (idNumber)
                                                setEmployeesIdNumbers(
                                                    [...loadedEmployees].filter(
                                                        empl => `${empl.id_number}`.includes(idNumber)
                                                    )
                                                )
                                            else setEmployeesIdNumbers([...loadedEmployees])
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 pad0">
                                    <DropDown
                                        selectedName={currentEmployee?.name ? currentEmployee?.name : translate('EmpName')}
                                        textColorBlack={currentEmployee?.id}
                                        list={employeesIdNumbers}
                                        itemName="name"
                                        select={setCurrentEmployee}
                                    />
                                </div>
                            </div>
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            confirm={addAttendance}
                            disabled={btnDisabled}
                            confirmLabel={translate("AddAttendance")} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AttendanceIn