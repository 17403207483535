const Loading = props => {
    /**
     * display: flex;
    justify-content: center;
    align-items: center;
     */
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div className="data-loader"></div>
        </div>
    )
}

export default Loading