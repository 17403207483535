import { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import Axios from '../../../../../../Axios'
import Config from '../../../../../../Config'
import AppContext from '../../../../../partials/AppContext'
import AddExtra from '../../AddExtra'
import DataLine from '../../../../../Shared/DataLine/DataLine'
import './Membership.css'
import Freeze from './Freeze'
import Renewal from './Renewal'
import GrayButton from '../../../../../Shared/GrayButton'

const Membership = ({visible ,memberId ,data ,setData ,loadGeneralData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members`
    const [addExtraType ,setAddExtraType] = useState(undefined)
    const [freezeAction ,setFreezeAction] = useState(undefined)
    const [renewalAction ,setRenewalAction] = useState(undefined)
    const [globalDisabled ,setGlobalDisabled] = useState(false)

    const cancel = () => {
        setAddExtraType(undefined)
        setFreezeAction(undefined)
        setRenewalAction(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/members/membership/show/${memberId}` ,{cancelToken: axiosSource.token})
        .then(response => {
            if (response?.data?.not_active) setGlobalDisabled(true)
            setData(response?.data?.data)
            loadGeneralData()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[entityUrl ,alert ,gymToken ,setData ,clearUserData ,memberId])

    const printClick = () => Config.printByToken(`${entityUrl}/members/membership/print/${memberId}` ,false ,gymToken)

    return (
        <div className={`page-content display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section content small-page-section member-details-page">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title"> {translate("GeneralInfo")} </span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-undo"></i>
                            </button>
                            <button type="button" onClick={printClick}>
                                <i className="fas fa-print"></i>
                            </button>
                        </div>
                    </div>
                    <div className="membership-img-container">
                        <div className="membership-img">
                            <img
                                alt={data?.name}
                                className="full-width"
                                src={data.avatar ? data.avatar : "/assets/svg/img-change.svg"}/>
                        </div>
                        <p className="membership-name"> {data?.name} </p>
                        <p className="membership-status"> {data?.status} </p>
                        <p className="membership-unique-id"> {data?.member_unique_id} </p>
                    </div>
                    <div className="membership-info">
                        <div className="membership-info-section">
                            <span className="membership-info-title">{translate("SubscInfo")}</span>
                            <div className="membership-info-data">
                                <DataLine changeClick={() => {
                                    cancel()
                                    setRenewalAction(true)
                                }}  btnLabel={translate("Upgrade")} title={translate("Subscription")} value={data?.subscription_name} />
                                <DataLine disabled={true} title={translate("StartDate")} value={data?.subscription_start} />
                                <DataLine disabled={true} title={translate("EndDate")} value={data?.subscription_end} />
                                <DataLine withoutBtn={true} title={translate("InvitationNumber")} value={data?.subscription_invitations} />
                            </div>
                        </div>
                    </div>
                    <div className="membership-info">
                        <div className="membership-info-section">
                            <span className="membership-info-title">{translate("Sessions")}</span>
                            <div className="membership-info-data">
                                {
                                    data.extraSessions && Array.isArray(data.extraSessions) ?
                                    data.extraSessions.map(eachSession => <DataLine
                                        key={eachSession?.id}
                                        withoutBtn={true}
                                        title={eachSession?.name}
                                        value={eachSession?.allDetails}/>
                                    )
                                    : ''
                                }
                                <button disabled={globalDisabled} className="membership-add-extra-btn" type="button"
                                onClick={() => {
                                    cancel()
                                    setAddExtraType('session')
                                }}>
                                    + {translate("AddSession")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="membership-info">
                        <div className="membership-info-section">
                            <span className="membership-info-title">{translate("PrivateCoach")}</span>
                            <div className="membership-info-data">
                                {
                                    data.extraPrivateCoaches && Array.isArray(data.extraPrivateCoaches) ?
                                    data.extraPrivateCoaches.map(eachSession => <DataLine
                                        key={eachSession?.id}
                                        withoutBtn={true}
                                        title={eachSession?.name}
                                        value={eachSession?.allDetails}/>
                                    )
                                    : ''
                                }
                                <button disabled={globalDisabled} className="membership-add-extra-btn" type="button"
                                onClick={() => {
                                    cancel()
                                    setAddExtraType('coach')
                                }}>
                                    + {translate("AddPrivateCoach")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="membership-info">
                        <div className="membership-info-section">
                            <span className="membership-info-title">{translate("TotalPrice")}</span>
                            <div className="membership-info-data">
                                <DataLine withoutBtn={true} title={translate("Subscription")} value={`${translate("EGP")} ${data?.subscription_price ?? ''}`} />
                                <DataLine withoutBtn={true} title={translate("VIPSessions")} value={`${translate("EGP")} ${data?.sessions_price ?? ''}`} />
                                <DataLine withoutBtn={true} title={translate("Total")} value={`${translate("EGP")} ${data?.total_price ?? ''}`} />
                            </div>
                        </div>
                    </div>
                    <div className="membership-info">
                        <div className="membership-info-section">
                            <GrayButton
                                label={data?.real_status === 3 ? translate('UnfreezeAccount') : translate('FreezeAccount')}
                                color='#807F7F'
                                onClick={() => {
                                    cancel()
                                    setFreezeAction(true)}
                                }
                            />
                        </div>
                    </div>
                </div>
                {
                    addExtraType ?
                    <AddExtra
                        defaultExtraType={addExtraType}
                        id={memberId}
                        cancel={cancel}
                        reloadData={loadData}
                    />
                    :''
                }
                {
                    freezeAction ? <Freeze memberId={memberId} realStatus={data?.real_status} cancel={cancel} reloadData={loadData}/> : ''
                }
                {
                    renewalAction ? <Renewal memberId={memberId} cancel={cancel} reloadData={loadData} startDate={data?.subscription_end?.split?.(" ")?.[0]}/> : ''
                }
            </div>
        </div>
    )
}

export default Membership