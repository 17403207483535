import { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import Navbar from '../../partials/Navbar'
import axios from "../../../Axios"
import Config from '../../../Config'
import SearchBox from '../../Shared/SearchBox'
import PrintButton from '../../Shared/PrintButton'
import AppContext from '../../partials/AppContext'
import AccountCard from './AccountCard'
import DeleteAccount from './DeleteAccount'
import AddAccount from './AddAccount'
import EditAccount from './EditAccount'
import './accounts.css'

const Index = () => {
    const axiosSource = axios.CancelToken.source()
    const alert = useAlert()
    const entity_url = `${Config.apiBaseUrl}/gym-dashboard-services/gym-accounts`
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    const [accounts ,setAccounts] = useState([])
    const [invokeEdit ,setInvokeEdit] = useState(undefined)
    const [invokeDelete ,setInvokeDelete] = useState(undefined)
    const [invokeAdd ,setInvokeAdd] = useState(undefined)
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)

    const editItem = (itemId) => {
        cancelModal()
        setInvokeEdit(itemId)
    }

    const deleteItem = (itemId ,itemName) => {
        cancelModal()
        setInvokeDelete({itemId ,itemName})
    }

    const addItem = () => {
        cancelModal()
        setInvokeAdd(true)
    }

    const cancelModal = () => {
        setInvokeDelete(undefined)
        setInvokeEdit(undefined)
        setInvokeAdd(undefined)
    }

    const loadData = () => {
        axios
        .setAuthToken(gymToken)
        .get(`${entity_url}?limit=100${keyword ? '&keyword=' + keyword : ''}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const rolesCount = response?.data?.data?.length,
                accountsCountKey = `Account${rolesCount > 1 ? 's' : ''}`
            setAccounts([...response?.data?.data])
            setHeaderTitle(`${rolesCount} ${translate(accountsCountKey)}`)
        })
        .catch(err => {
            const error = {...err} ,response = error?.response?.data
            if (response?.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setAccounts ,setHeaderTitle ,entity_url ,alert])

    const searchTrigger = () => loadData()

    const printClick = () => Config.printByToken(`${entity_url}/print?keyword=${keyword}` ,true ,gymToken)

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Accounts")}/>
            </div>
            <div className="page-content">
                <div className="page-section header-box">
                    <div className="row header">
                        <SearchBox searchTrigger={searchTrigger} setKeyword={setKeyword} keyword={keyword}/>
                        <button onClick={addItem} className="btn btn-light">{translate('AddAccount')}</button>
                    </div>
                </div>
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <PrintButton onClick={printClick}/>
                        </div>
                        <div className="table-container">
                            {
                                accounts.map(
                                    account => <AccountCard editItem={editItem}
                                        deleteItem={deleteItem} key={Math.random()} account={account}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
                { invokeAdd ? <AddAccount cancel={cancelModal} reloadData={loadData} /> : '' }
                { invokeEdit ? <EditAccount accountId={invokeEdit} cancel={cancelModal} reloadData={loadData} /> : '' }
                { invokeDelete ? <DeleteAccount itemId={invokeDelete.itemId} cancel={cancelModal} reloadData={loadData} itemName={invokeDelete.itemName} /> : ''}
            </div>
        </div>
    )
}

export default Index