import Input from "../../FormShared/Input"
import FieldError from "../../FormShared/FieldError"
import { useContext } from "react"
import AppContext from "../../partials/AppContext"
import ModalHeader from "../../FormShared/ModalHeader"
import ModalButtons from "../../FormShared/ModalButtons"

const ChangeModal = ({
    cancel,
    confirm,
    disabled,
    error,
    passError,
    password,
    changePass,
    value,
    changeValue,
    modalHeader
}) => {
    const {translate} = useContext(AppContext)
    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={`${translate("Change")} ${modalHeader}`} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <Input value={value} onChange={changeValue}/>
                            {error ? <FieldError message={error} /> : ''}
                            <Input type="password" placeholder={translate("Password")} value={password} onChange={changePass}/>
                            {passError ? <FieldError message={passError} /> : ''}
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            confirm={confirm}
                            confirmLabel={translate("SaveChanges")}
                            disabled={disabled}
                        />
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangeModal