import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Input from '../../../FormShared/Input'
import DropDown from '../../../FormShared/DropDown'
import FieldError from "../../../FormShared/FieldError"
import Axios from "../../../../Axios"

const SupplementInputs = ({
    supplementData,
    setSupplementData,
    errors,
    setErrors,
    readOnly = undefined,
    qntReadOnly = undefined
}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/stock/supplements`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [allDropDown ,setAllDropDown] = useState({brands: [] ,categories: [] ,units: [] ,sizes: []})

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/getAllData` ,{cancelToken: axiosSource.token})
        .then(response => {
            setAllDropDown({
                brands: response?.data?.brands,
                categories: [...response?.data?.categories ,{name_en: translate('AddNew') ,id: '-1'}],
                sizes: response?.data?.Sizes,
                units: response?.data?.units
            })
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[gymToken ,entityUrl ,alert ,clearUserData ,setAllDropDown])

    return (
        <>
            <div className="row">
                <div className="col-md-6 pl-0">
                    <DropDown
                        selectedName={supplementData?.category?.name}
                        textColorBlack={supplementData?.category?.id}
                        list={allDropDown?.categories}
                        itemName="name_en"
                        select={category => {
                            setErrors({...errors ,category_id: ''})
                            setSupplementData({...supplementData ,category: {id: category?.id ,name: category?.name_en}})
                        }}
                    />
                    {errors?.category_id ? <FieldError message={errors?.category_id} /> : ''}
                </div>
                <div className="col-md-6 pr-0">
                    <DropDown
                        selectedName={supplementData?.brand?.name}
                        textColorBlack={supplementData?.brand?.id}
                        list={allDropDown?.brands}
                        itemName="name_en"
                        select={brand => {
                            setErrors({...errors ,brand_id: ''})
                            setSupplementData({...supplementData ,brand: {id: brand?.id ,name: brand?.name_en}})
                        }}
                    />
                    {errors?.brand_id ? <FieldError message={errors?.brand_id} /> : ''}
                </div>
            </div>
            {
                supplementData && supplementData.category && supplementData.category.id === '-1' ?
                <div className="row">
                    <div className="col-md-6 pl-0">
                        <Input
                            placeholder={translate("NameEnglish")}
                            value={supplementData?.newCategory?.name_en}
                            onChange={e => {
                                setErrors({...errors ,name_en: ''})
                                setSupplementData({...supplementData ,newCategory: {...supplementData.newCategory ,name_en: e.target.value}})
                            }}
                        />
                        {errors?.name_en ? <FieldError message={errors?.name_en} /> : ''}
                    </div>
                    <div className="col-md-6 pr-0">
                        <Input
                            placeholder={translate("NameArabic")}
                            value={supplementData?.newCategory?.name_ar}
                            onChange={e => {
                                setErrors({...errors ,name_ar: ''})
                                setSupplementData({...supplementData ,newCategory: {...supplementData.newCategory ,name_ar: e.target.value}})
                            }}
                        />
                        {errors?.name_ar ? <FieldError message={errors?.name_ar} /> : ''}
                    </div>
                </div>
                : ''
            }
            <h6> {translate("PurchaseDetails")} </h6>
            <div className="row">
                <div className="col-md-6 pl-0">
                    <DropDown
                        selectedName={supplementData?.size?.name}
                        textColorBlack={supplementData?.size?.id}
                        list={allDropDown?.sizes}
                        itemName="name_en"
                        select={size => {
                            setErrors({...errors ,size_id: ''})
                            setSupplementData({...supplementData ,size: {id: size?.id ,name: size?.name_en}})
                        }}
                    />
                    {errors?.size_id ? <FieldError message={errors?.size_id} /> : ''}
                </div>
                <div className="col-md-6 pr-0">
                    <DropDown
                        selectedName={supplementData?.unit?.name}
                        textColorBlack={supplementData?.unit?.id}
                        list={allDropDown?.units}
                        itemName="name_en"
                        select={unit => {
                            setErrors({...errors ,unit_id: ''})
                            setSupplementData({...supplementData ,unit: {id: unit?.id ,name: unit?.name_en}})
                        }}
                    />
                    {errors?.unit_id ? <FieldError message={errors?.unit_id} /> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 pl-0">
                    <Input
                        placeholder={translate("Quantity")}
                        showSpanHint={`| ${translate("AvailableQTY")}`}
                        value={supplementData?.quantity}
                        onChange={e => {
                            if (isNaN(e.target.value)) {
                                setErrors({...errors ,quantity: translate('validNumber')})
                                return
                            }
                            setErrors({...errors ,quantity: ''})
                            setSupplementData({...supplementData ,quantity: e.target.value})
                        }}
                        readOnly={qntReadOnly}
                    />
                    {errors?.quantity ? <FieldError message={errors?.quantity} /> : ''}
                </div>
                <div className="col-md-6 pr-0">
                    <Input
                        placeholder={translate('Price')}
                        showSpanHint={`| ${translate("EGP")}`}
                        value={supplementData?.purchase_price}
                        onChange={e => {
                            if (isNaN(e.target.value)) {
                                setErrors({...errors ,purchase_price: translate('validNumber')})
                                return
                            }
                            setErrors({...errors ,purchase_price: ''})
                            setSupplementData({...supplementData ,purchase_price: e.target.value})
                        }}
                        readOnly={readOnly}
                    />
                    {errors?.purchase_price ? <FieldError message={errors?.purchase_price} /> : ''}
                </div>
            </div>
            <h6> {translate("SellingDetails")} </h6>
            <div className="row">
                <div className="col-md-6 pl-0">
                    <Input
                        placeholder={translate('Price')}
                        showSpanHint={`| ${translate("EGP")}`}
                        value={supplementData?.selling_price}
                        onChange={e => {
                            if (isNaN(e.target.value)) {
                                setErrors({...errors ,selling_price: translate('validNumber')})
                                return
                            }
                            setErrors({...errors ,selling_price: ''})
                            setSupplementData({...supplementData ,selling_price: e.target.value})
                        }}
                    />
                    {errors?.selling_price ? <FieldError message={errors?.selling_price} /> : ''}
                </div>
                <div className="col-md-6 pr-0">
                    <Input
                        placeholder={translate("LowQntAlert")}
                        value={supplementData?.low_quantity}
                        onChange={e => {
                            if (isNaN(e.target.value)) {
                                setErrors({...errors ,low_quantity: translate('validNumber')})
                                return
                            }
                            setErrors({...errors ,low_quantity: ''})
                            setSupplementData({...supplementData ,low_quantity: e.target.value})
                        }}
                    />
                    {errors?.low_quantity ? <FieldError message={errors?.low_quantity} /> : ''}
                </div>
            </div>
        </>
    )
}

export default SupplementInputs