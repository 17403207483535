import { Switch ,Route } from 'react-router-dom'
import Dashboard from '../dashboard/Dashboard/Index'
import Earnings from '../dashboard/Earnings/OwnerEarnings'
import History from '../dashboard/History/Index'
import Branches from '../dashboard/Branches/Index'
import Expenses from '../dashboard/Expenses/OwnerExpenses'
import AccountRoles from '../dashboard/AccountRoles/Index'
import Accounts from '../dashboard/Accounts/Index'
import Profile from '../dashboard/Profile/Index'
import HelpCenter from '../dashboard/HelpCenter/Index'
import Settings from '../dashboard/Settings/Index'

const PortalContent = (props) => {
    return (
        <Switch>
            {/* Main Section */}
            <Route path="/" exact>
                <Dashboard />
            </Route>
            <Route path="/dashboard" exact>
                <Dashboard />
            </Route>
            <Route path="/earnings" exact>
                <Earnings />
            </Route>
            <Route path="/history" exact>
                <History />
            </Route>
            {/* Main Section */}

            {/* Gym Section */}
            <Route path="/branches" exact>
                <Branches />
            </Route>
            <Route path="/expenses" exact>
                <Expenses />
            </Route>
            {/* Gym Section */}

            {/* More Section */}
            <Route path="/account-roles" exact>
                <AccountRoles />
            </Route>
            <Route path="/accounts" exact>
                <Accounts />
            </Route>
            <Route path="/help-center" exact>
                <HelpCenter />
            </Route>
            <Route path="/settings" exact>
                <Settings />
            </Route>
            {/* More Section */}

            <Route path="/profile" exact>
                <Profile />
            </Route>
        </Switch>
    )
}

export default PortalContent