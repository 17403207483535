import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import DropDown from "../../../FormShared/DropDown"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import FieldError from "../../../FormShared/FieldError"
import AppContext from "../../../partials/AppContext"
import GuestData from "./GuestData"
import { NavLink } from "react-router-dom"
import SearchBox from "../../../Shared/SearchBox"
import axios from "axios"

const Add = ({cancel ,reloadData ,booking_id = undefined ,member = undefined ,unique_id = undefined}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate ,hasLockers} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members`
    const apiUrl = `${Config.apiBaseUrl}/gym-dashboard-services`
    const [selectedMember ,setSelectedMember] = useState({
        name: member && member.name ? member.name : translate('MemberID'),
        id:  member && member.id ? member.id : ''
    })
    const [disabled ,setDisabled] = useState(false)
    const [errors ,setErrors] = useState({member_id: '' ,locker_number: '' ,guests: []})
    const [lockers ,setLockers] = useState([{name: translate('LockerNumber') ,value: ''}])
    const [selectedLocker ,setSelectedLocker] = useState({name: translate('LockerNumber') ,value: ''})
    const [subscEndData ,setSubscEndData] = useState(undefined)
    const [guests ,setGuests] = useState([/*{
        name: '' ,phone: '' ,age: '' ,gender: '' ,
        province: {name: 'Province' ,id: undefined} ,city: {name: 'City' ,id: undefined}
    }*/])
    const guestObject = {
        name: '' ,phone: '' ,age: '' ,gender: '' ,
        province: {name: translate('Province') ,id: undefined} ,city: {name: translate('City') ,id: undefined}
    }
    const [conflict ,setConflict] = useState('')
    const [memberId ,setMemberId] = useState(unique_id ? unique_id : '')
    const [ownHasLockers ,setOwnHasLockers] = useState(hasLockers)

    let changeMemberSource = axios.CancelToken.source()
    const changeMember = () => {
        if (!memberId) {
            setErrors({...errors ,member_id: translate('MemberFirst')})
            setSubscEndData(undefined)
            setLockers([{name: translate('LockerNumber') ,value: ''}])
            setSelectedMember({name: '' ,id: undefined})
            return
        }
        setConflict(undefined)
        setErrors({...errors ,member_id: ''})
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/attendance/getRemainingSubscription/${memberId}` ,{cancelToken: changeMemberSource.token})
        .then(response => {
            setDisabled(false)
            setSubscEndData(response?.data?.subscription_end)
            setLockers(response?.data?.lockers)
            setSelectedMember({name: response?.data?.name ,id: response?.data?.id})
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        // 
        Axios
        .setAuthToken(gymToken)
        .get(`${apiUrl}/branch-setting/locker-status` ,{cancelToken: changeMemberSource.token})
        .then(response => setOwnHasLockers(response?.data?.has_lockers))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        if (unique_id) changeMember()
        return () => {
            changeMemberSource.cancel()
            console.log('clean up')
        }
    } ,[unique_id ,apiUrl ,setOwnHasLockers ,alert ,clearUserData])

    const saveChanges = () => {
        setDisabled(true)
        const formData = new FormData()
        formData.append(`member_id` ,selectedMember?.id)
        formData.append(`locker_number` ,selectedLocker?.value)
        guests.forEach((guest ,index) => {
            formData.append(`guests[${index}][name]` ,guest?.name)
            formData.append(`guests[${index}][phone]` ,guest?.phone)
            formData.append(`guests[${index}][age]` ,guest?.age)
            formData.append(`guests[${index}][gender]` ,guest?.gender)
            formData.append(`guests[${index}][city_id]` ,guest?.city?.id)
        })
        if (booking_id) formData.append('booking_id' ,booking_id)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/attendance/store` ,formData)
        .then(response => {
            const msg = `${response?.data?.data?.name} ${translate("AttendSuccessfully")} ,${translate("HeInvite")}`
            setDisabled(false)
            alert.success(`${msg} ${response?.data?.data?.invitation}`)
            cancel()
            reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) {
                const validation = error?.response?.data?.errors
                const _errors = {...errors ,guests: [...guests].map(() => {
                    return {
                        name: '',
                        phone: '',
                        age: '',
                        gender: '',
                        city_id: ''
                    }
                })}
                if (validation.member_id) _errors.member_id = validation.member_id
                if (validation.locker_number) _errors.locker_number = validation.locker_number
                Object.keys(validation).forEach(key => {
                    if (key !== 'member_id' && key !== 'locker_number') {
                        const errorExplain = key.split(".") // 0 : guest ,1: index ,2: field
                        _errors.guests[errorExplain[1]][errorExplain[2]] = validation[key].join(",")
                    }
                })
                setErrors(_errors)
            } else if (
                error?.response?.data?.message &&
                error?.response?.status !== Config.workingHourConflictStatus
            ) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.status === Config.workingHourConflictStatus)
                setConflict(error?.response?.data?.message)
        })
        setTimeout(() => setDisabled(false) ,5000)
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddAttendance")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section">
                            {
                                conflict ?
                                <div className="row">
                                    <div className="col-sm-12 pad0 booking-conflict">
                                        {conflict}
                                        <NavLink className="white-underline ml-2"
                                            exact to="/settings?tab=hours">
                                            {translate("GoSettingAddThem")}
                                        </NavLink>
                                    </div>
                                </div>
                                : ''
                            }
                            {
                                subscEndData ?
                                <div className="row">
                                    <div className="col-sm-12 pad0 subsc-end">
                                        {selectedMember?.name} ,{subscEndData} {translate("subscDaysLeft")}
                                    </div>
                                </div>
                                : ''
                            }
                            <div className="row">
                                <div className={ownHasLockers ? "col-sm-6 pl-0 toogle-textfield" : "col-sm-12 pad0"}>
                                    <SearchBox
                                        extraClass="clear-left-padding"
                                        searchTrigger={changeMember}
                                        setKeyword={value => {
                                            if (!isNaN(value)) setMemberId(value)
                                        }}
                                        keyword={memberId}
                                        placeholder={translate("SearchFullMemberID")}
                                    />
                                    {errors?.member_id ? <FieldError message={errors?.member_id} /> : ''}
                                </div>
                                {
                                    ownHasLockers ?
                                    <div className="col-sm-6 pr-0 toogle-textfield">
                                        <DropDown
                                            selectedName={selectedLocker?.name}
                                            textColorBlack={selectedLocker?.value}
                                            list={lockers}
                                            itemName="name"
                                            select={locker => {
                                                setErrors({...errors ,locker: ''})
                                                setSelectedLocker({name: locker?.name ,value: locker?.value})
                                            }}
                                        />
                                        {errors?.locker_number ? <FieldError message={errors?.locker_number} /> : ''}
                                    </div>
                                    : ''
                                }
                            </div>
                            <GuestData
                                errors={errors?.guests}
                                setErrors={__errors => setErrors({...errors ,guests: __errors})}
                                guests={guests}
                                setGuests={setGuests}
                                guestObject={guestObject}
                            />
                        </div>
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("Add")}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Add