const words = {
    Branch: "الفرع",
    BrowseFiles: "تصفح الملفات",
    BranchCoverTitle: "صورة الغلاف (إختياري)",
    BranchCoverMsg: "صورة الغلاف هنا",
    BranchName: "إسم الفرع",
    Branches: "الفروع",
    Bar: "البار",
    BookInfo: "معلومات الحجز",
    Barman: "البارمان",
    Brand: "ماركة",
    Booking: "حجز",
    BookingAt: "حجز في",
    BookedSuccessfully: "تم الحجز بنجاح",
    BookDate: "حجز في تاريخ",
    BookingType: "نوع الحجز",
    BroughtBy: "تم الإشتراك بواسطة",
    By: "بواسطة",
    CantUndoAction: "إحذر ,انك غير قادر علي إلغاء تلك العملية",
    CreatedSuccessfully: "تم الإنشاء بنجاح",
    City: "المدينة",
    CameraIPs: "آي بي الكاميرا",
    CameraIP: "آي بي الكاميرا",
    CreationDate: "تاريخ الإنشاء",
    Classes: "الفصول",
    Change: "تغيير",
    Cancel: "إلغاء",
    ChangeIP: "تغيير الآي بي",
    ChangeBar: "تغيير البار",
    ChangeLanguage: "تغيير اللغة",
    ChangePhoto: "تغيير الصورة",
    ChangeType: "تغيير النوع",
    ChangeName: "تغيير الاسم",
    ChangePhone: "تغيير الهاتف",
    ChangeWebsite: "تغيير الموقع",
    Camera: "الكاميرا",
    ChangeMaleLockers: "تغيير خزائن الرجال",
    ChangeFemaleLockers: "تغيير خزائن السيدات",
    CloseTime: "وقت الإغلاق",
    ComingSoon: "قريباً",
    Coach: "مدرب",
    CheckOutSuccess: "تم تسجيل الخروج بنجاح",
    ConfirmEmployeeCheckout: "هل انت تريد تسجيل خروج الموظف ؟ برجاء العلم أنه لا يمكن تغيير هذه العملية",
    CheckoutAlert: "تنبيه تسجيل الخروج",
    CheckInSuccess: "تم تسجيل الدخول بنجاح",
    ContractInfo: "معلومات العقد",
    CommissionEgp: "النسبة (بالجنيه)",
    Commission: "النسبة",
    Confirm: "تأكيد",
    Category: "الصنف",
    Calories: "السعرات الحرارية",
    CAL: "السعرات",
    CanceledSuccessfully: "تم الإلغاء بنجاح",
    CurrMemInGym: "الأعضاء الموجودين في الجيم حاليا",
    CameThrough: "سجل بواسطة",
    Cost: "التكلفة",
    CM: "سنتيمتر",
    Checkout: "تسجيل خروج",
    ChangePassword: "تغيير كلمة المرور",
    ConfirmPassword: "تأكيد كلمة المرور",
    ChangePlan: "تغيير الخطة",
    ChangedTopaySuccessfully: "تم التغيير لمدفوع بنجاح",
    ChangedToleftoverSuccessfully: "تم التغيير لآجل بنجاح",
    CancelOffer: "إلغاء العرض",
    Class: "الفصل",
    ClassDetails: "تفاصيل الفصل",
    ClassTipMsg: "نصيحة: جدوِل الفصل مسبقاً لإتاحة الأعضاء للتسجيل",
    ClassTimes: "أوقات الفصل",
    CheckChosenMembers: "من فضلك راجع الأعضاء المختاريين",
    CommingSoon: "قريبا",
    Clients: "العملاء",
    Client: "عميل",
    ClientsEarnings: "أرباح العملاء",
    BarEarnings: "أرباح البار",
    CashType: "من/إلي",
    CashIn: "تحصيل",
    CashOut: "صرف",
    ClientsStatistics: "إحصائية الأعضاء",
    BarStatistics: "إحصائية البار",
    ChangeNameEn: "تغيير الإسم الإنجليزي",
    ChangeNameAr: "تغيير الإسم العربي",
    ChangeAddress: "تغيير العنوان",
    ChangeDescriptionEnglish: "تغيير الوصف الإنجليزي",
    ChangeDescriptionArabic: "تغيير الوصف العربي",
    ChangeLocation: "تغيير الموقع علي الخريطة",
    BranchDetails: "بيانات الفرع"
}

export default words