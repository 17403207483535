const Row = ({row ,cancel ,setEditClick ,setDeleteClick}) => {
    
    return (
        <tr key={Math.random()}>
            <td width="20%"> { row.name } </td>
            <td width="20%"> { row.name_ar } </td>
            <td width="20%"> { row.price } </td>
            <td width="20%" className="actions">
                <button onClick={() => {
                    cancel()
                    setEditClick({id: row.id ,title: row.name ,title_ar: row.name_ar ,price: row.price})
                }}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={() => {
                    cancel()
                    setDeleteClick({id: row.id ,name: row.name})
                }}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default Row