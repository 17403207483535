import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import Loading from "../../../Shared/Loading"

const styles = {
    rowStyle: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
        paddingBottom: 10,
        borderBottom: '#E8E8E8 1px solid',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center'
    },
    childStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
    },
    nameStyle: {
        fontSize: 13
    },
    qntStyle: {
        color: 'gray',
        fontSize: 15
    },
    priceStyle: {
        font: 'normal normal bold 14px/16px Public Sans',
        letterSpacing: 0,
        color: '#191919',
        opacity: 1
    },
    costStyle: {
        display: 'flex',
        flexDirection: 'row',
        padding: 16,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        background: '#F8F8F8 0% 0% no-repeat padding-box',
        border: '1px solid #191919',
        borderRadius: 30,
        opacity: 1,
        marginBottom: 15
    },
    buttonsRowStyle: {justifyContent: 'space-between'},
    buttonsBtnStyle: {width: '100%'}
}

const CheckoutAll = ({memberName ,memberId ,cancel ,reloadData}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/leftover/orders`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)

    const [details ,setDetails] = useState({orders_list: [] ,total_price: '' ,no_of_orders: ''})
    const [loaded ,setLoaded] = useState(false)
    const [disabled ,setDisabled] = useState(false)

    useEffect(() => {
        let axiosSource = axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/getAllLeftoverOrders/${memberId}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const data = response?.data
            setDetails({
                orders_list: data?.orders_list,
                total_price: data?.total_price,
                no_of_orders: data?.no_of_orders
            })
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[entityUrl ,clearUserData ,gymToken ,setDetails ,alert])

    const payAll = () => {
        setDisabled(true)
        let axiosSource = axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/payAllLeftoverOrders/${memberId}` ,{_method: 'PUT' ,cancelToken: axiosSource.token})
        .then(response => {
            alert.success(`${memberName ? memberName : ''} ${translate("OrdersPaidSuccess")}`)
            setDisabled(false)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("PayLeftover")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                            <>
                                <div className="section mb-2">
                                    <div style={styles.costStyle}>
                                        <div style={styles.childStyle}>
                                            <span style={styles.nameStyle}>
                                                { details?.no_of_orders } {translate(`LeftoverOrder${ details?.no_of_orders > 1 ? 's' : '' }`)}
                                            </span>
                                        </div>
                                        <span style={styles.priceStyle}>
                                            {translate("EGP")} { details?.total_price }
                                        </span>
                                    </div>
                                    {
                                        details && details.orders_list && Array.isArray(details.orders_list) ?
                                        details.orders_list.map((item ,index) => <div key={index} style={styles.rowStyle}>
                                            <div style={styles.childStyle}>
                                                <span style={styles.nameStyle}> #{ item?.order_id } </span>
                                            </div>
                                            <span style={styles.priceStyle}>
                                                {item?.order_total_price}
                                            </span>
                                        </div>)
                                        : ''
                                    }
                                </div>
                                <ModalButtons
                                    rowStyle={styles.buttonsRowStyle}
                                    btnStyle={styles.buttonsBtnStyle}
                                    disabled={disabled}
                                    cancel={cancel}
                                    confirm={payAll}
                                    confirmLabel={translate("Pay")} />
                            </>
                            : <Loading/>
                        }
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default CheckoutAll
