import { useContext } from 'react'
import DataLine from '../../Shared/DataLine/DataLine'
import AppContext from "../../partials/AppContext"
import './OpeningHours.css'
import OpeningHoursRow from './OpeningHoursRow'

const OpeningHours = ({
    branch,
    isVisible,
    addWorkingHours,
    deleteWorkingHours,
    updateWorkingHours,
    openModal
}) => {
    const {translate} = useContext(AppContext)
    
    const male = branch?.working_hours?.male,
        female = branch?.working_hours?.female,
        mixed = branch?.working_hours?.mix

    return (
        <div className={`page-content display-${isVisible ? 'show' : 'hide'}`}>
            <div className="page-section content profile-card-content">
                <div className="details-page-content settings-page">
                    <div className="table-header">
                        <span className="table-header-title"> {translate('workingHours')} </span>
                    </div>
                    <div className="details-page-section">
                        <div className="details-gym-info">
                            <div className="item-row">
                                <p className="item-row-headline">{translate("Male")}</p>
                                <div className="action-div flex-row flex-gap-10">
                                    <button className="red-text clickable" onClick={() => addWorkingHours('male')}>
                                        {translate("Add")}
                                    </button>
                                </div>
                            </div>
                            {
                                male && Array.isArray(male) && male.length > 0 ?
                                    male.map(workingHour => <OpeningHoursRow
                                        deleteWorkingHours={deleteWorkingHours}
                                        updateWorkHour={updateWorkingHours}
                                        type="male"
                                        start={workingHour?.start}
                                        end={workingHour?.end}
                                        id={workingHour?.id}
                                        days={workingHour?.days}
                                    />)
                                : translate('NoWorkHrsYet')
                            }
                        </div>
                        <div className="details-gym-info">
                            <div className="item-row">
                                <p className="item-row-headline">{translate("Female")}</p>
                                <div className="action-div flex-row flex-gap-10">
                                    <button className="red-text clickable" onClick={() => addWorkingHours('female')}>
                                        {translate("Add")}
                                    </button>
                                </div>
                            </div>
                            {
                                female && Array.isArray(female) && female.length > 0 ?
                                    female.map(workingHour => <OpeningHoursRow
                                        deleteWorkingHours={deleteWorkingHours}
                                        updateWorkHour={updateWorkingHours}
                                        type="female"
                                        start={workingHour?.start}
                                        end={workingHour?.end}
                                        id={workingHour?.id}
                                        days={workingHour?.days}
                                    />)
                                : translate('NoWorkHrsYet')
                            }
                        </div>
                        <div className="details-gym-info">
                            <div className="item-row">
                                <p className="item-row-headline">{translate("Mixed")}</p>
                                <div className="action-div flex-row flex-gap-10">
                                    <button className="red-text clickable" onClick={() => addWorkingHours('mix')}>
                                        {translate("Add")}
                                    </button>
                                </div>
                            </div>
                            {
                                mixed && Array.isArray(mixed) && mixed.length > 0 ?
                                    mixed.map(workingHour => <OpeningHoursRow
                                        deleteWorkingHours={deleteWorkingHours}
                                        updateWorkHour={updateWorkingHours}
                                        type="mix"
                                        start={workingHour?.start}
                                        end={workingHour?.end}
                                        id={workingHour?.id}
                                        days={workingHour?.days}
                                    />)
                                : translate('NoWorkHrsYet')
                            }
                        </div>
                        <div className="details-gym-info">
                            <div className="details-info-section">
                                <span className="details-info-title">{translate('BookInfo')}</span>
                                <div className="details-info-data">
                                    <DataLine
                                        title={translate("AllowMemInGym")}
                                        value={branch?.booking_attendance}
                                        changeClick={() => openModal({
                                            title: `${translate("Change")} ${translate("AllowMemInGym")}`,
                                            attr: 'max_number_per_training_session',
                                            value: branch?.booking_attendance,
                                            placeholder: translate('AttendNo')
                                        })}
                                    />
                                    <DataLine
                                        title={`${translate("TrainDuration")} (minutes)`}
                                        value={branch?.training_duration}
                                        changeClick={() => openModal({
                                            title: `${translate("Change")} ${translate("TrainDuration")}`,
                                            attr: 'training_duration',
                                            value: branch?.training_duration,
                                            placeholder: `${translate("TrainDuration")} (minutes)`
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpeningHours