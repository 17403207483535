import Input from "../../FormShared/Input"
import FieldError from "../../FormShared/FieldError"
import { useContext, useState } from "react"
import axios from "../../../Axios"
import { useAlert } from "react-alert"
import AppContext from "../../partials/AppContext"
import Config from "../../../Config"
import ModalHeader from "../../FormShared/ModalHeader"
import ModalButtons from "../../FormShared/ModalButtons"

const ChangeLocation = ({cancel ,reloadData ,long ,lat}) => {
    const alert= useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [longitude ,setLongitude] = useState(long)
    const [latitude ,setLatitude] = useState(lat)
    const [errors ,setErrors] = useState({longitude: '' ,latitude: ''})
    const [pass ,setPass] = useState('')
    const [passError ,setPassError] = useState('')
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        const data = {password: pass ,_method: 'PUT' ,longitude: longitude ,latitude: latitude}
        axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/branch-setting` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) {
                setErrors({
                    longitude: error?.response?.data?.errors?.longitude,
                    latitude: error?.response?.data?.errors?.latitude
                })
                if (error?.response?.data?.errors?.password) setPassError(error?.response?.data?.errors?.password)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader cancel={cancel} title={translate("ChangeLocation")}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <Input placeholder={translate("Longitude")} value={longitude} onChange={e => {
                                setErrors({...errors ,longitude: ''})
                                setLongitude(e.target.value)
                            }}/>
                            {errors?.longitude ? <FieldError message={errors?.longitude} /> : ''}
                            <Input placeholder={translate("Latitude")} value={latitude} onChange={e => {
                                setErrors({...errors ,latitude: ''})
                                setLatitude(e.target.value)
                            }}/>
                            {errors?.latitude ? <FieldError message={errors?.latitude} /> : ''}
                            <Input type="password" placeholder={translate("Password")} value={pass} onChange={e => setPass(e.target.value)}/>
                            {passError ? <FieldError message={passError} /> : ''}
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            disabled={disabled}
                            confirmLabel={translate("SaveChanges")}
                            confirm={saveChanges}/>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangeLocation