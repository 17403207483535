import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import DateInput from "../../../../FormShared/DateInput"
import DropDown from "../../../../FormShared/DropDown"
import FieldError from "../../../../FormShared/FieldError"
import ModalButtons from "../../../../FormShared/ModalButtons"
import AppContext from "../../../../partials/AppContext"
import CoachInputs from "./CoachInputs"
import SessionInputs from "./SessionInputs"

const Membership = ({
    subscription,
    changeSubscription,
    errors,
    sessions,
    setSessions,
    coaches,
    setCoaches,
    clickNext,
    clickPrevious,
    changeStart,
    cancelLabel
}) => {
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/members`
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [subscriptions ,setSubscriptions] = useState([{id: undefined ,title: translate('Subscription') ,price: 0}])

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/getAllSubscriptions` ,{cancelToken: axiosSource.token})
        .then(response => setSubscriptions(response?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setSubscriptions])

    const [privateCoaches ,setPrivateCoaches] = useState([{id: undefined ,title: translate('PrivateCoach') ,price: 0}])
    const [extraSessions ,setExtraSessions] = useState([{id: undefined ,name: translate('Session') ,price: 0}])

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/getAllPrivateCoaches` ,{cancelToken: axiosSource.token})
        .then(response => setPrivateCoaches(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/getAllSessions` ,{cancelToken: axiosSource.token})
        .then(response => setExtraSessions(response?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setPrivateCoaches ,setExtraSessions ,alert ,gymToken ,clearUserData ,entityUrl])

    return (
        <form onSubmit={e => e.preventDefault()}>
            <div className="section">
                <div className="row">
                    <h6> {translate("SubscInfo")} </h6>
                </div>
                <div className="row">
                    <div className="col-sm-6 pad0 toggle-textfiled">
                        <div className="form-group">
                            <DropDown
                                selectedName={subscription?.title}
                                textColorBlack={subscription.id}
                                list={subscriptions}
                                itemName="title"
                                select={changeSubscription}
                            />
                            {errors?.subscription ? <FieldError message={errors?.subscription} /> : ''}
                        </div>
                    </div>
                    <div className="col-sm-6 pad0 toggle-textfiled">
                        <div className="form-group">
                            <DateInput placeholder="Start Date" type="date" min={new Date().toISOString().split("T")[0]}
                                value={subscription?.start} onChange={changeStart} isDisabled={subscription?.isDisabled}/>
                            {errors?.subscription_start ? <FieldError message={errors?.subscription_start} /> : ''}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <h6> {translate("AddMorSeession")} </h6>
                    {errors?.sessions ? <FieldError message={errors?.sessions} /> : ''}
                </div>
                <SessionInputs
                    extraSessions={extraSessions}
                    sessions={sessions}
                    setSessions={setSessions}
                />
                <div className="row mt-3">
                    <h6> {translate("PrivateCoachInfo")} </h6>
                    {errors?.coaches ? <FieldError message={errors?.coaches} /> : ''}
                </div>
                <CoachInputs
                    privateCoaches={privateCoaches}
                    coaches={coaches}
                    setCoaches={setCoaches}
                />
            </div>
            <ModalButtons
                cancel={clickPrevious}
                confirm={() => clickNext()}
                confirmLabel={translate("Next")}
                cancelLabel={cancelLabel ? cancelLabel : translate("Previous")}
            />
        </form>
    )
}

export default Membership