import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import Axios from '../../../../Axios'
import Config from '../../../../Config'
import AppContext from '../../../partials/AppContext'
import Loading from '../../../Shared/Loading'
import OrderForm from './Add/OrderForm'

const Edit = ({id ,cancel ,reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const [orderData ,setOrderData] = useState({items: []})
    const [errors ,setErrors] = useState({items: ''})
    const [products ,setProducts] = useState({})
    const [disabled ,setDisabled] = useState(false)
    const [loaded ,setLoaded] = useState(false)

    const saveChanges = () => {
        const axiosSource = axios.CancelToken.source()
        const data = {cancelToken: axiosSource.token ,_method: 'PUT' ,items: []}
        orderData.items.forEach(item => {
            data.items.push({
                product_id: item?.id,
                product_type: item.type === Config.Order.type.foods ? translate('Food') : item.type,
                quantity: item.qnt
            })
        })
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/orders/${id}` ,data)
        .then(response => {
            const order = response?.data?.data
            setDisabled(false)
            alert.success(`${translate("Order")} #${order?.id} ${translate("ForMember")} ${order?.member} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) {
                try {
                    const validation = error?.response?.data?.errors
                    setErrors({...errors ,items: validation.items.join(',')})
                } catch (__err) {}
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const axiosSource = axios.CancelToken.source()
    const loadItems = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${Config.apiBaseUrl}/gym-dashboard-services/orders/edit/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setLoaded(true)
            setOrderData({items: response?.data})
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        Axios
        .setAuthToken(gymToken)
        .get(`${Config.apiBaseUrl}/gym-dashboard-services/orders/bar/products` ,{cancelToken: axiosSource.token})
        .then(response => {
            setProducts(response?.data)
            loadItems()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.status === 405) cancel()
        })

        return () => axiosSource.cancel()
    } ,[setProducts ,alert ,clearUserData ,gymToken])

    return loaded ? <OrderForm
        products={products}
        cancel={cancel}
        orderData={orderData}
        setOrderData={setOrderData}
        placeOrder={items => saveChanges(items)}
        errorMsg={errors.items}
        disabled={disabled}
    />
    : <Loading/>
}

export default Edit