import { useContext, useState } from "react"
import AppContext from "../../partials/AppContext"
import Navbar from "../../partials/Navbar"
import CrudIndexDS from "../../Shared/CrudIndexDS"
import Equipment from "./Equipment"
import Stock from "./Stock"
import Salaries from "./Salaries/Index"
import GymExpenses from "./GymExpenses/Index"
import Statistics from "./Statistics/Index"

const Index = () => {
    const {translate} = useContext(AppContext)
    const [showSection ,setShowSection] = useState('statistics')

    const [equipments ,setEquipments] = useState({...CrudIndexDS})
    const [stock ,setStock] = useState({...CrudIndexDS})
    const [salaries ,setSalaries] = useState({...CrudIndexDS})
    const [gymExpenses ,setGymExpenses] = useState({...CrudIndexDS})

    const EquipmentIndex = <Equipment
        data={equipments}
        setData={setEquipments}
        visible={showSection === 'equipment'}
    />,
    StockIndex = <Stock
        data={stock}
        setData={setStock}
        visible={showSection === 'stock'}
    />,
    SalariesIndex = <Salaries
        data={salaries}
        setData={setSalaries}
        visible={showSection === 'salaries'}
    />,
    GymExpensesIndex = <GymExpenses
        data={gymExpenses}
        setData={setGymExpenses}
        visible={showSection === 'gym'}
    />,
    StatsIndex = <Statistics
        visible={showSection === 'statistics'}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Expenses")}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'statistics' ? 'active' : ''}
                    onClick={() => setShowSection('statistics')}>
                    {translate("Statistics")}
                </button>
                <button className={showSection === 'gym' ? 'active' : ''}
                    onClick={() => setShowSection('gym')}>
                    {translate("Gym")}
                </button>
                <button className={showSection === 'salaries' ? 'active' : ''}
                    onClick={() => setShowSection('salaries')}>
                    {translate("Salaries")}
                </button>
                <button className={showSection === 'stock' ? 'active' : ''}
                    onClick={() => setShowSection('stock')}>
                    {translate("Stock")}
                </button>
                <button className={showSection === 'equipment' ? 'active' : ''}
                    onClick={() => setShowSection('equipment')}>
                    {translate("Equipment")}
                </button>
            </div>
            <div className="page-content">
                {StatsIndex}
                {EquipmentIndex}
                {StockIndex}
                {SalariesIndex}
                {GymExpensesIndex}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index