import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../Axios"
import Config from "../../../../../../Config"
import AppContext from "../../../../../partials/AppContext"
import PrintButton from "../../../../../Shared/PrintButton"
import SearchBox from "../../../../../Shared/SearchBox"
import Thead from "../../../../../Shared/Thead"
import Table from "../../../../../Shared/Table"
import Pagination from "../../../../../Shared/Pagination"
import Delete from "./Delete"
import AddMember from '../AddMember'

const Index = ({visible ,data ,setData ,classId}) => {
    console.log(data)
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/training-classes/members`
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    const [deleteAction ,setDeleteAction] = useState(undefined)
    const [addAction ,setAddAction] = useState(undefined)

    const cancel = () => {
        setDeleteAction(undefined)
        setAddAction(undefined)
    }

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/${classId}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate("Members")}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,alert ,dataPerPage ,currentPage ,clearUserData ,gymToken])

    const reloadForDelete = () => {
        if (dataList.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }
    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const printClick = () => Config.printByToken(`${entityUrl}/${classId}/print?keyword=${keyword}` ,true ,gymToken)

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <button className="btn btn-light" onClick={() => {
                        cancel()
                        setAddAction(true)
                    }}> {translate("AddMember")} </button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Name') ,colSpan: 3 ,width: '50%'},
                            {value: translate('IDNumber') ,width: '20%'},
                            {value: translate('RegistrationDate') ,width: '15%'},
                            {value: translate('Actions') ,className: 'actions' ,width: '15%'}
                        ]}/>
                        <tbody>
                            {dataList?.map?.((item ,index) => {
                                return (
                                    <tr key={index}>
                                        <td colSpan="3" width="50%"> {item?.name} </td>
                                        <td width="20%"> {item?.id_number} </td>
                                        <td width="15%"> {item?.registration} </td>
                                        <td width="15%">
                                            <button onClick={() => {
                                                cancel()
                                                setDeleteAction({id: item?.id ,name: item?.name ,cost: item?.cost})
                                            }}>
                                                <img alt="Gymme" src="/assets/svg/delete.svg"/>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n})}/>
                </div>
            </div>
            {addAction ? <AddMember classId={classId} reloadData={loadData} cancel={cancel} /> : ''}
            {
                deleteAction ?
                <Delete
                    cancel={cancel}
                    reloadData={reloadForDelete}
                    entityUrl={`${entityUrl}/${classId}/${deleteAction.id}`}
                    name={deleteAction?.name}
                    cost={deleteAction?.cost}/>
                : ''
            }
        </div>
    )
}

export default Index