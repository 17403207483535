import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../Axios"
import Config from "../../../../../../Config"
import FieldError from "../../../../../FormShared/FieldError"
import ModalButtons from "../../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../../FormShared/ModalHeader"
import AppContext from "../../../../../partials/AppContext"
import SearchBox from "../../../../../Shared/SearchBox"

const Add = ({cancel ,reloadData ,classId}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/training-classes/members`
    const [disabled ,setDisabled] = useState(false)
    const [members ,setMembers] = useState([])
    const [selectedMember ,setSelectedMember] = useState({id: undefined ,name: "" ,id_number: ""})
    const [memberId ,setMemberId] = useState('')
    const [error ,setError] = useState('')

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()

        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/${classId}/all` ,{cancelToken: axiosSource.token})
        .then(response => {
            if (response?.data?.data?.length <= 0) alert.error(translate("NoMemberForClass"))
            else {
                setMembers(response?.data?.data)
            }
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })

        return () => axiosSource.cancel()
    } ,[gymToken ,clearUserData ,alert ,entityUrl ,classId ,setMembers])

    const addMember = () => {
        if (!selectedMember.id) {
            alert.error('Select member first')
        }
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/${classId}/${selectedMember.id}`)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AttendMember")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section pb-1">
                            {
                                selectedMember?.id ?
                                <span className="mtb-0-mlr-10">
                                    { `#${selectedMember?.id_number} ,${selectedMember?.name}` }
                                </span>
                                : null
                            }
                            <SearchBox
                                extraClass="custom-search-box"
                                extraParentClass="custom-parent-search-box"
                                searchTrigger={() => {
                                    if (memberId === '') {
                                        setSelectedMember({name: '' ,id: undefined ,id_number: ''})
                                        setError(translate("RequiredField"))
                                    } else {
                                        const searchMember = members?.filter?.(__mem => `${__mem?.id_number}` === `${memberId}`)
                                        if (searchMember.length === 1) {
                                            setError('')
                                            setSelectedMember({name: searchMember?.[0]?.name ,id: searchMember?.[0]?.id ,id_number: searchMember?.[0]?.id_number})
                                        } else {
                                            setSelectedMember({name: '' ,id: undefined ,id_number: ''})
                                            setError(translate("RequiredField"))
                                        }
                                    }
                                }}
                                setKeyword={value => {
                                    if (!isNaN(value)) setMemberId(value)
                                }}
                                keyword={memberId}
                                placeholder={translate("MemberID")}/>
                            {error ? <FieldError message={error}/> : null}
                        </div>
                        <div className="col-md-12">
                            <ModalButtons disabled={disabled} cancel={cancel} confirm={addMember} confirmLabel={translate("AttendMember")} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Add