import { useContext } from "react"
import AppContext from "../../partials/AppContext"
import DataLine from "../../Shared/DataLine/DataLine"

const AdminData = ({admin ,openChangeModal ,openPhotoModal}) => {
    const {translate} = useContext(AppContext)
    return (
        <div className="page-content">
            <div className="page-section content profile-card-content">
                <div className="details-page-content profile-page">
                    <div className="details-page-section">
                        <div className="details-img-container">
                            <div className="details-img">
                                <img alt={admin?.name} id="gym-logo-img" src={admin?.photo} className="details-full-img full-width"/>
                            </div>
                            <button id="cam-btn" className="details-cam-btn en">
                                <img alt={admin?.name} src="/assets/svg/img-change.svg" onClick={openPhotoModal}/>
                            </button>
                        </div>
                        
                        <div className="details-gym-info">
                            <div className="details-info-section">
                                <span className="details-info-title">{translate("PersonalInfo")}</span>
                                <div className="details-info-data">
                                    <DataLine title={translate("Name")} value={admin.name} changeClick={() => openChangeModal('name')} />
                                    <DataLine title={translate("Email")} value={admin.email} changeClick={() => openChangeModal('email')} />
                                    <DataLine title={translate("Phone")} value={admin.phone} changeClick={() => openChangeModal('phone')} />
                                </div>
                            </div>
                            <div className="details-info-section">
                                <span className="details-info-title">{translate("Security")}</span>
                                <div className="details-info-data">
                                    <DataLine
                                        title={`${translate("Password")}:********`}
                                        changeClick={() => openChangeModal('password') }
                                        btnLabel={translate("ChangePassword")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminData