const words = {
    Food: "الطعام",
    Female: "أنثي",
    FemaleLockers: "خزائن السيدات",
    From: "من",
    Friday: "الجمعة",
    Fri: "الجمعة",
    February: "فبراير",
    Foods: "الطعام",
    FullMemberID: "أكتب رقم العضو كاملا",
    FreezeAccount: "تجميد الحساب",
    FreezeStart: "بداية التجميد",
    FreezeEnd: "إنتهاء التجميد",
    Freeze: "تجميد",
    FieldReq: "الحقل لا يمكن ان يكون فارغ",
    Facebook: "فيسبوك",
    ForMember: "للعضو",
    FoodRevenue: "أرباح الطعام",
    ForThisMember: "لهذا العضو",
    FreezingDays: "تجميد الأيام",
    FromClass: "من فصل",
    GeneralSetting: "إعدادات عامة",
    GymType: "نوع الجيم",
    GymInfo: "معلومات الجيم",
    General: "عامة",
    Gender: "النوع",
    Gym: "الجيم",
    Guests: "الضيوف",
    Guest: "ضيف",
    GoToSettings: "إذهب للإعدادات",
    GoSettingAddThem: "",//إذهب للإعدادات و قم بتعديلهم
    GeneralInfo: "معلومات عامة",
    GTStart: "أكبر من بداية",
    GymExpenses: "مصروفات الجيم",
    Host: "هوست",
    History: "السجل",
    Histories: "السجلات",
    HelpCenterImgHint: "ارفع صور المشكلة او الإقتراح",
    HelpCenter: "مركز المساعدة",
    HeInvite: "قام بدعوة",
    Hostman: "موظف الإستقبال",
    HeightCM: "الطول (سنتيمتر)",
    Height: "الطول",
    January: "يناير",
    June: "يونيو",
    July: "يوليو",
    IPAddress: "رقم الآي بي",
    IDNumber: "الرقم المميز",
    Image: "الصورة",
    ItemName: "إسم المنتج بالإنجليزية",
    ItemArName: "إسم المنتج بالعربية",
    Item: "المنتج",
    Invitation: "الدعوة",
    InvitationNumber: "رقم الدعوة",
    Instagram: "الإنستجرام",
    ItemAtLeast: "منتج واحد علي الأقل",
    ItemRefundedSuccess: "تم إسترجاع المنتجات بنجاح",
    Items: "المنتجات",
    Job: "الوظيفة",
    FromTo: "من/إلي",
    GuestInformation: "معلومات الضيف / الضيوف",
    InvalidFormat: "قيمة غير صحيحة",
    FemaleWorkingHours: "ساعات عمل السيدات",
}

export default words