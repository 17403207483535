import { NavLink } from "react-router-dom"

const EmployeeRow = ({employee ,editInvoker ,deleteInvoker}) => {
    return (
        <tr key={Math.random()}>
            <td>
                <NavLink exact to={ `/employees/${employee?.id}` }>
                    { employee?.name }
                </NavLink>
            </td>
            <td> { employee?.gender } </td>
            <td> { employee?.id_number } </td>
            <td> { employee?.phone } </td>
            <td> { employee?.position } </td>
            <td> { employee?.salary } </td>
            <td className="actions">
                <button onClick={() => editInvoker(employee?.id)}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={deleteInvoker}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default EmployeeRow