import { useContext } from "react"
import AppContext from "../components/partials/AppContext"

const WeekDays = () => {
    const {translate} = useContext(AppContext)

    return [
        {key: 'Sat' ,value: translate('Saturday')},
        {key: 'Sun' ,value: translate('Sunday')},
        {key: 'Mon' ,value: translate('Monday')},
        {key: 'Tue' ,value: translate('Tuesday')},
        {key: 'Wed' ,value: translate('Wednesday')},
        {key: 'Thu' ,value: translate('Thursday')},
        {key: 'Fri' ,value: translate('Friday')},
    ]
}

export default WeekDays