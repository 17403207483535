import { useAlert } from 'react-alert'
import { useContext, useState } from "react"
import axios from "../../../Axios"
import Config from "../../../Config"
import AppContext from "../../partials/AppContext"
import DangerousAlert from "../../Shared/DangerousAlert"

const DeleteAccount = ({itemId ,itemName ,cancel ,reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)

    const confirmDelete = () => {
        setDisabled(true)
        axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/gym-accounts/${itemId}` ,{_method: 'delete'})
        .then(response => {
            setDisabled(false)
            const message = response.data?.message
            alert.success(`${message ? message : translate('AccoDelSuccess')}`)
            cancel()
            reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,response = error.response.data
            cancel()
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading=""
                message={`${translate('surePermanentlyDelete')} "${itemName}"? ${translate('cantUndoAction')}`}
                cancel={cancel}
                confirm={confirmDelete}
                confirmLabel={translate('YesDelete')}
            />
        </div>
    )
}

export default DeleteAccount