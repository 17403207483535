import { useContext } from "react"
import ModalButtons from "../../../../FormShared/ModalButtons"
import AppContext from "../../../../partials/AppContext"
import Table from "../../../../Shared/Table"
import Thead from "../../../../Shared/Thead"
import './Preview.css'

const Preview = ({
    subscription,
    sessions,
    coaches,
    clickPrevious,
    disabled,
    addMember
}) => {
    const {translate} = useContext(AppContext)
    let total = subscription?.price
    
    return (
        <form onSubmit={e => e.preventDefault()}>
            <div className="section">
                <Table rootClassName="full-width">
                    <Thead headers={[
                        {value: translate('Subscription') ,className: 'data-header no-border'},
                        {value: translate('Cost') ,className: 'right-text data-header no-border'}
                    ]}/>
                    <tbody>
                        <tr>
                            <td className="no-border"> {subscription?.title} </td>
                            <td className='right-text no-border'> {subscription?.price} </td>
                        </tr>
                    </tbody>
                </Table>
                {
                    sessions?.length === 0 ?
                    ''
                    :
                    <Table rootClassName="full-width">
                        <Thead headers={[
                            {value: translate('Session') ,width:"50%" ,className: 'data-header no-border'},
                            {value: translate('Qty') ,className: 'right-text data-header no-border'},
                            {value: translate('Cost') ,className: 'right-text data-header no-border'}
                        ]}/>
                        <tbody>
                            {
                                sessions?.map(session => {
                                    total += session?.times * session?.price
                                    return (
                                        <tr key={Math.random()}>
                                            <td width="50%" className="no-border"> {session?.name} </td>
                                            <td width="25%" className='right-text no-border'> {session?.times} </td>
                                            <td width="25%" className='right-text no-border'> {translate("EGP")} {session?.times * session?.price} </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                }
                {
                    coaches?.length === 0 ?
                    ''
                    :
                    <Table rootClassName="full-width">
                        <Thead headers={[
                            {value: translate('PrivateCoach') ,width:"50%" ,className: 'data-header no-border'},
                            {value: translate('Qty') ,className: 'data-header right-text no-border'},
                            {value: translate('Cost') ,className: 'data-header right-text no-border'}
                        ]}/>
                        <tbody>
                            {
                                coaches?.map(coach => {
                                    total += coach?.times * coach?.price
                                    return (
                                        <tr key={Math.random()}>
                                            <td width="50%" className="no-border"> {coach?.title} </td>
                                            <td width="25%" className='right-text no-border'> {coach?.times} </td>
                                            <td width="25%" className='right-text no-border'> {translate("EGP")} {coach?.times * coach?.price} </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                }
                <Table rootClassName="full-width">
                    <Thead headers={[
                        {value: translate('Total') ,className: 'no-border'},
                        {value: `${translate("EGP")} ${total}` ,className: 'right-text no-border'}
                    ]}/>
                </Table>
            </div>
            <ModalButtons
                cancel={clickPrevious}
                confirm={addMember}
                confirmLabel={translate("AddMember")}
                cancelLabel={translate("Previous")}
                disabled={disabled}
            />
        </form>
    )
}

export default Preview