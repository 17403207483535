import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import Axios from "../../../../Axios"
import AppContext from "../../../partials/AppContext"
import ChartComp from "../../../Shared/ChartComp"
import './Chart.css'
import BranchStatsCounter from "../../../Shared/BranchStatsCounter"
import StatCounter from "../../../Shared/StatCounter"

const Index = ({visible ,loadCounters = false}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate ,systemLang} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/safe`
    const [currentFilter ,setCurrentFilter] = useState('Week')
    const [data ,setData] = useState({})
    const [counters ,setCounters] = useState({})
    const [stats ,setStats] = useState({
        gym_expenses: [], gym_expenses_labels: [], gym_expenses_counters: data?.week?.counters?.gym_expenses,
        salaries: [], salaries_labels: [], salaries_counters: data?.week?.counters?.salaries,
        stock: [], stock_labels: [], stock_counters: data?.week?.counters?.stock,
        equipments: [], equipments_labels: [], equipments_counters: data?.week?.counters?.equipments,
    })

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/expenses-stats` ,{cancelToken: axiosSource.token})
        .then(response => {
            setData(response?.data?.data)
            setCounters(response?.data?.data?.branches)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,entityUrl ,alert ,clearUserData])

    const refreshCounters = () => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/expenses-branches?filter=${currentFilter}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setCounters(response?.data?.data)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        switch(currentFilter) {
            case "Week":
                if (loadCounters) refreshCounters()
                setStats({
                    gym_expenses: data?.week?.charts?.gym_expenses,
                    gym_expenses_labels: data?.week?.charts?.labels,
                    gym_expenses_counters: data?.week?.counters?.gym_expenses,
                    
                    salaries: data?.week?.charts?.salaries,
                    salaries_labels: data?.week?.charts?.labels,
                    salaries_counters: data?.week?.counters?.salaries,
                    
                    stock: data?.week?.charts?.stock,
                    stock_labels: data?.week?.charts?.labels,
                    stock_counters: data?.week?.counters?.stock,
                    
                    equipments: data?.week?.charts?.equipments,
                    equipments_labels: data?.week?.charts?.labels,
                    equipments_counters:data?.week?.counters?.equipments
                })
                break
            case "Month":
                if (loadCounters) refreshCounters()
                setStats({
                    gym_expenses: data?.month?.charts?.gym_expenses,
                    gym_expenses_labels: data?.month?.charts?.labels,
                    gym_expenses_counters: data?.month?.counters?.gym_expenses,
                    
                    salaries: data?.month?.charts?.salaries,
                    salaries_labels: data?.month?.charts?.labels,
                    salaries_counters: data?.month?.counters?.salaries,
                    
                    stock: data?.month?.charts?.stock,
                    stock_labels: data?.month?.charts?.labels,
                    stock_counters: data?.month?.counters?.stock,
                    
                    equipments: data?.month?.charts?.equipments,
                    equipments_labels: data?.month?.charts?.labels,
                    equipments_counters:data?.month?.counters?.equipments
                })
                break
            case "Year":
                if (loadCounters) refreshCounters()
                setStats({
                    gym_expenses: data?.year?.charts?.gym_expenses,
                    gym_expenses_labels: data?.year?.charts?.labels,
                    gym_expenses_counters: data?.year?.counters?.gym_expenses,
                    
                    salaries: data?.year?.charts?.salaries,
                    salaries_labels: data?.year?.charts?.labels,
                    salaries_counters: data?.year?.counters?.salaries,
                    
                    stock: data?.year?.charts?.stock,
                    stock_labels: data?.year?.charts?.labels,
                    stock_counters: data?.year?.counters?.stock,
                    
                    equipments: data?.year?.charts?.equipments,
                    equipments_labels: data?.year?.charts?.labels,
                    equipments_counters:data?.year?.counters?.equipments
                })
                break
            default:
                console.log('Unknow filter')
                break
        }
    } ,[data ,currentFilter ,loadCounters])

    const statCounters = [
        {
            style: {borderTopColor: data?.gym_expenses_color} ,title: translate("GymExpenses"),
            subTitle: translate(currentFilter + "Overview"),
            stat: Config.readableStats(stats?.gym_expenses_counters ,systemLang)
        },
        {
            style: {borderTopColor: data?.salaries_color} ,title: translate("Salaries"),
            subTitle: translate(currentFilter + "Overview"),
            stat: Config.readableStats(stats?.salaries_counters ,systemLang)
        },
        {
            style: {borderTopColor: data?.stock_color} ,title: translate("StockExpensens"),
            subTitle: translate(currentFilter + "Overview"),
            stat: Config.readableStats(stats?.stock_counters ,systemLang)
        },
        {
            style: {borderTopColor: data?.equipments_color} ,title: translate("EquipmentExpenses"),
            subTitle: translate(currentFilter + "Overview"),
            stat: Config.readableStats(stats?.equipments_counters ,systemLang)
        },
    ]

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            {translate("LastUpdateTime")} : {Config.getYesterday(systemLang)}
            <div className="page-section content">
                <div className="stats-counters">
                    {
                        statCounters.map((counter ,index) => 
                            <StatCounter key={index}
                                rootStyle={counter.style}
                                title={counter.title}
                                subTitle={counter.subTitle}
                                statValue={counter.stat}/>
                        )
                    }
                </div>
                <div className="expenses-stats-filters">
                    <button
                        onClick={() => setCurrentFilter("Week")}
                        className={currentFilter === 'Week' ? "active" : ''}>
                        {translate("Week")}
                    </button>
                    <button
                        onClick={() => setCurrentFilter("Month")}
                        className={currentFilter === 'Month' ? "active" : ''}>
                        {translate("Month")}
                    </button>
                    <button
                        onClick={() => setCurrentFilter("Year")}
                        className={currentFilter === 'Year' ? "active" : ''}>
                        {translate("Year")}
                    </button>
                </div>
                <div className="chart-parent mt-4">
                    <div className="chart-child">
                        <h4> {translate("GymExpenses")} </h4>
                        <ChartComp
                            label={translate("GymExpenses")}
                            color={data?.gym_expenses_color}
                            data={stats?.gym_expenses}
                            labels={stats?.gym_expenses_labels}/>
                    </div>
                    <div className="chart-child">
                        <h4> {translate("Salaries")} </h4>
                        <ChartComp
                            label={translate("Salaries")}
                             color={data?.salaries_color}
                             data={stats?.salaries}
                             labels={stats?.salaries_labels}/>
                    </div>
                </div>
                <div className="chart-parent">
                    <div className="chart-child">
                        <h4> {translate("StockExpensens")} </h4>
                        <ChartComp
                            label={translate("StockExpensens")}
                             color={data?.stock_color}
                             data={stats?.stock}
                             labels={stats?.stock_labels}/>
                    </div>
                    <div className="chart-child">
                        <h4> {translate("EquipmentExpenses")} </h4>
                        <ChartComp
                            label={translate("EquipmentExpenses")}
                             color={data?.equipments_color}
                             data={stats?.equipments}
                             labels={stats?.equipments_labels}/>
                    </div>
                </div>
            </div>

            {
                loadCounters ?
                <div className="branch-counters-container">
                    {
                        counters?.map?.(branch => <BranchStatsCounter data={branch}/>)
                    }
                </div>
                : null
            }
        </div>
    )
}

export default Index