const words = {
    Role: "الوظيفة",
    RoleName: "إسم الوظيفة",
    RolePermissions: "صلاحيات الوظيفة",
    Roles: "الوظائف",
    RowDeleted: "تم حذف الصف بنجاح",
    Receipt: "الإيصال",
    RefillSuccessfully: "تم إعادة التعبئة بنجاح",
    RefillDrink: "إعادة التعبئة للمشروبات",
    RefillFood: "إعادة التعبئة للاطعمة",
    RefillSupplement: "إعادة التعبئة المكملات",
    RequiredField: "خانة مطلوبة",
    RenewalMember: "تجديد للعضو",
    RefundedSuccess: "تم الإسترجاع بنجاح",
    RefundOrder: "إسترجاع الطلب",
    RefundPartial: "إسترجاع جزئي",
    RefundAll: "إسترجاع كلي",
    RefundOrderItems: "إسترجاع منتجات الطلب",
    RefundDelete: "إسترجع/حذف",
    RegistrationDate: "تاريخ التسجيل",
    StoredSuccessfully: "تم الإنشاء بنجاح",
    SurePermanentlyDelete: "هل أنت متأكد أنك تريد حذف هذا بشكل دائم",
    Subscriptions: "الإشتراكات",
    Sessions: "الجلسات",
    SaveChanges: "حفظ التغييرات",
    Supplement: "المكملات",
    StartNo: "رقم البداية",
    Settings: "الإعدادات",
    Saturday: "السبت",
    Sat: "السبت",
    Sunday: "الأحد",
    Sun: "الأحد",
    SelectDay: "إختر يوم",
    Salesman: "موظف مبيعات",
    SelectEmpFirst: "من فضلك إختر موظف أولا",
    SalaryEgp: "الراتب (بالجنيه)",
    September: "سبتمبر",
    Salary: "الراتب",
    Status: "الحالة",
    Suggestion: "إقتراح",
    SendUs: "أرسل لنا",
    Search: "إبحث",
    SuccessAlert: "تنبيه عملية ناجحة",
    Safe: "الخزنة",
    Stock: "المخزون",
    Supplements: "المكملات",
    Size: "الحجم",
    SellingDetails: "تفاصيل البيع",
    SellingPrice: "سعر البيع",
    SurePermanentlyDeleteGuest: "هل أنت متأكد أنك تريد حذف هذا الضيف بشكل دائم",
    Subscription: "الإشتراكات",
    SearchFullMemberID: "إبحث برقم العضو كاملا",
    SurePermanentlyCancelBooking: "هل أنت متأكد أنك تريد إلغاء هذا الحجز بشكل دائم ",
    SubscDaysLeft: "الأيام المتبقية لنهاية الإشتراك",
    SureToTakeLockerKey: "تأكد من انك أخذت مفتاح الخزانة",
    SalesmanFirst: "إختر موظف مبيعات أولا",
    Session: "جلسات",
    SubscInfo: "معلومات الإشتراك",
    StartDateReq: "تاريخ البداية مطلوب",
    SureToUnfreeze: "هل أنت متأكد أنك تريد إلغاء تجميد هذا العضو",
    StartDate: "تاريخ البداية",
    SureMakeLeftoverOrdersPaid: "هل انت متأكد من انك تريد جعل الطلبات الآجلة مدفوعة",
    Security: "الأمان",
    SocialMedia: "شبكات التواصل الإجتماعي",
    SelectOrderFirst: "إختر الطلب أولا",
    SelectedOrderPaid: "تم دفع الطلبات المختارة بنجاح",
    SupplementRevenue: "أرباح المكملات",
    Statistics: "إحصائيات",
    Starts: "يبدأ",
    Subject: "عنوان الرسالة",
    SubscriptionStatus: "حالة الإشتراك",
    Show: "إعرض",
    Salaries: "الرواتب",
    SubCategory: "الصنف الفرعي",
    StockExpensens: "مصروفات المخزون",
    Reason: "السبب",
    SafeBalance: "رصيد الخزانة",
    RevenueStatistics: "إحصائية الأرباح",
    SelectTime: "إختار الوقت",
    SoldQty: "الكمية المباعة",
    RedirectionInSeconds: "جاري تحويلك في غضون ثوان",
    Skip: "تخطي",
}

export default words