import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import ModalHeader from "../../../FormShared/ModalHeader"
import ModalButtons from "../../../FormShared/ModalButtons"
import Axios from "../../../../Axios"
import Loading from "../../../Shared/Loading"
import FoodInput from "./FoodInput"

const Edit = ({cancel ,reloadData ,id}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/stock/foods`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const [foodData ,setFoodData] = useState({
        name: '' ,ar_name: '' ,calories: '' ,description: '' ,description_ar: '' ,category: {name: translate('Category') ,id: ''},
        newCategory: {name_en: '' ,name_ar: ''}, quantity: '' ,purchase_price: '' ,selling_price: '' ,low_quantity: ''
    })
    const [errors ,setErrors] = useState({
        name: '' ,ar_name: '' ,calories: '' ,description: '' ,description_ar: '' ,category_id: '' ,name_en: '' ,name_ar: '' ,
        quantity: '' ,purchase_price: '' ,selling_price: '' ,low_quantity : ''
    })
    const [loaded ,setLoaded] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        const data = {
            _method: 'PUT',
            name: foodData?.name,
            ar_name: foodData?.ar_name,
            calories: foodData?.calories,
            description: foodData?.description,
            description_ar: foodData?.description_ar,
            category_id: foodData?.category?.id,
            quantity: foodData?.quantity,
            purchase_price: foodData?.purchase_price,
            selling_price: foodData?.selling_price,
            low_quantity: foodData?.low_quantity
        }
        if (data.category_id === '-1') {
            delete data.category_id
            data['name_en'] = foodData?.newCategory?.name_en
            data['name_ar'] = foodData?.newCategory?.name_ar
        }
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/${id}` ,data)
        .then(response => {
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
            setDisabled(false)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            setDisabled(false)
        })
    }

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/edit/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setLoaded(true)
            setFoodData(response?.data?.data)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[gymToken ,clearUserData ,entityUrl ,id ,setFoodData ,alert ,setLoaded])

    return (    
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditFood")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                                <div className="section">
                                    <FoodInput
                                        foodData={foodData}
                                        setFoodData={setFoodData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        readOnly={true}
                                        qntReadOnly={true}
                                    />
                                </div>
                            : <Loading/>
                        }
                        <ModalButtons
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("EditFood")}
                            disabled={disabled}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Edit