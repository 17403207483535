import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import axios from "../../../../Axios"

import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import Input from "../../../FormShared/Input"
import FieldError from "../../../FormShared/FieldError"
import AppContext from "../../../partials/AppContext"
import DateInput from "../../../FormShared/DateInput"

const styles = {
    messageDiv: {bottom: -20},
    message: {
        color: '#807F7F',
        fontSize: 11
    }
}

const Offer = ({cancel ,reloadData ,offerId ,subscriptionPrice ,subscription_id ,offer}) => {
    const {gymToken ,clearUserData ,translate} = useContext(AppContext)
    const alert = useAlert()

    const [offerValue ,setOffer] = useState({
        subscription_id: subscription_id,
        offer_title: offer?.title,
        offer_title_ar: offer?.title_ar,
        offer_start: offer?.start,
        offer_end: offer?.end,
        offer_quantity: offer?.quantity,
        discount_percentage: offer?.discount,
        price_after_discount: subscriptionPrice - (offer?.discount ? subscriptionPrice * offer?.discount / 100 : 0)
    })

    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/subscriptions/addOffer${offerId ? `/${offerId}` : ''}`
    const [errors ,setErrors] = useState({
        offer_title: '',
        offer_title_ar: '',
        offer_start: '',
        offer_end: '',
        offer_quantity: '',
        discount_percentage: '',
        price_after_discount: '',
    })
    const [disabled ,setDisabled] = useState(false)

    const changeDiscount = (e) => {
        const v = e.target.value
        if (v < 0 || v > 100) {
            alert.error(translate('DiscountValidPercentage'))
            return
        }
        setOffer({...offer ,discount_percentage: v ,price_after_discount: subscriptionPrice - (subscriptionPrice * v / 100)})
        setErrors({...errors ,discount_percentage : '' ,price_after_discount: ''})
    }

    const changeAttr = (attr ,value) => {
        const _errors = {...errors}
        _errors[attr] = ''
        setErrors(_errors)
        const _offer = {...offer}
        _offer[attr] = value
        setOffer(_offer)
    }

    const submitOfferData = () => {
        const data = {...offer ,status: 1}
        if (offerId) data._method = 'PUT'
        setDisabled(true)
        axios
        .setAuthToken(gymToken)
        .post(entityUrl ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${translate("Offer")} ${translate(`${offerId ? 'updated' : 'created'}Successfully`)}`)
            cancel()
            reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate(`${offerId ? 'Update' : 'Add'}Offer`)} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div>
                            <div className="section pb-1">
                                <div className="row flex-gap-10">
                                    <Input placeholder={translate("Title")} value={offerValue.offer_title}
                                        onChange={e => changeAttr('offer_title' ,e.target.value)}/>
                                    {errors?.offer_title ? <FieldError message={errors?.offer_title}/> : ''}
                                </div>
                                <div className="row flex-gap-10">
                                    <Input placeholder={translate("ArabicTitle")} value={offerValue.offer_title_ar}
                                        onChange={e => changeAttr('offer_title_ar' ,e.target.value)}/>
                                    {errors?.offer_title_ar ? <FieldError message={errors?.offer_title_ar}/> : ''}
                                </div>
                                <div className="row flex-gap-10">
                                    <DateInput placeholder={translate("StartDate")}
                                        type="date"
                                        value={offerValue.offer_start}
                                        onChange={e => changeAttr('offer_start' ,e.target.value)}/>
                                    {errors?.offer_start ? <FieldError message={errors?.offer_start}/> : ''}
                                </div>
                                <div className="row flex-gap-10">
                                    <DateInput placeholder={translate("EndDate")}
                                        type="date"
                                        value={offerValue.offer_end}
                                        onChange={e => changeAttr('offer_end' ,e.target.value)}/>
                                    {errors?.offer_end ? <FieldError message={errors?.offer_end}/> : ''}
                                </div>
                                <div className="row flex-gap-10 rel-position">
                                    <Input type="number" placeholder={translate("OfferLimit")} value={offerValue.offer_quantity}
                                        onChange={e => changeAttr('offer_quantity' ,e.target.value)}/>
                                    <div className="messages abs-position" style={styles.messageDiv}>
                                        <p className="message" style={styles.message}>
                                            {translate("OfferLimitedNumber")}
                                        </p>
                                    </div>
                                    {errors?.offer_quantity ? <FieldError message={errors?.offer_quantity}/> : ''}
                                </div>
                                <div className="row flex-gap-10">
                                    <Input type="number" placeholder={translate("DiscountPercentage")} value={offerValue.discount_percentage}
                                        onChange={changeDiscount}/>
                                    {errors?.discount_percentage ? <FieldError message={errors?.discount_percentage}/> : ''}
                                </div>
                                <div className="row flex-gap-10">
                                    <Input placeholder={translate("PriceAfterDiscount")} value={offerValue.price_after_discount} readOnly={true}/>
                                    {errors?.price_after_discount ? <FieldError message={errors?.price_after_discount}/> : ''}
                                </div>
                            </div>
                        </div>
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={submitOfferData}
                            confirmLabel={translate(`${offerId ? 'Update' : 'Add'}Offer`)} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Offer