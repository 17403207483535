import { useContext, useState } from "react"
import AppContext from "../../partials/AppContext"
import Navbar from "../../partials/Navbar"
import CrudIndexDS from "../../Shared/CrudIndexDS"
import Clients from "./Clients"
import Bar from "./Bar"
import Statistics from './Statistics/Index'

const Index = () => {
    const {translate} = useContext(AppContext)
    const [showSection ,setShowSection] = useState('statistics')

    const [clients ,setClients] = useState({...CrudIndexDS})
    const [bar ,setBar] = useState({...CrudIndexDS})

    const ClientsIndex = <Clients
        data={clients}
        setData={setClients}
        visible={showSection === 'clients'}
    />,
    BarIndex = <Bar
        data={bar}
        setData={setBar}
        visible={showSection === 'bar'}
    />,
    StatsIndex = <Statistics
        visible={showSection === 'statistics'}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Earnings")}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'statistics' ? 'active' : ''}
                    onClick={() => setShowSection('statistics')}>
                    {translate("Statistics")}
                </button>
                <button className={showSection === 'clients' ? 'active' : ''}
                    onClick={() => setShowSection('clients')}>
                    {translate("Clients")}
                </button>
                <button className={showSection === 'bar' ? 'active' : ''}
                    onClick={() => setShowSection('bar')}>
                    {translate("Bar")}
                </button>
            </div>
            <div className="page-content">
                {StatsIndex}
                {ClientsIndex}
                {BarIndex}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index