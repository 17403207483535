import Input from '../../FormShared/Input'
import FieldError from '../../FormShared/FieldError'
import DropDown from '../../FormShared/DropDown'
import { useContext } from 'react'
import AppContext from '../../partials/AppContext'

const styles = {
    salesManStyle: {display: 'flex' ,justifyContent: 'left' ,color: 'rgb(128, 127, 127)'}
}
const EmployeeInputs = ({
    employee,
    errors,
    updateEmployeeAttr,
    setGender,
    gender,
    genders,
    setPosition,
    position,
    positions,
    showSalesManInputs
}) => {
    const {translate} = useContext(AppContext)
    return (
        <>
            <div className="row mb-0">
                <div className="col-sm-12 pad0">
                    <h6>{translate('PersonalInfo')}</h6>
                </div>
            </div>
            <div className="row mb-0">
                <div className="col-sm-6 pad0 toggle-textfiled">
                    <div className="form-group">
                        <Input
                            placeholder={translate("Name")}
                            value={employee?.name}
                            onChange={(e) => updateEmployeeAttr(e.target.value ,'name')}
                        />
                    </div>
                    {errors?.name ? <FieldError message={errors?.name}/> : ''}
                </div>
                <div className="col-sm-6 pad0 toggle-textfiled">
                    <div className="form-group">
                        <Input
                            placeholder={translate("NationalID")}
                            value={employee?.national_id}
                            onChange={(e) => updateEmployeeAttr(e.target.value ,'national_id')}
                        />
                    </div>
                    {errors?.national_id ? <FieldError message={errors?.national_id}/> : ''}
                </div>
                <div className="col-sm-6 pad0 toggle-textfiled">
                    <div className="form-group">
                        <DropDown
                            selectedName={gender?.name ? gender?.name : translate('Gender')}
                            textColorBlack={gender?.value}
                            list={genders}
                            itemName="name"
                            select={setGender}
                        />
                    </div>
                    {errors?.gender ? <FieldError message={errors?.gender}/> : ''}
                </div>
                <div className="col-sm-6 pad0 toggle-textfiled">
                    <div className="form-group">
                        <Input
                            placeholder={translate("AgeYears")}
                            value={employee?.age}
                            onChange={(e) => updateEmployeeAttr(e.target.value ,'age')}
                        />
                    </div>
                    {errors?.age ? <FieldError message={errors?.age}/> : ''}
                </div>
                <div className="col-sm-6 pad0 toggle-textfiled">
                    <div className="form-group">
                        <Input
                            placeholder={translate("Phone")}
                            value={employee?.phone}
                            onChange={(e) => updateEmployeeAttr(e.target.value ,'phone')}
                        />
                    </div>
                    {errors?.phone ? <FieldError message={errors?.phone}/> : ''}
                </div>
                <div className="col-sm-6 pad0 toggle-textfiled">
                    <div className="form-group">
                        <Input
                            placeholder={`${translate("Email")} (${translate("Optional")})"`}
                            value={employee?.email}
                            onChange={(e) => updateEmployeeAttr(e.target.value ,'email')}
                        />
                    </div>
                    {errors?.email ? <FieldError message={errors?.email}/> : ''}
                </div>
            </div>
            

            <div className="row mb-0 mt-4">
                <div className="col-sm-12 pad0">
                    <h6>{ translate('ContractInfo') }</h6>
                </div>
            </div>
            <div className="row mb-0">
                <div className="col-sm-6 pad0 toggle-textfiled">
                    <div className="form-group">
                        <DropDown
                            selectedName={position?.name ? position?.name : translate('Position')}
                            textColorBlack={position?.value}
                            list={positions}
                            itemName="name"
                            select={setPosition}
                        />
                    </div>
                    {errors?.position ? <FieldError message={errors?.position}/> : ''}
                </div>
                <div className="col-sm-6 pad0 toggle-textfiled">
                    <div className="form-group">
                        <Input
                            placeholder={translate("SalaryEgp")}
                            value={employee?.salary}
                            onChange={(e) => updateEmployeeAttr(e.target.value ,'salary')}
                        />
                    </div>
                    {errors?.salary ? <FieldError message={errors?.salary}/> : ''}
                </div>
                {
                    showSalesManInputs ?
                    <>
                        <div style={styles.salesManStyle}>
                            {translate("EmployeeHintMsg")}
                        </div>
                        <div className='row col-md-12 pad0'>
                            <div className="col-sm-6 pad0 toggle-textfiled">
                                <div className="form-group">
                                    <Input
                                        placeholder={translate("Target")}
                                        value={employee?.target}
                                        onChange={(e) => updateEmployeeAttr(e.target.value ,'target')}
                                    />
                                </div>
                                {errors?.target ? <FieldError message={errors?.target}/> : ''}
                            </div>
                            <div className="col-sm-6 pad0 toggle-textfiled">
                                <div className="form-group">
                                    <Input
                                        placeholder={translate("CommissionEgp")}
                                        value={employee?.commission}
                                        onChange={(e) => updateEmployeeAttr(e.target.value ,'commission')}
                                    />
                                </div>
                                {errors?.commission ? <FieldError message={errors?.commission}/> : ''}
                            </div>
                        </div>
                    </>
                    : ''
                }
            </div>
        </>
    )
}

export default EmployeeInputs