import { useContext ,useEffect,useState } from "react"
import { useAlert } from "react-alert"
import AppContext from "../../partials/AppContext"
import ModalHeader from "../../FormShared/ModalHeader"
import ModalButtons from "../../FormShared/ModalButtons"
import Loading from "../../Shared/Loading"
import Axios from "../../../Axios"
import RecordInput from "./RecordInput"
import SafeTypes from "../../../constants/SafeTypes"
import SafeCashTypes from "../../../constants/SafeCashTypes"

const Edit = ({id ,cancel ,entityUrl ,reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const [loaded ,setLoaded] = useState(false)
    const [recordData ,setRecordData] = useState({ type: '', cash_type: '', reason: '', amount: '' })
    const [errors ,setErrors] = useState({ type: '', cash_type: '', reason: '', amount: '' })

    const [selectedType ,setSelectedType] = useState({name: translate('Type') ,value: ''})
    const [selectedCashType ,setSelectedCashType] = useState({name: translate('CashType') ,value: ''})

    const types = SafeTypes(true),
        cashTypes = SafeCashTypes()

    const saveChanges = () => {
        const data = {...recordData}
        data.type = selectedType?.value
        data.cash_type = selectedCashType?.value
        data._method = "PUT"
        
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/${id}` ,data)
        .then(response => {
            const responseData = response?.data?.data
            alert.success(`${responseData?.type} ,${responseData?.cash_type} ,${responseData?.amount} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
            setDisabled(false)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            setDisabled(false)
        })
    }

    const changeType = __type => {
        setErrors({...errors ,type: ''})
        setRecordData({...recordData ,type: __type?.value})
        setSelectedType({name: __type?.name ,value: __type?.value})
        // if (__type?.value === 'order' || __type?.value === 'member') {
        //     changeCashType({name: translate('CashIn') ,value: 'in'})
        // } else {
        //     changeCashType({name: translate('CashOut') ,value: 'out'})
        // }
    }

    const changeCashType = cashType => {
        setErrors({...errors ,cash_type: ''})
        setRecordData({...recordData ,cash_type: cashType?.value})
        setSelectedCashType({name: cashType?.name ,value: cashType?.value})
    }

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/${id}`)
        .then(response => {
            const responseData = response?.data?.data
            setRecordData(responseData)
            const __selectedType = types?.filter(t => t?.value === responseData?.type)
            setSelectedType(__selectedType[0] ?? {name: translate('Type') ,value: ''})

            const __selectedCashType = cashTypes?.filter(t => t?.value === responseData?.cash_type)
            setSelectedCashType(__selectedCashType[0] ?? {name: translate('CashType') ,value: ''})
            
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            setLoaded(false)
        })
        return axiosSource.cancel()
    } ,[alert ,clearUserData ,gymToken ,entityUrl ,id])

    return (    
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditRecord")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                            <RecordInput
                                recordData={recordData}
                                setRecordData={setRecordData}
                                errors={errors}
                                setErrors={setErrors}
                                changeCashType={changeCashType}
                                changeType={changeType}
                                types={types}
                                cashTypes={cashTypes}
                                selectedCashType={selectedCashType}
                                selectedType={selectedType}
                            />
                            : <Loading/>
                        }
                        <ModalButtons
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("EditRecord")}
                            disabled={disabled}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Edit