const Row = ({row ,showRow ,editRow ,deleteRow ,changeStatus}) => {
    return (
        <tr>
            <td>
                <span className="clickable" onClick={showRow}> #{ row?.id }</span>
            </td>
            <td> { row?.order_time } </td>
            <td> { row?.member } </td>
            <td> { row?.member_unique_id } </td>
            <td> { row?.barman } </td>
            <td> { row?.cost } </td>
            <td className="actions">
                <button onClick={changeStatus}>
                    <img alt="Gymme" src="/assets/svg/reciept.svg"/>
                </button>
                <button onClick={editRow}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={deleteRow}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default Row