import { useContext } from "react"
import AppContext from "../../../partials/AppContext"

const Row = ({row ,refundPartial}) => {
    const {translate} = useContext(AppContext)
    return (
        <tr>
            <td> #{row?.id} </td>
            <td> {row?.member} </td>
            <td> {row?.member_unique_id} </td>
            <td> {row?.barman} </td>
            <td> {`${row?.date_time}`} </td>
            <td> {row?.cost} </td>
            <td className="actions">
                {
                    row?.status !== 3 ?
                    <>
                        <button onClick={refundPartial} title={translate("RefundOrderItems")}>
                            <img alt="Gymme" src="/assets/svg/refund.svg"/>
                        </button>
                    </>
                    : translate('RefundDelete')
                }
            </td>
        </tr>
    )
}

export default Row