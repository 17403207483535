import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import MaintenanceInput from "./MaintenanceInput"

const Add = ({cancel ,reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/maintenance`
    const [disabled ,setDisabled] = useState(false)
    const [errors ,setErrors] = useState({image: ''})
    const [maintenanceData ,setMaintenanceData] = useState({image: undefined ,equip_id: undefined ,date: undefined ,price: undefined})
    const [equipments ,setEquipments] = useState([])
    const [selectedEquip ,setSelectedEquip] = useState({name: translate('Equipment') ,value: undefined})

    const changeAttr = (attrName ,attrValue) => {
        const tempErrors = {...errors} ,tempData = {...maintenanceData}
        tempErrors[attrName] = ''
        tempData[attrName] = attrValue
        setErrors(tempErrors)
        setMaintenanceData(tempData)
    }

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/all-equipments`)
        .then(response => setEquipments(response?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })

        return () => axiosSource.cancel()
    } ,[gymToken ,entityUrl ,setEquipments ,alert ,clearUserData])

    const saveChanges = () => {
        const formData = new FormData()
        Object.keys(maintenanceData).forEach(key => formData.append(key ,maintenanceData[key]))
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(entityUrl ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                setErrors(validation)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddMaintenance")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()} className="flex-col">
                        <MaintenanceInput
                            maintenanceData={maintenanceData}
                            changeAttr={changeAttr}
                            errors={errors}
                            setErrors={setErrors}
                            setSelectedEquip={setSelectedEquip}
                            selectedEquip={selectedEquip}
                            equipments={equipments}
                        />
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("AddMaintenance")} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Add