const words = {
    KG: "KG",
    LockersNo: "Lockers No.",
    Language: "Language",
    Lockers: "Lockers",
    LeavingTime: "Leaving Time",
    LastMaintenance: "LastMaintenance",
    LogOut: "Log Out",
    LowQntAlert: "Low quantity alert",
    Leave: "Leave",
    LeaveTime: "Leave Time",
    Locker: "Locker",
    LockerNumber: "Locker Number",
    Leaving: "Leaving",
    LogoFirst: "Select Logo First",
    LeftoverOrder: "Leftover Order",
    LeftoverOrders: "Leftover Orders",
    Leftover: "Leftover",
    Manage: "Manage",
    Male: "Male",
    Mixed: "Mixed",
    MainInfo: "main information",
    MaleLockers: "Male Lockers",
    Minutes: "minutes",
    Monday: "Monday",
    Mon: "Mon",
    Month: "Month",
    March: "March",
    May: "May",
    MaintenanceRecUpl: "Maintenance receipt uploaded here",
    MaintenanceDate: "Maintenance Date",
    Maintenance: "Maintenance",
    Main: "Main",
    More: "More",
    Members: "Members",
    Membership: "Membership",
    Member: "Member",
    MemberID: "Member ID",
    MemberFirst: "Select member first",
    MemberCheckoutConfirm: "Are you sure you want to checkout this member",
    MaxMemInGymReached: "you've reached the maximum number of members in the gym",
    Name: "Name",
    NoCancel: "No, Cancel",
    NoWorkHrsYet: "No working hours added yet",
    NationalID: "National ID",
    November: "November",
    NewEquipment: "New Equipment",
    NumberOnly: "Number Only",
    NoNotifications: "No Notifications Yet",
    NameEnglish: "Name English",
    NameArabic: "Name Arabic",
    NoClose: "No, Close",
    NewMember: "New Member",
    NewMembers: "New Members",
    Next: "Next",
    NewPassword: "New Password",
    NextBiling: "Your next billing date is",
    NoOfDays: "No Of Days",
    NoOfInvitations: "No. Of Invitations",
    NumberOfMembers: "Number Of Members",
    NoMemberForClass: "No member in this class",
    Message: "Message",
    LockerInfo: "Locker Information",
    MonthOverview: "Overview Of Last Month",
    NetRevenue: "Net Revenue",
    LastUpdateTime: "Last Update Time",
    Number: "Number",
    MapLocation: "Location On Map",
    Longitude: "Longitude",
    Latitude: "Latitude",
    MemberNotAttend: "Member not attend now",
    MaleWorkingHours: "Male Working Hours",
    MixWorkingHours: "Mix Working Hours",
    MainBoard: "Main Board",
    MapUrl: "Branch, Google Map Link",
}

export default words