import {GoogleMap as GoogleMapComponent, LoadScript, Marker} from '@react-google-maps/api'
import Config from "../../../Config"

const containerStyle = {
    width: "100%",
    height: "100%",
}

const GoogleMap = ({url}) => {
    const location = Config?.getLocation(url)
    const position = {lat: location.lat, lng: location.lng}

    return (
        <LoadScript googleMapsApiKey={Config.googleMapApiKey}>
            <GoogleMapComponent
                mapContainerStyle={containerStyle}
                center={position}
                zoom={location.zoom ? location.zoom : 17}>
                <Marker position={position}></Marker>
            </GoogleMapComponent>
        </LoadScript>
    )
}

export default GoogleMap