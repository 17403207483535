const Row = ({row ,deleteSalary ,editSalary}) => {
    return (
        <tr>
            <td> {row?.employee} </td>
            <td> {row?.job} </td>
            <td> {row?.date} </td>
            <td> {row?.salary} </td>
            <td> {row?.extra} </td>
            <td> {row?.overtime} </td>
            <td> {row?.deduction} </td>
            <td> {row?.total} </td>
            <td className="actions">
                <button onClick={editSalary}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={deleteSalary}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default Row