import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../Axios"
import Config from "../../../Config"
import Genders from "../../../constants/Genders"
import DropDown from "../../FormShared/DropDown"
import AppContext from "../../partials/AppContext"
import Pagination from "../../Shared/Pagination"
import PrintButton from "../../Shared/PrintButton"
import SearchBox from "../../Shared/SearchBox"
import Table from "../../Shared/Table"
import Thead from "../../Shared/Thead"
import StatCounter from "../../Shared/StatCounter"

const styles = {
    members: {borderTopColor: "#6DBE43" ,width: "25%"},
    earnings: {borderTopColor: "#F26F10" ,width: "25%"}
}

const Clients = ({data ,setData ,visible}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/safe/earnings`
    const clients = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword
    const [stats ,setStats] = useState({total_cost: 0 ,new_members: 0 ,loaded: undefined})
    const [filters ,setFilters] = useState({
        month: {name: translate('All') ,value: ''},
        year: {name: translate('All') ,value: ''},
        gender: {name: translate('All') ,value: ''}
    })
    const genders = Genders()

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const getQuery = () => {
        let query = `keyword=${keyword}`
        query += `&limit=${dataPerPage}`
        query += `&page=${currentPage}`
        query += `&month=${filters?.month?.value}`
        query += `&year=${filters?.year?.value}`
        query += `&gender=${filters?.gender?.value}`

        return query
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        const url = `${entityUrl}/clients?${getQuery()}`
        console.log(stats)
        Axios
        .setAuthToken(gymToken)
        .get(`${url}${!stats.loaded ? '&counters=true' : ''}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate("NewMember")}`,
                list: responseData,
                meta: meta
            })
            if (!stats.loaded) {
                setStats({loaded: true ,total_cost: response?.data?.total_cost ,new_members: response?.data?.new_members})
            }
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[alert ,gymToken ,clearUserData ,entityUrl ,dataPerPage ,currentPage ,filters])

    const printClick = () => {
        return Config.printByToken(`${entityUrl}/clients/print?${getQuery()}` ,true ,gymToken)
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <DropDown
                        placeholder={translate('Gender')}
                        selectedName={filters?.gender?.name}
                        textColorBlack={filters?.gender?.name !== translate('All')}
                        list={genders}
                        itemName="name"
                        select={gender => {
                            setFilters({...filters ,gender: {name: gender?.name ,value: gender?.value}})
                        }} />
                    <DropDown
                        placeholder={translate('Month')}
                        selectedName={filters?.month?.name}
                        textColorBlack={filters?.month?.name !== translate('All')}
                        list={Config.monthsNameValue.map(m => {return {...m ,name: translate(m.name)}})}
                        itemName="name"
                        select={month => {
                            setFilters({...filters ,month: {name: month?.name ,value: month?.value}})
                        }} />
                    <DropDown
                        placeholder={translate('Year')}
                        selectedName={filters?.year?.name}
                        textColorBlack={filters?.year?.name !== translate('All')}
                        list={Config.yearsNameValue().map(y => {return {...y ,name: translate(y.name)}})}
                        itemName="name"
                        select={year => {
                            setFilters({...filters ,year: {name: year?.name ,value: year?.value}})
                        }} />
                </div>
            </div>
            <div className="page-section content">
                <div className="stats-counters jus-con-center">
                    <StatCounter
                        rootStyle={styles.members}
                        title={translate("NewMembers")}
                        subTitle={translate("ThirtyDaysOverview")}
                        statValue={stats?.new_members}/>
                    <StatCounter
                        rootStyle={styles.earnings}
                        title={translate("TotalEarnings")}
                        subTitle={translate("ThirtyDaysOverview")}
                        statValue={stats?.total_cost}/>
                </div>
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('DateTime')},
                            {value: translate('Name')},
                            {value: translate('Gender')},
                            {value: translate('IDNumber')},
                            {value: translate('Phone')},
                            {value: translate('Subscription')},
                            {value: translate('Cost')}
                        ]}/>
                        <tbody>
                            {
                                clients?.map((row ,index) =>
                                    <tr key={index}>
                                        <td> {row.date} </td>
                                        <td> {row.name} </td>
                                        <td> {row.gender} </td>
                                        <td> {row.id_number} </td>
                                        <td> {row.phone} </td>
                                        <td> {row.subscription} </td>
                                        <td> {row.cost} </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                </div>
            </div>
        </div>
    )
}

export default Clients