import { useContext, useState } from 'react'
import { useAlert } from 'react-alert'
import Axios from '../../../../Axios'
import Config from '../../../../Config'
import AppContext from '../../../partials/AppContext'
import './styles.css'

const BannerAdSlider = ({cancel ,bannerAds}) => {
    const alert = useAlert()
    const {translate ,gymToken ,clearUserData} = useContext(AppContext)
    const [showIndex ,setShowIndex] = useState(0)
    const limit = bannerAds?.length
    const [disabled ,setDisabled] = useState(false)

    const skipAds = () => {
        const data = {ad_ids: []}

        bannerAds?.forEach?.(ad => data.ad_ids.push(ad.id))

        if (data.ad_ids.length > 0) {
            setDisabled(true)
            Axios
            .setAuthToken(gymToken)
            .post(`${Config.apiBaseUrl}/gym-dashboard-services/skip-banner-ads` ,data)
            .then(() => cancel())
            .catch(err => {
                setDisabled(false)
                const error = {...err}
                if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
                if (error?.response?.status === 401) clearUserData()
            })
        } else {
            cancel()
        }
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain banner-ads-modal">
                    <div className="section banner-ads-section">
                        {
                            bannerAds?.map?.((bannerAd ,index) => <div style={{display: index === showIndex ? "block" : "none"}}>
                                <img src={bannerAd?.photo} alt="Gymme"/>
                                <p> {bannerAd?.description} </p>
                            </div>)
                        }
                        <ul className='banner-ads-ul'>
                            {
                                bannerAds?.map?.((bannerAd ,index) =>
                                    <li className={`banner-ads-dot${index === showIndex ? 'active' : ''}`}
                                        onClick={() => setShowIndex(index)}
                                    ></li>
                                )
                            }
                        </ul>
                        <div className='banner-ads-actions'>
                            <button disabled={disabled} type="button" className="btn btn-light"
                                onClick={() => setShowIndex(showIndex < limit - 1 ? showIndex + 1 : 0)}>
                                {translate("Next")}
                            </button>
                            <button disabled={disabled} type="button" className="btn btn-default" onClick={skipAds}>
                                {translate("Skip")}
                            </button>
                            <button disabled={disabled} type="button" className="btn btn-default" onClick={cancel}>
                                {translate("Cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerAdSlider