import { useContext, useEffect, useState } from "react"
import {useAlert} from 'react-alert'
import axios from "../../../Axios"
import Config from '../../../Config'
import './form.css'
import AppContext from "../../partials/AppContext"

const RolePermittedEntities = ({itemPermissions ,cancel ,updateItemPermission}) => {
    // V.I.Hint : this only work with entities like this [{module : 'very important field' ,screen ,view ,add ,edit ,delete}]

    const alert = useAlert()
    const entitiesUrl = `${Config.apiBaseUrl}/gym-dashboard-services/roles/entities`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)

    const [entities ,setEntities] = useState([])

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(gymToken)
        .get(entitiesUrl ,{cancelToken: axiosSource.token})
        .then(response => setEntities(response.data.entities))
        .catch(err => {
            const error = {...err} ,response = error.response.data
            cancel()
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setEntities ,entitiesUrl ,alert])

    const isPermissionExists = (moduleName ,action) => {
        const filtered = itemPermissions ? itemPermissions.filter(perm => {
            return perm.module === moduleName && perm[action] !== 'Off'
        }) : []
        return filtered.length > 0
    }

    const injectPermission = (entity ,clickedAction) => {
        if (!itemPermissions) return
        const permIndex = itemPermissions.findIndex(perm => perm.module === entity.module)
        const permissions = itemPermissions
        let permission = {}
        if (permIndex === -1) {
            permission.module = entity.module
            permission.screen = entity.name
            permission.add = clickedAction === 'add' ? 'On' : 'Off'
            permission.delete = clickedAction === 'delete' ? 'On' : 'Off'
            permission.edit = clickedAction === 'edit' ? 'On' : 'Off'
            permission.view = clickedAction === 'view' ? 'On' : 'Off'
            permissions.push(permission)
        } else {
            permission = itemPermissions[permIndex]
            permission[clickedAction] = permission[clickedAction] === 'Off' ? 'On' : 'Off'
            permissions[permIndex] = permission
        }
        updateItemPermission(permissions)
    }

    return (
        <div className="row">
            {
                entities?.map(entity => {
                    return (
                        <div className="col-sm-4 pad0 box" key={Math.random()}>
                            <div className="input-label">
                                <label htmlFor="branch-adding"> {entity.name} </label>
                            </div>
                            {
                                Object.keys(entity.actions).map(action => {
                                    const permitted = isPermissionExists(entity.module ,action)
                                    return (
                                        <div className="element" key={Math.random()}>
                                            <span>{translate(action.toUpperCase())}</span>
                                            <div
                                                onClick={() => injectPermission(entity ,action)}
                                                className={`toggle ${permitted ? 'active' : ''}`}></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default RolePermittedEntities