import { useAlert } from "react-alert"
import { useContext, useState } from "react"
import AppContext from "../../../partials/AppContext"
import Axios from "../../../../Axios"
import DangerousAlert from "../../../Shared/DangerousAlert"

const Delete = ({entityUrl ,cancel ,loadData ,name}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)

    const confirmDelete = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(entityUrl ,{_method: 'delete'})
        .then(response => {
            setDisabled(false)
            alert.success(translate('GymExpenses') +' '+ translate('deletedSuccessfully'))
            cancel()
            loadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,response = error.response.data
            cancel()
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading=""
                message={`${translate('surePermanentlyDelete')} "${name}"? ${translate('cantUndoAction')}`}
                cancel={cancel}
                confirm={confirmDelete}
                confirmLabel={translate("YesDelete")}
            />
        </div>
    )
}

export default Delete