import axios from "axios"
import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"

const styles = {
    row: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justicfyContent: 'center',
        gap: 16
    },
    button: {
        width: '100%',
        height: 48,
        textAlign: 'center',
        borderRadius: 24
    },
    cancel: {
        border: 'none',
        color: '#191919'
    },
    leftover: {
        border: '1px solid #E03F3F',
        color: '#E03F3F'
    },
    pay: {
        background: '#E03F3F 0% 0% no-repeat padding-box',
        color: 'white'
    }
}

const ChangeStatusButtons = ({id ,cancel ,reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)

    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/orders`

    const changeStatusAction = action => {
        if (action !== 'pay' && action !== 'leftover') return
        const axiosSource = axios.CancelToken.source()
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/${action}/${id}` ,{_method: 'PUT' ,cancelToken: axiosSource.token})
        .then(response => {
            const order = response?.data?.data
            setDisabled(false)
            alert.success(`${translate("Order")} #${order?.id} ${translate("ForMember")} ${order?.member} ${translate(`ChangedTo${action}Successfully`)}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div style={styles.row}>
            <button
                disabled={disabled}
                style={{...styles.button ,...styles.cancel}}
                onClick={cancel}>
                {translate("Cancel")}
            </button>
            <button
                disabled={disabled}
                style={{...styles.button ,...styles.leftover}}
                onClick={() => changeStatusAction('leftover')}>
                {translate("Leftover")}
            </button>
            <button
                disabled={disabled}
                style={{...styles.button ,...styles.pay}}
                onClick={() => changeStatusAction('pay')}>
                {translate("Pay")}
            </button>
        </div>
    )
}

export default ChangeStatusButtons