import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../Axios"
import Config from "../../../Config"
import AppContext from "../../partials/AppContext"
import Navbar from "../../partials/Navbar"
import DataLine from "../../Shared/DataLine/DataLine"
import ChangeModal from "./ChangeModal"
import ChangePhoto from "./ChangePhoto"
import './gym-details.css'
import '../../../css-files/form.css'


const GymDetails = () => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [gym ,setGym] = useState({})
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/gym-settings`
    const [isModalOpen ,setIsModalOpen] = useState(false)
    const [editedField ,setEditedField] = useState('')
    const [editedValue ,setEditedValue] = useState('')
    const [modalHeader ,setModalHeader] = useState('')
    const [password ,setPassword] = useState('')
    const [passError ,setPassError] = useState('')
    const [error ,setError] = useState('')
    const [photo ,setPhoto] = useState({})
    const [isPhotoModal ,setIsPhotoModal] = useState(false)
    const [photoDisabled ,setPhotoDisabled] = useState(false)
    const [disabled ,setDisabled] = useState(false)

    const axiosSource = axios.CancelToken.source()
    const loadGym = () => {
        axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/getGymSettings` ,{cancelToken: axiosSource.token})
        .then(response => setGym(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadGym()
        return () => axiosSource.cancel()
    },[setGym ,clearUserData ,gymToken ,entityUrl])

    const openChangeModal = (fieldName) => {
        cancelModal()
        setIsModalOpen(true)
        setEditedField(fieldName)
        setEditedValue(gym[fieldName])
        setModalHeader(fieldName.split('_').join(' '))
        setError('')
    }

    const updateGym = () => {
        if (!editedValue) {
            setError(translate("FieldReq"))
            return
        }
        if (!password) {
            setPassError(translate("EnterPassToComplete"))
            return
        }
        const data = {password: password}
        data[editedField] = editedValue
        setDisabled(true)
        axios
        .setAuthToken(gymToken)
        .put(`${entityUrl}/updateGymSettings` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${translate("Gym")} ${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            cancelModal()
            loadGym()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.data?.errors) {
                if (error?.response?.data?.errors[editedField]) {
                    setError(error?.response?.data?.errors[editedField].join(" ,"))
                }
                if (error?.response?.data?.errors?.password) {
                    setPassError(error?.response?.data?.errors?.password)
                }
            }
        })
    }

    const cancelModal = () => {
        setIsModalOpen(false)
        setIsPhotoModal(false)
        setPassword('')
        setPassError('')
    }

    const updatePhoto = () => {
        if (!photo) {
            alert.error(translate('LogoFirst'))
            return
        }
        if (!password) {
            setPassError(translate("EnterPassToComplete"))
            return
        }
        const formData = new FormData()
        formData.set('logo' ,photo)
        formData.set('password' ,password)
        formData.set('_method' ,'PUT')
        setPhotoDisabled(true)
        axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/updateGymSettings` ,formData)
        .then(response => {
            setPhotoDisabled(false)
            alert.success(`${translate("Gym")} ${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            cancelModal()
            loadGym()
        })
        .catch(err => {
            setPhotoDisabled(false)
            const error = {...err}
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.data?.errors) {
                if (error?.response?.data?.errors?.logo) {
                    alert.error(error?.response?.data?.errors?.logo.join(" ,"))
                }
                if (error?.response?.data?.errors?.password) {
                    setPassError(error?.response?.data?.errors?.password)
                }
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
        })
    }

    const changePassword = e => {
        setPassError('')
        setPassword(e.target.value)
    }

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Settings")} />
            </div>
            <div className="page-content">
                <div className="page-section content profile-card-content">
                    <div className="details-page-content settings-page">
                        <div className="details-page-section">
                            <div className="details-img-container">
                                <div className="details-img">
                                    <img alt={gym?.name} id="gym-logo-img" src={gym?.logo} className="details-full-img"/>
                                </div>
                                <button id="cam-btn" className="details-cam-btn en">
                                    <img alt={gym?.name} src="/assets/svg/img-change.svg" onClick={() => {cancelModal(); setIsPhotoModal(true)}}/>
                                </button>
                            </div>
                            <div className="details-gym-info">
                                <div className="details-info-section">
                                    <span className="details-info-title">{translate("MainInfo")}</span>
                                    <div className="details-info-data">
                                        <DataLine
                                            title={translate("Name")}
                                            value={gym.name}
                                            changeClick={() => openChangeModal('name')}
                                        />
                                        <DataLine
                                            title={translate("Phone")}
                                            value={gym.phone}
                                            changeClick={() => openChangeModal('phone')}
                                        />
                                        <DataLine
                                            title={translate("Email")}
                                            value={gym.email}
                                            changeClick={() => openChangeModal('email')}
                                        />
                                        <DataLine
                                            title={translate("Website")}
                                            value={gym.website}
                                            changeClick={() => openChangeModal('website')}
                                        />
                                    </div>
                                </div>
                                <div className="details-info-section">
                                    <span className="details-info-title">{translate("PlanDetails")}</span>
                                    <div className="details-info-data">
                                        <DataLine title={gym?.subscription_name} disabled={true} btnLabel={translate("ChangePlan")} />
                                    </div>
                                    <div className="plan-end-date">
                                        {translate("NextBiling")} <span> { gym.subscription_end } </span>
                                    </div>
                                </div>
                                <div className="details-info-section">
                                    <span className="details-info-title">{translate("SocialMedia")}</span>
                                    <div className="details-info-data">
                                        <DataLine title={translate("Facebook")} value={gym.facebook_url} changeClick={() => openChangeModal('facebook_url')} />
                                        <DataLine title={translate("Twitter")} value={gym.twitter_url} changeClick={() => openChangeModal('twitter_url')} />
                                        <DataLine title={translate("Instagram")} value={gym.instagram_url} changeClick={() => openChangeModal('instagram_url')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isModalOpen ? 
                <ChangeModal 
                    disabled={disabled}
                    modalHeader={modalHeader}
                    value={editedValue}
                    changePass={changePassword}
                    password={password}
                    passError={passError}
                    changeValue={(e) => setEditedValue(e.target.value)}
                    error={error}
                    cancel={() => setIsModalOpen(false)}
                    confirm={updateGym}/>
                : ''
            }
            {
                isPhotoModal ?
                    <ChangePhoto
                        disabled={photoDisabled}
                        changePhoto={setPhoto}
                        cancel={cancelModal}
                        changePass={changePassword}
                        password={password}
                        passError={passError}
                        error=""
                        confirm={updatePhoto}
                    /> : ''
            }
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default GymDetails