import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../Axios"
import Config from "../../../../../../Config"
import AppContext from "../../../../../partials/AppContext"
import DangerousAlert from "../../../../../Shared/DangerousAlert"

const styles = {
    container: {
        display: 'flex' ,
        flexDirection: 'row' ,
        justifyContent: 'space-between' ,
        alignContent: 'center' ,
        alignItems: 'center',
        background: '#F8F8F8 0% 0% no-repeat padding-box',
        border: '1px dashed #DDDDDD',
        borderRadius: 30,
        opacity: 1,
        margin: 10,
        padding: 10,
        paddingRight: 36,
        paddingLeft: 16
    },
    payBtn: {
        color: 'rgb(224, 63, 63)'
    }
}

const UnpaidOrders = ({unpaidOrders ,memberId ,loadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/leftover/orders`
    const [disabled ,setDisabled] = useState(false)
    const [payClicked ,setPayClicked] = useState(undefined)

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/request-leftover/${memberId}` ,{_method: 'PUT' ,cancelToken: axiosSource.token})
        .then(response => console.log(response?.data?.message))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[alert ,clearUserData ,gymToken ,entityUrl ,memberId])

    const payLeftover = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/payAllLeftoverOrders/${memberId}` ,{_method: 'PUT'})
        .then(response => {
            alert.success(translate("OrdersPaidSuccess"))
            setDisabled(false)
            loadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <>
            <div style={styles.container}>
                <span> {unpaidOrders.orders} {translate(`Order${unpaidOrders?.orders?.length > 1 ? 's' : ''}NotPaid`)} </span>
                <span> {unpaidOrders.cost} {translate("EGP")}  </span>
                <button style={styles.payBtn}
                    onClick={() => setPayClicked(true)}
                    className="clickable"> {translate("PayLeftover")} </button>
            </div>
            {
                payClicked ?
                <div id="forms">
                    <DangerousAlert
                        disabled={disabled}
                        heading=""
                        message={`${translate("SureMakeLeftoverOrdersPaid")} ${unpaidOrders.cost} ${translate("EGP")}? ${translate("cantUndoAction")}`}
                        cancel={() => setPayClicked(false)}
                        confirm={payLeftover}
                        confirmLabel={translate("YesPay")}
                    />
                </div>
                : ''
            }
        </>
    )
}

export default UnpaidOrders