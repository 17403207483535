import { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import ModalHeader from '../../FormShared/ModalHeader'
import ModalButtons from '../../FormShared/ModalButtons'
import AppContext from '../../partials/AppContext'
import axios from '../../../Axios'
import Config from '../../../Config'
import Loading from '../../Shared/Loading'
import BranchInputs from './BranchInputs'
import PhotoInput from '../../FormShared/PhotoInput'

const EditBranch = ({id ,cancel ,loadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const api = `${Config.apiBaseUrl}/gym-dashboard-services/branches`
    const [branch ,setBranch] = useState({name: '' ,phone: '' ,address: '' ,name_ar: '' ,description_ar: '' ,description_en: '' ,defaultCover: '' ,map_url: ''})
    const [errors ,setErrors] = useState({name: '' ,phone: '' ,address: '' ,city_id: '' ,ips: '' ,name_ar: '' ,description_ar: '' ,description_en: '' ,map_url: ''})
    const [cities ,setCities] = useState([])
    const [selectedProvince ,setSelectedProvince] = useState({id: undefined ,name: translate('Province')})
    const [selectedCity ,setSelectedCity] = useState({id: undefined ,name: translate('City')})
    const [cameras ,setCameras] = useState([{ip: ''}])
    const [loaded ,setLoaded] = useState(false)
    const [disabled ,setDisabled] = useState(false)
    const [cover ,setCover] = useState(undefined)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(gymToken)
        .get(`${api}/edit/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const branch = response?.data?.branch,
                province = response?.data?.province,
                cityId = response?.data?.city_id,
                ips = response?.data?.ips
            setBranch({
                name: branch?.name,
                address: branch?.address,
                phone: branch?.phone,
                name_ar: branch?.name_ar,
                map_url: branch?.map_url,
                description_ar: branch?.description_ar,
                description_en: branch?.description_en,
                defaultCover: branch?.defaultCover
            })
            setSelectedProvince(province)
            setSelectedCity({id: cityId ,name: branch?.city})
            setCities(response?.data?.cities)
            setCameras(ips)
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[
        gymToken ,api ,setBranch ,setSelectedProvince ,setSelectedCity,
        setCities ,setCameras ,setLoaded ,alert ,clearUserData
    ])

    const editBranch = () => {
        const formData = new FormData()
        const data = {
            name: branch?.name ,phone: branch?.phone ,address : branch?.address ,
            name_ar: branch?.name_ar ,description_ar: branch?.description_ar ,description_en: branch?.description_en,
            city_id: selectedCity?.id, map_url: branch?.map_url
        }
        Object.keys(data).forEach(key => {
            console.log(key)
            formData.append(key ,data[key])
        })
        if (cover) formData.append('cover' ,cover)
        cameras.forEach((camera ,indx) => {
            if (camera?.ip) formData.append(`ips[${indx}]` ,camera?.ip)
        })
        formData.append('_method' ,'PUT')

        setDisabled(true)
        axios
        .setAuthToken(gymToken)
        .post(`${api}/${id}` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("createdSuccessfully")}`)
            loadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) {
                const paramErrors = error?.response?.data?.errors ,_errors = {}
                Object.keys(paramErrors).forEach(attrError => _errors[attrError] = paramErrors[attrError].join(" ,"))
                setErrors(_errors)
            }
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditBranch")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                            <>
                                <div className="section">
                                    <PhotoInput
                                        updatePhoto={(img) => {
                                            setErrors({...errors ,cover: ''})
                                            setCover(img)
                                        }}
                                        defaultImg={branch.defaultCover}
                                        isOptional={true}
                                        description={translate('BranchCoverMsg')}
                                        error={errors.cover} />
                                    <BranchInputs
                                        branch={branch}
                                        setBranch={setBranch}
                                        errors={errors}
                                        setErrors={setErrors}
                                        selectedCity={selectedCity}
                                        setSelectedCity={setSelectedCity}
                                        cameras={cameras}
                                        setCameras={setCameras}
                                        cities={cities}
                                        setCities={setCities}
                                        selectedProvince={selectedProvince}
                                        setSelectedProvince={setSelectedProvince}
                                        cover={cover}
                                        setCover={setCover}
                                    />
                                </div>
                                <ModalButtons disabled={disabled} cancel={cancel} confirm={() => editBranch()} confirmLabel={translate("EditBranch")} />

                            </>
                            : <Loading/>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditBranch