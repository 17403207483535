import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import Genders from "../../../../constants/Genders"
import SubscriptionStatus from "../../../../constants/SubscriptionStatus"
import DropDown from "../../../FormShared/DropDown"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import Add from "./Add"
import AddExtra from "./AddExtra"
import Delete from "./Delete"
import Edit from "./Edit"
import Row from "./Row"

const Index = ({setData, data, visible}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members`
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    const genders = Genders()
    const subscriptionStatus = SubscriptionStatus()
    const [chosenGender ,setChosenGender] = useState({name: translate('All') ,value: ''})
    const [chosenSubscription ,setChosenSubscription] = useState({name: translate('All') ,value: ''})
    const [chosenSubscriptionStatus ,setChosenSubscriptionStatus] = useState({name: translate('All') ,value: ''})
    const [subscriptions ,setSubscriptions] = useState([{name: translate('All') ,value: ''}])

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const [addClick ,setAddClick] = useState(undefined)
    const [editClick ,setEditClick] = useState(undefined)
    const [deleteClick ,setDeleteClick] = useState(undefined)
    const [extraClick ,setExtraClick] = useState(undefined)

    const cancel = () => {
        setAddClick(undefined)
        setEditClick(undefined)
        setDeleteClick(undefined)
        setExtraClick(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        let query = `keyword=${keyword}`
        query += `&limit=${dataPerPage}`
        query += `&page=${currentPage}`
        query += `&gender=${chosenGender.value}`
        query += `&subscription=${chosenSubscription.value}`
        query += `&subscription_status=${chosenSubscriptionStatus.value}`
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?${query}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate(`Member${responseData?.length > 1 ? 's' : ''}`)}`,
                list: responseData,
                meta: meta
            })
            executeScroll()

        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    } ,[
        alert ,gymToken ,clearUserData ,entityUrl ,
        chosenGender ,dataPerPage ,currentPage ,chosenSubscription ,
        chosenSubscriptionStatus
    ])

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/members/getAllSubscriptions` ,{cancelToken: axiosSource.token})
        .then(response => {
            const temp = response?.data && Array.isArray(response?.data) ? response?.data.map(subs => {
                return {
                    name: subs.title,
                    value: subs.title
                }
            }) : []
            setSubscriptions([{name: translate('All') ,value: ''} ,...temp])
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[gymToken ,alert ,clearUserData ,setSubscriptions])

    const printClick = () => {
        let query = `keyword=${keyword}`
        query += `&page=${currentPage}`
        query += `&gender=${chosenGender.value}`
        query += `&subscription=${chosenSubscription.value}`
        query += `&subscription_status=${chosenSubscriptionStatus.value}`
        return Config.printByToken(`${entityUrl}/print?${query}` ,true ,gymToken)
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    const reloadForDelete = () => {
        if (dataList.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <DropDown
                        placeholder={translate("Gender")}
                        selectedName={chosenGender.name}
                        textColorBlack={chosenGender.value !== ''}
                        list={genders}
                        itemName="name"
                        select={gender => setChosenGender(gender)}
                    />
                    <DropDown
                        placeholder={translate("Subscription")}
                        selectedName={chosenSubscription.name}
                        textColorBlack={chosenSubscription.value !== ''}
                        list={subscriptions}
                        itemName="name"
                        select={subs => setChosenSubscription(subs)}
                    />
                    <DropDown
                        placeholder={translate("SubscriptionStatus")}
                        selectedName={chosenSubscriptionStatus.name}
                        textColorBlack={chosenSubscriptionStatus.value !== ''}
                        list={subscriptionStatus}
                        itemName="name"
                        select={subs => setChosenSubscriptionStatus(subs)}
                    />
                    <button className="btn btn-light" onClick={() => {
                        cancel()
                        setAddClick(true)
                    }}> {translate("AddMember")} </button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Name')},
                            {value: translate('Gender')},
                            {value: translate('IDNumber')},
                            {value: translate('Phone')},
                            {value: translate('Paid')},
                            {value: translate('Actions') ,className: 'actions'}
                        ]}/>
                        <tbody>
                            { dataList?.map(row => <Row key={Math.random()}
                                member={row}
                                cancel={cancel}
                                editAction={e => setEditClick(e)}
                                deleteAction={e => setDeleteClick(e)}
                                extrAction={e => setExtraClick(e)}
                            />) }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                </div>
            </div>
            {deleteClick ? <Delete member={deleteClick} reloadData={reloadForDelete} cancel={cancel} /> : ''}
            {addClick ? <Add reloadData={reloadForAdd} cancel={cancel} /> : ''}
            {editClick ? <Edit id={editClick} reloadData={loadData} cancel={cancel} /> : ''}
            {extraClick ? <AddExtra id={extraClick} reloadData={loadData} cancel={cancel} /> : ''}
        </div>
    )
}

export default Index