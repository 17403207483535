const words = {
    DeletedSuccessfully: "deleted successfully",
    DELETE: "Delete",
    Drinks: "Drinks",
    Download: "Download",
    Delete: "Delete",
    December: "December",
    Date: "Date",
    Dashboard: "Dashboard",
    DeleteAlert: "Delete Alert",
    Drink: "Drink",
    Description: "Description",
    DeleteGuest: "Delete Guest",
    DeleteEmployee: "Delete Employee Account",
    DeactivateMember: "Deactivate member",
    DateTime: "Date & Time",
    DrinkRevenue: "Drink Revenue",
    Days: "Days",
    DiscountValidPercentage: "Discount most be a valid percentage",
    DiscountPercentage: "Discount Percentage",
    Day: "Day",
    Disabled: "Disabled",
    EditRole: "Edit Role",
    EDIT: "Edit",
    Email: "Email",
    Employee: "Employee",
    EmployeeID: "Employee ID",
    EMail: "E-Mail",
    EditAccount: "Edit Account",
    EnglishAbout: "English About",
    EditBranch: "Edit Branch",
    EmployeesNo: "Employees No.",
    English: "English",
    ExtraFiles: "Extra Files",
    Earnings: "Earnings",
    EmpName: "Employee Name",
    EditEmployee: "Edit Employee",
    Employees: "Employees",
    Equipment: "Equipment",
    EquipmentPowerOff: "Are you sure you want to power off this equipment",
    EquipmentPowerOn: "Are you sure you want to power on this equipment",
    EquipmentSwitchLater: "You can switch it on later from same button",
    EditEquipment: "Edit Equipment",
    Equipments: "Equipments",
    EGP: "EGP",
    EditMaintenance: "Edit Maintenance",
    Expenses: "Expenses",
    EditDrink: "Edit Drink",
    EditFood: "Edit Food",
    EditSupplement: "Edit Supplement",
    EndDateReq: "End date required",
    EndDate: "End Date",
    ExtraSessions: "extra sessions",
    ExtraPrivate: "extra private coach",
    EditMember: "Edit Member",
    EnterPassToComplete: "Enter your password to complete",
    EarningPerCategory: "Earning Per Category",
    ExtraDiscount: "Extra Discount",
    EditOffer: "Edit Offer",
    Edit: "Edit",
    EditSubscription: "Edit Subscription",
    EditSession: "Edit Session",
    End: "End",
    Ended: "Ended",
    EnglishLang: "English",
    EmployeeHintMsg: "Hint: commission (fixed amount per member) ,target (number of members)",
    Extra: "Extra",
    Deduction: "Deduction",
    EmployeeSalary: "Employee Salary",
    EditSalary: "Edit Salary",
    EditExpenses: "Edit Expenses",
    EquipmentExpenses: "Equipment Expenses",
    EditRecord: "Edit Record",
    EnglishEquipment: "Equipment in EN",
    EnglishTitle: "Title in EN",
    DescriptionEnglish: "Description in EN",
    DescriptionArabic: "Description in AR",
}

export default words