import Input from "../../FormShared/Input"
import { useContext, useState } from "react"
import axios from "../../../Axios"
import { useAlert } from "react-alert"
import AppContext from "../../partials/AppContext"
import Config from "../../../Config"
import FieldError from "../../FormShared/FieldError"
import ModalButtons from "../../FormShared/ModalButtons"
import ModalHeader from "../../FormShared/ModalHeader"

const ChangeLockersModal = ({attr ,cancel ,reloadData ,modalHeader ,start ,lockers}) => {
    const alert= useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const attribute = attr ,keys = {
        start: `${attribute === 'male' ? 'male_lockers_start_from' : 'female_lockers_start_from'}`,
        lockers: `${attribute === 'male' ? 'number_of_male_lockers' : 'number_of_female_lockers'}`
    }
    const [startValue ,setStart] = useState(start ? start : 0)
    const [lockersValue ,setLockers] = useState(lockers ? lockers : 0)
    const [pass ,setPass] = useState('')
    const [errors ,setErrors] = useState({start: '' ,lockers: ''})
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        const data = {password: pass ,_method: 'PUT'}
        if (attribute === 'male') {
            data.number_of_male_lockers = lockersValue
            data.male_lockers_start_from = startValue
        } else {
            data.number_of_female_lockers = lockersValue
            data.female_lockers_start_from = startValue
        }
        setDisabled(true)
        axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/branch-setting` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                const _errs = {...errors}
                if (validation[keys.start]) _errs.start = validation[keys.start]
                if (validation[keys.lockers]) _errs.start = validation[keys.lockers]
                setErrors(_errs)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader cancel={cancel} title={modalHeader}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <Input placeholder={translate("LockersNo")} value={lockersValue} onChange={e => setLockers(e.target.value)}/>
                            {errors.lockers ? <FieldError message={errors.lockers} /> : ''}
                            <Input placeholder={translate("StartNo")} value={startValue} onChange={e => setStart(e.target.value)}/>
                            {errors.start ? <FieldError message={errors.start} /> : ''}
                            <Input type="password" placeholder={translate("Password")} value={pass} onChange={e => setPass(e.target.value)}/>
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            disabled={disabled}
                            confirmLabel={translate("SaveChanges")}
                            confirm={saveChanges}/>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangeLockersModal