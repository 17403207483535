import { useContext, useState } from 'react'
import AppContext from '../../partials/AppContext'
import Navbar from '../../partials/Navbar'
import CrudIndexDS from '../../Shared/CrudIndexDS'
import Equipment from './Equipment/Index'
import Maintenance from './Maintenance/Index'

const Index = () => {
    const {translate} = useContext(AppContext)
    const [showSection ,setShowSection] = useState('equipments')

    const [equipments ,setEquipments] = useState({...CrudIndexDS})
    const [maintenanceData ,setMaintenance] = useState({...CrudIndexDS})

    const equipmentsComponent = <Equipment
        visible={showSection === 'equipments'}
        data={equipments}
        setData={setEquipments}
    />,
    maintenanceComponent = <Maintenance
        visible={showSection === 'maintenance'}
        data={maintenanceData}
        setData={setMaintenance}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Equipments")}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'equipments' ? 'active' : ''}
                    onClick={() => setShowSection('equipments')}>
                    {translate("Equipments")}
                </button>
                <button className={showSection === 'maintenance' ? 'active' : ''}
                    onClick={() => setShowSection('maintenance')}>
                    {translate("Maintenance")}
                </button>
            </div>
            <div className="page-content">
                {equipmentsComponent}
                {maintenanceComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index