import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import Add from "./Add"
import Delete from "./Delete"
import Edit from "./Edit"
import Refill from "./Refill"
import Row from "./Row"

const Index = ({visible ,data ,setData}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/stock/drinks`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword
    
    const [addAction ,setAddAction] = useState(undefined)
    const [editAction ,setEditAction] = useState(undefined)
    const [deleteAction ,setDeleteAction] = useState(undefined)
    const [refillAction ,setRefillAction] = useState(undefined)

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta ,
                drinkCountKey = `Drink${responseData?.length > 1 ? 's' : ''}`
            setData({...data,
                headerTitle: `${responseData?.length} ${translate(drinkCountKey)}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }
    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,alert ,dataPerPage ,currentPage ,clearUserData ,gymToken])

    const cancelModal = () => {
        setAddAction(undefined)
        setEditAction(undefined)
        setDeleteAction(undefined)
        setRefillAction(undefined)
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    const reloadForDelete = () => {
        if (dataList.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,gymToken)

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}/>
                    <button className="btn btn-light" onClick={() => {
                        cancelModal()
                        setAddAction(true)
                    }}>{translate("AddItem")}</button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={triggerSearch}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Item') ,width: '20%'},
                            {value: translate('Brand')},
                            {value: translate('UpdatedDate')},
                            {value: translate('Size')},
                            {value: translate('Category')},
                            {value: translate('AvailableQTY')},
                            {value: translate('SoldQty')},
                            {value: translate('SellingPrice')},
                            {value: translate('Actions') ,className: 'actions'}
                        ]}/>
                        <tbody>
                            {
                                dataList?.map(
                                    row => <Row key={Math.random()}
                                        row={row}
                                        editRow={() => {
                                            cancelModal()
                                            setEditAction(row?.id)
                                        }}
                                        deleteRow={() => {
                                            cancelModal()
                                            setDeleteAction({id: row?.id ,name: row?.name})
                                        }}
                                        refillRow={() => {
                                            cancelModal()
                                            setRefillAction({
                                                id: row?.id,
                                                price: parseFloat(row?.selling_price),
                                                purchase_price: parseFloat(row?.purchase_price)
                                            })
                                        }}
                                    />
                                )
                            }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n})}/>
                </div>
            </div>
            {deleteAction ? <Delete id={deleteAction?.id} name={deleteAction?.name} cancel={cancelModal} reloadData={reloadForDelete}/> : ''}
            {addAction ? <Add cancel={cancelModal} reloadData={reloadForAdd}/> : ''}
            {editAction ? <Edit id={editAction} cancel={cancelModal} reloadData={loadData}/> : ''}
            {refillAction ? <Refill data={refillAction} cancel={cancelModal} reloadData={loadData} /> : ''}
        </div>
    )
}

export default Index