const RoleRow = ({item ,editItem ,deleteItem}) => {
    return (
        <tr>
            <td> {item.name_en} </td>
            <td> {item.name_ar} </td>
            <td> {item.created} </td>
            <td className="actions">
                <button onClick={() => editItem(item.id)}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={() => deleteItem(item.id ,item.name_en)}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default RoleRow