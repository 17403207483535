const Row = ({row ,deleteAction ,editAction}) => {
    return (
        <tr>
            <td>
                <img alt="Gymme" src={row?.receipt} width="100px"/>
            </td>
            <td> {row?.date} </td>
            <td> {row?.category} </td>
            <td> {row?.sub_category} </td>
            <td> {row?.added_by} </td>
            <td> {row?.cost} </td>
            <td className="actions">
                <button onClick={editAction}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={deleteAction}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default Row