import { useContext } from 'react'
import AppContext from "../../partials/AppContext"

const OpeningHoursRow = ({start ,end ,days ,id ,type ,deleteWorkingHours ,updateWorkHour}) => {
    const {translate} = useContext(AppContext)
    return (
        <div className="working-card">
            <div className="info-div">
                <span className="days"> { Array.isArray(days) ? days?.map(d => translate(d)).join(" ,") : '' } </span>
                <span className="time"> {translate("From")} { start } ~ {translate("To")} { end } </span>
            </div>
            <div className="action-div flex-row flex-gap-10">
                <button className="red-text clickable"
                    onClick={() => updateWorkHour({id: id ,type:type ,from: start ,to: end ,days: days})}>
                    {translate("Update")}
                </button>
                <span className="red-text"> | </span>
                <button className="red-text clickable"
                    onClick={() => deleteWorkingHours(id)}>
                    {translate("Delete")}
                </button>
            </div>
        </div>
    )
}

export default OpeningHoursRow