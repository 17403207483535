import { useContext } from "react"
import AppContext from "../components/partials/AppContext"

const SafeCashTypes = (minified = false) => {
    const {translate} = useContext(AppContext)

    return [
        {name: translate('All') ,value: ''},
        {name: translate('CashIn') ,value: 'in'},
        {name: translate('CashOut') ,value: 'out'}
    ]
}

export default SafeCashTypes