import {useContext, useState} from 'react'
import AppContext from '../../partials/AppContext'
import Navbar from '../../partials/Navbar'
import CrudIndexDS from '../../Shared/CrudIndexDS'
import DrinkIndex from './Drinks/Index'
import FoodIndex from './Food/Index'
import SupplementIndex from './Supplement/Index'

const Index = () => {
    const {barSetting ,translate} = useContext(AppContext)
    const [showSection ,setShowSection] = useState('drinks')

    const [drinkData ,setDrinkData] = useState({...CrudIndexDS})
    const [foodData ,setFoodData] = useState({...CrudIndexDS})
    const [supplementData ,setSupplementData] = useState({...CrudIndexDS})

    const DrinkComponent = <DrinkIndex
        visible={showSection === 'drinks'}
        data={drinkData}
        setData={setDrinkData}
    />,
    FoodComponent = <FoodIndex
        visible={showSection === 'foods'}
        data={foodData}
        setData={setFoodData}
    />,
    SupplementComponent = <SupplementIndex
        visible={showSection === 'supplement'}
        data={supplementData}
        setData={setSupplementData}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Stock")}/>
            </div>
            <div className="pages-navbar pad0">
                {
                    barSetting.includes('Drinks') ?
                    <button className={showSection === 'drinks' ? 'active' : ''}
                        onClick={() => setShowSection('drinks')}>
                        {translate("Drinks")}
                    </button>
                    : ''
                }
                {
                    barSetting.includes('Food') ?
                    <button className={showSection === 'foods' ? 'active' : ''}
                        onClick={() => setShowSection('foods')}>
                        {translate("Food")}
                    </button>
                    : ''
                }
                {
                    barSetting.includes('Supplement') ?
                    <button className={showSection === 'supplement' ? 'active' : ''}
                        onClick={() => setShowSection('supplement')}>
                        {translate("Supplements")}
                    </button>
                    : ''
                }
            </div>
            <div className="page-content">
                {DrinkComponent}
                {FoodComponent}
                {SupplementComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index
