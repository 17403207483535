import { useContext } from "react"
import { useAlert } from "react-alert"
import AppContext from '../../../../partials/AppContext'

const styles = {
    errorStyle: {padding: '15px 0 0 15px', color: '#E03F3F'}
}

const CartForm = ({orderData ,setOrderData ,placeOrder ,errorMsg ,disabled}) => {
    const alert = useAlert()
    const {translate} = useContext(AppContext)
    const updateItem = (index ,qnt) => {
        const tempItems = [...orderData.items]
        tempItems[index].qnt = parseFloat(qnt)
        setOrderData({...orderData, items: [...tempItems]})
    }

    const removeItem = index => {
        const tempItems = [...orderData.items].filter((item ,indx) => indx !== index)
        setOrderData({...orderData, items: [...tempItems]})
    }

    const getTotal = () => {
        let total = 0
        orderData?.items?.map?.(item => total += parseFloat(item?.price) * parseFloat(item?.qnt))
        return total > 0 ? `: ${total} ${translate("EGP")}` : ''
    }

    return (
        <div className="order-items-section">
            {
                errorMsg ?
                <div style={styles.errorStyle}> { errorMsg } </div>
                : ''
            }
            <div className="cart">
                {
                    orderData.items && Array.isArray(orderData.items) ?
                    orderData.items.map((item ,index) => {
                        return (
                            <div className="cart-row" key={index}>
                                <div className="info">
                                    <span className="title"> {item?.name} </span>
                                    <span className="price"> {item?.price} {translate("EGP")} </span>
                                </div>
                                <div className="qnt">
                                    <input type="number" min="1" max={item.maxQnt ? item.maxQnt : 1}
                                        onChange={e => {
                                            if (e.target.value > (item.maxQnt ? item.maxQnt : 1)) {
                                                alert.error("Max quantity exceed")
                                                return
                                            }
                                            updateItem(index ,e.target.value)
                                        }} value={item?.qnt}/>
                                </div>
                                <div className="delete clickable">
                                    <i className="fas fa-trash" onClick={() => removeItem(index)}></i>
                                </div>
                            </div>
                        )
                    })
                    : ''
                }
            </div>
            <div className={`place-order-btn clickable ${disabled ? 'disabled' : ''}`} onClick={() => {
                if (!disabled) placeOrder()
            }}>
                {translate("PlaceOrder")} {getTotal()}
            </div>
        </div>
    )
}

export default CartForm