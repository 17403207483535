import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import Loading from "../../../Shared/Loading"
import Input from "../../../FormShared/Input"
import ModalButtons from "../../../FormShared/ModalButtons"

const styles = {
    rowStyle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        paddingBottom: 10,
        borderBottom: '#E8E8E8 1px solid'
    },
    childStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
    },
    nameStyle: {
        fontSize: 13,
        display: 'flex',
        flexDirection: 'column',
    },
    qntStyle: {
        color: 'gray',
        fontSize: 15
    },
    priceStyle: {
        font: 'normal normal bold 14px/16px Public Sans',
        letterSpacing: 0,
        color: '#E03F3F',
        opacity: 1
    },
    costStyle: {
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        alignItems: 'center',
        alignContent: 'center',
        background: '#F8F8F8 0% 0% no-repeat padding-box',
        border: '1px solid #191919',
        borderRadius: 30,
        opacity: 1
    },
    container: {
        maxWidth: 520
    },
    qntyFlex: {flex: '0 0 30%'},
    buttonsRowStyle: {justifyContent: 'space-between'}
}

const Refund = ({id ,cancel ,reloadData}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/orders`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)

    const [details ,setDetails] = useState({cost: '' ,items: []})
    const [loaded ,setLoaded] = useState(false)
    const [refundOrders ,setRefundOrders] = useState([])
    const [disabled ,setDisabled] = useState(false)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setDetails(response?.data?.data)
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[entityUrl ,clearUserData ,gymToken ,setDetails ,alert])

    const refundItems = () => {
        if (refundOrders?.length < 1) {
            alert.error(translate('ItemAtLeast'))
            return
        }
        setDisabled(true)
        const axiosSource = axios.CancelToken.source()
        const data = {cancelToken: axiosSource.token ,items: refundOrders ,_method: 'PUT'}
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/refundOrderItems/${id}` ,data)
        .then(response => {
            const order = response?.data?.data
            setDisabled(false)
            alert.success(`${translate("Order")} #${order?.id} ${translate("ForMember")} ${order?.member} ${translate("ItemRefundedSuccess")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}// ,validation = error?.response?.data?.errors
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const refundAll = () => {
        setDisabled(true)
        const axiosSource = axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/refundAllOrder/${id}` ,{cancelToken: axiosSource.token ,_method: 'PUT'})
        .then(response => {
            const order = response?.data?.data
            setDisabled(false)
            alert.success(`${translate("Order")} #${order?.id} ${translate("ForMember")} ${order?.member} ${translate("RefundedSuccess")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain" style={styles.container}>
                    <ModalHeader title={`${translate("RefundOrder")} #${id} ${translate("Items")}`} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                            <div className="section mb-2">
                                {
                                    details && details.items && Array.isArray(details.items) ?
                                    details.items.map((item ,index) => {
                                        const refundOrder = refundOrders?.filter?.(refund => refund.id === item?.id)[0]
                                        return (
                                            <div key={index} style={styles.rowStyle}>
                                                <div style={styles.childStyle}>
                                                    <span style={styles.nameStyle}>
                                                        { item?.name }
                                                        <span style={styles.priceStyle}>
                                                            {item?.price}
                                                        </span>
                                                    </span>
                                                    <span style={styles.qntStyle}> X{ item?.quantity } </span>
                                                    <Input
                                                        style={styles.qntyFlex}
                                                        placeholder="Qnt"
                                                        type="number"
                                                        value={refundOrder?.quantity}
                                                        onChange={e => {
                                                            if (e.target.value < 1) {
                                                                alert.error(translate("QntGTEOne"))
                                                                return
                                                            }
                                                            if (e.target.value > item?.quantity) {
                                                                alert.error(translate("QntGTE")+" "+item?.quantity)
                                                                return
                                                            }
                                                            const refund = {id: item?.id, quantity: e.target.value}
                                                            const existsIndex = refundOrders?.findIndex?.(__refund => __refund?.id === refund.id)
                                                            const temp = [...refundOrders]
                                                            if (existsIndex === -1) temp.push(refund)
                                                            else {
                                                                temp[existsIndex] = refund
                                                            }
                                                            setRefundOrders([...temp])
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ''
                                }
                                <div style={styles.costStyle}>
                                    Price : { details?.cost }
                                </div>
                                <ModalButtons disabled={disabled} rowStyle={styles.buttonsRowStyle} cancel={cancel}>
                                    <button type="button" className="btn btn-default" onClick={cancel}>
                                        {translate("Cancel")}
                                    </button>
                                    <button type="button" className="btn btn-light" disabled={disabled} onClick={refundItems}>
                                        {translate("RefundPartial")}
                                    </button>
                                    <button type="button" className="btn btn-light" disabled={disabled} onClick={refundAll}>
                                        {translate("RefundAll")}
                                    </button> 
                                </ModalButtons>
                            </div>
                            : <Loading/>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Refund