import { useContext } from "react"
import DropDown from "../../../../FormShared/DropDown"
import FieldError from "../../../../FormShared/FieldError"
import Input from "../../../../FormShared/Input"
import AppContext from "../../../../partials/AppContext"
import Genders from "../../../../../constants/Genders"

const PersonalInputs = ({
    member,
    errors,
    changeAttr,
    setGender,
    gender
}) => {
    const {translate} = useContext(AppContext)
    const genders = Genders()
    return (
        <div className="row">
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input
                        placeholder={translate("Name")}
                        value={member?.name}
                        onChange={(e) => changeAttr(e.target.value ,'name')}
                    />
                    {errors?.name ? <FieldError message={errors?.name} /> : ''}
                </div>
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input
                        placeholder={translate("NationalID")}
                        showSpanHint={`| ${translate("Optional")}`}
                        value={member?.national_id}
                        onChange={(e) => changeAttr(e.target.value ,'national_id')}
                    />
                    {errors?.national_id ? <FieldError message={errors?.national_id} /> : ''}
                </div>
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <DropDown
                        selectedName={gender.name}
                        textColorBlack={gender.value !== ''}
                        list={genders}
                        itemName="name"
                        select={gender => {
                            changeAttr(gender?.value ,'gender')
                            setGender(gender)
                        }}
                    />
                    {errors?.gender ? <FieldError message={errors?.gender} /> : ''}
                </div>
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input
                        placeholder={translate("Age")}
                        showSpanHint={`| ${translate("Years")}`}
                        value={member?.age}
                        type="number"
                        onChange={(e) => changeAttr(e.target.value ,'age')}
                    />
                    {errors?.age ? <FieldError message={errors?.age} /> : ''}
                </div>
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input
                        showSpanHint={`${translate('CM')} | ${translate("Optional")}`}
                        placeholder={translate("Height")}
                        value={member?.height}
                        type="number"
                        onChange={(e) => changeAttr(e.target.value ,'height')}
                    />
                    {errors?.height ? <FieldError message={errors?.height} /> : ''}
                </div>
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input
                        showSpanHint={`${translate('KG')} | ${translate("Optional")}`}
                        placeholder={translate("Weight")}
                        value={member?.weight}
                        type="number"
                        onChange={(e) => changeAttr(e.target.value ,'weight')}
                    />
                    {errors?.weight ? <FieldError message={errors?.weight} /> : ''}
                </div>
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input
                        placeholder={translate("Phone")}
                        value={member?.phone_number}
                        onChange={(e) => changeAttr(e.target.value ,'phone_number')}
                    />
                    {errors?.phone_number ? <FieldError message={errors?.phone_number} /> : ''}
                </div>
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input
                        showSpanHint={`| ${translate("Optional")}`}
                        placeholder={translate("EMail")}
                        value={member?.email}
                        onChange={(e) => changeAttr(e.target.value ,'email')}
                    />
                    {errors?.email ? <FieldError message={errors?.email} /> : ''}
                </div>
            </div>
        </div>
    )
}

export default PersonalInputs