import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../Axios"
import Config from "../../../../../../Config"
import ModalHeader from "../../../../../FormShared/ModalHeader"
import AppContext from "../../../../../partials/AppContext"
import Membership from "../../Inputs/Membership"
import Preview from "../../Inputs/Preview"

const Renewal = ({cancel ,reloadData ,memberId ,startDate}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members`

    const [member ,setMember] = useState({
        subscription_id: '' ,subscription_start: startDate
    })
    const [currentTab ,setCurrentTab] = useState('membership')
    const [subscription ,setSubscription] = useState({id: undefined ,title: translate('Subscription') ,price: 0})
    const [sessions ,setSessions] = useState([])
    const [coaches ,setCoaches] = useState([])
    const [membershipErrors ,setMembershipErrors] = useState({subscription: '' ,subscription_start: '' ,sessions: '' ,coaches: ''})
    const [btnDisabled ,setBtnDisabled] = useState(false)
    
    const addMember = () => {
        const formData = new FormData()
        formData.append('subscription_id' ,member?.subscription_id)
        formData.append('subscription_start' ,member?.subscription_start)
        sessions.forEach((session ,index) => {
            if (session.id) {
                formData.append(`sessions[${index}][session_id]` ,session?.id)
                formData.append(`sessions[${index}][times]` ,session?.times)
            }
        })
        coaches.forEach((coach ,index) => {
            if (coach.id) {
                formData.append(`privateCoach[${index}][private_coach_id]` ,coach?.id)
                formData.append(`privateCoach[${index}][times]` ,coach?.times)
            }
        })
        formData.append('_method' ,'PUT')
        setBtnDisabled(true)
        
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/members/membership/renewalMember/${memberId}` ,formData)
        .then(response => {
            setBtnDisabled(false)
            alert.success(response?.data?.message)
            cancel()
            reloadData()
        })
        .catch(err => {
            setBtnDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) {
                const validation = error?.response?.data?.errors
                let isCurrentTabChanges = false
                const subscErrors = {...membershipErrors} ,sessionErrors = [] ,coachErrors = []
                for(let [key ,value] in Object.entries(validation)) {
                    if (key === 'subscription_id') subscErrors.subscription = value
                    else if (key === 'subscription_start') subscErrors.subscription_start = value
                    else if (key.includes('sessions')) sessionErrors.push(value)
                    else if (key.includes('privateCoach')) coachErrors.push(value)
                }
                subscErrors.sessions = sessionErrors.join(" ,")
                subscErrors.coaches = coachErrors.join(" ,")
                setMembershipErrors(subscErrors)
                if (!isCurrentTabChanges) {
                    setCurrentTab('membership')
                    isCurrentTabChanges = true
                }
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("RenewalMember")} cancel={cancel}/>
                    <div className="pages-navbar pad0">
                        <button className={currentTab === 'membership' ? 'active' : ''}> {translate("Membership")} </button>
                        <button className={currentTab === 'preview' ? 'active' : ''}> {translate("Overview")} </button>
                    </div>
                    {
                        currentTab === 'membership' ?
                        <Membership
                            subscription={{...subscription ,start: member?.subscription_start}}
                            changeSubscription={subsc => {
                                setMember({...member ,subscription_id: subsc?.id})
                                setSubscription(subsc)
                            }}
                            changeStart={e => setMember({...member ,subscription_start: e.target.value})}
                            sessions={sessions}
                            setSessions={setSessions}
                            coaches={coaches}
                            setCoaches={setCoaches}
                            clickNext={() => setCurrentTab('preview')}
                            clickPrevious={() => cancel()}
                            cancelLabel={translate("Cancel")}
                            errors={membershipErrors}
                        />
                        : ''
                    }
                    {
                        currentTab === 'preview' ?
                        <Preview
                            clickPrevious={() => setCurrentTab('membership')}
                            addMember={addMember}
                            subscription={subscription}
                            sessions={sessions}
                            coaches={coaches}
                            disabled={btnDisabled}
                        />
                        : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Renewal