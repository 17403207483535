import { useState ,useContext } from 'react'
import Navbar from '../../partials/Navbar'
import CrudIndexDS from '../../Shared/CrudIndexDS'
import MembersIndex from './Members/Index'
import BookingIndex from './Booking/Index'
import AttendanceIndex from './Attendance/Index'
import GuestIndex from './Guest/Index'
import HistoryIndex from './History/Index'
import AppContext from '../../partials/AppContext'

const Index = () => {
    const {translate} = useContext(AppContext)
    const [showSection ,setShowSection] = useState('members')

    const [members ,setMembers] = useState({...CrudIndexDS})
    const [booking ,setBooking] = useState({...CrudIndexDS})
    const [attendanceData ,setAttendanceData] = useState({...CrudIndexDS})
    const [guestData ,setGuestData] = useState({...CrudIndexDS})
    const [historytData ,setHistorytData] = useState({...CrudIndexDS})

    const membersComponent = <MembersIndex
        visible={showSection === 'members'}
        data={members}
        setData={setMembers}
    />,
    bookingComponent = <BookingIndex
        visible={showSection === 'booking'}
        data={booking}
        setData={setBooking}
    />,
    attendanceComponent = <AttendanceIndex
        visible={showSection === 'attendance'}
        data={attendanceData}
        setData={setAttendanceData}
    />,
    guestComponent = <GuestIndex
        visible={showSection === 'guests'}
        data={guestData}
        setData={setGuestData}
    />,
    historyComponent = <HistoryIndex
        visible={showSection === 'history'}
        data={historytData}
        setData={setHistorytData}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Members")}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'members' ? 'active' : ''}
                    onClick={() => setShowSection('members')}>
                    {translate("Members")}
                </button>
                <button className={showSection === 'guests' ? 'active' : ''}
                    onClick={() => setShowSection('guests')}>
                    {translate("Guests")}
                </button>
                <button className={showSection === 'attendance' ? 'active' : ''}
                    onClick={() => setShowSection('attendance')}>
                    {translate("Attendance")}
                </button>
                <button className={showSection === 'booking' ? 'active' : ''}
                    onClick={() => setShowSection('booking')}>
                    {translate("Booking")}
                </button>
                <button className={showSection === 'history' ? 'active' : ''}
                    onClick={() => setShowSection('history')}>
                    {translate("History")}
                </button>
            </div>
            <div className="page-content">
                {membersComponent}
                {bookingComponent}
                {attendanceComponent}
                {guestComponent}
                {historyComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index