import { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import axios from "../../../Axios"
import Input from '../../FormShared/Input'
import FieldError from '../../FormShared/FieldError'
import Config from '../../../Config'
import AppContext from '../../partials/AppContext'
import DropDown from '../../FormShared/DropDown'
import SearchBox from '../../Shared/SearchBox'

const AccountCommonInputs = ({
    account,
    errors,
    updateAccountAttr,
    addRole,
    employee,
    setEmployee,
    branch,
    setBranch
}) => {
    const alert = useAlert()
    const [roles ,setRoles] = useState([])
    const {clearUserData ,gymToken ,gymAccountType ,translate} = useContext(AppContext)
    const [branches ,setBranches] = useState([])
    const [employees ,setEmployees] = useState([])
    const [allEmployees ,setAllEmployees] = useState([])
    const [searchEmployee ,setSearchEmployee] = useState('')

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(gymToken)
        .get(`${Config.apiBaseUrl}/gym-dashboard-services/roles/getAll` ,{cancelToken: axiosSource.token})
        .then(response => setRoles(response.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.status === 401) clearUserData()
        })
        if (gymAccountType === 'owner') {
            axios
            .setAuthToken(gymToken)
            .get(`${Config.apiBaseUrl}/gym-dashboard-services/gym-accounts/getAllBranches`)
            .then(response => setBranches(response.data))
            .catch(err => {
                const error = {...err}
                if (error?.response?.data?.message) alert.error(error.response.data.message)
                if (error?.response?.status === 401) clearUserData()
            })
        } else {
            axios
            .setAuthToken(gymToken)
            .get(`${Config.apiBaseUrl}/gym-dashboard-services/employees/getAll`)
            .then(response => {
                setAllEmployees(response?.data?.employees)
                setEmployees(response?.data?.employees)
            })
            .catch(err => {
                const error = {...err}
                if (error?.response?.data?.message) alert.error(error.response.data.message)
                if (error?.response?.status === 401) clearUserData()
            })
        }
        return () => axiosSource.cancel()
    } ,[setRoles ,alert ,setBranches ,setEmployees ,clearUserData ,gymToken ,gymAccountType])

    return (
        <div className="row">
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input placeholder={translate("Username")} value={account?.username} autoComplete="none"
                        onChange={(e) => updateAccountAttr(e.target.value ,'username')}/>
                </div>
                {errors.username ? <FieldError message={errors.username}/> : ''}
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input placeholder={translate("Password")} type="password" autoComplete="none"
                        value={account?.password} onChange={(e) => updateAccountAttr(e.target.value ,'password')}/>
                </div>
                {errors.password ? <FieldError message={errors.password}/> : ''}
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <DropDown
                    selectedName={account?.role?.name ? account?.role?.name : translate('Role')}
                    textColorBlack={account?.role?.name}
                    list={roles}
                    itemName="name_en"
                    select={(role) => addRole(role.id ,role.name_en)} />
            </div>
            {
                gymAccountType === 'owner' ?
                <div className="col-sm-6 pad0 toggle-textfiled">
                    <DropDown
                        selectedName={branch?.name ? branch?.name : translate('Branch')}
                        textColorBlack={branch?.name}
                        list={branches}
                        itemName="name"
                        select={(branch) => setBranch({id: branch.id ,name: branch.name})} />
                    {errors.branch_id ? <FieldError message={errors.branch_id}/> : ''}
                </div>
                : 
                <div className="col-sm-6 pad0 toggle-textfiled">
                    <DropDown
                        selectedName={employee?.name ? employee?.name : translate('Employee')}
                        textColorBlack={employee?.id}
                        list={employees}
                        itemName="name"
                        select={employee => setEmployee({id: employee.id ,name: employee.name})}>
                        <SearchBox
                            placeholder={translate("EmployeeID")}
                            extraClass="custom-search-box"
                            extraParentClass="custom-parent-search-box embeded-dropdown"
                            searchTrigger={() => {
                                if (searchEmployee === '') setEmployees(allEmployees)
                                else {
                                    const tempEmployees = employees.filter(empl => `${empl.id_number}`.includes(searchEmployee))
                                    setEmployees(tempEmployees)
                                }
                            }}
                            setKeyword={setSearchEmployee}
                            keyword={searchEmployee}/>
                    </DropDown>
                    {errors.employee_id ? <FieldError message={errors.employee_id}/> : ''}
                </div>
            }
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input placeholder={translate("EMail")} value={account?.email} onChange={(e) => updateAccountAttr(e.target.value ,'email')}/>
                </div>
                {errors.email ? <FieldError message={errors.email}/> : ''}
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input placeholder={translate("Name")}
                        value={account?.name} onChange={(e) => updateAccountAttr(e.target.value ,'name')}/>
                </div>
                {errors.name ? <FieldError message={errors.name}/> : ''}
            </div>
            <div className="col-sm-6 pad0 toggle-textfiled">
                <div className="form-group">
                    <Input placeholder={translate("Phone")}
                        value={account?.phone} onChange={(e) => updateAccountAttr(e.target.value ,'phone')}/>
                </div>
                {errors.phone ? <FieldError message={errors.phone}/> : ''}
            </div>
        </div>
    )
}

export default AccountCommonInputs