import { useContext } from "react"
import DropDown from "../../../../FormShared/DropDown"
import Input from "../../../../FormShared/Input"
import AppContext from "../../../../partials/AppContext"
import '../../../Membership/Classes/ClassTimes.css'

const CoachInputs = ({
    children,
    coaches,
    setCoaches,
    rowClassName,
    btnClassName,
    className,
    privateCoaches
}) => {
    const {translate} = useContext(AppContext)
    return (
        <>
            {
                coaches?.map(
                    (coach ,index) =>
                    <div className={`row close-row ${rowClassName}`}>
                        <div className={className ? className : "col-sm-8 pad0 toggle-textfiled"}>
                            <div className="form-group">
                                <DropDown
                                    selectedName={coach?.title ? coach?.title : translate('PrivateCoach')}
                                    textColorBlack={coach.id}
                                    list={privateCoaches && Array.isArray(privateCoaches) ? privateCoaches : []}
                                    itemName="title"
                                    select={__coach => {
                                        const tempCoaches = [...coaches]
                                        tempCoaches[index] = {
                                            id: __coach?.id,
                                            title: __coach?.title,
                                            times: 0,
                                            price: __coach?.price
                                        }
                                        setCoaches([...tempCoaches])
                                    }}
                                />
                            </div>
                        </div>
                        <div className={className ? className : "col-sm-4 pad0 toggle-textfiled"}>
                            <div className="form-group">
                                <Input placeholder={translate("Number")} type="number" value={coach?.times}
                                    onChange={e => {
                                        const tempCoaches = [...coaches]
                                        tempCoaches[index].times = e.target.value
                                        setCoaches([...tempCoaches])
                                    }}/>
                            </div>
                        </div>
                        <i
                            onClick={() => {
                                const tempCoaches = [...coaches].filter((coach ,_index) => _index !== index)
                                setCoaches([...tempCoaches])
                            }}
                            className="fas fa-times close-btn"></i>
                    </div>
                )
            }
            {children}
            <button className={`${btnClassName} red-btn`} type="button"
                onClick={() =>  setCoaches([
                    ...coaches,
                    {id: undefined ,name: '' ,times: 0 ,price: 0}
                ])}>
                + {translate("AddPrivateCoach")}
            </button>
        </>
    )
}

export default CoachInputs