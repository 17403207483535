import { useContext } from "react"
import { NavLink } from "react-router-dom"
import AppContext from "../../../partials/AppContext"
import Config from "../../../../Config"

const styles = {
    container: {
        position: 'relative',
        paddingBottom: 75,
        width: 270
    },
    btn: {
        position: 'absolute',
        bottom: 20,
        left: 'calc((270px - 166px) / 2)'
    }
}

const ClassCard = ({
    addMemberClick,
    classData,
    actionMenu,
    setActionMenu,
    editClass,
    deleteClass
}) => {
    const {translate ,systemLang} = useContext(AppContext)

    const toggleMenuAction = () => {
        setActionMenu(actionMenu === classData.id ? undefined : classData.id)
    }

    const readableTime = classTime => {
        let t = classTime?.day
        if (classTime.start) {
            t += ' ' + Config?.translateTime(classTime.start ,systemLang)
        }
        if (classTime.end) {
            t += ' ' + Config?.translateTime(classTime.end ,systemLang)
        }
        return t
    }

    return (
        <div className="membership-card" style={styles.container} key={Math.random()}>
            <div className={`membership-actions display-${actionMenu === classData.id ? 'show' : 'hide'}`}>
                <NavLink exact to={`/membership/class-members/${classData.id}`} className="small-btn default-small-btn menu-action-hover">
                    <i className="fas fa-address-book"></i>
                    <span> {translate("Members")} </span>
                </NavLink>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={() => {
                    setActionMenu(undefined)
                    editClass(classData?.id)
                }}>
                    <i className="fas fa-pen"></i>
                    <span> {translate("Edit")} </span>
                </button>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={() => {
                    setActionMenu(undefined)
                    deleteClass(classData)
                }}>
                <i className="fas fa-trash"></i>
                    <span> {translate("Delete")} </span>
                </button>
            </div>
            <button className="dots en" type="button" onClick={toggleMenuAction}>
                <img alt={classData?.title} src="/assets/svg/dots.svg" className="full-img"/>
            </button>
            <span className="subscription-title"> { classData?.title } </span>
            <div className="main-title">
                <span className="price"> {classData?.price} {translate("EGP")} </span>
            </div>

            <ul className="options">
                <li> { classData?.number_of_members } {translate("Members")} </li>
                <li> {translate("Starts")} { classData?.class_start } </li>
                <li> {translate("End")} { classData?.class_end } </li>
                {classData?.times?.map(classTime => <li key={classTime.id}>{readableTime(classTime)}</li>)}
            </ul>
            <button onClick={() => addMemberClick?.()}
                disabled={!(classData?.number_of_members > classData?.registered_members) || classData?.is_class_ended}
                style={styles.btn} className="btn"> {translate("AddMembers")} </button>
        </div>
    )
}

export default ClassCard