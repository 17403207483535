import { useContext } from "react"
import AppContext from "../components/partials/AppContext"

const Months = () => {
    const {translate} = useContext(AppContext)

    return [
        {name: translate('All') ,value: ''},
        {name: translate('January') ,value: '01'},
        {name: translate('February') ,value: '02'},
        {name: translate('March') ,value: '03'},
        {name: translate('April') ,value: '04'},
        {name: translate('May') ,value: '05'},
        {name: translate('June') ,value: '06'},
        {name: translate('July') ,value: '07'},
        {name: translate('August') ,value: '08'},
        {name: translate('September') ,value: '09'},
        {name: translate('October') ,value: '10'},
        {name: translate('November') ,value: '11'},
        {name: translate('December') ,value: '12'}
    ]
}

export default Months