import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"

const Refill = ({data ,cancel ,reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/stock/supplements`
    const [qnt ,setQnt] = useState()
    const [purchasePrice ,setPurchasePrice] = useState(data?.purchase_price)
    const [price ,setPrice] = useState(data?.price)
    const [errors ,setErrors] = useState({quantity: '' ,selling_price: '' ,purchasePrice:''})
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/refill/${data?.id}` ,{_method: 'PUT' ,quantity: qnt ,selling_price: price ,purchase_price: purchasePrice})
        .then(response => {
            alert.success(`${response?.data?.data?.name} ${translate("refillSuccessfully")}`)
            reloadData()
            cancel()
            setDisabled(false)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            setDisabled(false)
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("RefillSupplement")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <Input placeholder={translate("Quantity")} value={qnt} onChange={e => {
                                setErrors({...errors ,quantity: ''})
                                setQnt(e.target.value)
                            }}/>
                            {errors.quantity ? <FieldError message={errors.quantity} /> : ''}
                            <Input
                                showSpanHint={`| ${translate("EGP")}`}
                                placeholder={translate("PurchasePrice")} value={purchasePrice} onChange={e => {
                                setErrors({...errors ,purchasePrice: ''})
                                setPurchasePrice(e.target.value)
                            }}/>
                            {errors.selling_price ? <FieldError message={errors.selling_price} /> : ''}
                            <Input
                                showSpanHint={`| ${translate("EGP")}`}
                                placeholder={translate("SellingPrice")} value={price} onChange={e => {
                                setErrors({...errors ,selling_price: ''})
                                setPrice(e.target.value)
                            }}/>
                            {errors.selling_price ? <FieldError message={errors.selling_price} /> : ''}
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("RefillSupplement")}
                            disabled={disabled}
                        />
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default Refill