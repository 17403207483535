import { useContext } from "react"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import AppContext from "../../../partials/AppContext"

const SessionInput = ({errors ,title ,title_ar ,changeTitle ,price ,changePrice}) => {
    const { translate } = useContext(AppContext)
    return (
        <>
            <Input placeholder={translate("Title")} value={title} onChange={changeTitle}/>
            {errors.title ? <FieldError message={errors.title} /> : ''}
            <Input placeholder={translate("ArabicTitle")} value={title_ar} onChange={e => changeTitle(e ,true)}/>
            {errors.title_ar ? <FieldError message={errors.title_ar} /> : ''}
            <Input placeholder={translate("PriceEgp")} value={price} onChange={changePrice}/>
            {errors.price ? <FieldError message={errors.price} /> : ''}
        </>
    )
}

export default SessionInput