import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../Axios"
import Config from "../../../Config"
import DropDown from "../../FormShared/DropDown"
import AppContext from "../../partials/AppContext"
import Pagination from "../../Shared/Pagination"
import PrintButton from "../../Shared/PrintButton"
import SearchBox from "../../Shared/SearchBox"
import Table from "../../Shared/Table"
import Thead from "../../Shared/Thead"
import StatCounter from "../../Shared/StatCounter"

const Bar = ({data ,setData ,visible}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/safe/earnings`
    const clients = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword
    const [stats ,setStats] = useState({
        total_cost: 0 ,drinks: 0, food: 0, supplements: 0 ,loaded: undefined
    })
    const [filters ,setFilters] = useState({
        month: {name: translate('All') ,value: ''},
        year: {name: translate('All') ,value: ''}
    })

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const getQuery = () => {
        let query = `keyword=${keyword}`
        query += `&limit=${dataPerPage}`
        query += `&page=${currentPage}`
        query += `&month=${filters?.month?.value}`
        query += `&year=${filters?.year?.value}`

        return query
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        const url = `${entityUrl}/bar?${getQuery()}`
        console.log(stats)
        Axios
        .setAuthToken(gymToken)
        .get(`${url}${!stats.loaded ? '&counters=true' : ''}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate("Item")}`,
                list: responseData,
                meta: meta
            })
            if (!stats.loaded) {
                setStats({loaded: true ,...response?.data?.stats})
            }
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[alert ,gymToken ,clearUserData ,entityUrl ,dataPerPage ,currentPage ,filters])

    const printClick = () => {
        return Config.printByToken(`${entityUrl}/bar/print?${getQuery()}` ,true ,gymToken)
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const counters = [
        {style: {borderTopColor: "#E03F3F"}, stat: stats?.food, subTitle: translate("MonthOverview"), title: translate("Food")},
        {style: {borderTopColor: "#6DBE43"}, stat: stats?.drinks, subTitle: translate("MonthOverview"), title: translate("Drinks")},
        {style: {borderTopColor: "#36AACC"}, stat: stats?.supplements, subTitle: translate("MonthOverview"), title: translate("Supplements")},
        {style: {borderTopColor: "#F26F10"}, stat: stats?.total_cost, subTitle: translate("MonthOverview"), title: translate("TotalEarnings")}
    ]

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <DropDown
                        placeholder={translate('Month')}
                        selectedName={filters?.month?.name}
                        textColorBlack={filters?.month?.name !== translate('All')}
                        list={Config.monthsNameValue.map(m => {return {...m ,name: translate(m.name)}})}
                        itemName="name"
                        select={month => {
                            setFilters({...filters ,month: {name: month?.name ,value: month?.value}})
                        }} />
                    <DropDown
                        placeholder={translate('Year')}
                        selectedName={filters?.year?.name}
                        textColorBlack={filters?.year?.name !== translate('All')}
                        list={Config.yearsNameValue().map(y => {return {...y ,name: translate(y.name)}})}
                        itemName="name"
                        select={year => {
                            setFilters({...filters ,year: {name: year?.name ,value: year?.value}})
                        }} />
                </div>
            </div>
            <div className="page-section content">
                <div className="stats-counters jus-con-center">
                    {
                        counters.map((counter ,index) =>
                            <StatCounter key={index}
                                rootStyle={counter.style}
                                title={counter.title}
                                subTitle={counter.subTitle}
                                statValue={counter.stat}/>
                        )
                    }
                </div>
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('DateTime')},
                            {value: translate('BarMan')},
                            {value: translate('Member')},
                            {value: translate('Qty')},
                            {value: translate('Cost')}
                        ]}/>
                        <tbody>
                            {
                                clients?.map((row ,index) =>
                                    <tr key={index}>
                                        <td> {row.date} </td>
                                        <td> {row.bar_man} </td>
                                        <td> {row.member} </td>
                                        <td> {row.qty} </td>
                                        <td> {row.cost} </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                </div>
            </div>
        </div>
    )
}

export default Bar