import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import AppContext from "../../../../partials/AppContext"
import DataLine from "../../../../Shared/DataLine/DataLine"
import ChangeTextAttribute from "./ChangeTextAttribute"
import ChangePhotoAttribute from './ChangePhotoAttribute'
import ChangeSelectAttribute from "./ChangeSelectAttribute"
import EmployeePositions from "../../../../../constants/EmployeePositions"
import Genders from "../../../../../constants/Genders"
import GrayButton from "../../../../Shared/GrayButton"

const General = ({id ,data ,setData ,visible}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/employees`
    const [showEdit ,setShowEdit] = useState(undefined)
    const [editPhoto ,setEditPhoto] = useState(undefined)
    const [showSelectEdit ,setShowSelectEdit] = useState(undefined)
    const positionSelect = EmployeePositions()
    const genderSelect = Genders()
    const employee = data

    const cancel = () => {
        setShowEdit(undefined)
        setEditPhoto(undefined)
        setShowSelectEdit(undefined)
    }

    const axiosSource = Axios.CancelToken.source()

    const loadData = () => {
        cancel()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/${id}` ,{cancelToken: axiosSource.token})
        .then(response => setData(response?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }
    
    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,alert ,clearUserData ,entityUrl ,id ,setData])
    
    const printClick = () => Config.printByToken(entityUrl ,false ,gymToken)

    return (
        <div className={`page-content display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section content  small-page-section">
                <div className="details-page-content member-details-page">
                    <div className="table-header flex-row jus-con-space-btwn">
                        <span className="table-header-title"> {translate("GeneralInfo")} </span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={printClick}>
                                <i className="fas fa-print"></i>
                            </button>
                        </div>
                    </div>
                    <div className="details-page-section">
                        <div className="details-img-container">
                            <div className="details-img">
                                <img alt={employee?.name} id="gym-logo-img" src={employee?.avatar} className="details-full-img full-width"/>
                            </div>
                            <button id="cam-btn" className="details-cam-btn en" onClick={setEditPhoto}>
                                <img alt={employee?.name} src="/assets/svg/img-change.svg"/>
                            </button>
                        </div>
                        
                        <div className="details-gym-info">
                            <div className="details-info-section">
                                <span className="details-info-title">{translate("PersonalInfo")}</span>
                                <div className="details-info-data">
                                    <DataLine changeClick={() => {
                                        setShowSelectEdit({attribute: 'gender'})
                                    }} title={translate("Gender")} value={employee?.gender_translated} />
                                    <DataLine changeClick={() => {
                                        setShowEdit({
                                            value: employee?.age,
                                            attribute: 'age',
                                            placeholder: translate("Age"),
                                            hint: `| ${translate("Years")}`
                                        })
                                    }} title={translate("Age")} value={`${employee?.age} ${translate('Years')}`} />
                                    <DataLine changeClick={() => {
                                        setShowEdit({
                                            value: employee?.phone,
                                            attribute: 'phone',
                                            placeholder: translate("Phone")
                                        })
                                    }} title={translate("Phone")} value={employee?.phone} />
                                    <DataLine changeClick={() => {
                                        setShowEdit({
                                            value: employee?.email,
                                            attribute: 'email',
                                            placeholder: translate("Email")
                                        })
                                    }} title={translate("Email")} value={employee?.email} />
                                </div>
                            </div>
                        </div>

                        <div className="details-gym-info">
                            <div className="details-info-section">
                                <span className="details-info-title">{translate("BroughtBy")}</span>
                                <div className="details-info-data">
                                    <DataLine changeClick={() => {
                                        setShowSelectEdit({attribute: 'position'})
                                    }} title={translate("Position")} value={employee?.position_translated} />
                                    <DataLine changeClick={() => {
                                        setShowEdit({
                                            value: employee?.salary,
                                            attribute: 'salary',
                                            placeholder: translate("Salary"),
                                            hint: `| ${translate("EGP")}`
                                        })
                                    }} title={translate("Salary")} value={`${employee?.salary} ${translate('EGP')}`} />
                                    <DataLine changeClick={() => {
                                        setShowEdit({
                                            value: employee?.target,
                                            attribute: 'target',
                                            placeholder: translate("Target"),
                                            hint: `| ${translate("EGP")}`
                                        })
                                    }} title={translate("Target")} value={`${employee?.target ? `${employee?.target} ${translate('EGP')}` : ''}`} />
                                    <DataLine changeClick={() => {
                                        setShowEdit({
                                            value: employee?.commission,
                                            attribute: 'commission',
                                            placeholder: translate("Commission"),
                                            hint: `| ${translate("EGP")}`
                                        })
                                    }} title={translate("Commission")} value={`${employee?.commission ? `${employee?.commission} ${translate('EGP')}` : ''}`} />
                                </div>
                            </div>
                        </div>

                        <div className="details-gym-info">
                            <div className="details-info-section">
                                <GrayButton
                                    onClick={() => alert.info(translate("CommingSoon"))}
                                    label={translate("DeleteEmployee")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showEdit ?
                <ChangeTextAttribute
                    id={id}
                    cancel={cancel}
                    reloadData={() => loadData()}
                    value={showEdit?.value}
                    attribute={showEdit?.attribute}
                    placeholder={showEdit?.placeholder}
                    hint={showEdit.hint ? showEdit.hint : undefined}
                />
                : null
            }
            {
                editPhoto ?
                <ChangePhotoAttribute
                    id={id}
                    cancel={cancel}
                    reloadData={() => loadData()}
                />
                : null
            }
            {
                showSelectEdit ?
                <ChangeSelectAttribute
                    id={id}
                    cancel={cancel}
                    reloadData={loadData}
                    attribute={showSelectEdit.attribute === 'position' ? 'position' : 'gender'}
                    startedOption={{
                        name: employee[showSelectEdit.attribute === 'position' ? 'position_translated' : 'gender_translated'],
                        value: showSelectEdit.attribute
                    }}
                    selects={showSelectEdit.attribute === 'position' ? positionSelect : genderSelect}
                    placeholder={`${translate("Edit")} ${translate(showSelectEdit.attribute === 'position' ? 'Position' : 'Gender')}`}
                />
                : null
            }
        </div>
    )
}

export default General