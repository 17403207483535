import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import Loading from "../../../Shared/Loading"
import AppContext from "../../../partials/AppContext"
import GymExpensesInput from "./GymExpensesInput"

const Edit = ({entityUrl ,loadData ,cancel ,categories ,id}) => {
    const alert = useAlert()
    const {translate ,gymToken ,clearUserData} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const [expenses ,setExpenses] = useState({
        category: '', sub_category: '', price: '', receipt: ''
    })
    const [receipt ,setReceipt] = useState(undefined)
    const [errors ,setErrors] = useState({
        category: '', sub_category: '', price: '', receipt: '',
    })
    const [selectedCategory ,setSelectedCategory] = useState({name: translate('Category') ,id: undefined})
    const [selectedSubCategory ,setSelectedSubCategory] = useState({name: translate('SubCategory') ,id: undefined})
    const [subCategories ,setSubCategories] = useState([])
    const [loaded ,setLoaded] = useState(false)
    const axiosSource = Axios.CancelToken.source()

    useEffect(() => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const exp = response?.data?.data
            setExpenses({
                category: exp?.category,
                sub_category: exp?.sub_category,
                price: exp?.price,
                receipt: exp?.receipt
            })
            setSelectedSubCategory({name: exp?.sub_category_name ,id: exp?.sub_category})
            setSelectedCategory({name: exp?.category_name ,id: exp?.category})
            setLoaded(true)
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validations = error?.response?.data?.errors
            if (validations) setErrors({...errors ,...validations})
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    } ,[gymToken ,entityUrl ,id ,setExpenses ,setSelectedSubCategory ,setSelectedCategory ,alert ,clearUserData ,setLoaded])

    const confirmEditExpenses = () => {
        setDisabled(true)
        const formData = new FormData()
        formData.append('category' ,expenses.category)
        formData.append('sub_category' ,expenses.sub_category)
        formData.append('price' ,expenses.price)
        formData.append('_method' ,'PUT')
        if (receipt) formData.append('receipt' ,receipt)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/${id}` ,formData ,{cancelToken: axiosSource.token})
        .then(() => {
            setDisabled(false)
            alert.success(`${translate("updatedSuccessfully")}`)
            loadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validations = error?.response?.data?.errors
            if (validations) setErrors({...errors ,...validations})
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditExpenses")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}
                        className={`flex-col ${loaded ? 'center-align-items jus-con-center' : ''}`}>
                        {
                            loaded ?
                            <>
                                <GymExpensesInput
                                    expenses={expenses}
                                    setExpenses={setExpenses}
                                    selectedCategory={selectedCategory}
                                    setSelectedCategory={setSelectedCategory}
                                    categories={categories}
                                    subCategories={subCategories}
                                    setSubCategories={setSubCategories}
                                    selectedSubCategory={selectedSubCategory}
                                    setSelectedSubCategory={setSelectedSubCategory}
                                    errors={errors}
                                    setErrors={setErrors}
                                    setReceipt={setReceipt}
                                />
                                <ModalButtons
                                    disabled={disabled}
                                    cancel={cancel}
                                    confirm={confirmEditExpenses}
                                    confirmLabel={translate("EditExpenses")}
                                />
                            </>
                            : <Loading/>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Edit