import { useContext } from "react"
import AppContext from "../components/partials/AppContext"

const EmployeePositions = () => {
    const {translate} = useContext(AppContext)

    return [
        {name: translate('All') ,value: ''},
        {name: translate('Salesman') ,value: 'salesman'},
        {name: translate('Coach') ,value: 'coach'},
        {name: translate('Barman') ,value: 'barman'},
        {name: translate('Other') ,value: 'other'},
        {name: translate('Host') ,value: 'host'},
    ]
}

export default EmployeePositions