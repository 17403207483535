const Row = ({row ,editRow ,deleteRow ,refillRow}) => {
    return (
        <tr key={Math.random()}>
            <td width="20%"> { row?.name } </td>
            <td> { row?.brand } </td>
            <td> { row?.updated } </td>
            <td> { row?.size } </td>
            <td> { row?.category } </td>
            <td> { row?.quantity } </td>
            <td> { row?.sold_qnt } </td>
            <td> { row?.selling_price } </td>
            <td className="actions">
                <button onClick={refillRow}>
                    <img alt="Gymme" src="/assets/svg/refill.svg"/>
                </button>
                <button onClick={editRow}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={deleteRow}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default Row