import { useContext } from "react"
import AppContext from "../components/partials/AppContext"

const EquipmentTypes = () => {
    const {translate} = useContext(AppContext)

    return [
        {name: translate("All") ,value: ''},
        {name: translate("Purchase") ,value: 'purchase'},
        {name: translate("Maintenance") ,value: 'maintenance'},
    ]
}

export default EquipmentTypes