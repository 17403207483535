import { useContext, useRef } from "react"
import Config from "../../../../../Config"
import AppContext from "../../../../partials/AppContext"

const ProductRow = ({index ,product ,currentTab ,appendItem}) => {
    const selectRef = useRef()
    const {translate} = useContext(AppContext)

    const FoodSetting = Config.Order.FoodSetting,
        SupplementSetting = Config.Order.SupplementSetting,
        DrinkSetting = Config.Order.DrinkSetting

    let productType = ''
    switch(currentTab) {
        case FoodSetting:
            productType = Config.Order.type.foods
            break;
        case DrinkSetting:
            productType = Config.Order.type.drinks
            break;
        case SupplementSetting:
            productType = Config.Order.type.supplements
            break;
        default:
            console.log("Unknow type")
    }

    const productName = currentTab === SupplementSetting ?
        (product?.brand?.name ? product?.brand?.name +',' : '')+
        (product?.size?.name ? product?.size?.name +',' : '')+
        (product?.unit?.name ? product?.unit?.name +',' : '')
        : product?.p_name

    return (
        <div className="products-row" key={index}>
            <div className="product-info">
                <span className="product-title">
                    {productName}
                </span>
                {
                    currentTab === FoodSetting ?
                    <>
                        <span className="food-calories"> { product?.calories } {translate("Calories")} </span>
                        <span className="food-description"> { product?.description } </span>
                    </>
                    : ''
                }
                <span className="product-price"> {product?.p_price} {translate("EGP")} </span>
            </div>
            <div className="available-qnt">
                <select ref={selectRef}>
                    {
                        [...Array(product?.p_qnt)].map((v ,number) =>
                            <option key={number} value={number + 1}> {number + 1} </option>
                        )
                    }
                </select>
            </div>
            <div className="plus clickable">
                <i className="fas fa-plus"
                    onClick={() =>
                        appendItem(productType ,{
                            id: product?.id,
                            name: productName,
                            price: product?.p_price,
                            maxQnt: product?.p_qnt,
                            qnt: selectRef.current.value
                        })
                    }
                ></i>
            </div>
        </div>
    )
}

export default ProductRow