import { useContext, useEffect, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import Axios from '../../../Axios'
import Config from '../../../Config'
import SafeTypes from '../../../constants/SafeTypes'
import DropDown from '../../FormShared/DropDown'
import AppContext from '../../partials/AppContext'
import Navbar from '../../partials/Navbar'
import Pagination from '../../Shared/Pagination'
import PrintButton from '../../Shared/PrintButton'
import SearchBox from '../../Shared/SearchBox'
import Table from '../../Shared/Table'
import Thead from '../../Shared/Thead'
import Add from './Add'
import Delete from './Delete'
import Edit from './Edit'
import Row from './Row'

const styles = {
    safeBalanceStyle: {
        font: 'normal normal normal 14px/16px Public Sans',
        letterSpacing: 0,
        color: '#807F7F',
        opacity: 1
    },
    ltZeroSafeBalance: {
        font: 'normal normal bold 24px/28px Public Sans',
        letterSpacing: 0,
        color: '#E03F3F',
        opacity: 1
    },
    gtZeroSafeBalance: {
        font: 'normal normal bold 24px/28px Public Sans',
        letterSpacing: 0,
        color: '#5DAF32',
        opacity: 1
    }
}

const Index = () => {
    const alert = useAlert()
    const {translate ,gymToken ,clearUserData} = useContext(AppContext)

    const [selectedType ,setSelectedType] = useState({name: translate('All') ,value: ''})

    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    const [dataPerPage ,setDataPerPage] = useState(10)
    const [currentPage ,setCurrentPage] = useState(1)
    const [meta ,setMeta] = useState({links: [] ,last_page: 1})
    const [data ,setData] = useState([])
    const [safeBalance ,setSafeBalance] = useState(undefined)

    const [addAction ,setAddAction] = useState(undefined)
    const [editAction ,setEditAction] = useState(undefined)
    const [deleteAction ,setDeleteAction] = useState({name: '' ,id: undefined})

    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/safe/safe`

    const getQuery = () => {
        let query = `keyword=${keyword}`
        query += `&limit=${dataPerPage}`
        query += `&page=${currentPage}`
        query += `&type=${selectedType?.value}`

        return query
    }

    const headers = [
        {value: translate('DateTime')},
        {value: translate('Employee')},
        {value: translate('FromTo')},
        {value: translate('Type')},
        {value: translate('Reason')},
        {value: translate('Amount')},
        {value: translate('Actions') ,className: "actions"}
    ]

    const types = SafeTypes()

    const cancelModal = () => {
        setAddAction(undefined)
        setEditAction(undefined)
        setDeleteAction({name: '' ,id: undefined})
    }

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?${getQuery()}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta ,
                CountKey = `Record${responseData?.length > 1 ? 's' : ''}`
            setData(response?.data?.data)
            setMeta(meta)
            setHeaderTitle(`${responseData?.length} ${translate(CountKey)}`)
            setSafeBalance(response?.data?.balance?.total)
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[alert ,gymToken ,clearUserData ,entityUrl ,dataPerPage ,currentPage ,selectedType])

    const printClick = () => Config.printByToken(`${entityUrl}/print?${getQuery()}` ,true ,gymToken)

    const reloadForDelete = () => {
        if (data.length === 1 && currentPage > 1) setCurrentPage(currentPage - 1)
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setCurrentPage(1)
    }

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Safe")}/>
            </div>
            <div className="page-content">
                <div className="page-section header-box" ref={elRef}>
                    <div className="row header">
                        <SearchBox
                            searchTrigger={() => currentPage !== 1 ? setCurrentPage(1) : loadData()}
                            setKeyword={setKeyword}
                            keyword={keyword}/>
                        <DropDown
                            placeholder={translate('Type')}
                            selectedName={selectedType?.name}
                            textColorBlack={selectedType?.name !== translate('All')}
                            list={types}
                            itemName="name"
                            select={type => setSelectedType({name: type?.name ,value: type?.value})}
                        />
                        <button
                            onClick={() => {
                                cancelModal()
                                setAddAction(true)
                            }}
                            className="btn btn-light">
                            {translate("AddRecord")}
                        </button>
                    </div>
                </div>
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header flex-col start-align-items">
                            <span style={styles.safeBalanceStyle}> { translate("SafeBalance") } </span>
                            <span style={safeBalance < 0 ? styles.ltZeroSafeBalance : styles.gtZeroSafeBalance}>
                                { safeBalance } { translate("EGP") }
                            </span>
                        </div>
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <div className="flex-row flex-gap-20">
                                <button type="button" onClick={() => currentPage !== 1 ? setCurrentPage(1) : loadData()}>
                                    <i className="fas fa-redo"></i>
                                </button>
                                <PrintButton onClick={printClick}/>
                            </div>
                        </div>
                        <Table>
                            <Thead headers={headers}/>
                            <tbody>
                                {
                                    data?.map?.((row ,index) => <Row
                                        key={index}
                                        row={row}
                                        editAction={() => {
                                            cancelModal()
                                            setEditAction(row?.id)
                                        }}
                                        deleteAction={() => {
                                            cancelModal()
                                            setDeleteAction({id: row?.id ,name: `${row?.type} ,${row?.cash_type} ,${row?.amount} ${translate("EGP")}`})
                                        }}
                                    />)
                                }
                            </tbody>
                        </Table>
                        <Pagination currentPage={currentPage} dataPerPage={dataPerPage} meta={meta}
                            changeDataPerPage={setDataPerPage} changeCurrentPage={setCurrentPage}/>
                    </div>
                </div>
            </div>
            {
                addAction ?
                <Add
                    cancel={cancelModal}
                    entityUrl={entityUrl}
                    reloadData={reloadForAdd}
                />
                : null
            }
            {
                editAction ?
                <Edit
                    id={editAction}
                    cancel={cancelModal}
                    entityUrl={entityUrl}
                    reloadData={loadData}
                />
                : null
            }
            {
                deleteAction?.id ?
                <Delete
                    id={deleteAction?.id}
                    name={deleteAction?.name}
                    entityUrl={entityUrl}
                    cancel={cancelModal}
                    reloadData={reloadForDelete}
                />
                : null
            }
        </div>
    )
}

export default Index