import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import CameThrough from "./Inputs/CameThrough"
import Membership from "./Inputs/Membership"
import Personal from "./Inputs/Personal"
import Preview from "./Inputs/Preview"

const Add = ({cancel, reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members`

    const [member ,setMember] = useState({
        employee_id: '' ,name: '' ,national_id: '' ,gender: '' ,age: '' ,height: '' ,weight: '' ,phone_number: '' ,email: '',
        subscription_id: '' ,subscription_start: ''
    })
    const [employeeType ,setEmployeeType] = useState('salesman')
    const [photo ,setPhoto] = useState(undefined)
    const [currentTab ,setCurrentTab] = useState('camethrough')
    const [chosenSalesman ,setChosenSalesman] = useState({name: translate('Employee') ,value: ''})
    const [gender ,setGender] = useState({name: translate('Gender') ,value: ''})
    const [subscription ,setSubscription] = useState({id: undefined ,title: translate('Subscription') ,price: 0})
    const [sessions ,setSessions] = useState([/*{id: undefined ,title: '' ,times: 0 ,price: 0}*/])
    const [coaches ,setCoaches] = useState([/*{id: undefined ,title: '' ,times: 0 ,price: 0}*/])
    const [cameThroughError ,setCameThroughError] = useState('')
    const [personalErrors ,setPersonalErrors] = useState({
        name: '' ,national_id: '' ,gender: '' ,age: '' ,height: '' ,weight: '' ,phone_number: '' ,email: ''
    })
    const [membershipErrors ,setMembershipErrors] = useState({subscription: '' ,subscription_start: '' ,sessions: '' ,coaches: ''})
    const [btnDisabled ,setBtnDisabled] = useState(false)

    const addMember = () => {
        const formData = new FormData()
        Object.keys(member).forEach(key => formData.append(key ,member[key]))
        sessions.forEach((session ,index) => {
            if (session.id) {
                formData.append(`sessions[${index}][session_id]` ,session?.id)
                formData.append(`sessions[${index}][times]` ,session?.times)
            }
        })
        coaches.forEach((coach ,index) => {
            if (coach.id) {
                formData.append(`privateCoach[${index}][private_coach_id]` ,coach?.id)
                formData.append(`privateCoach[${index}][times]` ,coach?.times)
            }
        })
        if (photo) formData.append('photo' ,photo)
        setBtnDisabled(true)
        
        Axios
        .setAuthToken(gymToken)
        .post(entityUrl ,formData)
        .then(response => {
            setBtnDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("createdSuccessfully")}`)
            cancel()
            reloadData()
        })
        .catch(err => {
            setBtnDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) {
                const validation = error?.response?.data?.errors
                let isCurrentTabChanges = false
                if (validation.employee_id) {
                    setCameThroughError(validation.employee_id)
                    setCurrentTab('camethrough')
                    isCurrentTabChanges = true
                }
                setPersonalErrors({...personalErrors ,...validation})
                if (!isCurrentTabChanges && (
                    validation.name || validation.national_id || validation.gender || validation.age ||
                    validation.height || validation.weight || validation.phone_number || validation.email
                )) {
                    setCurrentTab('personal')
                    isCurrentTabChanges = true
                }
                const subscErrors = {...membershipErrors} ,sessionErrors = [] ,coachErrors = []
                Object.entries(validation).forEach(error => {
                    const key = error[0] ,value = error[1]
                    if (key === 'subscription_id') subscErrors.subscription = value
                    else if (key === 'subscription_start') subscErrors.subscription_start = value
                    else if (key.includes('sessions')) sessionErrors.push(value)
                    else if (key.includes('privateCoach')) coachErrors.push(value)
                })
                subscErrors.sessions = sessionErrors.join(" ,")
                subscErrors.coaches = coachErrors.join(" ,")
                setMembershipErrors(subscErrors)
                if (!isCurrentTabChanges) {
                    setCurrentTab('membership')
                    isCurrentTabChanges = true
                }
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        currentTab === 'camethrough' ?
        <CameThrough
            employeeType={employeeType}
            setEmployeeType={setEmployeeType}
            chosenSalesman={chosenSalesman}
            setChosenSalesman={setChosenSalesman}
            cancel={cancel}
            clickNext={() => setCurrentTab('personal')}
            changeEmployee={employeeId => setMember({...member ,employee_id: employeeId})}
            error={cameThroughError}
        />
        :
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddMember")} cancel={cancel}/>
                    <div className="pages-navbar pad0">
                        <button className={currentTab === 'personal' ? 'active' : ''}> {translate("Personal")} </button>
                        <button className={currentTab === 'membership' ? 'active' : ''}> {translate("Membership")} </button>
                        <button className={currentTab === 'preview' ? 'active' : ''}> {translate("Overview")} </button>
                    </div>
                    {
                        currentTab === 'personal' ?
                        <Personal cancel={cancel}
                            clickNext={() => setCurrentTab('membership')}
                            clickPrevious={() => setCurrentTab('camethrough')}
                            changeMember={_member => {
                                const temp = {
                                    ...member,
                                    name: _member.name,
                                    national_id: _member.national_id,
                                    gender: _member.gender,
                                    age: _member.age,
                                    height: _member.height,
                                    weight: _member.weight,
                                    phone_number: _member.phone_number,
                                    email: _member.email
                                }
                                setMember(temp)
                            }}
                            member={{
                                name: member.name,
                                national_id: member.national_id,
                                gender: member.gender,
                                age: member.age,
                                height: member.height,
                                weight: member.weight,
                                phone_number: member.phone_number,
                                email: member.email
                            }}
                            photo={photo} setPhoto={setPhoto}
                            gender={gender} setGender={setGender}
                            errors={personalErrors}
                        />
                        : ''
                    }
                    {
                        currentTab === 'membership' ?
                        <Membership
                            subscription={{...subscription ,start: member.subscription_start}}
                            changeSubscription={subsc => {
                                setMember({...member ,subscription_id: subsc?.id})
                                setSubscription(subsc)
                            }}
                            changeStart={e => setMember({...member ,subscription_start: e.target.value})}
                            sessions={sessions}
                            setSessions={setSessions}
                            coaches={coaches}
                            setCoaches={setCoaches}
                            clickNext={() => setCurrentTab('preview')}
                            clickPrevious={() => setCurrentTab('personal')}
                            errors={membershipErrors}
                        />
                        : ''
                    }
                    {
                        currentTab === 'preview' ?
                        <Preview
                            clickPrevious={() => setCurrentTab('membership')}
                            addMember={addMember}
                            subscription={subscription}
                            sessions={sessions}
                            coaches={coaches}
                            disabled={btnDisabled}
                        />
                        : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Add