import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import FieldError from "../../../../FormShared/FieldError"
import ModalButtons from "../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../FormShared/ModalHeader"
import AppContext from "../../../../partials/AppContext"
import SearchBox from "../../../../Shared/SearchBox"

const MemberSelect = ({cancel ,next ,memberId ,setMemberId ,error}) => {
    const alert = useAlert()
    const {gymToken ,clearUserData ,translate} = useContext(AppContext)
    const [members ,setMembers] = useState([])
    const [selectedMember ,setSelectedMember] = useState({id: undefined ,name: 'Member ID'})
    const [loadMemErr ,setLoadMemErr] = useState('')
    const [searchMember ,setSearchMember] = useState('')

    useEffect(() => {
        let source = axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${Config.apiBaseUrl}/gym-dashboard-services/orders/getAttendedMembers` ,{cancelToken: source.token})
        .then(response => {
            if (response?.data?.length <= 0) {
                setLoadMemErr(translate('AttendMemberFirst'))
            }
            setMembers(response?.data)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })

        // clean up ,solve future memory leak
        return () => source.cancel()
    } ,[setMembers ,gymToken ,alert ,clearUserData])

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("PlaceOrder")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section">
                            <div className="col-sm-12 pad0 toogle-textfield">
                                {
                                    selectedMember?.id ?
                                    <span className="mtb-0-mlr-10"> {selectedMember?.id_number} ,{selectedMember?.name} </span>
                                    : null
                                }
                                <SearchBox
                                    placeholder={translate("SearchFullMemberID")}
                                    extraClass="custom-search-box"
                                    extraParentClass="custom-parent-search-box"
                                    searchTrigger={() => {
                                        if (searchMember !== '') {
                                            const filtered = members.filter(m => `${m.id_number}` === searchMember)
                                            if (filtered.length >= 1) {
                                                setMemberId(filtered[0]?.id)
                                                setSelectedMember({
                                                    id: filtered[0]?.id,
                                                    name: filtered[0]?.name,
                                                    id_number: filtered[0]?.id_number
                                                })
                                            } else {
                                                setLoadMemErr(translate("MemberNotAttend"))
                                            }
                                        } else {
                                            setLoadMemErr(`${translate("Please")} ${translate("FullMemberID")}`)
                                            setMemberId(undefined)
                                            setSelectedMember({
                                                id: undefined,
                                                name: '',
                                                id_number: ''
                                            })
                                        }
                                    }}
                                    setKeyword={setSearchMember}
                                    keyword={searchMember}/>
                                {error ? <FieldError message={error} /> : ''}
                                {loadMemErr ? <FieldError message={loadMemErr} /> : ''}
                            </div>
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            confirm={next}
                            confirmLabel={translate("Next")}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default MemberSelect