import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import ModalHeader from "../../../FormShared/ModalHeader"
import ModalButtons from "../../../FormShared/ModalButtons"
import SupplementInputs from "./SupplementInputs"
import Axios from "../../../../Axios"
import Loading from "../../../Shared/Loading"

const Edit = ({cancel ,reloadData ,id}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/stock/supplements`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const [supplementData ,setSupplementData] = useState({
        brand: {name: translate('Brand') ,id: ''} ,category: {name: translate('Category') ,id: ''},
        unit: {name: translate('Unit') ,id: ''} ,size: {name: translate('Size') ,id: ''} ,newCategory: {name_en: '' ,name_ar: ''},
        quantity: '' ,purchase_price: '' ,selling_price: '' ,low_quantity: ''
    })
    const [errors ,setErrors] = useState({name: '' ,brand_id: '' ,category_id: '' ,unit_id: '' ,size_id: ''})
    const [loaded ,setLoaded] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        const data = {
            _method: 'PUT',
            brand_id: supplementData?.brand?.id,
            category_id: supplementData?.category?.id,
            unit_id: supplementData?.unit?.id,
            size_id: supplementData?.size?.id,
            quantity: supplementData?.quantity,
            selling_price: supplementData?.selling_price,
            low_quantity: supplementData?.low_quantity
        }
        if (data.category_id === '-1') {
            delete data.category_id
            data['name_en'] = supplementData?.newCategory?.name_en
            data['name_ar'] = supplementData?.newCategory?.name_ar
        }
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/${id}` ,data)
        .then(response => {
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
            setDisabled(false)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            setDisabled(false)
        })
    }

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/edit/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setLoaded(true)
            setSupplementData(response?.data?.data)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[gymToken ,clearUserData ,entityUrl ,id ,setSupplementData ,alert ,setLoaded])

    return (    
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditSupplement")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                                <div className="section">
                                    <SupplementInputs
                                        setSupplementData={setSupplementData}
                                        supplementData={supplementData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        readOnly={true}
                                        qntReadOnly={true}
                                    />
                                </div>
                            : <Loading/>
                        }
                        <ModalButtons
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("EditSupplement")}
                            disabled={disabled}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Edit