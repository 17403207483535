import { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import Config from '../../../Config'
import axios from '../../../Axios'
import Navbar from '../../partials/Navbar'
import SearchBox from '../../Shared/SearchBox'
import PrintButton from '../../Shared/PrintButton'
import AppContext from '../../partials/AppContext'
import Thead from '../../Shared/Thead'
import Table from '../../Shared/Table'
import RoleRow from './RoleRow'
import EditRole from './EditRole'
import DeleteRole from './DeleteRole'
import AddRole from './AddRole'

const Index = () => {
    const axiosSource = axios.CancelToken.source()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/roles`
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    let [items ,setItems] = useState([])
    const [invokeEdit ,setInvokeEdit] = useState(undefined)
    const [invokeDelete ,setInvokeDelete] = useState(undefined)
    const [invokeAdd ,setInvokeAdd] = useState(undefined)

    const editItem = (itemId) => {
        cancelModal()
        setInvokeEdit(itemId)
    }

    const deleteItem = (itemId ,itemName) => {
        cancelModal()
        setInvokeDelete({itemId ,itemName})
    }

    const addItem = () => {
        cancelModal()
        setInvokeAdd(true)
    }

    const cancelModal = () => {
        setInvokeDelete(undefined)
        setInvokeEdit(undefined)
        setInvokeAdd(undefined)
    }

    const loadData = () => {
        axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=300` ,{cancelToken: axiosSource.token})
        .then(response => {
            const rolesCount = response?.data?.data?.length,
                roleCountKey = `AccountRole${rolesCount > 1 ? 's' : ''}`
            setItems(response?.data?.data)
            setHeaderTitle(`${rolesCount} ${translate(roleCountKey)}`)
        })
        .catch(err => {
            const error = {...err} ,response = error?.response?.data
            if (response?.message) alert.error(response?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setItems ,setHeaderTitle ,entityUrl ,alert])

    const searchTrigger = () => loadData()

    const headers = [
        {value: translate('Role')},
        {value: translate('ArabicRole')},
        {value: translate('AddedDate')},
        {value: translate('Actions'), className: 'actions'}
    ]

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,gymToken)

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate('AccountRoles')}/>
            </div>
            <div className="page-content">
                <div className="page-section header-box">
                    <div className="row header">
                        <SearchBox searchTrigger={searchTrigger} setKeyword={setKeyword} keyword={keyword}/>
                        <button onClick={addItem} className="btn btn-light">{translate('AddRole')}</button>
                    </div>
                </div>
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <PrintButton onClick={printClick}/>
                        </div>
                        <Table>
                            <Thead headers={headers}/>
                            <tbody>
                                { items.map(item => <RoleRow key={item.id} item={item} editItem={editItem} deleteItem={deleteItem}/>) }
                            </tbody>
                        </Table>
                    </div>
                </div>
                {invokeAdd ? <AddRole cancel={cancelModal} reloadData={loadData} /> : ''}
                {invokeEdit ? <EditRole itemId={invokeEdit} cancel={cancelModal} reloadData={loadData} /> : ''}
                {invokeDelete ?
                    <DeleteRole itemId={invokeDelete.itemId} cancel={cancelModal} reloadData={loadData}
                    itemName={invokeDelete.itemName} /> : ''
                }
            </div>
        </div>
    )
}

export default Index