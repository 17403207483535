import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import ModalHeader from '../../../FormShared/ModalHeader'
import ModalButtons from '../../../FormShared/ModalButtons'
import Loading from '../../../Shared/Loading'
import './AttendExtra.css'

const AttendExtra = ({attendRow ,cancel ,reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/attendance`

    const [loaded ,setLoaded] = useState(false)
    const [disabled ,setDisabled] = useState(false)
    const [sessions ,setSessions] = useState([])
    const [classes ,setClasses] = useState([])
    const [selectedSessions ,setSelectedSessions] = useState([])
    const [selectedClasses ,setSelectedClasses] = useState([])
    
    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/getExtraSessions/${attendRow?.member_id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setSessions(response?.data?.data?.extraSessions)
            setClasses(response?.data?.data?.extraPrivateCoaches)
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[gymToken ,alert ,entityUrl ,attendRow ,clearUserData ,setLoaded ,setSessions ,setClasses])

    const changeSession = session => {
        if (selectedSessions.findIndex(selectedSession => selectedSession === session?.id) === -1)
            setSelectedSessions([...selectedSessions ,session?.id])
        else setSelectedSessions([...selectedSessions?.filter(selectedSession => selectedSession !== session?.id)])
    }

    const changeClass = _class => {
        if (selectedClasses.findIndex(selectedClass => selectedClass === _class?.id) === -1)
            setSelectedClasses([...selectedClasses ,_class?.id])
        else setSelectedClasses([...selectedClasses?.filter(selectedClass => selectedClass !== _class?.id)])
    }

    const saveChanges = () => {
        setDisabled(true)
        const data = new FormData()
        if (selectedClasses?.length > 0) {
            selectedClasses?.forEach((_class ,index) => data.append(`coaches[${index}][member_private_coach_id]` ,_class))
        }
        if (selectedSessions?.length > 0) {
            selectedSessions?.forEach((session ,index) => data.append(`sessions[${index}][member_session_id]` ,session))
        }
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/addExtraSessions/${attendRow?.id}` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("AttendSuccessfully")}`)
            cancel()
            reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AttendSession")} cancel={cancel} />
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                            <>
                                <div className="section mb-2">
                                    {
                                        sessions?.map(session => <label key={Math.random()} class="checkbox attend-label-row">
                                            <div class="checkbox-btn attend-label-child">
                                                <input type="checkbox"
                                                    checked={selectedSessions?.includes(session?.id)}
                                                    onChange={() => changeSession(session)}/>
                                                <span class="input-checkbox"></span>
                                            </div>
                                            <h6 class="checkbox-text attend-label-child"> {session?.name} </h6>
                                            <h6 class="checkbox-text attend-label-child attend-session-left"> {session?.left} </h6>
                                        </label>)
                                    }
                                    {
                                        classes?.map(_class => <label key={Math.random()} class="checkbox attend-label-row">
                                            <div class="checkbox-btn attend-label-child">
                                                <input type="checkbox"
                                                    checked={selectedClasses?.includes(_class?.id)}
                                                    onChange={() => changeClass(_class)}/>
                                                <span class="input-checkbox"></span>
                                            </div>
                                            <h6 class="checkbox-text attend-label-child"> {_class?.name} </h6>
                                            <h6 class="checkbox-text attend-label-child attend-session-left"> {_class?.left} </h6>
                                        </label>)
                                    }
                                </div>
                                <ModalButtons confirmLabel={translate("Attend")} disabled={disabled} confirm={saveChanges} cancel={cancel} />
                            </>
                            : <Loading/>
                        }
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default AttendExtra