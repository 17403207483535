const Thead = (props) => {
    const headers = props?.headers
    return (
        <thead>
            <tr>
                {
                    headers?.map(header =>
                        <th
                            key={Math.random()}
                            className={header?.className}
                            colSpan={header.colSpan ? header.colSpan : 1}
                            width={header?.width}>
                            {header?.value}
                            {header.loadChildren ? props.children : ''}
                        </th>
                    )
                }
            </tr>
        </thead>
    )
}

export default Thead