const words = {
    TotalEarnings: "جميع الأرباح",
    YesDelete: "نعم حذف",
    UpdatedSuccessfully: "تم التعديل بنجاح",
    VIEW: "العرض",
    Username: "إسم المستخدم",
    UploadImgTitle: "رفع الصورة (إختياري)",
    UploadImgMsg: "الصورة هنا",
    WorkingHours: "ساعات العمل",
    Website: "الموقع الإلكتروني",
    TrainDuration: "وقت التمرين",
    To: "إلي",
    Update: "تعديل",
    Tuesday: "الثلاثاء",
    Wednesday: "الأربعاء",
    Thursday: "الخميس",
    Tue: "الثلاثاء",
    Wed: "الأربعاء",
    Thu: "الخميس",
    YesCheckout: "نعم تسجيل الخروج",
    Target: "الهدف",
    Time: "الوقت",
    Year: "السنة",
    Type: "النوع",
    UploadImages: "رفع الصور",
    UploadImage: "رفع صورة",
    Unit: "الوحدة",
    ValidNumber: "يجب ان يكون رقما صحيحا",
    UpdatedDate: "تاريخ التعديل",
    YesCancel: "نعم إلغاء",
    Times: "الاوقات",
    WeightKG: "الوزن (بالكيلو)",
    Total: "الإجمالي",
    Years: "السنوات",
    Weight: "الوزن",
    YesDeactivate: "نعم إلغاء التفعيل",
    YesActivate: "نعم قم بالتفعيل",
    YesUnfreeze: "نعم إلغاء التجميد",
    Upgrade: "تحديث",
    VIPSessions: "جلسات خاصة",
    TotalPrice: "إجمالي السعر",
    UnfreezeAccount: 'إلغاء تجميد الحساب',
    YesPay: "نعم دفع",
    Twitter: "تويتر",
    Title: "العنوان",
    UpdateOffer: "تعديل العرض",
    UpdateClass: "تعديل الفصل",
    YouMostRefund: "و يجب عليك إسترجاع",
    YouWillPayHim: "من فضلك اعلم انك ستقوم بالدفع له",
    Week: "اسبوع",
    YearOverview: "مراجعة السنة الآخيرة",
    WeekOverview: "مراجعة الأسبوع الآخير",
    TotalRevenue: "إجمالي الربح",
    ThirtyDaysOverview: "مراجعة آخر 30 يوم",
    VisitsNumber: "أقصي عدد الزيارات",
}

export default words