const index = ({rootStyle ,title ,subTitle ,statValue}) => {
    return (
        <div style={rootStyle}>
            <span> {title} </span>
            <span className="bold"> { statValue } </span>
            <span> {subTitle} </span>
        </div>
    )
}

export default index