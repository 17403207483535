import { useAlert } from 'react-alert'
import { useContext, useState } from 'react'
import axios from "../../../Axios"
import Config from '../../../Config'
import ModalHeader from '../../FormShared/ModalHeader'
import ModalButtons from '../../FormShared/ModalButtons'
import AccountCommonInputs from './AccountCommonInputs'
import PhotoInput from '../../FormShared/PhotoInput'
import AppContext from '../../partials/AppContext'
import '../../../css-files/form.css'

const AddAccount = ({cancel ,reloadData}) => {
    const alert = useAlert()
    const [account ,setAccount] = useState({username: '' ,email: '' ,name: '' ,phone: '' ,password: '' ,role: {id: '' ,name: ''}})
    const [errors ,setErrors] = useState({username: '' ,email: '' ,name: '' ,phone: '' ,password: '' ,role: '' ,photo: '' ,branch_id: '' ,employee_id: ''})
    const [branch ,_setBranch] = useState({id: 0 ,name: ''})
    const [employee ,setEmployee] = useState({id: 0 ,name: ''})
    const [photo ,setPhoto] = useState(undefined)
    const {clearUserData ,gymToken ,gymAccountType ,translate} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    
    const updateAccountAttr = (value ,attr) => {
        if (attr === 'photo') {
            setPhoto(value)
        } else {
            const _account = {...account}
            _account[attr] = value
            setAccount(_account)
        }
        const _errors = errors
        _errors[attr] = ''
        setErrors(_errors)
    }

    const addRole = (id ,name) => {
        setAccount({...account ,role: {id ,name}})
    }

    const storeAccount = () => {
        setDisabled(true)
        const formData = new FormData()
        Object.keys(account).forEach(attr => formData.append(attr ,account[attr]))
        formData.set('gym_roles[0]' ,account.role.id)
        if (photo) formData.set('photo' ,photo)
        if (gymAccountType === 'owner')
            formData.set('branch_id' ,branch.id)
        else
            formData.set('employee_id' ,employee?.id)
        axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/gym-accounts` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(`${translate('Account')} : ${response?.data?.data?.name} ,${translate('createdSuccessfully')}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const setBranch = _branch => {
        _setBranch(_branch)
        setErrors({...errors ,branch_id: ''})
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddAccount")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <PhotoInput
                            isOptional={true}
                            description={translate('UploadImgMsg')}
                            updatePhoto={(img) => updateAccountAttr(img ,'photo')}
                            error={errors.photo} />
                        <div className="section">
                            <AccountCommonInputs
                                addRole={addRole}
                                setBranch={setBranch}
                                branch={branch}
                                account={account}
                                updateAccountAttr={updateAccountAttr}
                                setEmployee={setEmployee}
                                employee={employee}
                                errors={errors} />
                        </div>
                        <ModalButtons disabled={disabled} cancel={cancel} confirm={storeAccount} confirmLabel={translate("AddAccount")} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddAccount