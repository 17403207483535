import { useContext } from "react"
import AppContext from "../../partials/AppContext"
import Navbar from "../../partials/Navbar"
import Statistics from './Statistics/Index'

const OwnerEarnings = () => {
    const {translate} = useContext(AppContext)

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Earnings")}/>
            </div>
            <div className="page-content">
                <Statistics loadCounters={true} visible={true}/>
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default OwnerEarnings