import { useContext } from "react"
import DropDown from "../../../FormShared/DropDown"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import PhotoInput from "../../../FormShared/PhotoInput"
import AppContext from "../../../partials/AppContext"

const styles = {
    photoContainer: {
        width: 262
    },
    sectionContainer: {
        marginBottom: 10,
        width: 696 - 262,
        paddingLeft: 16,
        paddingRight: 16
    },
    dropDownStyle: {
        marginTop: 25
    }
}

const GymExpensesInput = ({
    expenses,
    setExpenses,
    selectedCategory,
    setSelectedCategory,
    categories,
    subCategories,
    setSubCategories,
    selectedSubCategory,
    setSelectedSubCategory,
    errors,
    setErrors,
    setReceipt,
}) => {
    const {translate} = useContext(AppContext)

    return (
        <div className="flex-row">
            <div style={styles.photoContainer}>
                <PhotoInput
                    defaultImg={expenses?.receipt}
                    error={errors.receipt}
                    description={translate("UploadImage") + ' ' + translate("Receipt")}
                    updatePhoto={(receipt) => {
                        setErrors({...errors ,receipt: ''})
                        setReceipt(receipt)
                    }} />
            </div>
            <div className="section" style={styles.sectionContainer}>
                <DropDown
                    selectedName={selectedCategory?.name}
                    textColorBlack={expenses.category}
                    list={categories}
                    itemName="name"
                    select={category => {
                        setSelectedCategory({name: category?.name ,value: category?.id})
                        setErrors({...errors ,category: ''})
                        setExpenses({...expenses ,category: category?.id ,sub_category: ''})
                        setSubCategories(category?.sub_categories)
                        setSelectedSubCategory({name: translate('SubCategory') ,id: ''})
                    }} />
                {errors.category ? <FieldError message={errors.category} /> : ''}

                <DropDown
                    rootStyle={styles.dropDownStyle}
                    selectedName={selectedSubCategory?.name}
                    textColorBlack={expenses.sub_category}
                    list={subCategories}
                    itemName="name"
                    select={category => {
                        setSelectedSubCategory({name: category?.name ,value: category?.id})
                        setErrors({...errors ,sub_category: ''})
                        setExpenses({...expenses ,sub_category: category?.id})
                    }} />
                {errors.sub_category ? <FieldError message={errors.sub_category} /> : ''}
                
                <Input placeholder={translate("Price")} value={expenses?.price} showSpanHint={translate("EGP")}
                    onChange={e => {
                        const v = e.target.value
                        if (isNaN(v)) {
                            setErrors({...errors ,price: translate('NumberOnly')})
                            return
                        }
                        setErrors({...errors ,price: ''})
                        setExpenses({...expenses ,price: v})
                    }}/>
                {errors.price ? <FieldError message={errors.price}/> : ''}
            </div>
        </div>
    )
}

export default GymExpensesInput