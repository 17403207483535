import axios from "axios"
import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import DropDown from "../../../FormShared/DropDown"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import Row from "./Row"
import Refund from "./Refund"

const Index = ({visible ,data ,setData}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/history/orders`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword
    const [selectedMonth ,setSelectedMonth] = useState(translate('All'))
    const [selectedYear ,setSelectedYear] = useState(translate('All'))
    const [month ,setMonth] = useState('')
    const [year ,setYear] = useState('')

    const [refundPartial ,setRefundPartial] = useState(undefined)

    const cancel = () => {
        setRefundPartial(undefined)
    }

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}&month=${month}&year=${year}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate(`LeftoverOrder${responseData?.length > 1 ? 's' : ''}`)}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,entityUrl ,dataPerPage ,currentPage ,alert ,clearUserData ,month ,year])

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForDelete = () => {
        if (dataList.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}&month=${month}&year=${year}` ,true ,gymToken)

    const changeMonth = (month) => {
        setMonth(month.value)
        setSelectedMonth(month.name)
    }

    const changeYear = (year) => {
        setYear(year.value)
        setSelectedYear(year.name)
    }

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}/>
                    <DropDown
                        placeholder={translate('Month')}
                        selectedName={selectedMonth}
                        textColorBlack={selectedMonth !== translate('All')}
                        list={Config.monthsNameValue.map(m => {return {...m ,name: translate(m.name)}})}
                        itemName="name"
                        select={changeMonth} />
                    <DropDown
                        placeholder={translate('Year')}
                        selectedName={selectedYear}
                        textColorBlack={selectedYear !== translate('All')}
                        list={Config.yearsNameValue().map(y => {return {...y ,name: translate(y.name)}})}
                        itemName="name"
                        select={changeYear} />
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={triggerSearch}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('OrderID')},
                            {value: translate('Member')},
                            {value: translate('IDNumber')},
                            {value: translate('Barman')},
                            {value: translate('DateTime')},
                            {value: translate('Cost')},
                            {value: translate('Actions') ,className: 'actions'}
                        ]}/>
                        <tbody>
                            {
                                dataList?.map(
                                    row => <Row key={Math.random()}
                                        row={row}
                                        refundPartial={() => {
                                            cancel()
                                            setRefundPartial(row?.id)
                                        }}
                                    />
                                )
                            }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n})}/>
                </div>
            </div>
            {
                refundPartial ? <Refund id={refundPartial} cancel={cancel} reloadData={reloadForDelete}/> : ''
            }
        </div>
    )
}

export default Index