import {useAlert} from 'react-alert'
import { useContext, useEffect, useState } from "react"
import axios from "../../../Axios"
import Config from '../../../Config'
import './form.css'
import ModalHeader from '../../FormShared/ModalHeader'
import FieldError from '../../FormShared/FieldError'
import Input from '../../FormShared/Input'
import ModalButtons from '../../FormShared/ModalButtons'
import RolePermittedEntities from './RolePermittedEntities'
import AppContext from "../../partials/AppContext"
import Loading from '../../Shared/Loading'

const EditRole = ({itemId ,cancel ,reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entity_url = `${Config.apiBaseUrl}/gym-dashboard-services/roles/${itemId}`

    const [errors ,setErrors] = useState({name_en: '', name_ar: '', permissions: ''})
    const [item ,setItem] = useState({name_ar: '' ,name_en: '' ,permissions: []})
    const [loaded ,setLoaded] = useState(false)
    const [disabled ,setDisabled] = useState(false)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(gymToken)
        .get(entity_url ,{cancelToken: axiosSource.token})
        .then(response => {
            setItem(response.data.data)
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err} ,response = error.response.data
            cancel()
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setItem ,setLoaded ,entity_url ,alert ,gymToken])

    const updateItem = () => {
        setDisabled(true)
        axios
        .setAuthToken(gymToken)
        .post(entity_url ,{...item, _method: 'PUT'})
        .then(response => {
            setDisabled(false)
            cancel()
            alert.success(`${translate('Role')} "${item.name_en}" ${translate('updatedSuccessfully')}`)
            try {
                reloadData()
            } catch (err) {}
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,response = error.response.data
            if (response.errors) {setErrors(response.errors)}
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeNameEn = (e) => {
        setItem({...item ,name_en: e.target.value})
        setErrors({...errors ,name_en: ''})
    }

    const changeNameAr = (e) => {
        setItem({...item ,name_ar: e.target.value})
        setErrors({...errors ,name_ar: ''})
    }

    return (
        <div id="forms">
            <div id="add-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditRole")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                            <>
                                <div className="section pb-1">
                                    <div className="row">
                                        <div className="col-sm-6 pl-0">
                                            <Input placeholder={translate("RoleName")} value={item.name_en} onChange={changeNameEn}/>
                                            {errors.name_en ? <FieldError message={errors.name_en}/> : ''}
                                        </div>
                                        <div className="col-sm-6 pr-0">
                                            <Input placeholder={translate("ArRoleName")} isAr={true} value={item.name_ar} onChange={changeNameAr}/>
                                            {errors.name_ar ? <FieldError message={errors.name_ar}/> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="toggle-section mb-4">
                                    <div className="input-label mb-2">
                                        <label htmlFor="branch-adding">{translate('RolePermissions')}</label>
                                        {errors.permissions ? <FieldError message={errors.permissions}/> : ''}
                                    </div>
                                    <RolePermittedEntities
                                        itemPermissions={item.permissions}
                                        cancel={cancel}
                                        updateItemPermission={(permissions) => setItem({...item ,permissions})}/>
                                </div>
                                <ModalButtons disabled={disabled} cancel={cancel} confirm={updateItem} confirmLabel={translate("EditRole")} />
                            </>
                            :
                            <Loading/>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditRole