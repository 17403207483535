import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../Axios"
import Config from "../../../Config"
import DropDown from "../../FormShared/DropDown"
import AppContext from "../../partials/AppContext"
import Pagination from "../../Shared/Pagination"
import PrintButton from "../../Shared/PrintButton"
import SearchBox from "../../Shared/SearchBox"
import Table from "../../Shared/Table"
import Thead from "../../Shared/Thead"

const Stock = ({data ,setData ,visible}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/safe/stock`
    const equipments = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword
    const [selectedMonth ,setSelectedMonth] = useState({name: translate('All') ,value: undefined})
    const [selectedYear ,setSelectedYear] = useState({name: translate('All') ,value: undefined})
    const [selectedCategory ,setSelectedCategory] = useState({name: translate('All') ,value: undefined})
    const [selectedSubCategory ,setSelectedSubCategory] = useState({name: translate('All') ,value: undefined})
    const [month ,setMonth] = useState('')
    const [year ,setYear] = useState('')
    const [category ,setCategory] = useState('')
    const [subCategory ,setSubCategory] = useState('')

    const [categories ,setCategories] = useState([])
    const [subCategories ,setSubCategories] = useState([])

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const getQuery = () => {
        let query = `keyword=${keyword}`
        query += `&limit=${dataPerPage}`
        query += `&page=${currentPage}`
        if (selectedMonth?.value) query += `&month=${selectedMonth?.value}`
        if (selectedYear?.value) query += `&year=${selectedYear?.value}`
        if (selectedCategory?.value) query += `&category=${selectedCategory?.value}`
        if (selectedSubCategory?.value) query += `&subcategory=${selectedSubCategory?.value}`

        return query
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?${getQuery()}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate("Item")}`,
                list: responseData,
                meta: meta
            })
            executeScroll()

        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        if (categories.length <= 0 || subCategories.length <= 0) {
            Axios
            .setAuthToken(gymToken)
            .get(`${entityUrl}/categories` ,{cancelToken: axiosSource.token})
            .then(response => {
                setCategories([{name: translate('All') ,value: undefined} ,...response?.data?.data?.categories])
                setSubCategories([{name: translate('All') ,value: undefined} ,...response?.data?.data?.subcategories])
            })
            .catch(err => {
                const error = {...err}
                if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
                if (error?.response?.status === 401) clearUserData()
            })
        }
        return () => axiosSource.cancel()
    }, [
        alert ,gymToken ,clearUserData ,entityUrl ,dataPerPage ,
        currentPage ,month ,year ,category ,subCategory
    ])

    const printClick = () => {
        return Config.printByToken(`${entityUrl}/print?${getQuery()}` ,true ,gymToken)
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <DropDown
                        placeholder={translate('Month')}
                        selectedName={selectedMonth?.name}
                        textColorBlack={selectedMonth?.value}
                        list={Config.monthsNameValue.map(m => {return {...m ,name: translate(m.name)}})}
                        itemName="name"
                        select={m => {
                            setSelectedMonth(m)
                            setMonth(m?.value)
                        }} />
                    <DropDown
                        placeholder={translate('Year')}
                        selectedName={selectedYear?.name}
                        textColorBlack={selectedYear?.value}
                        list={Config.yearsNameValue().map(y => {return {...y ,name: translate(y.name)}})}
                        itemName="name"
                        select={y => {
                            setSelectedYear(y)
                            setYear(y?.value)
                        }} />
                    <DropDown
                        placeholder={translate('Type')}
                        selectedName={selectedCategory?.name}
                        textColorBlack={selectedCategory?.value}
                        list={categories}
                        itemName="name"
                        select={c => {
                            setSelectedCategory(c)
                            setCategory(c?.value)
                        }} />
                    <DropDown
                        placeholder={translate("Category")}
                        selectedName={selectedSubCategory?.name}
                        textColorBlack={selectedSubCategory?.value}
                        list={subCategories}
                        itemName="name"
                        select={c => {
                            setSelectedSubCategory(c)
                            setSubCategory(c?.value)
                        }} />
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Item')},
                            {value: translate('Type')},
                            {value: translate('Category')},
                            {value: translate('Quantity')},
                            {value: translate('Date')},
                            {value: translate('AddedBy')},
                            {value: translate('Cost')}
                        ]}/>
                        <tbody>
                            {
                                equipments?.map((row ,index) =>
                                    <tr key={index}>
                                        <td> {row.item} </td>
                                        <td> {row.type} </td>
                                        <td> {row.category} </td>
                                        <td> {row.quantity} </td>
                                        <td> {row.date} </td>
                                        <td> {row.added_by} </td>
                                        <td> {row.cost} </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                </div>
            </div>
        </div>
    )
}

export default Stock