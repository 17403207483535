import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Navbar from "../../partials/Navbar"
import DropDown from '../../FormShared/DropDown'
import FieldError from '../../FormShared/FieldError'
import Input from '../../FormShared/Input'
import './styles.css'
import AppContext from "../../partials/AppContext"
import axios from "../../../Axios"
import Config from "../../../Config"
import HelpCenterTypes from "../../../constants/HelpCenterTypes"

const Index = () => {
    const alert = useAlert() ,{clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [type ,setType] = useState({name: translate('Type') ,value: ''})
    const [subject ,setSubject] = useState('')
    const [message ,setMessage] = useState('')
    const [errors ,setErrors] = useState({type: '' ,subject: '' ,description: '' ,images: ''})
    const [images ,setImages] = useState([])
    const types = HelpCenterTypes()
    const [disabled ,setDisabled] = useState(false)

    const changeType = type => {
        setType(type)
        setErrors({...errors ,type: ''})
    }

    const changeMessage = e => {
        setMessage(e.target.value)
        setErrors({...errors ,description: ''})
    }

    const changeSubject = e => {
        setSubject(e.target.value)
        setErrors({...errors ,subject: ''})
    }

    const appendImage = (event) => {
        setErrors({...errors ,images: ''})
        if (event.target.files) {
            const files = event.target.files ,tempImages = [...images]
            Object.keys(files).forEach(index => tempImages.push(files[index]))
            setImages(tempImages)
        }
    }

    const removeImage = removedIndex => setImages(images?.filter((file ,index) => index !== removedIndex))

    const sendRequst = () => {
        const formData = new FormData()
        formData.append('type' ,type.value)
        formData.append('subject' ,subject)
        formData.append('description' ,message)
        Object.keys(images).forEach(index => {
            formData.append(`attachments[${index}]` ,images[index])
        })
        setDisabled(true)
        axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/help-center` ,formData)
        .then(response => {
            alert.success(response?.data?.message)
            setType({name: 'Type', value: ''})
            setMessage('')
            setSubject('')
            setImages([])
            setDisabled(false)
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("HelpCenter")}/>
            </div>
            <div className="page-content page-content-help-center">
                <div className="page-section page-section-help-center">
                    <DropDown selectedName={type?.name} list={types} className="full-width clear-margin"
                        textColorBlack={type?.value !== ''}
                        btnClassName="rounded-box rounded-box-help-center clear-margin"
                        itemName="name" select={changeType} />
                    {errors.type ? <FieldError message={errors.type}/> : ''}
                    <Input
                        placeholder={translate("Subject")} className="rounded-box rounded-box-help-center"
                        value={subject} onChange={changeSubject} />
                    {errors.subject ? <FieldError message={errors.subject}/> : ''}
                    <textarea
                        className="rounded-box rounded-box-help-center" placeholder={translate("Message")}
                        value={message} onChange={changeMessage}></textarea>
                    {errors.description ? <FieldError message={errors.description}/> : ''}
                    <div className="img-upload img-upload-help-center"
                        onClick={() => {
                            document.getElementById('file-uploader').click()
                        }}>
                        <span> {translate('helpCenterImgHint')} </span>
                        <div id="dropzone" className="dropzone dropzone-help-center">
                            <i className="fas fa-upload"></i>
                            <span className="palceholder palceholder-help-center">{translate("UploadImages")}</span>
                            <span id="drop-zone-files-text"></span>
                        </div>
                        <input id="file-uploader" onChange={appendImage} type="file" multiple accept=".jpg,.png,.jpeg"/>
                    </div>
                    {
                        images?.map((img ,index) => 
                            <div className="attachment-line" key={index}>
                                <div>
                                    <img alt="Gymme" className="small-image" src={URL.createObjectURL(img)}/>
                                    <span> {img?.name?.substring(0, 25) + '...'} </span>
                                </div>
                                <span className="red-text clickable" onClick={() => removeImage(index)}>
                                    <i className="fas fa-times"></i>
                                </span>
                            </div>
                        )
                    }
                    <button disabled={disabled} className="btn btn-light send send-help-center" onClick={sendRequst}>{translate("SendUs")}</button>
                </div>
            </div>
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default Index