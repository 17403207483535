import { useContext, useState } from "react"
import AppContext from "../../partials/AppContext"

const AccountCard = ({account ,editItem ,deleteItem}) => {
    const {translate} = useContext(AppContext)
    const [photo ,setPhoto] = useState(account?.photo)
    const [photoClass ,setPhotoClass] = useState('full-img')
    const getDefaultImage = () => {
        setPhoto("/assets/svg/logo-icon.svg")
        setPhotoClass("full-img default-img")
    }

    const name = account?.name ? account?.name : ''
    const username = account?.username ? account?.username : ''
    const email = account?.email ? account?.email : ''
    const phone = account?.phone ? account?.phone : ''
    let role = ''
    try {
        role = account.roles[0].name_en
    } catch (err) {}

    return (
        <div className="account-item">
            <div className="img">
                <img alt="Gymme" src={photo} onError={getDefaultImage} className={photoClass}/>
            </div>
            <div className="name-section">
                <span className="name"> {name.substring(0, 10) + (name.length >= 10 ? '...' : '')} </span>
                <div className="controls">
                    <button onClick={() => editItem(account?.id)}>
                        <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                    </button>
                    <button onClick={() => deleteItem(account?.id ,account?.name)}>
                        <img alt="Gymme" src="/assets/svg/delete.svg"/>
                    </button>
                </div>
            </div>
            <div className="content">
                <span className="title">{translate("Roles")} </span>
                <span className="data"> { role } </span>
                
                <span className="title">{translate("Username")} </span>
                <span className="data"> {username.substring(0, 15) + (username.length >= 15 ? '...' : '')} </span>

                <span className="title">{translate("Email")} </span>
                <span className="data"> {email.substring(0, 15) + (email.length >= 15 ? '...' : '')} </span>
                
                <span className="title">{translate("Phone")} </span>
                <span className="data"> {phone.substring(0, 15) + (phone.length >= 15 ? '...' : '')} </span>
            </div>
        </div>
    )
}

export default AccountCard