const Row = ({data ,deleteAction ,addMember}) => {

    return (
        <tr>
            <td> { data?.name } </td>
            <td> { data?.phone } </td>
            <td> { data?.province } </td>
            <td> { data?.city } </td>
            <td> { data?.member } </td>
            <td className="actions">
                <button onClick={addMember}>
                    <i className="fas fa-address-book"></i>
                </button>
                <button onClick={deleteAction}>
                    <i className="fas fa-trash-alt"></i>
                </button>
            </td>
        </tr>
    )
}

export default Row