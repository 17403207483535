import { useContext, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Navbar from '../../../../partials/Navbar'
import CrudIndexDS from '../../../../Shared/CrudIndexDS'
import General from './General/General'
import Attendance from './Attendance/Attendance'
import Membership from './Membership/Membership'
import Orders from './Orders/OrderIndex'
import History from './History'
import AppContext from '../../../../partials/AppContext'
import { useAlert } from 'react-alert'
import Config from '../../../../../Config'
import Axios from '../../../../../Axios'

const Index = () => {
    const alert = useAlert()
    const params = useParams() ,id = params.id
    const {translate ,systemLang ,clearUserData ,gymToken} = useContext(AppContext)

    const [showSection ,setShowSection] = useState('general')

    const [general ,setGeneral] = useState()
    const [attendanceData ,setAttendanceData] = useState({...CrudIndexDS})
    const [ordersData ,setOrdersData] = useState({...CrudIndexDS})
    const [historyData ,setHistoryData] = useState({...CrudIndexDS})
    const [membershipData ,setMembershipData] = useState({})

    const loadGeneralData = (cancelToken = undefined) => {
        const u = `${Config.apiBaseUrl}/gym-dashboard-services/members`
        const requestConfig = {}
        if (cancelToken) requestConfig.cancelToken = cancelToken

        Axios
        .setAuthToken(gymToken)
        .get(`${u}/members/general/${id}` ,requestConfig)
        .then(response => setGeneral(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const generalComponent = <General
        visible={showSection === 'general'}
        id={id}
        data={general}
        loadData={loadGeneralData}
    />,
    attendanceComponent = <Attendance
        visible={showSection === 'attendance'}
        memberId={id}
        data={attendanceData}
        setData={setAttendanceData}
    />,
    membershipComponent = <Membership
        visible={showSection === 'membership'}
        memberId={id}
        data={membershipData}
        setData={setMembershipData}
        loadGeneralData={loadGeneralData}
    />,
    ordersComponent = <Orders
        visible={showSection === 'orders'}
        memberId={id}
        data={ordersData}
        setData={setOrdersData}
    />,
    historyComponent = <History
        visible={showSection === 'history'}
        memberId={id}
        data={historyData}
        setData={setHistoryData}
    />

    const navbar = <>
        <NavLink to="/members" exact> <i className={`fas fa-arrow-${systemLang === 'ar' ? 'right' : 'left'} black-color`}></i> </NavLink> {translate("Members")}
    </>

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={navbar}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'general' ? 'active' : ''}
                    onClick={() => setShowSection('general')}>
                    {translate("General")}
                </button>
                <button className={showSection === 'membership' ? 'active' : ''}
                    onClick={() => setShowSection('membership')}>
                    {translate("Membership")}
                </button>
                <button className={showSection === 'attendance' ? 'active' : ''}
                    onClick={() => setShowSection('attendance')}>
                    {translate("Attendance")}
                </button>
                <button className={showSection === 'orders' ? 'active' : ''}
                    onClick={() => setShowSection('orders')}>
                    {translate("Orders")}
                </button>
                <button className={showSection === 'history' ? 'active' : ''}
                    onClick={() => setShowSection('history')}>
                    {translate("History")}
                </button>
            </div>
            <div className="page-content">
                {generalComponent}
                {attendanceComponent}
                {membershipComponent}
                {ordersComponent}
                {historyComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index