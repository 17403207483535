import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import DropDown from "../../../FormShared/DropDown"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import AppContext from "../../../partials/AppContext"
import Genders from "../../../../constants/Genders"

const GuestData = ({guests ,setGuests ,guestObject ,errors ,setErrors}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate ,systemLang} = useContext(AppContext)
    const cityProvinceApi = `${Config.apiBaseUrl}/gym-dashboard-services/branches`
    const [provinces ,setProvinces] = useState([{id: undefined ,name: translate('Province')}])
    const [cities ,setCities] = useState([])
    const genders = Genders()

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${cityProvinceApi}/country/getAllProvince`, {cancelToken: axiosSource.token})
        .then(response => setProvinces(response.data.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setProvinces ,gymToken ,alert ,cityProvinceApi ,clearUserData])

    const changeProvince = province => {
        if (!province?.id) {
            alert.error(translate('ProvinceFirst'))
            return
        }
        Axios
        .setAuthToken(gymToken)
        .get(`${cityProvinceApi}/country/getAllCities?province_id=${province?.id}`)
        .then(response => setCities(response.data.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <>
            <h6> {translate("GuestInformation")} </h6>
            {
                guests?.map((guest ,index) => <div className="row close-row" key={index * 100}>
                    <div className="col-sm-6 pl-0 toggle-textfield">
                        <Input placeholder={translate("Name")} value={guest?.name}
                            onChange={e => {
                                const tempGuests = [...guests]
                                tempGuests[index].name = e.target.value
                                setGuests([...tempGuests])

                                try {
                                    const tempErrors = [...errors]
                                    tempErrors[index].name = ''
                                    setErrors([...tempErrors])
                                } catch (err) {}
                            }}/>
                        {errors[index] && errors[index].name ? <FieldError message={errors[index].name} /> : ''}
                    </div>
                    <div className="col-sm-6 pr-0 toggle-textfield">
                        <Input placeholder={translate("Phone")} value={guest?.phone}
                            onChange={e => {
                                const tempGuests = [...guests]
                                tempGuests[index].phone = e.target.value
                                setGuests([...tempGuests])

                                try {
                                    const tempErrors = [...errors]
                                    tempErrors[index].phone = ''
                                    setErrors([...tempErrors])
                                } catch (err) {}
                            }}/>
                        {errors[index] && errors[index].phone ? <FieldError message={errors[index].phone} /> : ''}
                    </div>
                    <div className="col-sm-6 pl-0 toggle-textfield">
                        <DropDown
                            selectedName={guest?.gender ? `${guest?.gender.charAt(0).toUpperCase() + guest?.gender.slice(1)}` : translate('Gender')}
                            textColorBlack={guest?.gender !== ''}
                            list={genders}
                            itemName="name"
                            select={gender => {
                                const tempGuests = [...guests]
                                tempGuests[index].gender = gender.value
                                setGuests([...tempGuests])

                                try {
                                    const tempErrors = [...errors]
                                    tempErrors[index].gender = ''
                                    setErrors([...tempErrors])
                                } catch (err) {}
                            }}
                        />
                        {errors[index] && errors[index].gender ? <FieldError message={errors[index].gender} /> : ''}
                    </div>
                    <div className="col-sm-6 pr-0 toggle-textfield">
                        <Input placeholder={translate("Age")} value={guest?.age}
                            onChange={e => {
                                const tempGuests = [...guests]
                                tempGuests[index].age = e.target.value
                                setGuests([...tempGuests])

                                try {
                                    const tempErrors = [...errors]
                                    tempErrors[index].age = ''
                                    setErrors([...tempErrors])
                                } catch (err) {}
                            }}/>
                        {errors[index] && errors[index].age ? <FieldError message={errors[index].age} /> : ''}
                    </div>
                    <div className={`col-sm-6 pad0 ${systemLang === 'ar' ? 'right' : 'left'}-col`}>
                        <DropDown
                            selectedName={guest?.province?.name}
                            textColorBlack={guest?.province?.id}
                            list={provinces}
                            itemName="name"
                            select={province => {
                                changeProvince(province)
                                if (province?.id) {
                                    const tempGuests = [...guests]
                                    tempGuests[index].province = {name: province?.name ,id: province?.id}
                                    setGuests([...tempGuests])
                                }
                            }}
                        />
                    </div>
                    <div className={`col-sm-6 pad0 ${systemLang === 'ar' ? 'left' : 'right'}-col`}>
                        <DropDown
                            selectedName={guest?.city?.name}
                            textColorBlack={guest?.city?.id}
                            list={cities}
                            itemName="name"
                            select={city => {
                                if (city?.id) {
                                    const tempGuests = [...guests]
                                    tempGuests[index].city = {name: city?.name ,id: city?.id}
                                    setGuests([...tempGuests])

                                    try {
                                        const tempErrors = [...errors]
                                        tempErrors[index].city_id = ''
                                        setErrors([...tempErrors])
                                    } catch (err) {}
                                }
                            }}
                        />
                        {errors[index] && errors[index].city_id ? <FieldError message={errors[index].city_id} /> : ''}
                    </div>
                    <i
                        onClick={() => {
                            const tempGuests = [...guests].filter((guest ,_index) => _index !== index)
                            setGuests([...tempGuests])
                        }}
                        className="fas fa-times close-btn"></i>
                </div>)
            }
            <button type="button" className="red-btn"
                onClick={() =>  setGuests([
                    ...guests,
                    {...guestObject}
                ])}>
                + {translate("AddGuest")}
            </button>
        </>
    )
}

export default GuestData