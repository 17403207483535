import { useContext } from "react"
import DropDown from "../../../../FormShared/DropDown"
import Input from "../../../../FormShared/Input"
import AppContext from "../../../../partials/AppContext"
import '../../../Membership/Classes/ClassTimes.css'

const SessionInputs = ({
    children,
    sessions,
    setSessions,
    extraSessions,
    btnClassName,
    rowClassName,
    className
}) => {
    const {translate} = useContext(AppContext)
    return (
        <>
            {
                sessions?.map(
                    (session ,index) =>
                    <div className={`row close-row ${rowClassName}`}>
                        <div className={className ? className : "col-sm-6 pad0 toggle-textfiled"}>
                            <div className="form-group">
                                <DropDown
                                    selectedName={session?.name ? session?.name : translate('Session')}
                                    textColorBlack={session.id}
                                    list={extraSessions && Array.isArray(extraSessions) ? extraSessions : []}
                                    itemName="name"
                                    select={__session => {
                                        const tempSessions = [...sessions]
                                        tempSessions[index] = {
                                            id: __session?.id,
                                            name: __session?.name,
                                            times: 0,
                                            price: __session?.price
                                        }
                                        setSessions([...tempSessions])
                                    }}
                                />
                            </div>
                        </div>
                        <div className={className ? className : "col-sm-6 pad0 toggle-textfiled"}>
                            <div className="form-group">
                                <Input placeholder={translate("Number")} type="number" value={session?.times}
                                    onChange={e => {
                                        const tempSessions = [...sessions]
                                        tempSessions[index].times = e.target.value
                                        setSessions([...tempSessions])
                                    }}/>
                            </div>
                        </div>
                        <i
                            onClick={() => {
                                const tempsessions = [...sessions].filter((coach ,_index) => _index !== index)
                                setSessions([...tempsessions])
                            }}
                            className="fas fa-times close-btn"></i>
                    </div>
                )
            }
            {children}
            <button className={`${btnClassName} red-btn`} type="button"
                onClick={() =>  setSessions([
                    ...sessions,
                    {id: undefined ,name: '' ,times: 0 ,price: 0}
                ])}>
                + {translate("AddSession")}
            </button>
        </>
    )
}

export default SessionInputs