import { useContext } from "react"
import AppContext from "../../partials/AppContext"
import Navbar from "../../partials/Navbar"
import IndexComponent from "./IndexComponent"

const GymDetails = () => {
    const {translate} = useContext(AppContext)
    
    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Settings")} />
            </div>
            <IndexComponent/>
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default GymDetails