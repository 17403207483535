import { useContext, useEffect, useState } from "react"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import Axios from "../../../../Axios"
import AppContext from "../../../partials/AppContext"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import EquipmentInput from "./EquipmentInput"
import MaintenanceTypes from "../../../../constants/MaintenanceTypes"

const Edit = ({cancel ,reloadData ,id}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/equipment`
    const [disabled ,setDisabled] = useState(false)
    const [errors ,setErrors] = useState({image: '' ,equipment: '' ,equipment_ar: '' ,type: '' ,purchase_price: ''})
    const [equipmentData ,setEquipmentData] = useState({image: undefined ,equipment: '' ,equipment_ar: '' ,type: '' ,purchase_price: ''})
    const [selectedType ,setSelectedType] = useState({name: 'Type' ,value: undefined})
    const [photo ,setPhoto] = useState(undefined)
    const types = MaintenanceTypes()

    const changeAttr = (attrName ,attrValue) => {
        const tempErrors = {...errors} ,tempData = {...equipmentData}
        tempErrors[attrName] = ''
        tempData[attrName] = attrValue
        setErrors(tempErrors)
        setEquipmentData(tempData)
    }

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setEquipmentData(response?.data?.data)
            setSelectedType(response?.data?.data?.ob_type)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })

        return () => axiosSource.cancel()
    } ,[gymToken ,clearUserData ,setEquipmentData ,alert ,id ,entityUrl])

    const saveChanges = () => {
        setDisabled(true)
        const formData = new FormData()
        formData.append('equipment_ar' ,equipmentData?.equipment_ar)
        formData.append('equipment' ,equipmentData?.equipment)
        formData.append('type' ,equipmentData?.type)
        formData.append('purchase_price' ,equipmentData?.purchase_price)
        if (photo) formData.append('image' ,photo)
        formData.append('_method' ,'PUT')
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/${id}` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                setErrors(validation)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditEquipment")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <EquipmentInput
                            equipmentData={equipmentData}
                            changeAttr={changeAttr}
                            errors={errors}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            types={types}
                            setErrors={setErrors}
                            setPhoto={setPhoto}
                        />
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("EditEquipment")} />
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default Edit