import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import SessionInput from "./SessionInput"

const Edit = ({id, title, title_ar, price, cancel, reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/member-sessions`

    const [_title ,setTitle] = useState(title)
    const [_title_ar ,setTitleAr] = useState(title_ar)
    const [_price ,setPrice] = useState(price)
    const [errors ,setErrors] = useState({title: '' ,title_ar: '' ,price: ''})
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .put(`${entityUrl}/${id}` ,{name: title ,name_ar: title_ar ,price: price})
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                const _errors = {...errors}
                if (validation.name) _errors.title = validation.name
                if (validation.name_ar) _errors.title_ar = validation.name_ar
                if (validation.price) _errors.price = validation.price
                setErrors(_errors)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeTitle = (e ,forAr = false) => {
        if (forAr) {
            setErrors({...errors ,title_ar: ''})
            setTitleAr(e.target.value)
            return    
        }
        setErrors({...errors ,title: ''})
        setTitle(e.target.value)
    }

    const changePrice = e => {
        setErrors({...errors ,title: ''})
        setPrice(e.target.value)
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditSession")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <SessionInput
                                errors={errors}
                                title={_title}
                                title_ar={_title_ar}
                                changeTitle={changeTitle}
                                price={_price}
                                changePrice={changePrice}
                            />
                        </div>
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("Edit")}/>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default Edit