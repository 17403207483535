import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import Axios from '../../../../../Axios'
import Config from '../../../../../Config'
import AppContext from '../../../../partials/AppContext'
import Loading from '../../../../Shared/Loading'
import MemberSelect from './MemberSelect'
import OrderForm from './OrderForm'

const Add = ({cancel ,reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const [currentTab ,setCurrentTab] = useState('member-select')
    const [orderData ,setOrderData] = useState({member_id: undefined, items: []})
    const [errors ,setErrors] = useState({member_id: '' ,items: ''})
    const [products ,setProducts] = useState({})
    const [disabled ,setDisabled] = useState(false)
    const [loaded ,setLoaded] = useState(false)

    const saveChanges = () => {
        const axiosSource = axios.CancelToken.source()
        const data = {cancelToken: axiosSource.token ,member_id: orderData.member_id ,items: []}
        orderData.items.forEach(item => {
            data.items.push({
                product_id: item?.id,
                product_type: item.type === Config.Order.type.foods ? 'food' : item.type,
                quantity: item.qnt
            })
        })
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/orders` ,data)
        .then(response => {
            const order = response?.data?.data
            setDisabled(false)
            alert.success(`${translate("Order")} #${order?.id} ${translate("ForMember")} ${order?.member} ${translate("createdSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) {
                try {
                    const validation = error?.response?.data?.errors
                    setErrors({...errors ,items: validation.items.join(',')})
                } catch (__err) {}
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${Config.apiBaseUrl}/gym-dashboard-services/orders/bar/products` ,{cancelToken: axiosSource.token})
        .then(response => {
            setProducts(response?.data)
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.status === 405) cancel()
        })

        return () => axiosSource.cancel()
    } ,[setProducts ,alert ,clearUserData ,gymToken])

    return loaded ?
    <>
        {
            currentTab === 'member-select' ?
            <MemberSelect
                cancel={cancel}
                next={() => {
                    if (!orderData?.member_id) {
                        setErrors({...errors ,member_id: translate('MemberFirst')})
                        return
                    }
                    setCurrentTab('order-form')
                }}
                memberId={orderData?.member_id}
                setMemberId={id => {
                    setErrors({...errors ,member_id: ''})
                    setOrderData({...orderData ,member_id: id})
                }}
                error={errors?.member_id} />
            :
            <OrderForm
                products={products}
                cancel={cancel}
                orderData={orderData}
                setOrderData={setOrderData}
                placeOrder={items => saveChanges(items)}
                errorMsg={errors.items}
                disabled={disabled}
            />
        }
    </>
    :

    <div id="forms">
        <div id="small-modal-form" className="form-container">
            <div className="contain">
                <Loading/>
            </div>
        </div>
    </div>
}

export default Add