import { Switch ,Route } from 'react-router-dom'
import Dashboard from '../dashboard/Dashboard/BranchDashboard'
import Earnings from '../dashboard/Earnings/Index'
import History from '../dashboard/History/Index'
import Expenses from '../dashboard/Expenses/Index'
import AccountRoles from '../dashboard/AccountRoles/Index'
import Accounts from '../dashboard/Accounts/Index'
import Profile from '../dashboard/Profile/Index'
import HelpCenter from '../dashboard/HelpCenter/Index'
import Settings from '../dashboard/BranchSettings/Index'
import Membership from '../dashboard/Membership/Index'
import Employees from '../dashboard/Employees/Index'
import EmployeeDetails from '../dashboard/Employees/Details/Index'
import Equipments from '../dashboard/Equipments/Index'
import Orders from '../dashboard/Orders/Index'
import Stock from '../dashboard/Stock/Index'
import Members from '../dashboard/Members/Index'
import MemberDetails from '../dashboard/Members/Members/MemberDetails/Index'
import Safe from '../dashboard/Safe/Index'
import ClassMembers from '../dashboard/Membership/Classes/Members/Index'

const BranchPortalContent = (props) => {
    return (
        <Switch>
            {/* Main Section */}
            <Route path="/" exact>
                <Dashboard />
            </Route>
            <Route path="/dashboard" exact>
                <Dashboard />
            </Route>
            <Route path="/safe" exact>
                <Safe />
            </Route>
            <Route path="/members" exact>
                <Members />
            </Route>
            <Route path="/members/:id" exact>
                <MemberDetails />
            </Route>
            {/* Main Section */}

            {/* Bar Section */}
            <Route path="/orders" exact>
                <Orders />
            </Route>
            <Route path="/stock" exact>
                <Stock />
            </Route>
            {/* Bar Section */}

            {/* Gym Section */}
            <Route path="/employees" exact>
                <Employees />
            </Route>
            <Route path="/employees/:id" exact>
                <EmployeeDetails />
            </Route>
            <Route path="/equipments" exact>
                <Equipments />
            </Route>
            <Route path="/earnings" exact>
                <Earnings />
            </Route>
            <Route path="/expenses" exact>
                <Expenses />
            </Route>
            <Route path="/membership" exact>
                <Membership />
            </Route>
            <Route path="/membership/class-members/:id" exact>
                <ClassMembers />
            </Route>
            {/* Gym Section */}

            {/* More Section */}
            <Route path="/account-roles" exact>
                <AccountRoles />
            </Route>
            <Route path="/accounts" exact>
                <Accounts />
            </Route>
            <Route path="/settings" exact>
                <Settings />
            </Route>
            <Route path="/history" exact>
                <History />
            </Route>
            <Route path="/help-center" exact>
                <HelpCenter />
            </Route>
            {/* More Section */}

            <Route path="/profile" exact>
                <Profile />
            </Route>
        </Switch>
    )
}

export default BranchPortalContent