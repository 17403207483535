const words = {
    Food: "Food",
    Female: "Female",
    FemaleLockers: "Female Lockers",
    From: "From",
    Friday: "Friday",
    Fri: "Fri",
    February: "February",
    Foods: "Foods",
    FullMemberID: "Write full member ID first",
    FreezeAccount: "Freeze Account",
    FreezeStart: "Freeze Start",
    FreezeEnd: "Freeze End",
    Freeze: "Freeze",
    FieldReq: "Field can`t be empty",
    Facebook: "Facebook",
    ForMember: "for member",
    FoodRevenue: "Food Revenue",
    ForThisMember: "for this member",
    FreezingDays: "Freezing Day(s)",
    FromClass: "from class",
    GeneralSetting: "General Settings",
    GymType: "Gym Type",
    GymInfo: "gym information",
    General: "General",
    Gender: "Gender",
    Gym: "Gym",
    Guests: "Guests",
    Guest: "Guest",
    GoToSettings: "Go To Settings",
    GoSettingAddThem: "please go to settings and add them",
    GeneralInfo: "General Information",
    GTStart: "Great than start",
    GymExpenses: "Gym Expenses",
    Host: "Host",
    History: "History",
    Histories: "Histories",
    HelpCenterImgHint: "Upload images of the issue or suggestion",
    HelpCenter: "Help Center",
    HeInvite: "he invite",
    Hostman: "Hostman",
    HeightCM: "Height (CM)",
    Height: "Height",
    January: "January",
    June: "June",
    July: "July",
    IPAddress: "IP Address",
    IDNumber: "ID Number",
    Image: "Image",
    ItemName: "Item`s Name",
    ItemArName: "Item`s Arabic Name",
    Item: "Item",
    Invitation: "Invitation",
    InvitationNumber: "Invitation Number",
    Instagram: "Instagram",
    ItemAtLeast: "One item at least",
    ItemRefundedSuccess: "item(s) has been refunded successfully",
    Items: "Items",
    Job: "Job",
    FromTo: "From/To",
    GuestInformation: "Guest /Guests Information",
    InvalidFormat: "Invalid Format",
    FemaleWorkingHours: "Female Working Hours",
}

export default words