import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import Loading from "../../../Shared/Loading"
import CoachInput from "./CoachInput"

const Edit = ({cancel ,reloadData ,id}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/private-coach`

    const [quantity ,setQuantity] = useState('')
    const [price ,setPrice] = useState('')
    const [employee ,setEmployee] = useState({id: undefined ,name: translate('Coach')})
    const [errors ,setErrors] = useState({quantity: '' ,price: '' ,employee_id: ''})
    const [employees ,setEmployees] = useState([])
    const [loaded ,setLoaded] = useState(false)
    const [disabled ,setDisabled] = useState(false)

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/getCoaches` ,{cancelToken: axiosSource.token})
        .then(response => setEmployees(response?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[alert ,clearUserData ,gymToken ,setEmployees])

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/${id}`, {cancelToken: axiosSource.token})
        .then(response => {
            const res = response?.data?.data
            setQuantity(res?.real_quantity)
            setPrice(res?.real_price)
            setEmployee({id: res?.employee_id ,name: res?.name})
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setQuantity ,setPrice ,setEmployee ,alert ,clearUserData ,gymToken])

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .put(`${entityUrl}/${id}` ,{employee_id: employee?.id, quantity: quantity, price: price})
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                const _errors = {...errors}
                if (validation.name) _errors.title = validation.name
                if (validation.price) _errors.price = validation.price
                setErrors(_errors)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeQnty = e => {
        setErrors({...errors ,quantity: ''})
        setQuantity(e.target.value)
    }

    const changePrice = e => {
        setErrors({...errors ,title: ''})
        setPrice(e.target.value)
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditSession")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                            <>
                                <div className="section mb-2">
                                    <CoachInput
                                        employee={employee}
                                        employees={employees}
                                        setEmployee={setEmployee}
                                        errors={errors}
                                        quantity={quantity}
                                        changeQnty={changeQnty}
                                        price={price}
                                        changePrice={changePrice}
                                    />
                                </div>
                                <ModalButtons
                                    disabled={disabled}
                                    cancel={cancel}
                                    confirm={saveChanges}
                                    confirmLabel={translate("SaveChanges")}/>
                            </>
                            : <Loading/>
                        }
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default Edit