import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import Add from "./Add"
import Delete from "./Delete"
import Row from "./Row"

const Index = ({visible ,data ,setData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/guest`
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const [deleteRow ,setDeleteRow] = useState(undefined)
    const [addMember ,setAddMember] = useState(undefined)

    const cancel = () => {
        setDeleteRow(undefined)
        setAddMember(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        let query = `keyword=${keyword}`
        query += `&limit=${dataPerPage}`
        query += `&page=${currentPage}`
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/all?${query}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate("Guest")}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
            
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[alert ,gymToken ,clearUserData ,entityUrl ,dataPerPage ,currentPage])

    const printClick = () => {
        let query = `keyword=${keyword}`
        query += `&page=${currentPage}`
        return Config.printByToken(`${entityUrl}/print?${query}` ,true ,gymToken)
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForDelete = () => {
        if (dataList.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Name')},
                            {value: translate('Phone')},
                            {value: translate('Province')},
                            {value: translate('City')},
                            {value: translate('Member')},
                            {value: translate('Actions') ,className: 'actions'}
                        ]}/>
                        <tbody>
                            { dataList?.map(row => <Row key={Math.random()}
                                data={row}
                                deleteAction={() => {
                                    cancel()
                                    setDeleteRow({id: row.id ,name: row.name})
                                }}
                                addMember={() => {
                                    cancel()
                                    setAddMember(row?.id)
                                }}
                            />) }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                </div>
            </div>
            {deleteRow ? <Delete data={deleteRow} cancel={cancel} reloadData={reloadForDelete} /> : ''}
            {addMember ? <Add id={addMember} cancel={cancel} reloadData={reloadForAdd} /> : ''}
        </div>
    )
}

export default Index