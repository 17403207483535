import { useContext, useState } from 'react'
import Config from '../../../../Config'
import DropDown from '../../../FormShared/DropDown'
import FieldError from '../../../FormShared/FieldError'
import AppContext from '../../../partials/AppContext'
import './ClassTimes.css'

const ClassTimes = ({
    start,
    end,
    classTime,
    changeEnd,
    fullMembers,
    changeDay,
    changeStart,
    removeDay
}) => {
    const {translate ,systemLang} = useContext(AppContext)
    const [endError ,setEndError] = useState('')

    const tt = []
    for(let i = 0; i <= 23; i++) {
        if (i < 12) {
            const iValue = (i < 10 ? `0${i}` : `${i}`) + ':00 AM'
            tt.push({value: iValue ,name: Config?.translateTime(iValue ,systemLang)})
        } else {
            const y = i - 12 === 0 ? 12 : i - 12,
              yValue = (y < 10 ? `0${y}` : `${y}`) + ':00 PM'
            tt.push({value: yValue ,name: Config?.translateTime(yValue ,systemLang)})
        }
    }

    const days = start && end ? Config.getDaysArray(new Date(start) ,new Date(end) ,systemLang) : []

    let __day = translate('Day')
    if (classTime?.day) {
        __day = Config?.translateDate(classTime?.day ,systemLang)
    }

    const changeTimTo = endTime => {
        let _time = parseInt(endTime) ,startTime = parseInt(classTime?.start)
        if (endTime.includes('PM')) _time += 12
        if (classTime?.start.includes('PM')) startTime += 12
        if (startTime >= _time) {
            setEndError(translate('GTStart'))
            return
        }
        setEndError('')
        changeEnd(endTime)
    }

    return (
        <div className="row close-row">
            <div className="col-sm-6 pl-0">
                <DropDown
                    selectedName={__day}
                    textColorBlack={__day !== translate('Day')}
                    list={days}
                    itemName="humanRead"
                    select={_day => {
                        if (fullMembers) return
                        changeDay(_day.date)
                    }} />
            </div>
            <div className="col-sm-3">
                <DropDown
                    selectedName={classTime?.start ? Config?.translateTime(classTime?.start ,systemLang) : translate('From')}
                    textColorBlack={classTime?.start}
                    list={tt}
                    itemName="name"
                    select={_time => {
                        if (fullMembers) return
                        console.log(_time)
                        changeStart(_time.value)
                    }} />
            </div>
            <div className="col-sm-3 pr-0">
                <DropDown
                    selectedName={classTime?.end ? Config?.translateTime(classTime?.end ,systemLang) : translate('To')}
                    textColorBlack={classTime?.end}
                    list={tt}
                    itemName="name"
                    select={_time => {
                        if (fullMembers) return
                        console.log(_time)
                        changeTimTo(_time.value)
                    }} />
                {endError ? <FieldError message={endError} /> : ''}
            </div>
            <i className="fas fa-times close-btn" onClick={removeDay}></i>
        </div>
    )
}

export default ClassTimes