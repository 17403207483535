import { useContext, useEffect, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import axios from "../../../Axios"
import Config from '../../../Config'
import AppContext from '../../partials/AppContext'
import Navbar from '../../partials/Navbar'
import Pagination from '../../Shared/Pagination'
import HistoryRow from './HistoryRow'
import FilterBox from './FilterBox'
import Table from "../../Shared/Table"
import Thead from "../../Shared/Thead"
import HistoryActions from '../../../constants/HistoryActions'

const Index = ({defaultPage}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/history`

    const [keyword ,setKeyword] = useState('')
    const [histories ,setHistories] = useState([])
    const actions = HistoryActions()
    const [dataPerPage ,setDataPerPage] = useState(10)
    const [currentPage ,setCurrentPage] = useState(defaultPage ? defaultPage : 1)
    const [meta ,setMeta] = useState({links: [] ,last_page: 1})
    const [selectedAction ,setSelectedAction] = useState({name: translate('All')})
    const [action ,setAction] = useState({name: translate('All') ,value: ''})
    const [month ,setMonth] = useState('')
    const [year ,setYear] = useState('')

    const elRef = useRef()

    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        const urlQuery = `?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}&action=${action?.value}&month=${month}&year=${year}`
        axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}${urlQuery}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setHistories(response?.data?.data)
            setMeta(response?.data?.meta)
            elRef.current.scrollIntoView()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[
        setHistories ,setMeta ,clearUserData ,entityUrl ,
        gymToken ,alert ,dataPerPage ,currentPage ,action ,month ,year
    ])

    const changeAction = (action) => {
        setSelectedAction(action)
        if (action.name !== translate('All')) setAction({name: action?.name ,value: action?.value})
        else setAction({name: translate('All') ,value: ''})
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setCurrentPage(1)
        else loadData()
    }

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("History")} />
            </div>
            <div className="page-content flex-gap-15">
                <div className="page-section header-box" ref={elRef}>
                    <FilterBox 
                        loadData={triggerSearch}
                        setKeyword={setKeyword}
                        keyword={keyword}
                        selectedAction={selectedAction}
                        actions={actions}
                        changeAction={changeAction}
                        changeMonth={setMonth}
                        month={month}
                        changeYear={setYear}
                        year={year}/>
                </div>
                <div className="page-section content mt-3">
                    <div className="table-section">
                        <Table>
                            <Thead headers={[{value: translate('AccountAction') ,colSpan: 2} ,{value: translate('Date'),className: 'left-text'}]}/>
                            <tbody>
                                {histories.map(history => <HistoryRow key={history.id} history={history}/>)}
                            </tbody>
                        </Table>
                        <Pagination
                            changeCurrentPage={setCurrentPage} currentPage={currentPage}
                            dataPerPage={dataPerPage} changeDataPerPage={setDataPerPage} meta={meta}/>
                    </div>
                </div>
            </div>
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default Index