import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import Add from "./Add"
import Cancel from "./Cancel"
import Row from "./Row"
import ApproveBooking from '../Attendance/Add'

const Index = ({data ,setData ,visible}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/booking`
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const [addClick ,setAddClick] = useState(undefined)
    const [approveClick ,setApproveClick] = useState(undefined)
    const [cancelBookClick ,setCancelBookClick] = useState(undefined)

    const cancel = () => {
        setAddClick(undefined)
        setApproveClick(undefined)
        setCancelBookClick(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        let query = `keyword=${keyword}`
        query += `&limit=${dataPerPage}`
        query += `&page=${currentPage}`
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/all?${query}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate("Booking")}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
            
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[alert ,gymToken ,clearUserData ,entityUrl ,dataPerPage ,currentPage])

    const printClick = () => {
        let query = `keyword=${keyword}`
        query += `&page=${currentPage}`
        return Config.printByToken(`${entityUrl}/print?${query}` ,true ,gymToken)
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    const reloadForDelete = () => {
        if (dataList.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <button className="btn btn-light" onClick={() => {
                        cancel()
                        setAddClick(true)
                    }}> {translate("BookDate")} </button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Name')},
                            {value: translate('IDNumber')},
                            {value: translate('Date')},
                            {value: translate('Time')},
                            {value: translate('Actions') ,className: 'actions'}
                        ]}/>
                        <tbody>
                            { dataList?.map(row => <Row key={Math.random()}
                                data={row}
                                cancel={cancel}
                                approveBooking={setApproveClick}
                                cancelBooking={setCancelBookClick}
                            />) }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                </div>
            </div>
            {addClick ? <Add cancel={cancel} reloadData={reloadForAdd} /> : ''}
            {cancelBookClick ? <Cancel bookRow={cancelBookClick} cancel={cancel} reloadData={reloadForDelete} /> : ''}
            {
                approveClick ?
                <ApproveBooking
                    cancel={cancel}
                    reloadData={reloadForAdd}
                    booking_id={approveClick?.booking_id}
                    member={{name: approveClick?.name ,id: approveClick?.member_id}}
                    unique_id={approveClick?.unique_id}
                /> : ''}
        </div>
    )
}

export default Index