import { useContext } from "react"
import AppContext from "../partials/AppContext"

const DangerousAlert = (props) => {
    const {translate} = useContext(AppContext)
    return (
        <div id="delete-alert" className="form-container">
            <div className="contain">
                <div className="header">
                    <div className="title">
                        <h4 className="heading">
                            {props.heading ? props.heading : translate('DeleteAlert')}
                        </h4>
                    </div>
                </div>
                <form onSubmit={e => e.preventDefault()}>
                    <div className="section" style={{marginBottom: 0}}>
                        <p className="alert-message">
                            {props.message}
                        </p>
                        {props.children}
                    </div>
                    <div className="confirm">
                        <button
                            type="button"
                            className="btn btn-default col-sm-6"
                            onClick={props.cancel}>
                            {props.cancelLabel ? props.cancelLabel : translate('Cancel')}
                        </button> 
                        <button
                            type="button"
                            className="btn btn-light col-sm-6"
                            disabled={props.disabled ? props.disabled : false}
                            onClick={props.confirm}>
                            {props.confirmLabel ? props.confirmLabel : translate('SaveChanges')}
                        </button>
                    </div>
                </form>
        
            </div>
        </div>
    )
}

export default DangerousAlert