import {BrowserRouter as Router} from 'react-router-dom'

import Aside from './partials/Aside'
import PortalContent from './partials/PortalContent'
import BranchAside from './partials/BranchAside'
import BranchPortalContent from './partials/BranchPortalContent'
import '../css-files/owner-dashboard.css'
import { useContext } from 'react'
import AppContext from './partials/AppContext'
import { useEffect ,useState } from 'react'
import Loading from './Shared/Loading'

const AppTemplate = (props) => {
    const {gymAccountType ,closeMenu ,systemLang} = useContext(AppContext)
    const [appLoaded ,setAppLoaded] = useState(false)

    useEffect(() => {
        setAppLoaded(false)
        if (systemLang === 'ar') {
            const style = document.createElement("link")
            style.href = "/arabic-style.css"
            style.rel = "stylesheet"
            style.id = "arabic-style-link"
            style.async = true
            document.head.appendChild(style)
        } else {
            const styleExists = document.getElementById("arabic-style-link")
            if (styleExists) styleExists.remove()
        }
        setTimeout(() => setAppLoaded(true) ,2000)
    } ,[setAppLoaded ,systemLang])

    return appLoaded ?
    <Router>
        {
            gymAccountType === 'account' ?
            <>
                <BranchAside/>
                <BranchPortalContent/>
            </>
            :
            <>
                <Aside/>
                <PortalContent  />
            </>
        }
        <div onClick={closeMenu} id="aside-menu-overlay"></div>
    </Router>
    :
    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
        <Loading/>
    </div>
}

export default AppTemplate