import { useContext, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Navbar from '../../../partials/Navbar'
import CrudIndexDS from '../../../Shared/CrudIndexDS'
import General from './General/General'
import Attendance from './Attendance'
import AppContext from '../../../partials/AppContext'

const Index = () => {
    const params = useParams() ,id = params.id
    const {translate ,systemLang} = useContext(AppContext)

    const [showSection ,setShowSection] = useState('general')

    const [general ,setGeneral] = useState()
    const [attendanceData ,setAttendanceData] = useState({...CrudIndexDS})
    
    const generalComponent = <General
        visible={showSection === 'general'}
        id={id}
        data={general}
        setData={setGeneral}
    />,
    attendanceComponent = <Attendance
        visible={showSection === 'attendance'}
        id={id}
        data={attendanceData}
        setData={setAttendanceData}
    />

    const navbar = <>
        <NavLink to="/employees" exact> <i className={`fas fa-arrow-${systemLang === 'ar' ? 'right' : 'left'} black-color`}></i> </NavLink> {translate("Employees")}
    </>

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={navbar}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'general' ? 'active' : ''}
                    onClick={() => setShowSection('general')}>
                    {translate("General")}
                </button>
                <button className={showSection === 'attendance' ? 'active' : ''}
                    onClick={() => setShowSection('attendance')}>
                    {translate("Attendance")}
                </button>
            </div>
            <div className="page-content">
                {generalComponent}
                {attendanceComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index