import { md5 } from "hash-wasm";
import axios from "./Axios";

const isProduction = process.env.NODE_ENV === "production";

const Config = {
  apiBaseUrl: isProduction
    ? "https://backend.gymmeclub.com/api"
    : "http://127.0.0.1:8000/api",
  getHashForPrintToken: () => {
    const currentDate = new Date(),
      month =
        currentDate.getMonth() + 1 < 10
          ? `0${currentDate.getMonth() + 1}`
          : currentDate.getMonth() + 1,
      date =
        currentDate.getDate() < 10
          ? `0${currentDate.getDate()}`
          : currentDate.getDate(),
      hash = `${currentDate.getFullYear()}-${month}-${date}-print-token-auth`;
    return md5(hash);
  },
  globalPrint: (entityPrintUrl, gymToken = undefined) => {
    Config.getHashForPrintToken().then((result) => {
      axios
        .post(`${Config.apiBaseUrl}/print-token`, { hash: result })
        .then((response) =>
          window.open(
            `${entityPrintUrl}?token=${
              gymToken ? gymToken : response.data.token
            }`,
            "_blank"
          )
        )
        .catch((err) => {
          console.log(err);
          alert("error");
        });
    });
  },
  filteredPrint: (url) => window.open(url, "_blank"),
  printByToken: (url, hasParameters = false, gymToken) => {
    Config.getHashForPrintToken().then((result) => {
      const lang = localStorage.getItem('systemLang')
      axios
        .post(`${Config.apiBaseUrl}/print-token`, {
          hash: result,
          gym_identifier: gymToken,
        })
        .then((response) => {
          const token = response?.data?.token;
          let _url = url;
          if (hasParameters) _url += `&token=${token}&lang=${lang}`;
          else _url += `?token=${token}&lang=${lang}`;
          const branchId = localStorage.getItem('branch-id')
          if (branchId) _url += `&branch-id=${branchId}`
          window.open(_url, "_blank");
        }).catch((err) => alert("Call technical support please."));
    });
  },
  getDaysArray: function(start, end, lang = 'en') {

    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      const d = new Date(dt);
      arr.push({ date: d, humanRead: this.translateDate(dt ,lang)});
    }
    return arr;
  },
  monthsNameValue: [
    {name: "All" ,value: ""},
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ],
  yearsNameValue: () => {
    const years = [{name: "All" ,value: ""}];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      years.push({ name: currentYear - i, value: currentYear - i });
    }
    return years;
  },
  workingHourConflictStatus: 452,
  Order: {
    DrinkSetting: "Drinks",
    FoodSetting: "Food",
    SupplementSetting: "Supplement",
    type: { drinks: "drinks", foods: "foods", supplements: "supplements" },
  },
  validateEmail: email => {
      var re = /\S+@\S+\.\S+/
      return re.test(email)
  },
  defaultLang: 'en',
  systemLanguages: ['ar' ,'en'],
  systemLanguagesConfig: [
    {
      name: 'العربية',
      value: 'ar',
      flagUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Egypt.svg/1280px-Flag_of_Egypt.svg.png"
    },
    {
      name: 'English',
      value: 'en',
      flagUrl: "https://www.onlygfx.com/wp-content/uploads/2016/08/flag-of-uk.png"
    }
  ],
  handleStorageToken: ({action = 'get' ,token = undefined ,storage = 'local'}) => {
    let storageToken = undefined
    switch (action) {
      case "get":
        if (localStorage.getItem('gymToken')) storageToken = localStorage.getItem('gymToken')
        else if (sessionStorage.getItem('gymToken')) storageToken = sessionStorage.getItem('gymToken')
        break;
      case "set":
        if (!token) throw new Error("You must set token first")
        storageToken = token
        if (storage === 'local') localStorage.setItem('gymToken' ,token)
        else if (storage === 'session') sessionStorage.setItem('gymToken' ,token)
        break;
      case "remove":
        localStorage.removeItem('gymToken')
        sessionStorage.removeItem('gymToken')
        break;
      default:
        console.log('Undefined action')
        break;
    }
    return storageToken
  },
  readableStats: (number ,lang = 'en') => {
    let sign = ''
    if (number < 0) sign = '-'
    number = Math.abs(number)
    if (number < 1000) return number
    else if (number >= 1000 && number < 1000000) return `${sign} ${number / 1000} ${lang === 'ar' ? 'ألف' : 'K'}`
    else if (number >= 1000000) return `${sign} ${number / 1000000} ${lang === 'ar' ? 'مليون' : 'M'}`
    else return `${sign} ${number}`
  },
  getYesterday: (lang) => {
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
    const options = {weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'}
    return yesterday.toLocaleString(lang === 'ar' ? "ar-EG" : "en-US" ,options)
  },
  isTokenInLocal: () => localStorage.getItem("gymToken") ? true : false,
  translateDate: (dt ,lang) => {
    const days = {
      0: {ar: "الأحد" ,en: "Sun"},
      1: {ar: "الإثنين" ,en: "Mon"},
      2: {ar: "الثلاثاء" ,en: "Tue"},
      3: {ar: "الأربعاء" ,en: "Wed"},
      4: {ar: "الخميس" ,en: "Thu"},
      5: {ar: "الجمعة" ,en: "Fri"},
      6: {ar: "السبت" ,en: "Sat"},
    }, months = {
      0: {ar: "يناير" ,en: "Jan"},
      1: {ar: "فبراير" ,en: "Feb"},
      2: {ar: "مارس" ,en: "Mar"},
      3: {ar: "أبريل" ,en: "Apr"},
      4: {ar: "مايو" ,en: "May"},
      5: {ar: "يونيو" ,en: "Jun"},
      6: {ar: "يوليو" ,en: "Jul"},
      7: {ar: "أغسطس" ,en: "Aug"},
      8: {ar: "سبتمبر" ,en: "Sep"},
      9: {ar: "أكتوبر" ,en: "Oct"},
      10: {ar: "نوفمبر" ,en: "Nov"},
      11: {ar: "ديسمبر" ,en: "Dec"},
    }
    const d = new Date(dt);
    let humanRead = ''
    try {
      humanRead = `${days[d.getDay()][lang]}, ${d.getDate()} ${months[d.getMonth()][lang]} ${d.getFullYear()}`
    } catch (err) {
      humanRead = dt.toDateString()
    }
    return humanRead
  },
  translateTime: (time ,lang) => {
    if (lang === "ar") {
      let _time = `${time}`
      _time = _time.replace("PM" ,"م")
      _time = _time.replace("AM" ,"ص")
      return _time
    }
    return time
  },
  googleMapApiKey: "AIzaSyAXAQOPKmoPvDR8zuEenOpOmIO_J53dytw",
  getLocation: link => {
    const tokens = []
    try {
      const interestedPart = link?.split?.("?")?.[0]?.split?.("@")?.[1]
      const lastInterestedPart = interestedPart.split?.("!3d").pop?.()?.split?.("!4d")
      const firstInterestedPart = interestedPart.split?.("z")?.[0].split?.(",")
      // zoom
      tokens[2] = firstInterestedPart[2]
      if (lastInterestedPart) {
        tokens[0] = lastInterestedPart[0]
        tokens[1] = lastInterestedPart[1]
      } else {
        tokens[0] = firstInterestedPart[0]
        tokens[1] = firstInterestedPart[1]
      }
    } catch (err) {
      tokens.push(30.0162567)
      tokens.push(31.4160693)
      tokens.push(16)
    }
    return {
      lat: Number(tokens?.[0]),
      lng: Number(tokens?.[1]),
      zoom: Number(tokens?.[2])
    }
  },
};

export default Config;
