import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import Axios from "../../../../Axios"
import AppContext from "../../../partials/AppContext"
import Config from "../../../../Config"
import EquipmentInput from "./EquipmentInput"
import MaintenanceTypes from "../../../../constants/MaintenanceTypes"

const Add = ({cancel ,reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/equipment`
    const [disabled ,setDisabled] = useState(false)
    const [errors ,setErrors] = useState({image: '' ,equipment: '' ,equipment_ar: '' ,type: '' ,purchase_price: ''})
    const [equipmentData ,setEquipmentData] = useState({image: undefined ,equipment: '' ,equipment_ar: '' ,type: '' ,purchase_price: ''})
    const [selectedType ,setSelectedType] = useState({name: translate('Type') ,value: undefined})
    const types = MaintenanceTypes()

    const changeAttr = (attrName ,attrValue) => {
        const tempErrors = {...errors} ,tempData = {...equipmentData}
        tempErrors[attrName] = ''
        tempData[attrName] = attrValue
        setErrors(tempErrors)
        setEquipmentData(tempData)
    }

    const saveChanges = () => {
        setDisabled(true)
        const formData = new FormData()
        Object.keys(equipmentData).forEach(key => formData.append(key ,equipmentData[key]))
        Axios
        .setAuthToken(gymToken)
        .post(entityUrl ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                setErrors(validation)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddEquipment")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <EquipmentInput
                            equipmentData={equipmentData}
                            changeAttr={changeAttr}
                            errors={errors}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            types={types}
                            setErrors={setErrors}
                            setPhoto={(img) => changeAttr('image' ,img)}
                        />
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("AddEquipment")} />
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default Add