import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../Axios"
import Config from "../../../../../../Config"
import AppContext from "../../../../../partials/AppContext"
import DangerousAlert from "../../../../../Shared/DangerousAlert"
import DataLine from "../../../../../Shared/DataLine/DataLine"
import GrayButton from "../../../../../Shared/GrayButton"

const MemberData = ({member ,showEdit ,reloadData}) => {
    const alert = useAlert()
    const {gymToken ,clearUserData ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/${member?.id}/print-member`
    const [disabled ,setDisabled] = useState(false)
    const [swithcStatusAction ,setSwithcStatusAction] = useState(false)

    const printClick = () => Config.printByToken(entityUrl ,false ,gymToken)

    const switchStatus = () => {
        const url = `${Config.apiBaseUrl}/gym-dashboard-services/members/members/general/activeDeactivateMember/${member?.id}`
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .put(url)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData?.()
            setSwithcStatusAction(false)
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
            <div className="page-section content  small-page-section">
                <div className="details-page-content member-details-page">
                    <div className="table-header flex-row jus-con-space-btwn">
                        <span className="table-header-title"> {translate("GeneralInfo")} </span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={printClick}>
                                <i className="fas fa-print"></i>
                            </button>
                        </div>
                    </div>
                    <div className="details-page-section">
                        <div className="details-img-container">
                            <div className="details-img">
                                <img alt={member?.name} id="gym-logo-img" src={member?.avatar} className="details-full-img full-width"/>
                            </div>
                            <button id="cam-btn" className="details-cam-btn en" onClick={showEdit}>
                                <img alt={member?.name} src="/assets/svg/img-change.svg"/>
                            </button>
                        </div>
                        <div className="flex-col jus-con-center center-align-items">
                            <p className="membership-name mb-2"> {member?.name} </p>
                            <p className="membership-status mb-2"> {member?.status} </p>
                            <p className="membership-unique-id mb-2"> {member?.member_unique_id} </p>
                        </div>
                        
                        <div className="details-gym-info">
                            <div className="details-info-section">
                                <span className="details-info-title">{translate("PersonalInfo")}</span>
                                <div className="details-info-data">
                                    <DataLine changeClick={showEdit} title={translate("Gender")} value={member?.gender} />
                                    <DataLine changeClick={showEdit} title={translate("Age")} value={member?.age + ' ' + translate('Years')} />
                                    <DataLine changeClick={showEdit} title={translate("Height")} value={member?.height + ' ' + translate('CM')} />
                                    <DataLine changeClick={showEdit} title={translate("Weight")} value={member?.weight + ' ' + translate('KG')} />
                                    <DataLine changeClick={showEdit} title={translate("Phone")} value={member?.phone_number} />
                                    <DataLine changeClick={showEdit} title={translate("Email")} value={member?.email} />
                                </div>
                            </div>
                        </div>

                        <div className="details-gym-info">
                            <div className="details-info-section">
                                <span className="details-info-title">{translate("BroughtBy")}</span>
                                <div className="details-info-data">
                                    <DataLine withoutBtn={true} title={translate("By")} value={member?.by} />
                                    <DataLine withoutBtn={true} title={translate("Name")} value={member?.employee} />
                                </div>
                            </div>
                        </div>

                        <div className="details-gym-info">
                            <div className="details-info-section">
                                <GrayButton
                                    onClick={() => setSwithcStatusAction(true)}
                                    label={member?.real_status === 0 ? translate('DeactivateMember') : translate('ActivateMember')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    swithcStatusAction ?
                    <div id="forms">
                        <DangerousAlert
                            disabled={disabled}
                            heading={member?.real_status === 0 ? translate('DeactivateMember') : translate('ActivateMember')}
                            message={
                                `${translate("AreYouSure")} ${
                                    member?.real_status === 0 ? translate('DeactivateMember') : translate('ActivateMember')
                                } "${member?.name}"?`
                            }
                            cancel={() => setSwithcStatusAction(false)}
                            confirm={switchStatus}
                            confirmLabel={translate(`Yes${member?.real_status === 0 ? 'Deactivate' : 'Activate'}`)}
                        />
                    </div>
                    : ''
                }
            </div>
    )
}

export default MemberData