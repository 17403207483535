import { useAlert } from "react-alert"
import { useContext, useState } from "react"
import AppContext from "../../../../partials/AppContext"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import FieldError from "../../../../FormShared/FieldError"
import Input from "../../../../FormShared/Input"
import ModalHeader from "../../../../FormShared/ModalHeader"
import ModalButtons from "../../../../FormShared/ModalButtons"

const ChangeTextAttribute = ({id ,cancel ,reloadData ,value ,attribute ,placeholder ,hint = undefined}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/employees/update/${id}`

    const [disabled ,setDisabled] = useState(false)
    const [fieldValue ,setFieldValue] = useState(value)
    const [error ,setError] = useState("")

    const saveChanges = () => {
        setDisabled(true)
        const data = {}
        data[attribute] = fieldValue
        
        Axios
        .setAuthToken(gymToken)
        .put(entityUrl ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                setError(validation[attribute] ?? '')
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={placeholder} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <Input
                                showSpanHint={hint}
                                placeholder={placeholder} value={fieldValue} onChange={e => {
                                setError("")
                                setFieldValue(e.target.value)
                            }}/>
                            {error ? <FieldError message={error} /> : ''}
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            cancelLabel={translate("Cancel")}
                            disabled={disabled}
                            confirm={saveChanges}
                            confirmLabel={`${translate("Edit")} ${placeholder}`}
                        />
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangeTextAttribute