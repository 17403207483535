import { useContext, useState } from 'react'
import { useLocation } from "react-router"
import Navbar from '../../partials/Navbar'
import CrudIndexDS from '../../Shared/CrudIndexDS'
import SubscriptionIndex from './Subscriptions/Index'
import ClassesIndex from './Classes/Index'
import SessionIndex from './Sessions/Index'
import CoachIndex from './Coach/Index'
import AppContext from '../../partials/AppContext'

const useQuery = () => new URLSearchParams(useLocation().search)

const Index = () => {
    const query = useQuery()
    const currentTab = query ? query.get('tab') : 'subscriptions'
    const currentTabAllowed = ['subscriptions' ,'classes' ,'sessions' ,'coach'].includes(currentTab)
    console.log(currentTab ,currentTabAllowed) 
    const {translate} = useContext(AppContext)
    const [showSection ,setShowSection] = useState(currentTabAllowed ? currentTab : 'subscriptions')

    const [subscriptions ,setSubscriptions] = useState({...CrudIndexDS})
    const [classes ,setClasses] = useState({...CrudIndexDS})
    const [sessions ,setSessions] = useState({...CrudIndexDS})
    const [coaches ,setCoaches] = useState({...CrudIndexDS})

    const subscriptionsComponent = <SubscriptionIndex
        visible={showSection === 'subscriptions'}
        data={subscriptions}
        setData={setSubscriptions}
    />,
    classesComponent = <ClassesIndex
        visible={showSection === 'classes'}
        data={classes}
        setData={setClasses}
    />,
    sessionsComponent = <SessionIndex
        visible={showSection === 'sessions'}
        data={sessions}
        setData={setSessions}
    />,
    coachComponent = <CoachIndex
        visible={showSection === 'coach'}
        data={coaches}
        setData={setCoaches}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Membership")}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'subscriptions' ? 'active' : ''}
                    onClick={() => setShowSection('subscriptions')}>
                    {translate("Subscriptions")}
                </button>
                <button className={showSection === 'classes' ? 'active' : ''}
                    onClick={() => setShowSection('classes')}>
                    {translate("Classes")}
                </button>
                <button className={showSection === 'sessions' ? 'active' : ''}
                    onClick={() => setShowSection('sessions')}>
                    {translate("Sessions")}
                </button>
                <button className={showSection === 'coach' ? 'active' : ''}
                    onClick={() => setShowSection('coach')}>
                    {translate("PrivateCoach")}
                </button>
            </div>
            <div className="page-content">
                {subscriptionsComponent}
                {classesComponent}
                {sessionsComponent}
                {coachComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index