import { useContext } from "react"
import AppContext from "../partials/AppContext"

const ModalButtons = (props) => {
    const {translate} = useContext(AppContext)
    return (
        <div className="confirm" style={props?.rowStyle}>
            {
                props.children ?
                props.children :
                <>
                    <button type="button" style={props?.btnStyle}
                        className="btn btn-default"
                        onClick={props.cancel}>
                        {props.cancelLabel ? props.cancelLabel : translate('Cancel')}
                    </button> 
                    <button type="button" style={props?.btnStyle}
                        className="btn btn-light"
                        disabled={props.disabled ? props.disabled : false}
                        onClick={props.confirm}>
                        {props.confirmLabel ? props.confirmLabel : translate('Confirm')}
                    </button> 
                </>
            }
        </div>
    )
}

export default ModalButtons