import { useContext } from "react"
import AppContext from "../../../partials/AppContext"

const Row = ({row ,checkoutAll ,memberLeftover}) => {
    const {translate} = useContext(AppContext)
    return (
        <tr>
            <td>
                <span className="clickable" onClick={memberLeftover}>{row?.member}</span>
            </td>
            <td> {row?.member_unique_id} </td>
            <td> {row?.total_orders} {translate(`Order${row?.total_orders > 1 ? 's' : ''}`)} </td>
            <td> {row?.total_cost} </td>
            <td className="actions center-items">
                <button onClick={checkoutAll}>
                    <img alt="Gymme" src="/assets/svg/reciept.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default Row