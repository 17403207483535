import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import SalaryInputs from "./SalaryInputs"

const App = ({entityUrl ,loadData ,cancel}) => {
    const alert = useAlert()
    const {translate ,gymToken ,clearUserData} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const [salary ,setSalary] = useState({
        employee_id: '', salary: '', deduction: '', extra: '', overtime: '', total: ''
    })
    const [errors ,setErrors] = useState({
        employee_id: '', salary: '', deduction: '', extra: '', overtime: ''
    })
    const [employees ,setEmployees] = useState([])
    const [selectedEmployee ,setSelectedEmployee] = useState({name: translate('Employee') ,value: undefined})
    const axiosSource = Axios.CancelToken.source()

    useEffect(() => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/employees` ,{cancelToken: axiosSource.token})
        .then(response => setEmployees(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setEmployees ,gymToken ,clearUserData ,alert ,entityUrl])

    const confirmAddSalary = () => {
        if (!salary.employee_id || !salary.salary) {
            setErrors({
                ...errors ,employee_id: translate('RequiredField') ,salary: translate('RequiredField')
            })
            return
        }
        setDisabled(true)
        const data = {
            employee_id: salary.employee_id,
            salary: salary.salary,
            deduction: salary.deduction ? salary.deduction : 0,
            extra: salary.extra ? salary.extra : 0,
            overtime: salary.overtime ? salary.overtime : 0,
        }
        Axios
        .setAuthToken(gymToken)
        .post(entityUrl ,data ,{cancelToken: axiosSource.token})
        .then(() => {
            setDisabled(false)
            alert.success(`${translate("EmployeeSalary")} (${selectedEmployee?.name}) ${translate("createdSuccessfully")}`)
            loadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddSalary")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <SalaryInputs
                            errors={errors}
                            setErrors={setErrors}
                            salary={salary}
                            setSalary={setSalary}
                            selectedEmployee={selectedEmployee}
                            setSelectedEmployee={setSelectedEmployee}
                            employees={employees}
                        />
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={confirmAddSalary}
                            confirmLabel={translate("AddSalary")}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default App