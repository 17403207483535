import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import DangerousAlert from "../../../Shared/DangerousAlert"

const Delete = ({subscription ,cancel ,reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/subscriptions`
    const [disabled ,setDisabled] = useState(false)

    const deleteSubscription = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/${subscription.id}` ,{_method: 'DELETE'})
        .then(response => {
            setDisabled(false)
            alert.success(`${translate("Subscription")} ${response?.data?.data?.title} ,${translate("deletedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading={`${translate("Delete")} "${subscription?.name}"`}
                message={`${translate("surePermanentlyDelete")} ${translate("Subscription")} "${subscription?.name}" ? ${translate("cantUndoAction")}`}
                confirmLabel={translate("Delete")}
                confirm={deleteSubscription}
                cancel={cancel}
            />
        </div>
    )
}

export default Delete