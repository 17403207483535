import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Pagination from "../../../Shared/Pagination"
import Add from "./Add"
import ClassCard from "./ClassCard"
import Delete from "./Delete"
import Edit from "./Edit"
import '../../../../css-files/membership-styles.css'
import AddMember from "./Members/AddMember"

const Index = ({data ,setData ,visible}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/training-classes`
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const [addClick ,setAddClick] = useState(undefined)
    const [editClick ,setEditClick] = useState(undefined)
    const [deleteClick ,setDeleteClick] = useState(undefined)
    const [actionMenu ,setActionMenu] = useState(undefined)
    const [addMemberClick ,setAddMemberClick] = useState(undefined)

    const cancel = () => {
        setAddClick(undefined)
        setEditClick(undefined)
        setDeleteClick(undefined)
        setAddMemberClick(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${responseData?.length <= 1 ? translate('Class') : translate('Classes')}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }
    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,alert ,dataPerPage ,currentPage ,clearUserData ,gymToken])

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,gymToken)

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    const reloadForDelete = () => {
        if (dataList.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    const deleteClass = classData => {
        cancel()
        setDeleteClick({
            id: classData.id,
            name: classData.title,
            totalCost: parseFloat(classData?.price) * parseFloat(classData?.registered_members)
        })
    }

    const editClass = id => {
        cancel()
        setEditClick(id)
    }

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <button className="btn btn-light" onClick={() => {
                        cancel()
                        setAddClick(true)
                    }}> {translate("AddClass")} </button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <div className="membership-cards-container">
                        {
                            dataList?.map(
                                classData => <ClassCard key={Math.random()}
                                    classData={classData}
                                    actionMenu={actionMenu}
                                    setActionMenu={setActionMenu}
                                    deleteClass={deleteClass}
                                    editClass={editClass}
                                    addMemberClick={() => {
                                        cancel()
                                        setAddMemberClick(classData?.id)
                                    }}
                                />
                            )
                        }
                    </div>
                    <Pagination btnClassName="full-width full-min-width full-max-width"
                        meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n})}/>
                </div>
            </div>
            {addClick ? <Add reloadData={reloadForAdd} cancel={cancel} /> : ''}
            {
                deleteClick && deleteClick.id ?
                <Delete
                    id={deleteClick.id}
                    name={deleteClick.name}
                    cancel={cancel}
                    totalCost={deleteClick.totalCost}
                    reloadData={reloadForDelete}
                />
                : ''
            }
            {editClick ? <Edit id={editClick} reloadData={reloadForAdd} cancel={cancel} /> : ''}
            {addMemberClick ? <AddMember classId={addMemberClick} reloadData={reloadForAdd} cancel={cancel} /> : ''}
        </div>
    )
}

export default Index