import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import Loading from "../../../Shared/Loading"
import './AttendedSessions.css'

const AttendedSessions = ({id ,cancel}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/attendance-history/show/${id}`
    const [loaded ,setLoaded] = useState(false)
    const [data ,setData] = useState({day: '' ,in: '' ,out: '' ,sessions: [] ,private_coaches: []})

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(entityUrl ,{cancelToken: axiosSource.token})
        .then(response => {
            setData(response?.data?.data)
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[alert ,gymToken ,clearUserData ,entityUrl ,setData])

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AttendedSession")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()} className="form-pad">
                        {
                            loaded ?
                            <div className="section mb-2">
                                <div className="attend-row">
                                    {data?.day}
                                </div>
                                <div className="attend-row">
                                    <div>
                                        <span className="attend-header">
                                            {translate("Attendance")}
                                        </span>
                                        <span> {data?.in} </span>
                                    </div>
                                    <div>
                                        <span className="attend-header">
                                            {translate("Leave")}
                                        </span>
                                        <span> {data?.out} </span>
                                    </div>
                                </div>
                                <div className="attend-row">
                                    <span className="attend-header">
                                        {translate("Sessions")}
                                    </span>
                                    {
                                        data?.sessions?.map(session => <span> {session} </span>)
                                    }
                                </div>
                                <div className="attend-row">
                                    <span className="attend-header">
                                        {translate("Classes")}
                                    </span>
                                    {
                                        data?.private_coaches?.map(_class => <span> {_class} </span>)
                                    }
                                </div>
                            </div>
                            : <Loading/>
                        }
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default AttendedSessions