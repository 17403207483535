const words = {
    AddRole: "Add Role",
    ArRoleName: "الوظيفة بالعربي",
    AccountRole: "Account Role",
    AccountRoles: "Account Roles",
    ArabicRole: "Arabic Role",
    AddedDate: "Added Date",
    Actions: "Actions",
    ADD: "Add",
    Account: "Account",
    AddAccount: "Add Account",
    AccoDelSuccess: "Account has been deleted successfully",
    Accounts: "Accounts",
    AddBranch: "Add Branch",
    ArBranchName: "Branch Arabic Name",
    ArabicAbout: "Arabic About",
    Address: "Address",
    AddCamera: "Add Camera",
    Arabic: "Arabic",
    Add: "Add",
    AllowMemInGym: "Number of allowed Members in Gym",
    AttendNo: "No. of attendance",
    AddEmployee: "Add Employee",
    AddAttendance: "Add Attendance",
    AgeYears: "Age (Years)",
    April: "April",
    August: "August",
    Attendance: "Attendance",
    Attendances: "Attendances",
    AttendnaceTime: "Attendance Time",
    AddEquipment: "Add Equipment",
    ArabicEquipment: "Equipment in Ar",
    AddMaintenance: "Add Maintenance",
    Action: "Action",
    AccountAction: "Account Action",
    AddDrink: "Add Drink",
    AddNew: "Add New",
    AddItem: "Add Item",
    ArabicDescription: "Arabic Description",
    AddSupplement: "Add Supplement",
    AttendedSession: "Attended Session",
    AttendanceTime: "Attendance Time",
    AddMember: "Add Member",
    At: "at",
    AttendSuccessfully: "attend successfully",
    AttendSession: "Attend Session",
    Attend: "Attend",
    Age: "Age",
    AddGuest: "Add Guest",
    App: "App",
    AllowedMemInGym: "Allowed Members in GYM",
    AddPrivateCoach: "Add Private Coach",
    AddMorSeession: "Add more sessions",
    AddSession: "Add Session",
    ActivateMember: "Activate member",
    AreYouSure: "Are you sure you want to",
    AddExtra: "Add Extra",
    AttendMemberFirst: "No member has attended yet ,please add attendance of at least one member to place an order",
    ArabicTitle: "Title in AR",
    AddOffer: "Add Offer",
    AddSubscription: "Add Subscription",
    ArabicName: "Arabic Name",
    AddClass: "Add Class",
    AddClassTime: "Add Class Time",
    AddMembers: "Add Members",
    AttendMember: "Attend Member",
    ArabicLang: "Arabic",
    Active: "Active",
    AddedBy: "Added By",
    AddSalary: "Add Salary",
    AddExpenses: "Add Expenses",
    AddRecord: "Add Record",
    Amount: "Amount",
    AllMembers: "All Members",
    AddFood: "Add Food",
    AvailableQTY: "Available Qnt",
    All: "All",
}

export default words