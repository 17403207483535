import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import CoachInputs from "./Inputs/CoachInputs"
import SessionInputs from "./Inputs/SessionInputs"
import './AddExtra.css'

const AddExtra = ({id, cancel ,reloadData ,defaultExtraType}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members`
    const [disabled ,setDisabled] = useState(false)
    const [extraType ,setExtraType] = useState(defaultExtraType ? defaultExtraType : 'session')
    const [sessions ,setSessions] = useState([{id: undefined ,title: '' ,times: 0 ,price: 0}])
    const [coaches ,setCoaches] = useState([{id: undefined ,title: '' ,times: 0 ,price: 0}])
    const [sessionTotal ,setSessionTotal] = useState(0)
    const [coachTotal ,setCoachTotal] = useState(0)

    const changeSession = sessions => {
        let _total = 0
        sessions?.forEach(session => _total += session?.price * session?.times)
        setSessions(sessions)
        setSessionTotal(_total)
    }

    const changeCoaches = coaches => {
        let _total = 0
        coaches?.forEach(coach => _total += coach?.price * coach?.times)
        setCoaches(coaches)
        setCoachTotal(_total)
    }

    const saveChanges = () => {
        setDisabled(true)
        if (extraType === 'coach') return saveCoaches()
        return saveSessions()
    }

    const saveSessions = () => {
        return storeDataToServer(`${entityUrl}/members/addExtraSessions` ,{
            member_id: id,
            sessions: [...sessions].map(session => {
                return {
                    session_id: session?.id,
                    times: session?.times
                }
            })
        })
    }

    const saveCoaches = () => {
        return storeDataToServer(`${entityUrl}/members/addExtraPrivateCoach` ,{
            member_id: id,
            privateCoach: [...coaches].map(coach => {
                return {
                    private_coach_id: coach?.id,
                    times: coach?.times
                }
            })
        })
    }

    const storeDataToServer = (url ,data ,storeSession = true) => {
        Axios
        .setAuthToken(gymToken)
        .post(url ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${
                response?.data?.data?.name} ${storeSession ? translate('extraSessions') : translate('extraPrivate')
            } ${translate("createdSuccessfully")}`)
            cancel()
            reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const [privateCoaches ,setPrivateCoaches] = useState([{id: undefined ,title: translate('PrivateCoach') ,price: 0}])
    const [extraSessions ,setExtraSessions] = useState([{id: undefined ,name: translate('Session') ,price: 0}])

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/members/getAllPrivateCoaches` ,{cancelToken: axiosSource.token})
        .then(response => setPrivateCoaches(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/members/getAllSessions`)
        .then(response => setExtraSessions(response?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setPrivateCoaches ,setExtraSessions ,alert ,gymToken ,clearUserData ,entityUrl])

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddSession")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <div className="row">
                                <div className="col-md-6 pr-0">
                                    <label class="checkbox">
                                        <div class="checkbox-btn">
                                            <input type="checkbox" checked={extraType === 'session'} onChange={e => setExtraType('session')} />
                                            <span class="input-checkbox custom-checkbox"></span>
                                        </div>
                                        <h6 class="checkbox-text">{translate("Session")}</h6>
                                    </label>
                                </div>
                                <div className="col-md-6 pl-0">
                                    <label class="checkbox">
                                        <div class="checkbox-btn">
                                            <input type="checkbox" checked={extraType === 'coach'} onChange={e => setExtraType('coach')} />
                                            <span class="input-checkbox custom-checkbox"></span>
                                        </div>
                                        <h6 class="checkbox-text">{translate("PrivateCoach")}</h6>
                                    </label>
                                </div>
                            </div>
                            {
                                extraType === 'coach' ?
                                <CoachInputs
                                    rowClassName="extra-member-row"
                                    className="col-sm-12 pad0"
                                    btnClassName="add-extra-btn"
                                    coaches={coaches}
                                    privateCoaches={privateCoaches}
                                    setCoaches={changeCoaches}
                                >
                                    <div className="extra-total">
                                    {translate("Price")} : {translate("EGP")} { coachTotal }
                                    </div>
                                </CoachInputs>
                                :
                                <SessionInputs
                                    rowClassName="extra-member-row"
                                    className="col-sm-12 pad0"
                                    btnClassName="add-extra-btn"
                                    sessions={sessions}
                                    extraSessions={extraSessions}
                                    setSessions={changeSession}
                                >
                                    <div className="extra-total">
                                        {translate("Price")} : {translate("EGP")} { sessionTotal }
                                    </div>
                                </SessionInputs>
                            }
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("Add")}
                            disabled={disabled}
                        />
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default AddExtra