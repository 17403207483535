import { useContext } from "react"
import DropDown from "../../../FormShared/DropDown"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import AppContext from "../../../partials/AppContext"

const SalaryInputs = ({
    errors, setErrors,
    salary, setSalary,
    selectedEmployee, setSelectedEmployee,
    employees
}) => {
    
    const {translate} = useContext(AppContext)

    const changeAttr = (attrName ,attrValue) => {
        const tempErrors = {...errors} ,tempSalary = {...salary}
        if (isNaN(attrValue)) {
            tempErrors[attrName] = translate('NumberOnly')
            setErrors(tempErrors)
            return
        }
        tempErrors[attrName] = ''
        tempSalary[attrName] = attrValue
        setErrors(tempErrors)
        setSalary(tempSalary)
    }

    const calcTotal = () => {
        let total = 0
        if (salary.salary) total += parseFloat(salary.salary)
        if (salary.extra) total += parseFloat(salary.extra)
        if (salary.overtime) total += parseFloat(salary.overtime)
        if (salary.deduction) total -= parseFloat(salary.deduction)
        return total
    }

    return (
        <div className="row">
            <div className="col-md-6 pl-0">
                <DropDown
                    selectedName={selectedEmployee?.name}
                    textColorBlack={selectedEmployee?.value}
                    list={employees}
                    itemName="name"
                    select={employee => {
                        setErrors({...errors ,employee_id: ''})
                        setSalary({...salary ,employee_id: employee?.id ,salary: employee?.salary})
                        setSelectedEmployee({name: employee?.name ,value: employee?.id})
                    }}
                />
                {errors?.employee_id ? <FieldError message={errors?.employee_id} /> : ''}
            </div>
            <div className="col-md-6 pr-0">
                <Input placeholder={translate("Salary")} value={salary?.salary} showSpanHint={"| " + translate("EGP")}
                    onChange={e => changeAttr('salary' ,e.target.value)}/>
                {errors.salary ? <FieldError message={errors.salary}/> : ''}
            </div>
            <div className="col-md-6 pl-0">
                <Input placeholder={translate("Extra")} value={salary?.extra} showSpanHint={"| " + translate("EGP")}
                    onChange={e => changeAttr('extra' ,e.target.value)}/>
                {errors.extra ? <FieldError message={errors.extra}/> : ''}
            </div>
            <div className="col-md-6 pr-0">
                <Input placeholder={translate("Overtime")} value={salary?.overtime} showSpanHint={"| " + translate("EGP")}
                    onChange={e => changeAttr('overtime' ,e.target.value)}/>
                {errors.overtime ? <FieldError message={errors.overtime}/> : ''}
            </div>
            <div className="col-md-6 pl-0">
                <Input placeholder={translate("Deduction")} value={salary?.deduction} showSpanHint={"| " + translate("EGP")}
                    onChange={e => changeAttr('deduction' ,e.target.value)}/>
                {errors.deduction ? <FieldError message={errors.deduction}/> : ''}
            </div>
            <div className="col-md-6 pr-0">
                <Input placeholder={translate("Total")} value={calcTotal()} showSpanHint={"| " + translate("EGP")}
                    readOnly={true}/>
            </div>
        </div>
    )
}

export default SalaryInputs