import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../Axios"
import AppContext from "../../partials/AppContext"
import DangerousAlert from "../../Shared/DangerousAlert"

const Delete = ({id ,name ,entityUrl ,cancel ,reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .delete(`${entityUrl}/${id}`)
        .then(response => {
            setDisabled(false)
            const responseData = response?.data?.data
            alert.success(`${responseData?.type} ,${responseData?.cash_type} ,${responseData?.amount} ${translate("deletedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading=""
                message={`${translate("surePermanentlyDelete")} "${name}"? ${translate("cantUndoAction")}`}
                cancel={cancel}
                confirm={saveChanges}
                confirmLabel={translate("YesDelete")}
            />
        </div>
    )
}

export default Delete