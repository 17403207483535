import { useAlert } from 'react-alert'
import { useContext, useState } from 'react'
import axios from "../../../Axios"
import Config from '../../../Config'
import ModalHeader from '../../FormShared/ModalHeader'
import ModalButtons from '../../FormShared/ModalButtons'
import EmployeeInputs from './EmployeeInputs'
import PhotoInput from '../../FormShared/PhotoInput'
import AppContext from '../../partials/AppContext'
import '../../../css-files/form.css'
import EmployeePositions from '../../../constants/EmployeePositions'
import Genders from '../../../constants/Genders'

const AddEmployee = ({cancel ,loadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const positions = EmployeePositions()
    const genders = Genders()
    const [employee ,setEmployee] = useState({
        name: '', national_id: '', gender: '', age: '', phone: '', email: '', salary: '', target: '', commission: '', position: ''
    })
    const [photo ,setPhoto] = useState(undefined)
    const [errors ,setErrors] = useState({
        name: '', national_id: '', gender: '', photo: '', age: '', phone: '', email: '', salary: '', target: '', commission: '', position: ''
    })
    const [selectedGender ,setSelectedGender] = useState({name: translate('Gender') ,value: ''})
    const [selectedPosition ,setSelectedPosition] = useState({name: translate('Position') ,value: ''})
    const [disabled ,setDisabled] = useState(false)
    const [showSalesManInputs ,setShowSalesManInputs] = useState(false)

    const updateEmployeeAttr = (value ,attr) => {
        if (attr == 'photo') setPhoto(value)
        else {
            const _employee = {...employee}
            _employee[attr] = value
            setEmployee(_employee)
        }
        const _errors = errors
        _errors[attr] = ''
        setErrors(_errors)
    }

    const storeEmployee = () => {
        setDisabled(true)
        const formData = new FormData()
        Object.keys(employee).forEach(attr => formData.append(attr ,employee[attr]))
        formData.set('gender' ,selectedGender.value)
        formData.set('position' ,selectedPosition.value)
        formData.set('branch_id' ,'3')// most be removed and it force to be loaded from server
        if (photo) formData.set('photo' ,photo)

        axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/employees` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(`${translate("Employee")} : ${response?.data?.data?.name} ,${translate("createdSuccessfully")}`)
            loadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changePosition = (position) => {
        setSelectedPosition(position)
        setShowSalesManInputs(position.value === 'salesman')
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddEmployee")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <PhotoInput updatePhoto={(img) => updateEmployeeAttr(img ,'photo')} error={errors.photo} />
                        <div className="section">
                            <EmployeeInputs
                                employee={employee}
                                updateEmployeeAttr={updateEmployeeAttr}
                                errors={errors}
                                gender={selectedGender}
                                setGender={setSelectedGender}
                                genders={genders}
                                position={selectedPosition}
                                setPosition={changePosition}
                                showSalesManInputs={showSalesManInputs}
                                positions={positions}
                            />
                        </div>
                        <ModalButtons disabled={disabled} cancel={cancel} confirm={storeEmployee} confirmLabel={translate("AddEmployee")} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddEmployee