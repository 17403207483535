import { NavLink } from "react-router-dom"

const Row = ({member ,cancel ,editAction ,deleteAction ,extrAction}) => {
    const setEditClick = editAction
    const setDeleteClick = deleteAction
    const setExtrAction = extrAction
    return (
        <tr>
            <td> 
                <NavLink to={`/members/${member.id}`} exact>
                    { member?.name }
                </NavLink>
            </td>
            <td> { member?.gender } </td>
            <td> { member?.member_unique_id } </td>
            <td> { member?.phone_number } </td>
            <td> { member?.paid } </td>
            <td className="actions">
                <button onClick={() => {
                    cancel()
                    setExtrAction(member?.id)
                }}>
                    <i className="fas fa-hot-tub"></i>
                </button>
                <button onClick={() => {
                    cancel()
                    setEditClick(member?.id)
                }}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={() => {
                    cancel()
                    setDeleteClick({id: member.id ,name: member.name})
                }}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default Row