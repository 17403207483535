import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import DangerousAlert from "../../../Shared/DangerousAlert"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Add from "./Add"
import Delete from "./Delete"
import Edit from "./Edit"
import Offer from "./Offer"
import SubscriptionCard from "./SubscriptionCard"

const Index = ({visible}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/subscriptions`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    const [subscriptions ,setSubscriptions] = useState([])
    const [addAction ,setAddAction] = useState(undefined)
    const [editId ,setEditId] = useState(undefined)
    const [deleted ,setDeleted] = useState(undefined)
    const [actionMenu ,setActionMenu] = useState(undefined)
    const [offer ,setOffer] = useState({id: undefined ,start: '' ,end: '' ,discount: ''})
    const [membershipOffer ,setMembershipOffer] = useState({price: 0 ,id: 0 ,status: undefined})
    const [disabled ,setDisabled] = useState(false)

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=1000` ,{cancelToken: axiosSource.token})
        .then(response => {
            const subscriptionsCount = response?.data?.data?.length,
                subscriptions = response?.data?.data,
                headerTitle = `${subscriptionsCount} ${translate(`Subscription${subscriptionsCount > 1 ? 's' : ''}`)}`
            setSubscriptions(subscriptions)
            setHeaderTitle(headerTitle)
            executeScroll()
            
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setSubscriptions ,alert ,entityUrl])

    const cancelModal = () => {
        setAddAction(undefined)
        setEditId(undefined)
        setDeleted(undefined)
        setMembershipOffer({price: 0 ,id: 0 ,status: undefined})
        setOffer({...offer ,isCanceled: false})
    }

    const cancelOffer = () => {
        if (!offer.id) {
            alert.show('Select offer first')
            return
        }
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/cancelOffer/${offer.id}` ,{_method: 'PUT'})
        .then(response => {
            setDisabled(false)
            alert.success(translate(`OfferCanceledSuccessfully`))
            setActionMenu(undefined)
            loadData()
            cancelModal()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,gymToken)

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox searchTrigger={loadData} setKeyword={setKeyword} keyword={keyword}/>
                    <button className="btn btn-light" onClick={() => {
                        cancelModal()
                        setActionMenu(undefined)
                        setAddAction(true)
                    }}>{translate("AddSubscription")}</button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                    <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <div className="membership-cards-container">
                        {
                            subscriptions.map(
                                subscription => <SubscriptionCard
                                    entityUrl={entityUrl}
                                    key={subscription.id}
                                    subscription={subscription}
                                    actionMenu={actionMenu}
                                    setActionMenu={setActionMenu}
                                    cancel={cancelModal}
                                    deleted={setDeleted}
                                    edited={setEditId}
                                    reloadData={loadData}
                                    setOffer={setOffer}
                                    setMembershipOffer={setMembershipOffer} />
                            )
                        }
                    </div>
                </div>
            </div>
            {deleted ? <Delete subscription={deleted} reloadData={loadData} cancel={cancelModal} /> : ''}
            {addAction ? <Add reloadData={loadData} cancel={cancelModal} /> : ''}
            {editId ? <Edit id={editId} reloadData={loadData} cancel={cancelModal} /> : ''}
            {
                membershipOffer.status ? 
                <Offer
                    subscriptionPrice={membershipOffer.price}
                    offerId={offer.id}
                    subscription_id={membershipOffer.id}
                    offer={offer}
                    cancel={cancelModal}
                    reloadData={loadData}
                />
                :''
            }
            {
                offer && offer.isCanceled ?
                <div id="forms">
                    <DangerousAlert
                        disabled={disabled}
                        heading={translate(`CancelOffer`)}
                        message={`${translate("PermanentlyCancelOffer")} "${offer?.name}" ? ${translate("cantUndoAction")}`}
                        confirmLabel={translate("CancelOffer")}
                        confirm={(cancelOffer)}
                        cancel={cancelModal}/>
                </div>
                : ''
            }
        </div>
    )
}

export default Index