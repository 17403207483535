import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticTimePicker from '@mui/lab/StaticTimePicker'
import { useContext } from 'react'
import AppContext from "../partials/AppContext"

const TimePicker = ({value ,onChange}) => {
  const {translate ,systemLang} = useContext(AppContext)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticTimePicker
        label={translate("SelectTime")}
        displayStaticWrapperAs="mobile"
        value={value}
        onChange={v => onChange(v)}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  )
}

export default TimePicker
