import {Fragment ,useContext, useState} from 'react'
import { Link, useHistory } from 'react-router-dom'
import Config from '../../../Config'
import AppContext from '../AppContext'
import './CustomNavbar.css'

const Navbar = (props) => {
    const history = useHistory()
    const {clearUserData ,user ,toggleAsideMenu ,systemLang ,changeLanguage ,translate} = useContext(AppContext)

    const [showProfileWiddget ,setShowProfileWiddget] = useState(false)
    const [showNotificationWiddget ,setShowNotificationWiddget] = useState(false)
    const [showLanguageWiddget ,setShowLanguageWiddget] = useState(false)

    const openWidget = (widget) => {
        setShowNotificationWiddget(false)
        setShowProfileWiddget(false)
        setShowLanguageWiddget(false)
        switch(widget) {
            case "profile":
                setShowProfileWiddget(!showProfileWiddget)
            break;
            case "notification":
                setShowNotificationWiddget(!showNotificationWiddget)
            break;
            case "language":
                setShowLanguageWiddget(!showLanguageWiddget)
            break;
            default:
                console.log('Undefined widget')
            break;
        }
    }

    const logout = () => {
        clearUserData()
        history.push('/')
    }

    const profileImageStyle = {
        borderRadius: '50%'
    }

    const fireToggle = () => {
        const menuLeftValue = window.getComputedStyle(document.getElementById('aside-menu')).getPropertyValue(systemLang === 'ar' ? 'right' : 'left')
        if (menuLeftValue === '-242px') toggleAsideMenu(true)
        else toggleAsideMenu(false)
    }

    return (
        <Fragment>
            <button id="burger" className="burger" onClick={fireToggle}>
                <img alt="Gymme" className="full-img" src="/assets/svg/bars-solid.svg"/>
            </button>

            <span className="title"> { props.navbarTitle } </span>

            <button id="change-lang" onClick={() => openWidget('language')} style={{position: 'relative'}}>
                <i title={systemLang} className='fas fa-globe'></i>
                <ul
                    className="notifications en"
                    style={{
                        display: `${showLanguageWiddget ? 'flex' : 'none'}`,
                        flexDirection: 'column',
                        gap: 15,
                        width: 130,
                        right: 0,
                        top: 25
                    }}
                >
                    {
                        Config.systemLanguagesConfig.map((langConfig ,index) =>
                            <li key={index}
                                style={{display: 'flex' ,justifyContent: 'space-between' ,alignContent: 'center' ,alignItems: 'center'}}
                                className={`clickable ${langConfig.value === systemLang ? 'current-lang' : ''}`}
                                onClick={() => changeLanguage(langConfig.value)}
                            >
                                <img alt="Gymme" src={langConfig.flagUrl} width={20}/> {langConfig.name}
                            </li>
                        )
                    }
                </ul>
            </button>

            {/* <button id="open-notifs" onClick={() => openWidget('notification')} style={{position: 'relative'}}>
                <img alt="Gymme" className="full-img" src="/assets/svg/bell.svg"/>
                <span className="mark"></span>
                <ul className="notifications en" style={{display: `${showNotificationWiddget ? 'flex' : 'none'}` ,right: 0 ,top: 25}}>
                    <li>
                        <div className="pic">
                            <img alt="Gymme" className="full-img" src="/assets/fitness-4879191@2x.png"/>
                        </div>
                        <div className="details">
                            <span className="branch">Maadi</span>
                            <span className="content"><b>Randy Wagner</b> Added new client <b>“ Adam Jerry “</b></span>
                            <span className="time">5 hours ago</span>
                        </div>
                        {translate("NoNotifications")}
                    </li>
                </ul>
            </button> */}
            <button id="open-profile" className="profile" onClick={() => openWidget('profile')} style={{position: 'relative'}}>
                <img alt="Gymme" style={profileImageStyle} className="full-img"
                    src={user.avatar ? user.avatar : "/assets/fitness-4879191@2x.png"}/>
                <div className="profile-popup en" style={{display: `${showProfileWiddget ? 'flex' : 'none'}` ,right: 0 ,top: 45}}>
                    <div className="profile-pic">
                        <img alt="Gymme" className="full-img" src={user.avatar ? user.avatar : "/assets/fitness-4879191@2x.png"} />
                    </div>
                    <p className="name" style={{textAlign: 'center'}}> {user?.name} </p>
                    <p className="mail" style={{textAlign: 'center'}}> {user?.email} </p>
                    <ul className="options">
                        <li className="option">
                            <img alt="Gymme" src="/assets/svg/profile.svg" />
                            <Link to="/profile">{translate("PersonalProfile")}</Link>
                        </li>
                        <li className="option" style={{lineHeight: 0}}>
                            <img alt="Gymme" src="/assets/svg/logout.svg"/>
                            <button style={{width:'50%'}} onClick={logout} className="logout" >{translate("LogOut")}</button>
                        </li>
                    </ul>
                </div>
            </button>
            
        </Fragment>
    )
}
export default Navbar