import { useContext } from "react"
import DropDown from "../../../FormShared/DropDown"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import PhotoInput from "../../../FormShared/PhotoInput"
import AppContext from "../../../partials/AppContext"

const EquipmentInput = ({
    equipmentData,
    changeAttr,
    errors,
    setErrors,
    setPhoto,
    selectedType,
    setSelectedType,
    types,
}) => {
    const { translate } = useContext(AppContext)

    return (
        <>
            <PhotoInput defaultImg={equipmentData?.image} updatePhoto={(img) => {
                setErrors({...errors ,image: ''})
                setPhoto(img)
            }} error={errors.image} />
            <div className="section mb-2">
                <div className="row">
                    <div className="col-md-12 pad0">
                        <DropDown
                            styles={{maxWidth: '100%'}}
                            selectedName={selectedType?.name}
                            textColorBlack={selectedType.value}
                            list={types}
                            itemName="name"
                            select={type => {
                                setSelectedType(type)
                                changeAttr('type' ,type?.value)
                            }} />
                        {errors.type ? <FieldError message={errors.type} /> : ''}
                    </div>
                    <div className="col-md-6 pl-0">
                        <Input
                            placeholder={translate("Equipment")}
                            value={equipmentData.equipment}
                            onChange={e => changeAttr('equipment' ,e.target.value)}/>
                        {errors.equipment ? <FieldError message={errors.equipment} /> : ''}
                    </div>
                    <div className="col-md-6 pr-0">
                        <Input
                            placeholder={translate("ArabicEquipment")}
                            value={equipmentData.equipment_ar}
                            onChange={e => changeAttr('equipment_ar' ,e.target.value)}/>
                        {errors.equipment_ar ? <FieldError message={errors.equipment_ar} /> : ''}
                    </div>
                    {
                        selectedType?.value === 'new' ?
                        <div className="col-md-6 pl-0">
                            <Input
                                placeholder={translate("Cost")}
                                value={equipmentData.purchase_price}
                                onChange={e => changeAttr('purchase_price' ,e.target.value)}/>
                            {errors.purchase_price ? <FieldError message={errors.purchase_price} /> : ''}
                        </div>
                        : ''
                    }
                </div>
            </div>
        </>
    )
}

export default EquipmentInput