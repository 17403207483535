const Row = ({data ,cancel ,checkout ,attendExtra}) => {
    return (
        <tr>
            <td> { data?.name } </td>
            <td> { data?.id_number } </td>
            <td> { data?.type } </td>
            <td> { data?.time } </td>
            <td> { data?.locker } </td>
            <td> { data?.invitation } </td>
            <td className="actions">
                <button onClick={attendExtra}>
                    <i className="fas fa-dumbbell"></i>
                </button>
                <button onClick={checkout}>
                    <i className="fas fa-sign-out-alt"></i>
                </button>
            </td>
        </tr>
    )
}

export default Row