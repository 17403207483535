import { useAlert } from 'react-alert'
import { useContext, useEffect, useState } from 'react'
import axios from "../../../Axios"
import Config from '../../../Config'
import ModalHeader from '../../FormShared/ModalHeader'
import ModalButtons from '../../FormShared/ModalButtons'
import AccountCommonInputs from './AccountCommonInputs'
import PhotoInput from '../../FormShared/PhotoInput'
import AppContext from '../../partials/AppContext'
import '../../../css-files/form.css'
import Loading from '../../Shared/Loading'

const EditAccount = ({accountId ,cancel ,reloadData}) => {
    const alert = useAlert()
    const [account ,setAccount] = useState({id: '' ,username: '' ,email: '' ,name: '' ,phone: '' ,password: '' ,photo: {} ,role: {id: '' ,name: ''} ,defaultImg : ''})
    const [errors ,setErrors] = useState({username: '' ,email: '' ,name: '' ,phone: '' ,password: '' ,role: '' ,photo: '' ,branch_id: ''})
    const [branch ,_setBranch] = useState({id: 0 ,name: ''})
    const [employee ,setEmployee] = useState({id: 0 ,name: ''})
    const {clearUserData ,gymToken ,gymAccountType ,translate} = useContext(AppContext)
    const [loaded ,setLoaded] = useState(false)
    const [disabled ,setDisabled] = useState(false)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(gymToken)
        .get(`${Config.apiBaseUrl}/gym-dashboard-services/gym-accounts/${accountId}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const _acc = response.data.data
            console.log(_acc)
            const _account = {
                id: _acc?.id,
                username: _acc?.username,
                email: _acc?.email !== null ? _acc?.email : '',
                name: _acc?.name,
                phone: _acc?.phone !== null ? _acc?.phone : '',
                role: {id: '', name_en: ''}
            }
            try {
                _account.role.id = _acc.roles[0].id
                _account.role.name = _acc.roles[0].name_en
            } catch (err) {
            }
            _account.defaultImg = _acc?.photo
            setAccount(_account)
            setBranch({id: _acc?.branch?.id ,name: _acc?.branch?.name})
            setLoaded(true)
            setEmployee({name: _acc?.employee?.name ,id: _acc?.employee?.id})
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setAccount ,alert])

    const updateAccountAttr = (value ,attr) => {
        const _account = {...account}
        _account[attr] = value
        setAccount(_account)
        const _errors = errors
        _errors[attr] = ''
        setErrors(_errors)
    }

    const addRole = (id ,name) => {
        setAccount({...account ,role: {id ,name}})
    }

    const updateAccount = () => {
        setDisabled(true)
        const formData = new FormData()
        Object.keys(account).forEach(attr => formData.append(attr ,account[attr]))
        formData.set('gym_roles[0]' ,account.role.id)
        if (account.photo)
            formData.set('photo' ,account.photo)
        formData.set('_method' ,'put')
        if(gymAccountType === 'owner')
            formData.set('branch_id' ,branch.id)
        else
            formData.set('employee_id' ,employee?.id)
        axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/gym-accounts/${accountId}` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(`${translate("Account")} : ${response?.data?.data?.name} ,${translate('updatedSuccessfully')}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const setBranch = branch => {
        _setBranch(branch)
        setErrors({...errors ,branch_id: ''})
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditAccount")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ? 
                            <>
                                <PhotoInput
                                    isOptional={true}
                                    description={translate('UploadImgMsg')}
                                    updatePhoto={(img) => updateAccountAttr(img ,'photo')}
                                    defaultImg={account.defaultImg}
                                    error={errors.photo} />
                                <div className="section">
                                    <AccountCommonInputs
                                        addRole={addRole}
                                        setBranch={setBranch}
                                        branch={branch}
                                        account={account}
                                        employee={employee}
                                        setEmployee={setEmployee}
                                        updateAccountAttr={updateAccountAttr}
                                        errors={errors} />
                                </div>
                                <ModalButtons disabled={disabled} cancel={cancel} confirm={updateAccount} confirmLabel={translate("EditAccount")} />
                            </>
                            : <Loading/>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditAccount