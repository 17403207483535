import { useContext } from "react"
import DropDown from "../../../FormShared/DropDown"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import AppContext from "../../../partials/AppContext"

const CoachInput = ({
    employee,
    employees,
    setEmployee,
    errors,
    quantity,
    changeQnty,
    price,
    changePrice
}) => {
    const {translate} = useContext(AppContext)
    return (
        <>
            <DropDown
                selectedName={employee?.name}
                textColorBlack={employee.id}
                list={employees}
                itemName="name"
                select={empl => setEmployee({id: empl?.id ,name: empl?.name})} />
            {errors.employee_id ? <FieldError message={errors.employee_id} /> : ''}
            <Input placeholder={translate("Quantity")} value={quantity} onChange={changeQnty}/>
            {errors.quantity ? <FieldError message={errors.quantity} /> : ''}
            <Input placeholder={translate("PriceEgp")} value={price} onChange={changePrice}/>
            {errors.price ? <FieldError message={errors.price} /> : ''}
        </>
    )
}

export default CoachInput