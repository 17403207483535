import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../../Config"
import ModalHeader from "../../../../FormShared/ModalHeader"
import AppContext from "../../../../partials/AppContext"
import SearchBox from "../../../../Shared/SearchBox"
import CartForm from "./CartForm"
import "./OrderForm.css"
import ProductRow from "./ProductRow"

const OrderForm = ({products ,orderData ,setOrderData ,cancel ,placeOrder ,errorMsg ,disabled}) => {
    const alert = useAlert()
    const {translate ,systemLang ,barSetting} = useContext(AppContext)
    
    const bars = barSetting
    const DrinkSetting = Config.Order.DrinkSetting,
        FoodSetting = Config.Order.FoodSetting,
        SupplementSetting = Config.Order.SupplementSetting

    const [currentTab ,setCurrentTab] = useState(bars[0])
    const [currentCategory ,setCurrentCategory] = useState('')
    const [showProducts ,setShowProducts] = useState([])
    const [launchedProducts ,setLaunchedProducts] = useState([])
    const [searchKey ,setSearchKey] = useState('')

    const categoryRef = useRef()

    const changeCurrentTab = tab => {
        try {
            changeCurrentCategory(tab ,products[tab][0]?.id ,products[tab][0]?.name)
            setCurrentTab(tab)
            resetCaresoul()
        } catch (err) {
            alert.error('Empty Slot')
        }
    }

    const changeCurrentCategory = (tab ,categoryId ,categoryName) => {
        setCurrentCategory(categoryName)
        const __products = getProducts(tab ,categoryId)
        setShowProducts(__products)
        setLaunchedProducts(__products)
    }

    const getProducts = (tab ,categoryId) => {
        const temp = products[tab].filter(category => category?.id === categoryId)[0]
        return tab === DrinkSetting ? temp.drinks : (tab === FoodSetting ? temp.foods : temp.supplements)
    }

    useEffect(() => changeCurrentCategory(currentTab ,products[currentTab][0]?.id ,products[currentTab][0]?.name) ,[])
    
    const caresoul = direction => {
        const pixels = 450
        switch(direction) {
            case "right":
                categoryRef.current.scrollLeft += pixels
            break;
            case "left":
                categoryRef.current.scrollLeft -= pixels
            break;
            default:
                console.log('undefined direction')
        }
    }

    const resetCaresoul = () => {
        categoryRef.current.scrollLeft = 0
    }

    const appendItem = (type ,{id ,name ,price ,maxQnt ,qnt}) => {
        qnt = parseFloat(qnt)
        maxQnt = parseFloat(maxQnt)
        const existsIndex = orderData.items.findIndex(__item => __item?.id === id && __item.type === type)
        if (existsIndex === -1) {
            setOrderData({
                ...orderData,
                items: [
                    ...orderData.items,
                    {type ,id ,name ,price ,maxQnt ,qnt}
                ]
            })
        } else {
            const tempItems = [...orderData.items] ,currentQnt = parseFloat(tempItems[existsIndex].qnt)
            if (currentQnt + qnt <= maxQnt) {
                tempItems[existsIndex].qnt = currentQnt + qnt
            } else {
                tempItems[existsIndex].qnt = maxQnt
            }
            setOrderData({...orderData, items: [...tempItems]})
        }
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container custom-order-form">
                <div className="contain">
                    <ModalHeader title="Place Order" cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="custom-header">
                            <div className="bar-icons">
                                {
                                    bars.includes(DrinkSetting) ?
                                    <div
                                        onClick={() => changeCurrentTab(DrinkSetting)}
                                        className={`clickable ${currentTab === DrinkSetting ? 'active' : ''}`}> {translate("Drinks")} </div>
                                    : ''
                                }
                                {
                                    bars.includes("Food") ?
                                    <div
                                        onClick={() => changeCurrentTab(FoodSetting)}
                                        className={`clickable ${currentTab === FoodSetting ? 'active' : ''}`}> {translate("Food")} </div>
                                    : ''
                                }
                                {
                                    bars.includes(SupplementSetting) ?
                                    <div
                                        onClick={() => changeCurrentTab(SupplementSetting)}
                                        className={`clickable ${currentTab === SupplementSetting ? 'active' : ''}`}> {translate("Supplements")} </div>
                                    : ''
                                }
                            </div>
                            <div className="details-title"> {translate("OrderDetails")} </div>
                        </div>
                        <div className="custom-body">
                            <div className="products-section">
                                <div className="categories">
                                    <div id="categories-controls">
                                        <i className={`clickable fas fa-arrow-${systemLang === 'ar' ? 'right' : 'left'}`} onClick={() => caresoul(systemLang === 'ar' ? 'right' : 'left')}></i>
                                        <i className={`clickable fas fa-arrow-${systemLang === 'ar' ? 'left' : 'right'}`} onClick={() => caresoul(systemLang === 'ar' ? 'left' : 'right')}></i>
                                    </div>
                                    <div className="scroll-able" ref={categoryRef}>
                                        {
                                            products && products[currentTab] && Array.isArray(products[currentTab]) ?
                                            products[currentTab].map(category =>
                                                <div
                                                    key={Math.random()}
                                                    onClick={() => changeCurrentCategory(currentTab ,category?.id ,category?.name)}
                                                    className={`clickable category-div ${currentCategory === category?.name ? 'active' : ''}`}>
                                                    {category?.name}
                                                </div>
                                            )
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="search">
                                    <SearchBox
                                        searchTrigger={() => {
                                            if (searchKey === '') setShowProducts(launchedProducts)
                                            else {
                                                const temp = [...showProducts.filter(product => {
                                                    switch(currentTab) {
                                                        case SupplementSetting:
                                                            return product?.brand?.name?.includes(searchKey) ||
                                                                product?.unit?.name?.includes(searchKey) ||
                                                                product?.size?.name?.includes(searchKey)
                                                        case FoodSetting:
                                                            return product?.p_name?.includes(searchKey) ||
                                                                product?.calories?.includes(searchKey) ||
                                                                product?.description?.includes(searchKey)
                                                        default:
                                                            return product?.p_name?.includes(searchKey)
                                                    }
                                                })]
                                                setShowProducts([...temp])
                                            }
                                        }}
                                        setKeyword={value => setSearchKey(value)}
                                        keyword={searchKey}
                                        placeholder="Keyword"/>
                                </div>
                                <div className="products">
                                    {
                                        showProducts.map((product ,index) => 
                                            <ProductRow index={index} product={product} currentTab={currentTab} appendItem={appendItem}/>
                                        )
                                    }
                                </div>
                            </div>
                            <CartForm orderData={orderData} setOrderData={setOrderData} placeOrder={placeOrder} errorMsg={errorMsg} disabled={disabled}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default OrderForm