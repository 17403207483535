import axios from './Axios'
import { useEffect, useState } from 'react'
import AppTemplate from './components/AppTemplate';
import AuthTemplate from './components/Authenticate/AuthTemplate'
import Config from './Config'
import AppContext from './components/partials/AppContext'
import { positions, Provider } from "react-alert"
import AlertTemplate from "react-alert-template-basic"
import $ from 'jquery'
import translateByKey from './translation-words'

const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER
}

const App = (props) => {
    const handleStorageToken = Config.handleStorageToken
    const [appLoaded ,setAppLoaded] = useState(false)
    const [user ,setUser] = useState({username: '', email: '', avatar: '', name: '', phone: ''})
    const [token ,setToken] = useState(handleStorageToken({action: 'get'}))
    const [gymAccountType ,setGymAccountType] = useState('account')
    const [systemLang ,setSystemLang] = useState(Config.defaultLang)
    const [bar ,setBar] = useState([])
    const [hasLockers ,setHasLockers] = useState(true)
    
    const clearUserData = () => {
        handleStorageToken({action: 'remove'})
        setUser({username: '', email: '', avatar: '', name: '', phone: ''})
        setToken(undefined)
    }

    const updateToken = (token ,remember = false) => {
        setToken(token)
        handleStorageToken({action: 'set' ,token: token ,storage: remember ? 'local' : 'session'})
    }

    useEffect(() => {
        const storedLang = localStorage.getItem('systemLang')
        if (storedLang && Config.systemLanguages.includes(storedLang))
            setSystemLang(storedLang)
        else {
            localStorage.setItem('systemLang' ,Config.defaultLang)
            setSystemLang(Config.defaultLang)
        }
        if (appLoaded) return
        const savedToken = handleStorageToken({action: 'get'})
        if (savedToken) {
            axios.post(`${Config.apiBaseUrl}/auth-layer/gym/token-valid` ,{token: savedToken, remember: 'yes'})
            .then(response => {
                const _user = response?.data?.admin ,token = response?.data?.token
                setUser({
                    username: _user?.username,
                    email: _user?.email,
                    avatar: _user?.avatar,
                    name: _user?.name,
                    phone: _user?.phone
                })
                setToken(token)
                setBar(response?.data?.bar)
                if (_user?.account_type === 'owner') setGymAccountType('owner')
                setHasLockers(response?.data?.has_lockers)
                setAppLoaded(true)
            })
            .catch(err => {
                clearUserData()
                setAppLoaded(true)
            })
        } else {
            setAppLoaded(true)
            setToken(undefined)
        }
    } ,[setAppLoaded ,clearUserData ,setSystemLang ,setGymAccountType ,setBar ,setToken ,setUser ,setHasLockers])

    const refreshBarSettings = () => {
        axios.post(`${Config.apiBaseUrl}/auth-layer/gym/token-valid` ,{token: token})
        .then(response => setBar(response?.data?.bar))
        .catch(err => {
            clearUserData()
        })
    }

    const providerValue = {
        setToken: (token ,remember = false) => updateToken(token ,remember),
        clearUserData: () => clearUserData(),
        setUserData: (userData) => setUser({
            username: userData?.username,
            email: userData?.email,
            avatar: userData?.avatar,
            name: userData?.name,
            phone: userData?.phone
        }),
        gymToken: token,
        user: user,
        toggleAsideMenu : (isShow = false) => {
            if (isShow) {
                $('#aside-menu').addClass('show-aside-menu')
                $('#aside-menu-overlay').addClass('show')
            } else {
                $('#aside-menu').removeClass('show-aside-menu')
                $('#aside-menu-overlay').removeClass('show')
            }
        },
        gymAccountType: gymAccountType,
        setGymAccountType: accountType => setGymAccountType(accountType === 'owner' ? 'owner' : 'account'),
        closeMenu: () => document.getElementById('close-aside-menu').click(),
        barSetting: bar,
        setBranchBar: setBar,
        refreshBarSettings: refreshBarSettings,
        systemLang: systemLang,
        changeLanguage: lang => {
            localStorage.setItem('systemLang' ,lang)
            window.location.reload()
        },
        translate: key => {
            return translateByKey(key ,localStorage.getItem('systemLang'))
        },
        hasLockers: hasLockers,
        setHasLockers: setHasLockers
    }

    return (
        <Provider template={AlertTemplate} {...options}>
            {
                appLoaded ?
                <AppContext.Provider value={ providerValue }>
                    { token ? <AppTemplate/> : <AuthTemplate/> }
                </AppContext.Provider>
                : ''
            }
        </Provider>
    )
}

export default App