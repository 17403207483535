const Row = ({data ,cancel ,approveBooking ,cancelBooking}) => {
    const approve = () => {
        cancel()
        approveBooking({booking_id: data?.id ,member_id: data?.member_id ,name: data?.name ,unique_id: data?.id_number})
    }

    const cancelBook = () => {
        cancel()
        cancelBooking({id: data?.id ,name: data?.name ,date: data?.date ,time: data?.time})
    }

    return (
        <tr>
            <td> { data?.name } </td>
            <td> { data?.id_number } </td>
            <td> { data?.date } </td>
            <td> { data?.time } </td>
            <td className="actions">
                <button className="status-action-btn success" onClick={approve}>
                    <i className="fas fa-check"></i>
                </button>
                <button className="status-action-btn danger" onClick={cancelBook}>
                    <i className="fas fa-times"></i>
                </button>
            </td>
        </tr>
    )
}

export default Row