import { useContext } from "react"
import AppContext from "../../../partials/AppContext"

const SubscriptionCard = ({
    subscription,
    actionMenu,
    setActionMenu,
    edited,
    deleted,
    setOffer,
    setMembershipOffer
}) => {
    const {translate} = useContext(AppContext)
    const price = subscription?.activeOffer ? `${subscription?.activeOffer.price_after_discount} ${translate("EGP")}` : subscription?.price
    const oldPrice = subscription?.activeOffer ? true : false
    const addOffer = () => {
        setActionMenu(undefined)
        setMembershipOffer({price: subscription?.real_price ,id: subscription.id ,status: true})
        setOffer({id: undefined ,start: '' ,end: '' ,discount: '' ,title: '' ,title_ar: '' ,quantity: ''})
    }
    const editOffer = () => {
        setActionMenu(undefined)
        setMembershipOffer({price: subscription?.real_price ,id: subscription.id ,status: true})
        setOffer({
            id: subscription?.activeOffer?.id,
            start: subscription?.activeOffer?.offer_start,
            end: subscription?.activeOffer?.offer_end,
            discount: subscription?.activeOffer?.discount_percentage,
            title: subscription?.activeOffer?.offer_title,
            title_ar: subscription?.activeOffer?.offer_title_ar,
            quantity: subscription?.activeOffer?.offer_quantity,
        })
    }

    const invokeEdit = () => {
        setActionMenu(undefined)
        edited(subscription.id)
    }

    const invokeDelete = () => {
        setActionMenu(undefined)
        deleted({name: subscription.title ,id: subscription.id})
    }

    const toggleMenuAction = () => {
        setActionMenu(actionMenu === subscription.id ? undefined : subscription.id)
    }

    const cancelOffer = () => {
        setActionMenu(undefined)
        setOffer({
            name: `${subscription?.activeOffer?.discount_percentage} % ${translate("ExtraDiscount")}`,
            id: subscription?.activeOffer?.id,
            start: subscription?.activeOffer?.offer_start,
            end: subscription?.activeOffer?.offer_end,
            discount: subscription?.activeOffer?.discount_percentage,
            isCanceled: true
        })
    }

    return (
        <div className="membership-card">
            <div className={`membership-actions ${actionMenu === subscription.id ? 'display-show' : 'display-hide'}`}>
                <button type="button" className="small-btn default-small-btn menu-action-hover"
                    onClick={subscription?.activeOffer ? editOffer : addOffer}>
                    <i className="fas fa-percentage"></i>
                    <span> {translate(`${subscription?.activeOffer ? 'Edit' : 'Add'}Offer`)} </span>
                </button>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={invokeEdit}>
                <i className="fas fa-pen"></i>
                    <span> {translate("Edit")} </span>
                </button>
                <button type="button" className="small-btn default-small-btn menu-action-hover"
                    onClick={subscription?.activeOffer ? cancelOffer : invokeDelete}>
                <i className="fas fa-trash"></i>
                    <span> {subscription?.activeOffer ? translate('CancelOffer') : translate('Delete')} </span>
                </button>
            </div>
            <img alt={subscription?.title} src="/assets/svg/offer.svg"
                className={`offer-badge en ${subscription?.activeOffer ? '' : 'no-display'}`} />
            <button className="dots en" type="button" onClick={toggleMenuAction}>
                <img alt={subscription?.title} src="/assets/svg/dots.svg" className="full-img"/>
            </button>
            <span className="subscription-title"> { subscription?.title } </span>
            <div className="main-title">
                <span className="price"> {price} </span>
                {
                    oldPrice ? <span className="old-price"> {subscription?.price} {translate("EGP")} </span> : ''
                }
            </div>
            <span className={`duration-left ${subscription?.activeOffer ? '' : 'no-display'}`}> {subscription?.activeOffer?.daysLeft} </span>
            <ul className="options">
                <li> { subscription?.number_of_days } </li>
                <li> { subscription?.number_of_invitations } </li>
                <li> { subscription?.freezing_days } </li>
                <li> { subscription?.visits_number_text } </li>
                {
                    subscription?.session?.map(session => <li key={session.id} > {session.name} </li> )
                }
            </ul>
            
        </div>
    )
}

export default SubscriptionCard