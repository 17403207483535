import { useContext, useState } from "react"
import { useParams } from "react-router"
import { NavLink } from "react-router-dom"
import AppContext from "../../../../partials/AppContext"
import Navbar from "../../../../partials/Navbar"
import CrudIndexDS from "../../../../Shared/CrudIndexDS"
import Attendance from './Attendance/Index'
import History from './History/Index'
import Members from './Members/Index'

const Index = ({}) => {
    const {translate ,systemLang} = useContext(AppContext)
    const params = useParams() ,id = params.id
    const [showSection ,setShowSection] = useState('members')

    const [members ,setMembers] = useState({...CrudIndexDS})
    const [attendance ,setAttendance] = useState({...CrudIndexDS})
    const [history ,setHistory] = useState({...CrudIndexDS})

    const membersComponent = <Members
        visible={showSection === 'members'}
        data={members}
        setData={setMembers}
        classId={id}
    />,
    attendanceComponent = <Attendance
        visible={showSection === 'attendance'}
        data={attendance}
        setData={setAttendance}
        classId={id}
    />,
    historyComponent = <History
        visible={showSection === 'history'}
        data={history}
        setData={setHistory}
        classId={id}
    />
    
    const navbar = <>
        <NavLink to="/membership?tab=classes" exact> <i className={`fas fa-arrow-${systemLang === 'ar' ? 'right' : 'left'} black-color`}></i> </NavLink> {translate("Classes")}
    </>

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={navbar}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'members' ? 'active' : ''}
                    onClick={() => setShowSection('members')}>
                    {translate("Members")}
                </button>
                <button className={showSection === 'attendance' ? 'active' : ''}
                    onClick={() => setShowSection('attendance')}>
                    {translate("Attendance")}
                </button>
                <button className={showSection === 'history' ? 'active' : ''}
                    onClick={() => setShowSection('history')}>
                    {translate("History")}
                </button>
            </div>
            <div className="page-content">
                {membersComponent}
                {attendanceComponent}
                {historyComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index