import {useContext, useState} from 'react'
import Navbar from '../../partials/Navbar'
import CrudIndexDS from '../../Shared/CrudIndexDS'
import Order from './Orders/Index'
import Leftover from './Leftover/Index'
import Statistics from './Statistics/Index'
import History from './History/Index'
import AppContext from '../../partials/AppContext'

const Index = () => {
    const {translate} = useContext(AppContext)
    const [showSection ,setShowSection] = useState('orders')

    const [orderData ,setOrderData] = useState({...CrudIndexDS})
    const [historyData ,setHistoryData] = useState({...CrudIndexDS})
    const [leftoverData ,setLeftoverData] = useState({...CrudIndexDS})
    const [statisticsData ,setStatisticsData] = useState({})

    const OrderComponent = <Order
        visible={showSection === 'orders'}
        data={orderData}
        setData={setOrderData}
    />,
    LeftoverComponent = <Leftover
        visible={showSection === 'leftover'}
        data={leftoverData}
        setData={setLeftoverData}
    />,
    HistoryComponent = <History
        visible={showSection === 'history'}
        data={historyData}
        setData={setHistoryData}
    />,
    StatisticsComponent = <Statistics
        visible={showSection === 'statistics'}
        data={statisticsData}
        setData={setStatisticsData}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Orders")}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'orders' ? 'active' : ''}
                    onClick={() => setShowSection('orders')}>
                    {translate("Orders")}
                </button>
                <button className={showSection === 'statistics' ? 'active' : ''}
                    onClick={() => setShowSection('statistics')}>
                    {translate("Statistics")}
                </button>
                <button className={showSection === 'leftover' ? 'active' : ''}
                    onClick={() => setShowSection('leftover')}>
                    {translate("Leftover")}
                </button>
                <button className={showSection === 'history' ? 'active' : ''}
                    onClick={() => setShowSection('history')}>
                    {translate("History")}
                </button>
            </div>
            <div className="page-content">
                { OrderComponent }
                { StatisticsComponent }
                { LeftoverComponent }
                { HistoryComponent }
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index