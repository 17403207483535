import { useContext } from 'react'
import Config from '../../../Config'
import AppContext from '../../partials/AppContext'
import Navbar from '../../partials/Navbar'
import Statistics from './Statistics/Index'

const BranchDashboard = () => {
    const {translate ,systemLang} = useContext(AppContext)
    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Dashboard")}/>
            </div>
            <div className="page-content">
                {translate("LastUpdateTime")} : {Config.getYesterday(systemLang)}
                <Statistics lastCounterKey="counters" entityUrl={`${Config.apiBaseUrl}/gym-dashboard-services/dashboard`}/>
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default BranchDashboard