import { useAlert } from "react-alert"
import { useContext ,useState ,useEffect ,useRef } from "react"
import AppContext from "../../partials/AppContext"
import Navbar from "../../partials/Navbar"
import axios from '../../../Axios'
import Config from '../../../Config'
import SearchBox from '../../Shared/SearchBox'
import PrintButton from '../../Shared/PrintButton'
import Thead from '../../Shared/Thead'
import Table from '../../Shared/Table'
import Pagination from '../../Shared/Pagination'
import Row from "./Row"
import DeleteBranch from "./DeleteBranch"
import AddBranch from "./AddBranch"
import EditBranch from "./EditBranch"
import DropDown from "../../FormShared/DropDown"
import ManageBranch from "./ManageBranch"
import Axios from "../../../Axios"
import BranchSettings from "./BranchSettings"

const Index = () => {
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/branches`

    const {clearUserData ,gymToken ,translate ,setToken} = useContext(AppContext)
    const alert = useAlert()
    const [invokeAdd ,setInvokeAdd] = useState(undefined)
    const [invokeDelete ,setInvokeDelete] = useState(undefined)
    const [invokeEdit ,setInvokeEdit] = useState(undefined)
    const [invokeManage ,setInvokeManage] = useState(undefined)
    const [invokeSettings ,setInvokeSettings] = useState(undefined)
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    const [branches ,setBranches] = useState([])
    const [dataPerPage ,setDataPerPage] = useState(10)
    const [currentPage ,setCurrentPage] = useState(1)
    const [meta ,setMeta] = useState({links: [] ,last_page: 1})
    const [cities ,setCities] = useState([])
    const [selectedCity ,setSelectedCity] = useState({name: translate("All") ,value: undefined})
    const [btnDisabled ,setBtnDisabled] = useState(false)
    const axiosSource = axios.CancelToken.source()

    const queryParam = (asPagination = true) => {
        let q = `${asPagination ? `?limit=${dataPerPage}&page=${currentPage}&` : '?'}`
        q += `keyword=${keyword}&city=${selectedCity.value ? selectedCity?.value : ''}`
        return q
    }

    const loadData = () => {
        axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}${queryParam()}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const branchesCount = response?.data?.data?.length,
                branches = response?.data?.data,
                headerTitle = `${branchesCount} ${branchesCount > 1 ? translate('Branches') : translate('Branch')}`,
                meta = response?.data?.meta
            setBranches(branches)
            setHeaderTitle(headerTitle)
            executeScroll()
            setMeta(meta)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setBranches ,clearUserData ,alert ,gymToken ,currentPage ,dataPerPage ,entityUrl ,selectedCity])

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/cities/getCityNames` ,{cancelToken: axiosSource.token})
        .then(response => setCities([{name: translate('All')} ,...response?.data?.cities]))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setSelectedCity])

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const cancelModal = () => {
        setInvokeDelete(undefined)
        setInvokeAdd(undefined)
        setInvokeEdit(undefined)
        Axios.removeBranch()
        setInvokeManage(undefined)
        setInvokeSettings(undefined)
    }

    const headers = [
        {value: translate('BranchName')},
        {value: translate('CreationDate')},
        {value: translate('Province')},
        {value: translate('City')},
        {value: translate('EmployeesNo')},
        {value: translate('Actions') ,className: "actions"}
    ]

    const deleteLoadData = () => {
        if (branches.length <= 1 && currentPage > 1) setCurrentPage(currentPage - 1)
        else loadData()
    }

    const addLoadData = () => {
        if (currentPage > 1) setCurrentPage(1)
        else loadData()
    }

    const printClick = () => Config.printByToken(`${entityUrl}/print${queryParam(false)}` ,true ,gymToken)

    const manageBranch = branchId => {
        setBtnDisabled(true)
        axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/manage-branch/${branchId}`)
        .then(response => {
            localStorage.setItem("backToOwnerToken" ,gymToken)
            setToken(response?.data?.data?.token ,Config?.isTokenInLocal)
            alert.info(translate("RedirectionInSeconds"))
            setTimeout(() => window.location = "/" ,2000)
        })
        .catch(err => {
            setBtnDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Branches")}/>
            </div>
            <div className="page-content">
                <div className="page-section header-box" ref={elRef}>
                    <div className="row header">
                        <SearchBox
                            searchTrigger={() => currentPage !== 1 ? setCurrentPage(1) : loadData()}
                            setKeyword={setKeyword}
                            keyword={keyword}/>
                        <DropDown
                            placeholder={translate("City")}
                            selectedName={selectedCity?.name}
                            textColorBlack={selectedCity?.value}
                            list={cities}
                            itemName="name"
                            select={city => setSelectedCity({name: city.name ,value: city?.id})}
                        />
                        <button
                            onClick={() => {
                                cancelModal()
                                setInvokeAdd(true)
                            }}
                            className="btn btn-light">
                            {translate("AddBranch")}
                        </button>
                    </div>
                </div>
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <PrintButton onClick={printClick}/>
                        </div>
                        <Table>
                            <Thead headers={headers}/>
                            <tbody>
                                {
                                    branches.map(
                                        branch =>
                                        <Row key={branch.id} branch={branch}
                                            editAction={() => {
                                                cancelModal()
                                                setInvokeEdit(branch.id)
                                            }}
                                            deleteAction={() => {
                                                cancelModal()
                                                setInvokeDelete({id: branch.id ,name: branch.name})
                                            }}
                                            manageBranch={() => {
                                                cancelModal()
                                                Axios.setBranch(branch.id)
                                                setInvokeManage({id: branch.id ,name: branch.name})
                                            }}
                                            branchSetting={() => {
                                                cancelModal()
                                                Axios.setBranch(branch.id)
                                                setInvokeSettings({id: branch.id ,name: branch.name})
                                            }}
                                            openBranchBoard={() => manageBranch(branch.id)}
                                            btnDisabled={btnDisabled}
                                        />
                                    )
                                }
                            </tbody>
                        </Table>
                        <Pagination currentPage={currentPage} dataPerPage={dataPerPage} meta={meta}
                            changeDataPerPage={setDataPerPage} changeCurrentPage={setCurrentPage}/>
                    </div>
                </div>
                {invokeAdd ? <AddBranch cancel={cancelModal} loadData={addLoadData} /> : ''}
                {invokeEdit ? <EditBranch id={invokeEdit} cancel={cancelModal} loadData={addLoadData} /> : ''}
                {
                    invokeDelete ?
                    <DeleteBranch
                        id={invokeDelete.id}
                        cancel={cancelModal}
                        loadData={deleteLoadData}
                        name={invokeDelete.name}
                    />
                    : ''
                }
                {invokeManage ? <ManageBranch branch={invokeManage} cancel={cancelModal} /> : ''}
                {invokeSettings ? <BranchSettings branch={invokeSettings} cancel={cancelModal} /> : ''}
            </div>
        </div>
    )
}

export default Index