import { useContext, useState } from 'react'
import Config from '../../../Config'
import Navbar from '../../partials/Navbar'
import CrudIndexDS from '../../Shared/CrudIndexDS'
import AttendanceRow from './AttendanceRow'
import EmployeeRow from './EmployeeRow'
import ListData from './ListData'
import HistoryRow from './HistoryRow'
import AppContext from '../../partials/AppContext'

const Index = () => {
    const [showSection ,setShowSection] = useState('employees')
    const {translate} = useContext(AppContext)

    const [employeesIndexData ,setEmployeesIndexData] = useState({...CrudIndexDS})
    const [attendanceIndexData ,setAttendanceIndexData] = useState({...CrudIndexDS})
    const [historyIndexData ,setHistoryIndexData] = useState({...CrudIndexDS})

    const EmployeesIndexComponent = <ListData
        entity='employees'
        entityPlural={translate('Employees')}
        entitySingle='Employee'
        filterByGender={true}
        filterByPosition={true}
        employeesFilter={true}
        headers={[
            {value: translate('Name')},
            {value: translate('Gender')},
            {value: translate('IDNumber')},
            {value: translate('Phone')},
            {value: translate('Position')},
            {value: translate('Salary')},
            {value: translate('Actions') ,className: 'actions'}
        ]}
        entityUrl={`${Config.apiBaseUrl}/gym-dashboard-services/employees`}
        printUrl={`${Config.apiBaseUrl}/gym-dashboard-services/employees/print`}
        visible={showSection === 'employees'}
        data={employeesIndexData}
        setData={setEmployeesIndexData}
        mapRow={(dataRow ,editInvoker ,deleteInvoker) => <EmployeeRow key={Math.random()}
            employee={dataRow}
            editInvoker={editInvoker}
            deleteInvoker={() => deleteInvoker(dataRow.id ,dataRow.name)}
        />}
    />,
    AttendanceIndexComponent = <ListData
        entity='attendance'
        entityPlural={translate('Attendances')}
        entitySingle='Attendance'
        headers={[
            {value: translate('Name') ,width: '50%'},
            {value: translate('IDNumber') ,width: '20%'},
            {value: translate('Time') ,width: '15%'},
            {value: translate('Actions') ,className: 'actions' ,width: '15%'}
        ]}
        entityUrl={`${Config.apiBaseUrl}/gym-dashboard-services/employees/attendance/getTodayAttendance`}
        printUrl={`${Config.apiBaseUrl}/gym-dashboard-services/employees/attendance/getTodayAttendance/print`}
        visible={showSection === 'attendance'}
        data={attendanceIndexData}
        setData={setAttendanceIndexData}
        mapRow={(dataRow ,editInvoker ,deleteInvoker) => <AttendanceRow key={Math.random()}
            attendance={dataRow}
            editInvoker={editInvoker}
        />}
    />,
    HistoryIndexComponent = <ListData
        entity='history'
        entityPlural={translate('Histories')}
        entitySingle='History'
        withoutAddBtn={true}
        headers={[
            {value: translate('Date')},
            {value: translate('Name')},
            {value: translate('IDNumber')},
            {value: translate('AttendnaceTime')},
            {value: translate('LeavingTime')}
        ]}
        entityUrl={`${Config.apiBaseUrl}/gym-dashboard-services/employees/attendance/getAttendanceHistory`}
        printUrl={`${Config.apiBaseUrl}/gym-dashboard-services/employees/attendance/getAttendanceHistory/print`}
        visible={showSection === 'history'}
        data={historyIndexData}
        setData={setHistoryIndexData}
        mapRow={(dataRow ,editInvoker ,deleteInvoker) => <HistoryRow key={Math.random()}
            history={dataRow}
        />}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={translate("Employees")}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'employees' ? 'active' : ''}
                    onClick={() => setShowSection('employees')}>
                    {translate("Employees")}
                </button>
                <button className={showSection === 'attendance' ? 'active' : ''}
                    onClick={() => setShowSection('attendance')}>
                    {translate("Attendance")}
                </button>
                <button className={showSection === 'history' ? 'active' : ''}
                    onClick={() => setShowSection('history')}>
                    {translate("History")}
                </button>
            </div>
            <div className="page-content">
                {EmployeesIndexComponent}
                {AttendanceIndexComponent}
                {HistoryIndexComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index