import { useAlert } from 'react-alert'
import { useContext, useEffect, useState } from 'react'
import axios from "../../../Axios"
import Config from '../../../Config'
import ModalHeader from '../../FormShared/ModalHeader'
import ModalButtons from '../../FormShared/ModalButtons'
import EmployeeInputs from './EmployeeInputs'
import PhotoInput from '../../FormShared/PhotoInput'
import AppContext from '../../partials/AppContext'
import '../../../css-files/form.css'
import Loading from '../../Shared/Loading'
import EmployeePositions from '../../../constants/EmployeePositions'
import Genders from '../../../constants/Genders'

const EditEmployee = ({id ,cancel ,loadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const positions = EmployeePositions()
    const genders = Genders()
    
    const [employee ,setEmployee] = useState({
        name: '', national_id: '', gender: '', age: '', phone: '', email: '', salary: '', target: '', commission: '', position: ''
    })
    const [errors ,setErrors] = useState({
        name: '', national_id: '', gender: '', photo: '', age: '', phone: '', email: '', salary: '', target: '', commission: '', position: ''
    })
    const [selectedGender ,setSelectedGender] = useState({name: translate('Gender') ,value: ''})
    const [selectedPosition ,setSelectedPosition] = useState({name: translate('Position') ,value: ''})
    const [defaultImg ,setDefaultImg] = useState('')
    const [photo ,setPhoto] = useState(undefined)
    const [disabled ,setDisabled] = useState(false)
    const [showSalesManInputs ,setShowSalesManInputs] = useState(false)
    const [loaded ,setLoaded] = useState(false)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(gymToken)
        .get(`${Config.apiBaseUrl}/gym-dashboard-services/employees/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const _employee = response?.data
            setEmployee({
                ...employee,
                name: _employee?.name !== null ? _employee?.name : '',
                national_id: _employee?.national_id !== null ? _employee?.national_id : '',
                email: _employee?.email !== null ? _employee?.email : '',
                age: _employee?.age !== null ? _employee?.age : '',
                salary: _employee?.salary !== null ? _employee?.salary : '',
                commission: _employee?.commission !== null ? _employee?.commission : '',
                target: _employee?.target !== null ? _employee?.target : '',
                phone: _employee?.phone !== null ? _employee?.phone : ''
            })
            if (_employee?.gender === 'male') setSelectedGender({name: translate('Male') ,value: 'male'})
            else if (_employee?.gender === 'female') setSelectedGender({name: translate('Female') ,value: 'female'})
            else setSelectedGender({name: translate('Gender') ,value: ''})

            if (_employee?.position === 'barman') setSelectedPosition({name: translate('Barman') ,value: 'barman'})
            else if (_employee?.position === 'salesman') {
                setSelectedPosition({name: translate('Salesman') ,value: 'salesman'})
                setShowSalesManInputs(true)
            } else if (_employee?.position === 'coach') setSelectedPosition({name: translate('Coach') ,value: 'coach'})
            else if (_employee?.position === 'other') setSelectedPosition({name: translate('Other') ,value: 'other'})
            else if (_employee?.position === 'host') setSelectedPosition({name: translate('Host') ,value: 'host'})
            else setSelectedPosition({name: translate('Position') ,value: ''})

            setDefaultImg(_employee?.avatar)
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setEmployee ,setDefaultImg ,setSelectedPosition ,setSelectedGender ,clearUserData ,alert])

    const updateEmployeeAttr = (value ,attr) => {
        if (attr === 'photo') setPhoto(value)
        else {
            const _employee = {...employee}
            _employee[attr] = value
            setEmployee(_employee)
        }
        const _errors = errors
        _errors[attr] = ''
        setErrors(_errors)
    }

    const updateEmployee = () => {
        setDisabled(true)
        const formData = new FormData()
        Object.keys(employee).forEach(attr => formData.append(attr ,employee[attr]))
        formData.set('gender' ,selectedGender.value)
        formData.set('position' ,selectedPosition.value)
        if (photo) formData.set('photo' ,photo)
        formData.set('_method' ,'PUT')

        axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/employees/${id}` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(`${translate("Employee")} : ${response?.data?.data?.name} ,${translate("updatedSuccessfully")}`)
            loadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changePosition = (position) => {
        setSelectedPosition(position)
        setShowSalesManInputs(position.value === 'salesman')
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditEmployee")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                            <>
                                <PhotoInput
                                    updatePhoto={(img) => updateEmployeeAttr(img ,'photo')}
                                    error={errors.photo}
                                    defaultImg={defaultImg}
                                />
                                <div className="section">
                                    <EmployeeInputs
                                        employee={employee}
                                        updateEmployeeAttr={updateEmployeeAttr}
                                        errors={errors}
                                        gender={selectedGender}
                                        setGender={setSelectedGender}
                                        genders={genders}
                                        position={selectedPosition}
                                        setPosition={changePosition}
                                        showSalesManInputs={showSalesManInputs}
                                        positions={positions}
                                    />
                                </div>
                                <ModalButtons disabled={disabled} cancel={cancel} confirm={updateEmployee} confirmLabel={translate("EditEmployee")} />
                            </>
                            : <Loading/>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditEmployee