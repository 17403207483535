import Ar_A_Words from './translations/ar/A_Words'
import Ar_BC_Words from './translations/ar/BC_Words'
import Ar_DE_Words from './translations/ar/DE_Words'
import Ar_FJ_Words from './translations/ar/FGHIJ_Words'
import Ar_KN_Words from './translations/ar/KLMN_Words'
import Ar_OQ_Words from './translations/ar/OPQ_Words'
import Ar_RS_Words from './translations/ar/RS_Words'
import Ar_TZ_Words from './translations/ar/TZ_Words'

import En_A_Words from './translations/en/A_Words'
import En_BC_Words from './translations/en/BC_Words'
import En_DE_Words from './translations/en/DE_Words'
import En_FJ_Words from './translations/en/FGHIJ_Words'
import En_KN_Words from './translations/en/KLMN_Words'
import En_OQ_Words from './translations/en/OPQ_Words'
import En_RS_Words from './translations/en/RS_Words'
import En_TZ_Words from './translations/en/TZ_Words'

const translateByKey = (key ,language) => {
    try {
        const keyFirstLetter = key[0].toLowerCase()
        let translationObject
        if (keyFirstLetter === 'a') {
            translationObject = language === 'ar' ? Ar_A_Words : En_A_Words
        } else if (keyFirstLetter === 'b' || keyFirstLetter === 'c') {
            translationObject = language === 'ar' ? Ar_BC_Words : En_BC_Words
        } else if (keyFirstLetter === 'd' || keyFirstLetter === 'e') {
            translationObject = language === 'ar' ? Ar_DE_Words : En_DE_Words
        } else if (keyFirstLetter === 'f' || keyFirstLetter === 'h' || keyFirstLetter === 'g' || keyFirstLetter === 'i' || keyFirstLetter === 'j') {
            translationObject = language === 'ar' ? Ar_FJ_Words : En_FJ_Words
        } else if (keyFirstLetter === 'k' || keyFirstLetter === 'l' || keyFirstLetter === 'm' || keyFirstLetter === 'n') {
            translationObject = language === 'ar' ? Ar_KN_Words : En_KN_Words
        } else if (keyFirstLetter === 'o' || keyFirstLetter === 'p' || keyFirstLetter === 'q') {
            translationObject = language === 'ar' ? Ar_OQ_Words : En_OQ_Words
        } else if (keyFirstLetter === 'r' || keyFirstLetter === 's') {
            translationObject = language === 'ar' ? Ar_RS_Words : En_RS_Words
        } else {
            translationObject = language === 'ar' ? Ar_TZ_Words : En_TZ_Words
        }
        const translatedKey = key[0].toUpperCase() + key.slice(1)
        return translationObject[translatedKey] ?? key
    } catch (err) {
        return key
    }
}

export default translateByKey