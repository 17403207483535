import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import DangerousAlert from "../../../Shared/DangerousAlert"

const Cancel = ({cancel ,reloadData ,bookRow}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/booking`
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .put(`${entityUrl}/cancel/${bookRow?.id}`)
        .then(response => {
            setDisabled(false)
            const res = response?.data?.data
            alert.success(`${res?.name} ${translate("BookingAt")} ${res?.date} ${res?.time} ,${translate("canceledSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading=""
                message={
                    `${translate("surePermanentlyCancelBooking")}
                    "${bookRow?.name} ${translate("at")} ${bookRow?.date} ${bookRow?.time}"?
                    ${translate("cantUndoAction")}`
                }
                cancel={cancel}
                confirm={saveChanges}
                confirmLabel={translate("YesCancel")}
                cancelLabel={translate("NoClose")}
            />
        </div>
    )
}

export default Cancel