import { Line } from 'react-chartjs-2'

const options = {
    scales: {
        y: {
            beginAtZero: true
        }
    }
}

const ChartComp = ({label = "Label" ,data ,labels ,color = 'rgb(255, 99, 132)'}) => {
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: label,
                data: data,
                fill: false,
                backgroundColor: color,
                borderColor: color,
                tension: 0.3
            }
        ]
    }
    return (
        <Line data={chartData} options={options} />
    )
}

export default ChartComp