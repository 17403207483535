import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import DropDown from "../../../../FormShared/DropDown"
import FieldError from "../../../../FormShared/FieldError"
import ModalButtons from "../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../FormShared/ModalHeader"
import AppContext from "../../../../partials/AppContext"

const ChangeSelectAttribute = ({id ,cancel ,reloadData ,attribute ,startedOption ,selects ,placeholder}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/employees/update/${id}`

    const [disabled ,setDisabled] = useState(false)
    const [selectedOption ,setSelectedOption] = useState({name: startedOption?.name, value: startedOption?.value})
    const [error ,setError] = useState("")

    const saveChanges = () => {
        setDisabled(true)
        const data = {}
        data[attribute] = selectedOption?.value

        Axios
        .setAuthToken(gymToken)
        .put(entityUrl ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation.photo) {
                setError(validation.photo ?? '')
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={placeholder} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <DropDown
                                selectedName={selectedOption.name}
                                textColorBlack={selectedOption.value !== ''}
                                list={selects}
                                itemName="name"
                                select={option => setSelectedOption({name: option?.name ,value: option?.value})}
                            />
                            {error ? <FieldError message={error} /> : ''}
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            cancelLabel={translate("Cancel")}
                            disabled={disabled}
                            confirm={saveChanges}
                            confirmLabel={placeholder}
                        />
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangeSelectAttribute