import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import DropDown from "../../../FormShared/DropDown"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import EmployeePositions from "../../../../constants/EmployeePositions"
import Delete from "./Delete"
import Row from "./Row"
import Add from "./Add"
import Edit from "./Edit"

const Index = ({data ,setData ,visible}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/safe/salaries`
    const [deleteSalary ,setDeleteSalary] = useState({id: undefined ,name: ''})
    const [addSalary ,setAddSalary] = useState(false)
    const [editSalary ,setEditSalary] = useState(false)
    const salaries = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword
    const [selectedMonth ,setSelectedMonth] = useState(translate('All'))
    const [selectedYear ,setSelectedYear] = useState(translate('All'))
    const [selectedJob ,setSelectedJob] = useState(translate('All'))
    const [month ,setMonth] = useState('')
    const [year ,setYear] = useState('')
    const [job ,setJob] = useState('')
    const jobs = EmployeePositions()

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const getQuery = () => {
        let query = `keyword=${keyword}`
        query += `&limit=${dataPerPage}`
        query += `&page=${currentPage}`
        query += `&month=${month}`
        query += `&year=${year}`
        query += `&job=${job}`

        return query
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?${getQuery()}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate("Salaries")}`,
                list: responseData,
                meta: meta
            })
            executeScroll()

        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[alert ,gymToken ,clearUserData ,entityUrl ,dataPerPage ,currentPage ,month ,year ,job])

    const printClick = () => {
        return Config.printByToken(`${entityUrl}/print?${getQuery()}` ,true ,gymToken)
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const changeMonth = (month) => {
        setMonth(month.value)
        setSelectedMonth(month.name)
    }

    const changeYear = (year) => {
        setYear(year.value)
        setSelectedYear(year.name)
    }

    const changeJob = (job) => {
        setJob(job.value)
        setSelectedJob(job.name)
    }

    const cancelModal = () => {
        setDeleteSalary({id: undefined ,name: ''})
        setAddSalary(false)
        setEditSalary(false)
    }

    const reloadForDelete = () => {
        if (salaries.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <DropDown
                        placeholder={translate('Month')}
                        selectedName={selectedMonth}
                        textColorBlack={selectedMonth !== translate('All')}
                        list={Config.monthsNameValue.map(m => {return {...m ,name: translate(m.name)}})}
                        itemName="name"
                        select={changeMonth} />
                    <DropDown
                        placeholder={translate('Year')}
                        selectedName={selectedYear}
                        textColorBlack={selectedYear !== translate('All')}
                        list={Config.yearsNameValue().map(y => {return {...y ,name: translate(y.name)}})}
                        itemName="name"
                        select={changeYear} />
                    <DropDown
                        placeholder={translate('Job')}
                        selectedName={selectedJob}
                        textColorBlack={selectedJob !== translate('All')}
                        list={jobs}
                        itemName="name"
                        select={changeJob} />
                    <button className="btn btn-light" onClick={() => {
                        cancelModal()
                        setAddSalary(true)
                    }}> {translate("AddSalary")} </button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Employee')},
                            {value: translate('Job')},
                            {value: translate('Date')},
                            {value: translate('Salary')},
                            {value: translate('Extra')},
                            {value: translate('Overtime')},
                            {value: translate('Deduction')},
                            {value: translate('Total')},
                            {value: translate('Actions')}
                        ]}/>
                        <tbody>
                            {
                                salaries?.map((row ,index) =>
                                    <Row key={index} row={row} deleteSalary={() => {
                                        cancelModal()
                                        setDeleteSalary({
                                            id: row?.id,
                                            name: translate('Salary') + ': '+ row?.salary + ', ' + translate('Employee') + ': ' + row?.employee
                                        })
                                    }}
                                    editSalary={() => {
                                        cancelModal()
                                        setEditSalary(row?.id)
                                    }}/>
                                )
                            }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                </div>
                {
                    deleteSalary?.id ?
                    <Delete
                        entityUrl={`${entityUrl}/${deleteSalary?.id}`}
                        cancel={cancelModal}
                        loadData={reloadForDelete}
                        name={deleteSalary?.name}
                    />
                    : ''
                }
                {
                    addSalary ?
                    <Add entityUrl={entityUrl} loadData={reloadForAdd} cancel={cancelModal}/>
                    : ''
                }
                {
                    editSalary ?
                    <Edit entityUrl={entityUrl} loadData={loadData} cancel={cancelModal} id={editSalary}/>
                    : ''
                }
            </div>
        </div>
    )
}

export default Index