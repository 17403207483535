import { useState } from 'react'
import { useEffect } from 'react'
import { useContext, useRef } from 'react'
import AppContext from '../../partials/AppContext'
import './SearchBox.css'

const SearchBox = ({
    searchTrigger ,
    setKeyword ,
    extraParentClass = '' ,
    extraClass = '' ,
    keyword = '' ,
    placeholder = undefined
}) => {
    const {translate ,systemLang} = useContext(AppContext)
    const triggerSearchRef = useRef()
    const [inputStyle ,setInputStyle] = useState({
        padding: '15px !important',
        width: '100%',
        maxWidth: '100%',
        minWidth: '100%',
        paddingLeft: '30px !important',
        paddingRight: '85px !important'
    })

    const changeKeyword = e => {
        if (e.key === 'Enter') searchTrigger()
    }

    const clearSearch = async () => {
        await setKeyword('')
        triggerSearchRef.current.click()
    }

    
    useEffect(() => {
        if (systemLang === 'ar') {
            setInputStyle({...inputStyle ,paddingLeft: '85px !important' ,paddingRight: '30px !important'})
        }
    } ,[systemLang ,setInputStyle])

    return (
        <div className={`search-box ${extraParentClass}`}>
            <input
                style={inputStyle}
                className={`rounded-box ${extraClass}`}
                type="text"
                placeholder={placeholder ? placeholder : translate('Search')}
                onChange={e => setKeyword(e.target.value)}
                onKeyDown={changeKeyword}
                value={keyword}/>
            <i ref={triggerSearchRef} onClick={searchTrigger} className="en i-button fa fa-search"></i>
            <span> | </span>
            <i onClick={clearSearch} className="en close-button fa fa-times"></i>
        </div>
    )
}

export default SearchBox