import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../Axios"
import Config from "../../../../../../Config"
import DateInput from "../../../../../FormShared/DateInput"
import FieldError from "../../../../../FormShared/FieldError"
import ModalButtons from "../../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../../FormShared/ModalHeader"
import AppContext from "../../../../../partials/AppContext"
import DangerousAlert from "../../../../../Shared/DangerousAlert"

const Freeze = ({realStatus ,memberId ,cancel ,reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/members/membership`
    const [disabled ,setDisabled] = useState(false)
    const [startDate ,setStartDate] = useState('')
    const [endDate ,setEndDate] = useState('')
    const [errors ,setErrors] = useState({start: '' ,end: ''})

    const switchFreeze = () => {
        const data = {}
        const url = `${entityUrl}/${realStatus !== 3 ? 'freezeMember' : 'unfreezeMember'}/${memberId}`
        if (realStatus !== 3) {
            data.start = startDate
            data.end = endDate
            if (!startDate || !endDate) {
                setErrors({start: !startDate ? translate('StartDateReq') : '' ,end: !endDate ? translate('EndDateReq') : ''})
                return
            }
        }
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .put(url ,data)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData?.()
            cancel?.()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validationError = error?.response?.data?.errors
            if (validationError) setErrors({...validationError})
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return realStatus !== 3 ? 
    <div id="forms">
        <div id="small-modal-form" className="form-container">
            <div className="contain">
                <ModalHeader title={translate("FreezeAccount")} cancel={cancel}/>
                <form onSubmit={e => e.preventDefault()}>
                    <div className="section mb-2">
                        <DateInput placeholder={translate("FreezeStart")} value={startDate} onChange={e => {
                            setErrors({...errors ,start: ''})
                            setStartDate(e.target.value)
                        }}/>
                        {errors.start ? <FieldError message={errors.end} /> : ''}
                        <DateInput placeholder={translate("FreezeEnd")} value={endDate} onChange={e => {
                            setErrors({...errors ,end: ''})
                            setEndDate(e.target.value)
                        }}/>
                        {errors.end ? <FieldError message={errors.end} /> : ''}
                    </div>
                    <div className="col-md-12">
                        <ModalButtons disabled={disabled} cancel={cancel} confirm={switchFreeze} confirmLabel={translate("Freeze")} />
                    </div>
                </form>
            </div>
        </div>
    </div>
    :
    <div id="forms">
        <DangerousAlert
            disabled={disabled}
            heading=""
            message={`${translate("SureToUnfreeze")}?`}
            cancel={cancel}
            confirm={switchFreeze}
            confirmLabel={translate("YesUnfreeze")}
        />
    </div>
}

export default Freeze