import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import Loading from "../../../Shared/Loading"
import Personal from "./Inputs/Personal"

const Edit = ({id ,cancel ,reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members`
    const [loaded ,setLoaded] = useState(false)
    const [btnDisabled ,setBtnDisabled] = useState(false)

    const [gender ,setGender] = useState({name: translate('Gender') ,value: ''})
    const [member ,setMember] = useState({
        name: '' ,national_id: '' ,gender: '' ,age: '' ,height: '' ,weight: '' ,phone_number: '' ,email: ''
    })
    const [photo ,setPhoto] = useState(undefined)
    const [defaultImg ,setDefaultImg] = useState(undefined)

    const [personalErrors ,setPersonalErrors] = useState({
        name: '' ,national_id: '' ,gender: '' ,age: '' ,height: '' ,weight: '' ,phone_number: '' ,email: ''
    })

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const data = response?.data
            setMember({
                name: data?.name,
                national_id: data?.national_id,
                gender: data?.gender,
                age: data?.age,
                height: data?.height,
                weight: data?.weight,
                phone_number: data?.phone_number,
                email: data?.email !== null ? data?.email : ''
            })
            setGender({name: data?.gender === 'male' ? translate('Male') : translate('Female') ,value: data?.gender === 'male' ? 'male' : 'female'})
            setDefaultImg(data?.avatar)
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setMember ,entityUrl ,id ,alert ,clearUserData ,gymToken ,setLoaded ,setDefaultImg])

    const editMember = () => {
        const formData = new FormData()
        Object.keys(member).forEach(key => formData.append(key ,member[key]))
        if (photo) formData.append('photo' ,photo)
        formData.append('_method' ,'PUT')
        setBtnDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/${id}` ,formData)
        .then(response => {
            setBtnDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            cancel()
            reloadData()
        })
        .catch(err => {
            setBtnDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) {
                const validation = error?.response?.data?.errors
                const tempErrors = {...personalErrors}
                Object.keys(validation).forEach(attr => {
                    try {
                        tempErrors[attr] = validation[attr].join(",")
                    } catch (excep) {
                        tempErrors[attr] = validation[attr]
                    }
                })
                setPersonalErrors(tempErrors)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("EditMember")} cancel={cancel}/>
                    {
                        loaded ?
                        <Personal
                            loadErrors={true}
                            cancelLabel={translate("Cancel")}
                            confirmLabel={translate("SaveChanges")}
                            clickNext={editMember}
                            cancel={cancel}
                            clickPrevious={cancel}
                            changeMember={(_member ,attr) => {
                                const temp = {
                                    ...member,
                                    name: _member.name,
                                    national_id: _member.national_id,
                                    gender: _member.gender,
                                    age: _member.age,
                                    height: _member.height,
                                    weight: _member.weight,
                                    phone_number: _member.phone_number,
                                    email: _member.email
                                }
                                setMember(temp)
                                const tempErrors = {...personalErrors}
                                tempErrors[attr] = ''
                                setPersonalErrors(tempErrors)
                            }}
                            member={{
                                name: member.name,
                                national_id: member.national_id,
                                gender: member.gender,
                                age: member.age,
                                height: member.height,
                                weight: member.weight,
                                phone_number: member.phone_number,
                                email: member.email
                            }}
                            photo={photo} setPhoto={setPhoto}
                            gender={gender} setGender={setGender}
                            errors={personalErrors}
                            defaultImg={defaultImg}
                            disabled={btnDisabled}
                        />                        
                        : <form onSubmit={e => e.preventDefault()}> <Loading/> </form>
                    }
                </div>
            </div>
        </div>
    )
}

export default Edit