import Input from "../../FormShared/Input"
import FieldError from "../../FormShared/FieldError"
import { useContext, useState } from "react"
import axios from "../../../Axios"
import { useAlert } from "react-alert"
import AppContext from "../../partials/AppContext"
import Config from "../../../Config"
import ModalButtons from "../../FormShared/ModalButtons"
import ModalHeader from "../../FormShared/ModalHeader"

const ChangeModal = ({cancel ,reloadData ,attr ,modalHeader ,textarea ,placeholder ,value}) => {
    const alert= useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const attribute = attr
    const [inputValue ,setValue] = useState(value)
    const [error ,setError] = useState('')
    const [pass ,setPass] = useState('')
    const [passError ,setPassError] = useState('')
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        const data = {password: pass ,_method: 'PUT'}
        data[attribute] = inputValue
        axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/branch-setting` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors?.[attribute]) setError(error?.response?.data?.errors?.[attribute])
            if (error?.response?.data?.errors?.password) setPassError(error?.response?.data?.errors?.password)
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader cancel={cancel} title={modalHeader}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            {
                                textarea ?
                                <textarea rows={4}
                                    className="rounded-box"
                                    placeholder={placeholder}
                                    value={inputValue}
                                    onChange={e => setValue(e.target.value)}></textarea>
                                :
                                <Input placeholder={placeholder} value={inputValue} onChange={e => setValue(e.target.value)}/>
                            }
                            {error ? <FieldError message={error} /> : ''}
                            <Input type="password" placeholder={translate("Password")} value={pass} onChange={e => setPass(e.target.value)}/>
                            {passError ? <FieldError message={passError} /> : ''}
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            disabled={disabled}
                            confirmLabel={translate("SaveChanges")}
                            confirm={saveChanges}/>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangeModal