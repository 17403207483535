import { useContext } from "react"
import AppContext from "../components/partials/AppContext"

const HistoryActions = () => {
    const {translate} = useContext(AppContext)

    return [
        {name: translate('All') ,value: ''},
        {name: translate('Add') ,value: 'add'},
        {name: translate('Edit') ,value: 'edit'},
        {name: translate('Delete') ,value: 'delete'}
    ]
}

export default HistoryActions