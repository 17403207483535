const words = {
    TotalEarnings: "Total Earnings",
    YesDelete: "Yes, Delete",
    UpdatedSuccessfully: "updated successfully",
    VIEW: "View",
    Username: "Username",
    UploadImgTitle: "Upload Image (Optional)",
    UploadImgMsg: "Photo will be here",
    WorkingHours: "working hours",
    Website: "Website",
    TrainDuration: "Training Duration",
    To: "To",
    Update: "Update",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Tue: "Tue",
    Wed: "Wed",
    Thu: "Thu",
    YesCheckout: "Yes, Check Out",
    Target: "Target",
    Time: "Time",
    Year: "Year",
    Type: "Type",
    UploadImages: "Upload Images",
    UploadImage: "Upload Image",
    Unit: "Unit",
    ValidNumber: "Must be a valid number",
    UpdatedDate: "Updated Date",
    YesCancel: "Yes, Cancel",
    Times: "Times",
    WeightKG: "Weight (KG)",
    Total: "Total",
    Years: "Years",
    Weight: "Weight",
    YesDeactivate: "Yes, Deactivate",
    YesActivate: "Yes Activate",
    YesUnfreeze: "Yes, Unfreeze",
    Upgrade: "Upgrade",
    VIPSessions: "VIP Sessions",
    TotalPrice: "Total Price",
    UnfreezeAccount: 'Unfreeze Account',
    YesPay: "Yes, Pay",
    Twitter: "Twitter",
    Title: "Title",
    UpdateOffer: "Update Offer",
    UpdateClass: "Update Class",
    YouMostRefund: "and you must refund",
    YouWillPayHim: "Please note you will pay to him",
    Week: "Week",
    YearOverview: "Overview Of Last Year",
    WeekOverview: "Overview Of Last Week",
    TotalRevenue: "Total Revenue",
    ThirtyDaysOverview: "Last 30 Days Overview",
    VisitsNumber: "Max Visits Number",
}

export default words