const GrayButton = ({onClick ,label ,color = '#E03F3F'}) => {
    return (
        <button
            style={{
                width: 250,
                height: 48,
                background: '#F8F8F8 0% 0% no-repeat padding-box',
                border: '1px solid #DDDDDD',
                borderRadius: 24,
                opacity: 1,
                font: 'normal normal normal 14px/16px Public Sans',
                letterSpacing: 0,
                color: color
            }}
            onClick={onClick}
        >
            {label}
        </button>
    )
}

export default GrayButton