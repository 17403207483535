import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import Axios from "../../../../Axios"
import AppContext from "../../../partials/AppContext"
import ChartComp from "../../../Shared/ChartComp"
import './Chart.css'
import BranchStatsCounter from "../../../Shared/BranchStatsCounter"
import StatCounter from "../../../Shared/StatCounter"

const Index = ({visible ,loadCounters = false}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate ,systemLang} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/safe`
    const [currentFilter ,setCurrentFilter] = useState('Week')
    const [data ,setData] = useState({})
    const [counters ,setCounters] = useState({})
    const [stats ,setStats] = useState({
        total_earnings: [], total_earnings_labels: [], total_earnings_counters: data?.week?.counters?.total_earnings,
        clients: [], clients_labels: [], clients_counters: data?.week?.counters?.clients,
        bar: [], bar_labels: [], bar_counters: data?.week?.counters?.bar
    })

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/earnings-stats` ,{cancelToken: axiosSource.token})
        .then(response => {
            setData(response?.data?.data)
            setCounters(response?.data?.data?.branches)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,entityUrl ,alert ,clearUserData])

    const refreshCounters = () => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/earnings-branches?filter=${currentFilter}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setCounters(response?.data?.data)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        switch(currentFilter) {
            case "Week":
                if (loadCounters) refreshCounters()
                setStats({
                    total_earnings: data?.week?.charts?.total_earnings,
                    total_earnings_labels: data?.week?.charts?.labels,
                    total_earnings_counters: data?.week?.counters?.total_earnings,
                    
                    clients: data?.week?.charts?.clients,
                    clients_labels: data?.week?.charts?.labels,
                    clients_counters: data?.week?.counters?.clients,
                    
                    bar: data?.week?.charts?.bar,
                    bar_labels: data?.week?.charts?.labels,
                    bar_counters: data?.week?.counters?.bar
                })
                break
            case "Month":
                if (loadCounters) refreshCounters()
                setStats({
                    total_earnings: data?.month?.charts?.total_earnings,
                    total_earnings_labels: data?.month?.charts?.labels,
                    total_earnings_counters: data?.month?.counters?.total_earnings,
                    
                    clients: data?.month?.charts?.clients,
                    clients_labels: data?.month?.charts?.labels,
                    clients_counters: data?.month?.counters?.clients,
                    
                    bar: data?.month?.charts?.bar,
                    bar_labels: data?.month?.charts?.labels,
                    bar_counters: data?.month?.counters?.bar
                })
                break
            case "Year":
                if (loadCounters) refreshCounters()
                setStats({
                    total_earnings: data?.year?.charts?.total_earnings,
                    total_earnings_labels: data?.year?.charts?.labels,
                    total_earnings_counters: data?.year?.counters?.total_earnings,
                    
                    clients: data?.year?.charts?.clients,
                    clients_labels: data?.year?.charts?.labels,
                    clients_counters: data?.year?.counters?.clients,
                    
                    bar: data?.year?.charts?.bar,
                    bar_labels: data?.year?.charts?.labels,
                    bar_counters: data?.year?.counters?.bar
                })
                break
            default:
                console.log('Unknow filter')
                break
        }
    } ,[data ,currentFilter ,loadCounters])

    const statCounters = [
        {
            style: {borderTopColor: data?.total_earnings_color ,width: '25%'} ,title: translate("TotalEarnings"),
            subTitle: translate(currentFilter + "Overview"),
            stat: Config.readableStats(stats?.total_earnings_counters ,systemLang)
        },
        {
            style: {borderTopColor: data?.clients_color ,width: '25%'} ,title: translate("ClientsEarnings"),
            subTitle: translate(currentFilter + "Overview"),
            stat: Config.readableStats(stats?.clients_counters ,systemLang)
        },
        {
            style: {borderTopColor: data?.bar_color ,width: '25%'} ,title: translate("BarEarnings"),
            subTitle: translate(currentFilter + "Overview"),
            stat: Config.readableStats(stats?.bar_counters ,systemLang)
        }
    ]

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            {translate("LastUpdateTime")} : {Config.getYesterday(systemLang)}
            <div className="page-section content">
                <div className="stats-counters jus-con-center">
                    {
                        statCounters.map((counter ,index) => 
                            <StatCounter key={index}
                                rootStyle={counter.style}
                                title={counter.title}
                                subTitle={counter.subTitle}
                                statValue={counter.stat}/>
                        )
                    }
                </div>
                <div className="expenses-stats-filters">
                    <button
                        onClick={() => setCurrentFilter("Week")}
                        className={currentFilter === 'Week' ? "active" : ''}>
                        {translate("Week")}
                    </button>
                    <button
                        onClick={() => setCurrentFilter("Month")}
                        className={currentFilter === 'Month' ? "active" : ''}>
                        {translate("Month")}
                    </button>
                    <button
                        onClick={() => setCurrentFilter("Year")}
                        className={currentFilter === 'Year' ? "active" : ''}>
                        {translate("Year")}
                    </button>
                </div>
                <div className="chart-parent mt-4">
                    <div className="chart-child full-width">
                        <h4> {translate("TotalEarnings")} </h4>
                        <ChartComp
                            label={translate("TotalEarnings")}
                            color={data?.total_earnings_color}
                            data={stats?.total_earnings}
                            labels={stats?.total_earnings_labels}/>
                    </div>
                </div>
                <div className="chart-parent">
                    <div className="chart-child">
                        <h4> {translate("ClientsEarnings")} </h4>
                        <ChartComp
                            label={translate("ClientsEarnings")}
                             color={data?.clients_color}
                             data={stats?.clients}
                             labels={stats?.clients_labels}/>
                    </div>
                    <div className="chart-child">
                        <h4> {translate("BarEarnings")} </h4>
                        <ChartComp
                            label={translate("BarEarnings")}
                             color={data?.bar_color}
                             data={stats?.bar}
                             labels={stats?.bar_labels}/>
                    </div>
                </div>
            </div>

            {
                loadCounters ?
                <div className="branch-counters-container">
                    {
                        counters?.map?.(branch => <BranchStatsCounter data={branch}/>)
                    }
                </div>
                : null
            }
        </div>
    )
}

export default Index