import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import AppContext from './AppContext'

const Aside = (props) => {
    const {toggleAsideMenu ,closeMenu ,translate} = useContext(AppContext)
    return (
        <div className="side-panel en" id="aside-menu">
            <button type="button" id="close-aside-menu" onClick={() => toggleAsideMenu()}>
                <i className="fas fa-times"></i>
            </button>
            <div className="logo">
                <img alt="logo" className="full-img" src="/assets/svg/logo.svg"/>
            </div>
            <section>
                <span className="title">{translate("Main")}</span>
                <NavLink onClick={closeMenu} className="section-link" to="/" exact activeClassName="active">
                    <i className="fab fa-cloudscale"></i>
                    <span>{translate("Dashboard")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/earnings" exact activeClassName="active">
                    <i className="fas fa-coins"></i>
                    <span>{translate("Earnings")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/expenses" exact activeClassName="active">
                    <i className="fas fa-dollar-sign"></i>
                    <span>{translate("Expenses")}</span>
                </NavLink>
            </section>
            <section>
                <span className="title">{translate("Gym")}</span>
                <NavLink onClick={closeMenu} className="section-link" to="/branches" exact activeClassName="active">
                    <i className="fas fa-building"></i>
                    <span>{translate("Branches")}</span>
                </NavLink>
            </section>
            <section>
                <span className="title">{translate("More")}</span>
                <NavLink onClick={closeMenu} className="section-link" to="/account-roles" exact activeClassName="active">
                    <i className="fas fa-eye"></i>
                    <span>{translate("AccountRoles")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/accounts" exact activeClassName="active">
                    <i className="fas fa-user-circle"></i>
                    <span>{translate("Accounts")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/settings" exact activeClassName="active">
                    <i className="fas fa-cog"></i>
                    <span>{translate("Settings")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/history" exact activeClassName="active">
                    <i className="fas fa-history"></i>
                    <span>{translate("History")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/help-center" exact activeClassName="active">
                    <i className="fas fa-question-circle"></i>
                    <span>{translate("HelpCenter")}</span>
                </NavLink>
            </section>
        </div>
    )
}

export default Aside