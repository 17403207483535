import { useContext, useEffect, useState } from "react"
import ChangeModal from "./ChangeModal"
import ChangePhoto from "./ChangePhoto"
import './gym-details.css'
import '../../../css-files/form.css'
import GeneralSetting from "./GeneralSetting"
import ChangeLanguage from "./ChangeLanguage"
import { useAlert } from "react-alert"
import AppContext from "../../partials/AppContext"
import Config from "../../../Config"
import axios from "../../../Axios"
import ChangeTypeModal from "./ChangeTypeModal"
import ChangeBarModal from "./ChangeBarModal"
import ChangeLockersModal from "./ChangeLockersModal"
import OpeningHours from "./OpeningHours"
import WorkingHours from "./WorkingHours"
import DeleteWorkHours from "./DeleteWorkHours"
import { useLocation } from "react-router"
import CameraModal from "./CameraModal"
import ChangeLocation from "./ChangeLocation"

const useQuery = () => new URLSearchParams(useLocation().search)

const IndexComponent = () => {
    const query = useQuery()
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/branch-setting`
    const [branch ,setBranch] = useState()
    const [changeModal ,setChangeModal] = useState(undefined)
    const [changePhoto ,setChangePhoto] = useState(undefined)
    const [changeCover ,setChangeCover] = useState(undefined)
    const [changeLocation ,setChangeLocation] = useState({longitude: '' ,latitude: ''})
    const [changeLanguage ,setChangeLanguage] = useState(undefined)
    const [typeModal ,setTypeModal] = useState(undefined)
    const [barModal ,setBarModal] = useState(undefined)
    const [lockerModal ,setLockerModal] = useState(undefined)
    const [cameraModal ,setCameraModal] = useState(undefined)
    const [showSection ,setShowSection] =
        useState(query && query.get('tab') === 'hours' ? 'opening' : 'general')

    const [addWorkHour ,setAddWorkHour] = useState(undefined)
    const [updateWorkHour ,setUpdateWorkHour] = useState(undefined)
    const [deleteWorkHour ,setDeleteWorkHour] = useState(undefined)
    const axiosSource = axios.CancelToken.source()

    const cancelAllModal = () => {
        setChangeModal(undefined)
        setChangePhoto(undefined)
        setChangeCover(undefined)
        setChangeLanguage(undefined)
        setTypeModal(undefined)
        setBarModal(undefined)
        setLockerModal(undefined)
        setAddWorkHour(undefined)
        setDeleteWorkHour(undefined)
        setUpdateWorkHour(undefined)
        setCameraModal(undefined)
        setChangeLocation({latitude: '' ,longitude: ''})
    }

    const loadSetting = () => {
        axios
        .setAuthToken(gymToken)
        .get(entityUrl ,{cancelToken: axiosSource.token})
        .then(response => setBranch(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadSetting()
        return () => axiosSource.cancel()
    },[setBranch ,alert ,clearUserData ,gymToken ,entityUrl])

    return (
        <>
            <div className="pages-navbar pad0">
                <button className={showSection === 'general' ? 'active' : ''} onClick={() => setShowSection('general')}>
                    {translate("General")}
                </button>
                <button className={showSection === 'opening' ? 'active' : ''} onClick={() => setShowSection('opening')}>
                    {translate("OpeningHours")}
                </button>
            </div>
            <GeneralSetting
                isVisible={showSection === 'general'}
                branch={branch}
                openModal={data => {
                    cancelAllModal()
                    setChangeModal(data)
                }}
                openLogoModal={() => {
                    cancelAllModal()
                    setChangePhoto(true)
                }}
                openCoverModal={() => {
                    cancelAllModal()
                    setChangeCover(true)
                }}
                changeLocation={(lat ,long) => {
                    cancelAllModal()
                    setChangeLocation({longitude: long ,latitude: lat})
                }}
                openLanguageModal={lang => {
                    cancelAllModal()
                    setChangeLanguage(lang)
                }}
                openTypeModal={types => {
                    cancelAllModal()
                    setTypeModal(types)
                }}
                openBarModal={bars => {
                    cancelAllModal()
                    setBarModal(bars)
                }}
                openLockerModal={(lockerFor ,start ,lockers) => {
                    cancelAllModal()
                    setLockerModal({attr: lockerFor ,start ,lockers})
                }}
                openCameraModal={(ip ,id) => {
                    cancelAllModal()
                    setCameraModal({ip ,id})
                }}
            />
            <OpeningHours
                isVisible={showSection === 'opening'}
                branch={branch}
                openModal={data => {
                    cancelAllModal()
                    setChangeModal(data)
                }}
                addWorkingHours={workHourType => {
                    cancelAllModal()
                    setAddWorkHour(workHourType)
                }}
                updateWorkingHours={workHour => {
                    cancelAllModal()
                    setUpdateWorkHour(workHour)
                }}
                deleteWorkingHours={id => {
                    cancelAllModal()
                    setDeleteWorkHour(id)
                }}
            />
            {
                changeModal ?
                <ChangeModal
                    attr={changeModal?.attr}
                    modalHeader={changeModal?.title}
                    value={changeModal?.value}
                    placeholder={changeModal?.placeholder}
                    textarea={changeModal?.textarea}
                    cancel={cancelAllModal}
                    reloadData={loadSetting}/>
                : ''
            }
            {
                changePhoto ?
                <ChangePhoto
                    cancel={cancelAllModal}
                    reloadData={loadSetting}/>
                : ''
            }
            {
                changeCover ?
                <ChangePhoto
                    cover={true}
                    cancel={cancelAllModal}
                    reloadData={loadSetting}/>
                : ''
            }
            {
                changeLocation?.longitude || changeLocation?.latitude ?
                <ChangeLocation
                    long={changeLocation.longitude}
                    lat={changeLocation.latitude}
                    cancel={cancelAllModal}
                    reloadData={loadSetting}/>
                : ''
            }
            {
                changeLanguage ?
                <ChangeLanguage
                    value={changeLanguage}
                    cancel={cancelAllModal}
                    reloadData={loadSetting}/>
                : ''
            }
            {
                typeModal ?
                <ChangeTypeModal
                    types={typeModal}
                    cancel={cancelAllModal}
                    reloadData={loadSetting}/>
                : ''
            }
            {
                barModal ?
                <ChangeBarModal
                    bars={barModal}
                    cancel={cancelAllModal}
                    reloadData={loadSetting}/>
                : ''
            }
            {
                lockerModal ?
                <ChangeLockersModal
                    attr={lockerModal?.attr}
                    start={lockerModal?.start}
                    lockers={lockerModal?.lockers}
                    modalHeader={lockerModal?.attr === 'male' ? translate('ChangeMaleLockers') : translate('ChangeFemaleLockers')}
                    cancel={cancelAllModal}
                    reloadData={loadSetting}/>
                : ''
            }
            {
                addWorkHour ?
                <WorkingHours
                    reloadData={loadSetting}
                    cancel={cancelAllModal}
                    modalHeader={translate(`${addWorkHour}WorkingHours`)}
                    defaultData={{type: addWorkHour}}
                />
                : ''
            }
            {
                updateWorkHour ?
                <WorkingHours
                    reloadData={loadSetting}
                    cancel={cancelAllModal}
                    modalHeader={translate(`${updateWorkHour?.type}WorkingHours`)}
                    defaultData={updateWorkHour}
                />
                : ''
            }
            {
                deleteWorkHour ?
                <DeleteWorkHours
                    cancel={cancelAllModal}
                    id={deleteWorkHour}
                    reloadData={loadSetting}
                />
                : ''
            }
            {
                cameraModal ? <CameraModal id={cameraModal?.id} ip={cameraModal?.ip} cancel={cancelAllModal} reloadData={loadSetting}/> : ''
            }
        </>
    )
}

export default IndexComponent