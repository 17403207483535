const AttendanceRow = ({attendance ,editInvoker}) => {
    return (
        <tr key={Math.random()}>
            <td width='50%'> { attendance?.name } </td>
            <td width='20%'> { attendance?.id_number } </td>
            <td width='15%'> { attendance?.in } </td>
            <td width='15%' className="actions">
                <button onClick={() => editInvoker(attendance?.id)}>
                    <i className="fas fa-sign-out-alt"></i>
                </button>
            </td>
        </tr>
    )
}

export default AttendanceRow