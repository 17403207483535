const Row = ({row}) => {
    const styles = {}
    if (row?.status !== 'Paid')
        styles.color = '#E03F3F'
    return (
        <tr>
            <td> {row?.date_time} </td>
            <td> {row?.id} </td>
            <td> {row?.price} </td>
            <td style={styles}> {row?.status} </td>
        </tr>
    )
}

export default Row