import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import Axios from "../../../../Axios"
import AppContext from "../../../partials/AppContext"
import ChartComp from "../../../Shared/ChartComp"
import Loading from '../../../Shared/Loading'
import './Chart.css'
import BranchStatsCounter from "../../../Shared/BranchStatsCounter"
import TowVectorChart from "../../../Shared/TowVectorChart"
import StatCounter from "../../../Shared/StatCounter"
import BannerAdSlider from "../BannerAdSlider"

const Index = ({entityUrl ,lastCounterKey}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate ,systemLang} = useContext(AppContext)
    const [currentFilter ,setCurrentFilter] = useState('Week')
    const [data ,setData] = useState({})
    const [loading ,setLoading] = useState(true)
    const [statsCounters ,setStatsCounters] = useState([])
    const [showBannerAds ,setShowBannerAds] = useState(true)
    const [bannerAds ,setBannerAds] = useState([])
    
    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        setLoading(true)
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?filter=${currentFilter}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setLoading(false)
            setData(response?.data)
            setStatsCounters([
                {
                    style: {borderTopColor: response?.data?.all_members_color},
                    stat: Config.readableStats(response?.data?.all_members ,systemLang),
                    title: translate("AllMembers"), subTitle: translate(currentFilter + "Overview")
                },
                {
                    style: {borderTopColor: response?.data?.new_members_color},
                    stat: Config.readableStats(response?.data?.new_members ,systemLang),
                    title: translate("NewMembers"), subTitle: translate(currentFilter + "Overview")
                },
                {
                    style: {borderTopColor: response?.data?.total_revenue_color},
                    stat: Config.readableStats(response?.data?.total_revenue ,systemLang),
                    title: translate("TotalRevenue"), subTitle: translate(currentFilter + "Overview")
                },
                {
                    style: {borderTopColor: response?.data?.net_revenue_color},
                    stat: Config.readableStats(response?.data?.net_revenue ,systemLang),
                    title: translate("NetRevenue"), subTitle: translate(currentFilter + "Overview")
                }
            ])
            if (response?.data?.banner_ads) setBannerAds(response?.data?.banner_ads)
        })
        .catch(err => {
            setLoading(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,entityUrl ,alert ,clearUserData ,currentFilter ,setStatsCounters])

    return (
        <div>
            {
                loading ?
                <Loading/>
                : 
                <>
                    <div className="page-section content">
                        <div className="stats-counters">
                            {
                                statsCounters?.map?.((counter ,index) => <StatCounter key={index}
                                    rootStyle={counter.style}
                                    title={counter.title}
                                    subTitle={counter.subTitle}
                                    statValue={counter.stat}/>
                                )
                            }
                        </div>
                        <div className="expenses-stats-filters">
                            <button
                                onClick={() => setCurrentFilter("Week")}
                                className={currentFilter === 'Week' ? "active" : ''}>
                                {translate("Week")}
                            </button>
                            <button
                                onClick={() => setCurrentFilter("Month")}
                                className={currentFilter === 'Month' ? "active" : ''}>
                                {translate("Month")}
                            </button>
                            <button
                                onClick={() => setCurrentFilter("Year")}
                                className={currentFilter === 'Year' ? "active" : ''}>
                                {translate("Year")}
                            </button>
                        </div>
                        <div className="chart-parent mt-4">
                            <div className="chart-child full-width">
                                <h4> {translate("RevenueStatistics")} </h4>
                                <TowVectorChart
                                    labelOne={translate("Earnings")}
                                    labelTow={translate("Expenses")}
                                    dataOne={data?.charts?.earnings}
                                    dataTow={data?.charts?.expenses}
                                    colorOne="rgba(109, 190, 67, 1)"
                                    labels={data?.charts?.labels}
                                />
                            </div>
                        </div>
                        <div className="chart-parent">
                            <div className="chart-child">
                                <h4> {translate("ClientsStatistics")} </h4>
                                <ChartComp
                                    label={translate("ClientsStatistics")}
                                    color="rgba(247, 180, 103, 1)"
                                    data={data?.charts?.clients}
                                    labels={data?.charts?.labels}/>
                            </div>
                            <div className="chart-child">
                                <h4> {translate("BarStatistics")} </h4>
                                <ChartComp
                                    label={translate("BarStatistics")}
                                    color="rgba(146, 173, 164, 1)"
                                    data={data?.charts?.bar}
                                    labels={data?.charts?.labels}/>
                            </div>
                        </div>
                    </div>
                    <div className="branch-counters-container">
                        {
                            data[lastCounterKey]?.map?.(branch => <BranchStatsCounter data={branch}/>)
                        }
                    </div>
                    {
                        showBannerAds && bannerAds?.length > 0 ?
                        <BannerAdSlider cancel={() => setShowBannerAds(false)} bannerAds={bannerAds}/>
                        : null
                    }
                </>
            }
        </div>
    )
}

export default Index