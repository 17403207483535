import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../Axios"
import Config from "../../../Config"
import FieldError from "../../FormShared/FieldError"
import Input from "../../FormShared/Input"
import ModalButtons from "../../FormShared/ModalButtons"
import ModalHeader from "../../FormShared/ModalHeader"
import AppContext from "../../partials/AppContext"

const ChangeLanguage = ({value ,reloadData ,cancel}) => {
    const alert= useAlert()
    const {clearUserData ,gymToken ,translate ,changeLanguage} = useContext(AppContext)
    const [lang ,setLang] = useState(value)
    const [error ,setError] = useState('')
    const [pass ,setPass] = useState('')
    const [passError ,setPassError] = useState('')
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        const data = {password: pass ,_method: 'PUT' ,language: lang}
        Axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/branch-setting` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
            changeLanguage(lang === 'Arabic' ? 'ar' : 'en')
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors?.language) setError(error?.response?.data?.errors?.language)
            if (error?.response?.data?.errors?.password) setPassError(error?.response?.data?.errors?.password)
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }
    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader cancel={cancel} title={translate("ChangeLanguage")}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <label className="radio">
                                <div className="radio-btn">
                                    <input type="radio" checked={lang === 'English'} onChange={e => setLang('English')} />
                                    <span className="radio-check-box"></span>
                                </div>
                                <h6 className="radio-btn-text">{translate("English")}</h6>
                            </label>
                            <label className="radio">
                                <div className="radio-btn">
                                    <input type="radio" checked={lang === 'Arabic'} onChange={e => setLang('Arabic')} />
                                    <span className="radio-check-box"></span>
                                </div>
                                <h6 className="radio-btn-text">{translate("Arabic")}</h6>
                            </label>
                            {error ? <FieldError message={error} /> : ''}
                            <Input type="password" placeholder={translate("Password")} value={pass} onChange={e => setPass(e.target.value)}/>
                            {passError ? <FieldError message={passError} /> : ''}
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            disabled={disabled}
                            confirmLabel={translate("SaveChanges")}
                            confirm={saveChanges}/>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangeLanguage