import { useContext } from "react"
import AppContext from "../components/partials/AppContext"

const SafeTypes = (minified = false) => {
    const {translate} = useContext(AppContext)

    if (minified) {
        return [
            {name: translate('All') ,value: ''},
            {name: translate('Owner') ,value: 'owner'},
            {name: translate('Other') ,value: 'other'}
        ]
    }
    return [
        {name: translate('All') ,value: ''},
        {name: translate('Order') ,value: 'order'},
        {name: translate('Membership') ,value: 'member'},
        {name: translate('Expenses') ,value: 'expenses'},
        {name: translate('Salaries') ,value: 'salaries'},
        {name: translate('Equipment') ,value: 'equipment'},
        {name: translate('Stock') ,value: 'stock'},
        {name: translate('Owner') ,value: 'owner'},
        {name: translate('Other') ,value: 'other'}
    ]
}

export default SafeTypes