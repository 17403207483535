import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import DangerousAlert from "../../../Shared/DangerousAlert"

const Delete = ({data ,cancel ,reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/guest/destroy`
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .delete(`${entityUrl}/${data?.id}`)
        .then(response => {
            setDisabled(false)
            const res = response?.data?.data
            alert.success(`${res?.name} ${translate("deletedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }
    
    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading={translate("DeleteGuest")}
                message={
                    `${translate("surePermanentlyDeleteGuest")} 
                    "${data?.name}"?
                    ${translate("cantUndoAction")}`
                }
                cancel={cancel}
                confirm={saveChanges}
                confirmLabel={translate("Delete")}
            />
        </div>
    )
}

export default Delete