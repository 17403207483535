import { useContext } from "react"
import AppContext from "../../partials/AppContext"

const Row = ({branch ,manageBranch ,branchSetting ,editAction ,deleteAction ,openBranchBoard}) => {
    const {systemLang} = useContext(AppContext)
    return (
        <tr>
            <td>
                <button onClick={openBranchBoard}>{systemLang === 'ar' ? branch?.name_ar : branch?.name}</button>
            </td>
            <td>{branch?.created}</td>
            <td>{branch?.province}</td>
            <td>{branch?.city}</td>
            <td>{branch?.employees_number}</td>
            <td className="actions">
                <button onClick={manageBranch}>
                    <i className="fas fa-info-circle"></i>
                </button>
                <button onClick={branchSetting}>
                    <i className="fas fa-cog"></i>
                </button>
                <button onClick={editAction}>
                    <i className="fas fa-pen"></i>
                </button>
                <button onClick={deleteAction}>
                    <i className="fas fa-trash-alt"></i>
                </button>
            </td>
        </tr>
    )
}

export default Row