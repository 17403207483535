import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import Loading from "../../../Shared/Loading"

const styles = {
    rowStyle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        paddingBottom: 10,
        borderBottom: '#E8E8E8 1px solid'
    },
    childStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
    },
    nameStyle: {
        fontSize: 13
    },
    qntStyle: {
        color: 'gray',
        fontSize: 15
    },
    priceStyle: {
        font: 'normal normal bold 14px/16px Public Sans',
        letterSpacing: 0,
        color: '#E03F3F',
        opacity: 1
    },
    costStyle: {
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        alignItems: 'center',
        alignContent: 'center',
        background: '#F8F8F8 0% 0% no-repeat padding-box',
        border: '1px solid #191919',
        borderRadius: 30,
        opacity: 1
    }
}

const Index = ({children ,id ,cancel}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/orders/${id}`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)

    const [details ,setDetails] = useState({cost: '' ,items: []})
    const [loaded ,setLoaded] = useState(false)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(entityUrl ,{cancelToken: axiosSource.token})
        .then(response => {
            setDetails(response?.data?.data)
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[entityUrl ,clearUserData ,gymToken ,setDetails ,alert])

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={`#${id} ${translate("OrderDetails")}`} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                            <div className="section mb-2">
                                {
                                    details && details.items && Array.isArray(details.items) ?
                                    details.items.map(item => <div key={Math.random()} style={styles.rowStyle}>
                                        <div style={styles.childStyle}>
                                            <span style={styles.nameStyle}> { item?.name } </span>
                                            <span style={styles.qntStyle}> X{ item?.quantity } </span>
                                        </div>
                                        <span style={styles.priceStyle}>
                                            {item?.price}
                                        </span>
                                    </div>)
                                    : ''
                                }
                                <div style={styles.costStyle}>
                                    {translate("Price")} : { details?.cost }
                                </div>
                            </div>
                            : <Loading/>
                        }
                        {children}
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default Index