import { useContext, useEffect } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import AppContext from "../../../../partials/AppContext"
import Pagination from "../../../../Shared/Pagination"

const History = ({visible ,memberId ,data ,setData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members`
    const _data = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/history/${memberId}?limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const res = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                list: res,
                meta: meta
            })
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    } ,[gymToken ,entityUrl ,memberId ,alert ,clearUserData ,setData ,currentPage ,dataPerPage])

    const printClick = () => Config.printByToken(`${entityUrl}/history/print/${memberId}` ,false ,gymToken)

    return (
        <div className={`page-content display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section content small-page-section member-details-page">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title"> {translate("History")} </span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-undo"></i>
                            </button>
                            <button type="button" onClick={printClick}>
                                <i className="fas fa-print"></i>
                            </button>
                        </div>
                    </div>
                    { _data?.map((row ,index) =>
                        <div key={index}
                            className="gray-bottom-border flex-row jus-con-space-btwn center-align-items center-align-cont p-3"
                        >
                            <span className="smoth-black-color"> {row?.message} </span>
                            <span> {row?.date_time} </span>
                        </div>
                    ) }
                    {
                        <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                            changeCurrentPage={(page) => setData({...data, currentPage: page})}
                            changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default History