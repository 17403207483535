import { useContext, useEffect } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import PrintButton from "../../../Shared/PrintButton"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import ChartComp from "../../../Shared/ChartComp"
import './Chart.css'

const Index = ({visible ,data ,setData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/statistics/orders`

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(entityUrl ,{cancelToken: axiosSource.token})
        .then(response => setData(response?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,entityUrl ,alert ,clearUserData])

    const printClick = () => Config.printByToken(`${entityUrl}/print` ,false ,gymToken)

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section content">
                <div className="chart-parent mt-4">
                    {
                        data?.charts?.orders?.loaded ?
                        <div className="chart-child">
                            <h4> {translate("OrderRevenue")} </h4>
                            <ChartComp
                                label={translate("EarningPerCategory")}
                                color="#6DBE43"
                                data={data?.charts?.orders?.data}
                                labels={data?.charts?.orders?.days}/>
                        </div>
                        : null
                    }
                    {
                        data?.charts?.drinks?.loaded ?
                        <div className="chart-child">
                            <h4> {translate("DrinkRevenue")} </h4>
                            <ChartComp
                                label={translate("EarningPerCategory")}
                                color="#E03F3F"
                                data={data?.charts?.drinks?.data}
                                labels={data?.charts?.drinks?.days}/>
                        </div>
                        : null
                    }
                </div>
                <div className="chart-parent">
                    {
                        data?.charts?.foods?.loaded ?
                        <div className="chart-child">
                            <h4> {translate("FoodRevenue")} </h4>
                            <ChartComp
                                label={translate("EarningPerCategory")}
                                color="#36AACC"
                                data={data?.charts?.foods?.data}
                                labels={data?.charts?.foods?.days}/>
                        </div>
                        : null
                    }
                    {
                        data?.charts?.supplements?.loaded ?
                        <div className="chart-child">
                            <h4> {translate("SupplementRevenue")} </h4>
                            <ChartComp
                                label={translate("EarningPerCategory")}
                                color="#F26F10"
                                data={data?.charts?.supplements?.data}
                                labels={data?.charts?.supplements?.days}/>
                        </div>
                        : null
                    }
                </div>
                <div className="table-section mt-2">
                    <div className="table-header">
                        <span className="table-header-title">{translate("TopOrders")}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Item') ,width: '55%'},
                            {value: translate('Category') ,width: '15%'},
                            {value: translate('QNT') ,width: '15%'},
                            {value: translate('Earnings') ,width: '15%'}
                        ]}/>
                        <tbody>
                            {
                                data.top_products?.map?.(
                                    (row ,index) => <tr key={index}>
                                        <td width="55%"> {row?.name} </td>
                                        <td width="15%"> {row?.type} </td>
                                        <td width="15%"> {row?.used} </td>
                                        <td width="15%"> {row?.selling_price} </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default Index