import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import DropDown from "../../../FormShared/DropDown"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import Add from "./Add"
import AttendExtra from "./AttendExtra"
import Checkout from "./Checkout"
import Row from "./Row"
import "../../../../css-files/counters.css"
import BookinTypes from "../../../../constants/BookinTypes"

const Index = ({data ,setData ,visible}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/attendance`
    const dataList = data?.list,
        dataPerPage =data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const [addClick ,setAddClick] = useState(undefined)
    const [checkout ,setCheckout] = useState(undefined)
    const [attendExtra ,setAttendExtra] = useState(undefined)
    const [bookType ,setBookType] = useState({name: translate('All') ,value: ''})
    const bookTypes = BookinTypes()
    const [counters ,setCounters] = useState({allowed: 0 ,current: 0 ,trainDuration: 0})

    const cancel = () => {
        setAddClick(undefined)
        setCheckout(undefined)
        setAttendExtra(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        let query = `keyword=${keyword}`
        query += `&limit=${dataPerPage}`
        query += `&page=${currentPage}`
        query += `&type=${bookType?.value}`
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/all?${query}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate("Attendance")}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
            setCounters({
                allowed: response?.data?.max_number,
                current: response?.data?.current_attendance,
                trainDuration: `${response?.data?.training_duration} ${translate("minutes")}`
            })
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[alert ,gymToken ,clearUserData ,entityUrl ,dataPerPage ,currentPage ,bookType])

    const printClick = () => {
        let query = `keyword=${keyword}`
        query += `&page=${currentPage}`
        query += `&type=${bookType?.value}`
        return Config.printByToken(`${entityUrl}/print?${query}` ,true ,gymToken)
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    const reloadForCheckout = () => {
        if (dataList.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`} ref={elRef}>
            <div className="counters">
                <div className="counter-child red-top-border">
                    <span> {translate("AllowedMemInGym")} </span>
                    <span> { counters?.allowed } </span>
                    <i className="fas fa-users color-red"></i>
                </div>
                <div className="counter-child green-top-border rel-position">
                    <span> {translate("CurrMemInGym")} </span>
                    <span className={counters?.current >= counters.allowed ? 'color-red' : ''}>
                        { counters?.current }
                        {
                            counters?.current > counters.allowed ?
                                <span className='red-alert'>
                                    {translate("MaxMemInGymReached")}
                                </span>
                            : ''
                        }
                    </span>
                    <i className="fas fa-user-check color-green"></i>
                </div>
                <div className="counter-child orange-top-border">
                    <span> {translate("TrainDuration")} </span>
                    <span> { counters?.trainDuration } </span>
                    <i className="fas fa-clock color-orange"></i>
                </div>
            </div>
            <div className="page-section header-box">
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <DropDown
                        placeholder={translate("BookingType")}
                        selectedName={bookType?.name}
                        textColorBlack={bookType?.value}
                        list={bookTypes}
                        itemName="name"
                        select={bookType => setBookType({name: bookType?.name ,value: bookType?.value})}
                    />
                    <button className="btn btn-light" onClick={() => {
                        cancel()
                        setAddClick(true)
                    }}> {translate("AddAttendance")} </button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Name')},
                            {value: translate('IDNumber')},
                            {value: translate('Type')},
                            {value: translate('Time')},
                            {value: translate('Locker')},
                            {value: translate('Invitation')},
                            {value: translate('Actions') ,className: 'actions'}
                        ]}/>
                        <tbody>
                            { dataList?.map(row => <Row key={Math.random()}
                                data={row}
                                cancel={cancel}
                                checkout={() => {
                                    cancel()
                                    setCheckout({id: row?.id ,name: row?.name})
                                }}
                                attendExtra={() => {
                                    cancel()
                                    setAttendExtra({id: row?.id, member_id: row?.member_id})
                                }}
                            />) }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                </div>
            </div>
            {checkout ? <Checkout data={checkout} cancel={cancel} reloadData={reloadForCheckout} /> : ''}
            {attendExtra ? <AttendExtra attendRow={attendExtra} cancel={cancel} reloadData={loadData} /> : ''}
            {addClick ? <Add cancel={cancel} reloadData={reloadForAdd} /> : ''}
        </div>
    )
}

export default Index