import { useContext, useEffect, useState } from "react"
import ModalHeader from "../../FormShared/ModalHeader"
import CrudIndexDS from "../../Shared/CrudIndexDS"
import SubscriptionIndex from '../Membership/Subscriptions/Index'
import ClassesIndex from '../Membership/Classes/Index'
import SessionIndex from '../Membership/Sessions/Index'
import './ManageBranch.css'
import AppContext from "../../partials/AppContext"

const ManageBranch = ({branch ,cancel}) => {
    const {translate} = useContext(AppContext)
    const [showSection ,setShowSection] = useState('subscriptions')

    const [subscriptions ,setSubscriptions] = useState({...CrudIndexDS})
    const [classes ,setClasses] = useState({...CrudIndexDS})
    const [sessions ,setSessions] = useState({...CrudIndexDS})

    const subscriptionsComponent = <SubscriptionIndex
        visible={showSection === 'subscriptions'}
        data={subscriptions}
        setData={setSubscriptions}
    />,
    classesComponent = <ClassesIndex
        visible={showSection === 'classes'}
        data={classes}
        setData={setClasses}
    />,
    sessionsComponent = <SessionIndex
        visible={showSection === 'sessions'}
        data={sessions}
        setData={setSessions}
    />

    useEffect(() => {
        localStorage.setItem('branch-id' ,branch?.id)
        return () => localStorage.removeItem('branch-id' ,branch?.id)
    } ,[branch])

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain manage-branch-popup">
                    <ModalHeader title={`${translate("Manage")} ${branch.name ? branch.name : translate('Branch')}`} cancel={cancel}/>
                    <div className="crud-in-modal manage-popup-cintainer">
                        <div className="section manage-popup-section">
                            <div className="pages-navbar pad0">
                                <button className={showSection === 'subscriptions' ? 'active' : ''}
                                    onClick={() => setShowSection('subscriptions')}>
                                    {translate("Subscriptions")}
                                </button>
                                <button className={showSection === 'classes' ? 'active' : ''}
                                    onClick={() => setShowSection('classes')}>
                                    {translate("Classes")}
                                </button>
                                <button className={showSection === 'sessions' ? 'active' : ''}
                                    onClick={() => setShowSection('sessions')}>
                                    {translate("Sessions")}
                                </button>
                            </div>
                            {subscriptionsComponent}
                            {classesComponent}
                            {sessionsComponent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageBranch