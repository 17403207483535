import { useContext } from "react"
import PhotoInput from "../../../FormShared/PhotoInput"
import AppContext from "../../../partials/AppContext"
import DateInput from "../../../FormShared/DateInput"
import DropDown from "../../../FormShared/DropDown"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"

const styles = {
    container: {
        justifyContent: 'row'
    },
    photoContainer: {width: 262},
    selectContainer: {
        marginBottom: 10,
        width: 696 - 262,
        paddingLeft: 16
    }
}

const MaintenanceInput = ({
    maintenanceData,
    changeAttr,
    errors,
    setErrors,
    setSelectedEquip,
    selectedEquip,
    equipments
}) => {
    const {translate} = useContext(AppContext)
    return (
        <div className="flex-row" style={styles.container}>
            <div style={styles.photoContainer}>
                <PhotoInput
                    description={translate("MaintenanceRecUpl")}
                    mb='mb-0'
                    defaultImg={maintenanceData?.defaultReceipt}
                    updatePhoto={(img) => changeAttr('image' ,img)}
                    error={errors.image} />
            </div>
            <div className="section" style={styles.selectContainer}>
                <DropDown
                    selectedName={selectedEquip?.name}
                    textColorBlack={selectedEquip.value}
                    list={equipments}
                    itemName="name"
                    select={equip => {
                        setSelectedEquip({name: equip?.name ,value: equip?.id})
                        changeAttr('equip_id' ,equip?.id)
                    }} />
                {errors.equip_id ? <FieldError message={errors.equip_id} /> : ''}
                
                <DateInput placeholder={translate("MaintenanceDate")} type="date"
                    value={maintenanceData?.date} onChange={e => changeAttr('date' ,e.target.value)}/>
                {errors.date ? <FieldError message={errors.date}/> : ''}
                
                <Input placeholder={translate("Price")} value={maintenanceData?.price} showSpanHint={translate("EGP")}
                    onChange={e => {
                        const v = e.target.value
                        if (isNaN(v)) {
                            setErrors({...errors ,price: translate('NumberOnly')})
                            return
                        }
                        changeAttr('price' ,v)
                    }}/>
                {errors.price ? <FieldError message={errors.price}/> : ''}
            </div>
        </div>
    )
}

export default MaintenanceInput