import { useContext ,useState } from "react"
import { useAlert } from "react-alert"
import AppContext from "../../partials/AppContext"
import ModalHeader from "../../FormShared/ModalHeader"
import ModalButtons from "../../FormShared/ModalButtons"
import Axios from "../../../Axios"
import RecordInput from "./RecordInput"
import SafeTypes from "../../../constants/SafeTypes"
import SafeCashTypes from "../../../constants/SafeCashTypes"

const Add = ({cancel ,entityUrl ,reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const [recordData ,setRecordData] = useState({ type: '', cash_type: '', reason: '', amount: '' })
    const [errors ,setErrors] = useState({ type: '', cash_type: '', reason: '', amount: '' })

    const [selectedType ,setSelectedType] = useState({name: translate('Type') ,value: ''})
    const [selectedCashType ,setSelectedCashType] = useState({name: translate('CashType') ,value: ''})

    const types = SafeTypes(true),
    cashTypes = SafeCashTypes()

    const saveChanges = () => {
        const data = {...recordData}
        data.type = selectedType?.value
        data.cash_type = selectedCashType?.value
        
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(entityUrl ,data)
        .then(response => {
            const responseData = response?.data?.data
            alert.success(`${responseData?.type} ,${responseData?.cash_type} ,${responseData?.amount} ${translate("createdSuccessfully")}`)
            reloadData()
            cancel()
            setDisabled(false)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            setDisabled(false)
        })
    }

    const changeType = __type => {
        setErrors({...errors ,type: ''})
        setRecordData({...recordData ,type: __type?.value})
        setSelectedType({name: __type?.name ,value: __type?.value})
        // if (__type?.value === 'order' || __type?.value === 'member') {
        //     changeCashType({name: translate('CashIn') ,value: 'in'})
        // } else {
        //     changeCashType({name: translate('CashOut') ,value: 'out'})
        // }
    }

    const changeCashType = cashType => {
        setErrors({...errors ,cash_type: ''})
        setRecordData({...recordData ,cash_type: cashType?.value})
        setSelectedCashType({name: cashType?.name ,value: cashType?.value})
    }

    return (    
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddRecord")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <RecordInput
                            recordData={recordData}
                            setRecordData={setRecordData}
                            errors={errors}
                            setErrors={setErrors}
                            changeCashType={changeCashType}
                            changeType={changeType}
                            types={types}
                            cashTypes={cashTypes}
                            selectedCashType={selectedCashType}
                            selectedType={selectedType}
                        />
                        <ModalButtons
                            cancel={cancel}
                            confirm={saveChanges}
                            confirmLabel={translate("AddRecord")}
                            disabled={disabled}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Add