const words = {
    DeletedSuccessfully: "حذف بنجاح",
    DELETE: "حذف",
    Drinks: "المشروبات",
    Download: "تحميل",
    Delete: "حذف",
    December: "ديسمبر",
    Date: "التاريخ",
    Dashboard: "الرئيسية",
    DeleteAlert: "تنبيه الحذف",
    Drink: "المشروب",
    Description: "الوصف",
    DeleteGuest: "حذف الضيف",
    DeleteEmployee: "حذف حساب الموظف",
    DeactivateMember: "إلغاء تفيعل العضو",
    DateTime: "الوقت و التاريخ",
    DrinkRevenue: "أرباح المشروبات",
    Days: "الأيام",
    DiscountValidPercentage: "نسبة الخصم لابد ان تكون نسبة مئوية",
    DiscountPercentage: "نسبة الخصم",
    Day: "يوم",
    Disabled: "غير فعال",
    EditRole: "تعديل الوظيفة",
    EDIT: "تعديل",
    Email: "البريد الإلكتروني",
    Employee: "الموظف",
    EmployeeID: "رقم الموظف",
    EMail: "البريد الإلكتروني",
    EditAccount: "تعديل الحساب",
    EnglishAbout: "الوصف الإنجليزي",
    EditBranch: "تعديل الفرع",
    EmployeesNo: "عدد الموظفين",
    English: "الإنجليزية",
    ExtraFiles: "ملفات إضافية",
    Earnings: "الأرباح",
    EmpName: "إسم الموظف",
    EditEmployee: "تعديل موظف",
    Employees: "الموظفين",
    Equipment: "المعدات",
    EquipmentPowerOff: "هل انت واثق من لإلغاء هذه المعدات",
    EquipmentPowerOn: "هل انت واثق من تفعيل هذه المعدات",
    EquipmentSwitchLater: "يمكنك تفيعل المعدات مجددا في وقت لاحق من نفس الزر",
    EditEquipment: "تعديل المعدات",
    Equipments: "المعدات",
    EGP: "جنيه",
    EditMaintenance: "تعديل الصيانة",
    Expenses: "المصروفات",
    EditDrink: "تعديل المشروب",
    EditFood: "تعديل الطعام",
    EditSupplement: "تعديل المكمل",
    EndDateReq: "تاريخ الإنتهاء مطلوب",
    EndDate: "تاريخ الإنتهاء",
    ExtraSessions: "جلسات إضافية",
    ExtraPrivate: "جلسات مدرب خاص إضافية",
    EditMember: "تعديل العضو",
    EnterPassToComplete: "أدخل كلمة المرور لإكمال العملية",
    EarningPerCategory: "الأرباح للأصناف",
    ExtraDiscount: "خصم إضافي",
    EditOffer: "تعديل العرض",
    Edit: "تعديل",
    EditSubscription: "تعديل الإشتراك",
    EditSession: "تعديل الجلسة",
    End: "النهاية",
    Ended: "منتهية",
    EnglishLang: "إنجليزي",
    EmployeeHintMsg: "توضيح: النسبة (مبلغ ثابت عن كل عضو) ,الهدف (عدد الأعضاء)",
    Extra: "زيادات",
    Deduction: "خصومات",
    EmployeeSalary: "راتب الموظف",
    EditSalary: "تعديل راتب",
    EditExpenses: "تعديل المصروفات",
    EquipmentExpenses: "مصروفات المعدات",
    EditRecord: "تعديل صف في الخزانة",
    EnglishEquipment: "المعدات بالإنجليزية",
    EnglishTitle: "العنوان بالإنجليزية",
    DescriptionEnglish: "الوصف بالإنجليزي",
    DescriptionArabic: "الوصف بالعربي",
}

export default words