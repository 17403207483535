import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../Axios"
import Config from "../../../../../../Config"
import AppContext from "../../../../../partials/AppContext"
import Pagination from "../../../../../Shared/Pagination"
import Table from "../../../../../Shared/Table"
import Thead from "../../../../../Shared/Thead"
import Row from "./Row"
import UnpaidOrders from "./UnpaidOrders"

const OrderIndex = ({visible ,memberId ,data ,setData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members`
    const [unpaidOrders ,setUnpaidOrders] = useState({orders: 0 ,cost: 0})
    const _data = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/orders/${memberId}?limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const res = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${res?.length} ${translate("Orders")}`,
                list: res,
                meta: meta
            })
            setUnpaidOrders({cost: response?.data?.cost ,orders: response?.data?.orders})
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    } ,[gymToken ,entityUrl ,memberId ,alert ,clearUserData ,setData ,currentPage ,dataPerPage])

    const printClick = () => Config.printByToken(`${entityUrl}/orders/print/${memberId}` ,false ,gymToken)

    return (
        <div className={`page-content display-${visible ? 'show': 'hide'}`}>
            <div className="page-section content small-page-section member-details-page">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={printClick}>
                                <i className="fas fa-print"></i>
                            </button>
                        </div>
                    </div>
                    {
                        unpaidOrders && unpaidOrders.orders ?
                        <UnpaidOrders memberId={memberId} loadData={loadData} unpaidOrders={unpaidOrders} />
                        : ''
                    }
                    <Table>
                        <Thead headers={[
                            {value: translate('DateTime')},
                            {value: translate('OrderID')},
                            {value: translate('Cost')},
                            {value: translate('Status')}
                        ]}/>
                        <tbody>
                            { _data?.map(row => <Row key={Math.random()} row={row} />) }
                        </tbody>
                    </Table>
                    {
                        <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                            changeCurrentPage={(page) => setData({...data, currentPage: page})}
                            changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default OrderIndex