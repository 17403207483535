import axios from "axios"
import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import CheckoutAll from "./CheckoutAll"
import MemberLeftover from "./MemberLeftover"
import Row from "./Row"

const Index = ({visible ,data ,setData}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/leftover/orders`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const [checkoutAll ,setCheckoutAll] = useState(undefined)
    const [memberLeftover ,setMemberLeftover] = useState(undefined)

    const cancelModal = () => {
        setCheckoutAll(undefined)
        setMemberLeftover(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate(`LeftoverOrder${responseData?.length > 1 ? 's' : ''}`)}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,entityUrl ,dataPerPage ,currentPage ,alert ,clearUserData])

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    const reloadForDelete = () => {
        if (dataList.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,gymToken)

    return (
        <div className={`${memberLeftover ? 'page-relative' : ''} display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}/>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={triggerSearch}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Member') ,width: '40%'},
                            {value: translate('IDNumber') ,width: '15%'},
                            {value: translate('Leftover') ,width: '15%'},
                            {value: translate('Cost') ,width: '15%'},
                            {value: translate('Actions') ,className: 'actions center-items' ,width: '15%'}
                        ]}/>
                        <tbody>
                            {
                                dataList?.map(
                                    row => <Row key={Math.random()}
                                        row={row}
                                        checkoutAll={() => {
                                            cancelModal()
                                            setCheckoutAll({id: row?.member_id ,name: row?.member})
                                        }}
                                        memberLeftover={() => {
                                            cancelModal()
                                            setMemberLeftover({id: row?.member_id ,name: row?.member})
                                        }}
                                    />
                                )
                            }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n})}/>
                </div>
            </div>
            {
                checkoutAll ?
                <CheckoutAll
                    memberId={checkoutAll?.id}
                    memberName={checkoutAll?.name}
                    cancel={cancelModal}
                    reloadData={reloadForDelete}
                />
                : ''
            }
            {
                memberLeftover ?
                <MemberLeftover
                    memberId={memberLeftover?.id}
                    memberName={memberLeftover?.name}
                    cancel={cancelModal}
                    reloadData={reloadForDelete}
                />
                : ''
            }
        </div>
    )
}

export default Index