import { useContext } from 'react'
import { useAlert } from 'react-alert'
import { NavLink } from 'react-router-dom'
import AppContext from './AppContext'
import Config from '../../Config'

const BranchAside = (props) => {
    const alert = useAlert()

    const {toggleAsideMenu ,closeMenu ,translate ,systemLang ,setToken} = useContext(AppContext)
    return (
        <div className="side-panel en" id="aside-menu">
            <button type="button" id="close-aside-menu" onClick={() => toggleAsideMenu()}>
                <i className="fas fa-times"></i>
            </button>
            <div className="logo">
                <img alt="logo" className="full-img" src="/assets/svg/logo.svg"/>
            </div>
            <section>
                <span className="title">{translate("Main")}</span>
                <NavLink onClick={closeMenu} className="section-link" to="/" exact activeClassName="active">
                    <i className="fab fa-cloudscale"></i>
                    <span>{translate("Dashboard")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/safe" exact activeClassName="active">
                    <i className="fas fa-cash-register"></i>
                    <span>{translate("Safe")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/earnings" exact activeClassName="active">
                    <i className="fas fa-coins"></i>
                    <span>{translate("Earnings")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/expenses" exact activeClassName="active">
                    <i className="fas fa-wallet"></i>
                    <span>{translate("Expenses")}</span>
                </NavLink>
            </section>
            <section>
                <span className="title">{translate("Bar")}</span>
                <NavLink onClick={closeMenu} className="section-link" to="/orders" exact activeClassName="active">
                    <i className="fas fa-concierge-bell"></i>
                    <span>{translate("Orders")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/stock" exact activeClassName="active">
                    <i className="fas fa-hamburger"></i>
                    <span>{translate("Stock")}</span>
                </NavLink>
            </section>
            <section>
                <span className="title">{translate("Gym")}</span>
                <NavLink onClick={closeMenu} className="section-link" to="/members" exact activeClassName="active">
                    <i className="fas fa-address-book"></i>
                    <span>{translate("Members")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/employees" exact activeClassName="active">
                    <i className="fas fa-user-tie"></i>
                    <span>{translate("Employees")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/equipments" exact activeClassName="active">
                    <i className="fas fa-dumbbell" style={{fontSize: 11}}></i>
                    <span>{translate("Equipments")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/membership" exact activeClassName="active">
                    <i className="fas fa-layer-group"></i>
                    <span>{translate("Membership")}</span>
                </NavLink>
            </section>
            <section>
                <span className="title">{translate("More")}</span>
                {/* <NavLink onClick={closeMenu} className="section-link" to="/account-roles" exact activeClassName="active">
                    <i className="fas fa-eye"></i>
                    <span>{translate("Account Roles")}</span>
                </NavLink> */}
                <NavLink onClick={closeMenu} className="section-link" to="/accounts" exact activeClassName="active">
                    <i className="fas fa-user-circle"></i>
                    <span>{translate("Accounts")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/settings" exact activeClassName="active">
                    <i className="fas fa-cog"></i>
                    <span>{translate("Settings")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/history" exact activeClassName="active">
                    <i className="fas fa-history"></i>
                    <span>{translate("History")}</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/help-center" exact activeClassName="active">
                    <i className="fas fa-question-circle"></i>
                    <span>{translate("HelpCenter")}</span>
                </NavLink>
                {
                    localStorage.getItem("backToOwnerToken") ?
                        <button className='section-link' onClick={() => {
                            setToken(localStorage.getItem("backToOwnerToken"), Config?.isTokenInLocal)
                            alert.info(translate("RedirectionInSeconds"))
                            setTimeout(() => {
                                localStorage.removeItem("backToOwnerToken")
                                window.location = "/"
                            },2000)
                        }}>
                            <i className={`fas fa-arrow-${systemLang === "ar" ? 'right' : 'left'}`}></i>
                            <span>{translate("MainBoard")}</span>
                        </button>
                    : null
                }
            </section>
        </div>
    )
}

export default BranchAside