import {useContext} from "react"

import SearchBox from "../../Shared/SearchBox"
import DropDown from "../../FormShared/DropDown"
import AppContext from "../../partials/AppContext"
import Genders from "../../../constants/Genders"
import EmployeePositions from "../../../constants/EmployeePositions"
import Months from "../../../constants/Months"

const Filters = ({
    setGender,
    setPosition,
    setMonth,
    setYear,
    searchTrigger,
    setKeyword,
    keyword,
    employeesFilter,
    selectedGender,
    selectedPosition,
    selectedMonth,
    selectedYear,
    historyFilter,
    withoutAddBtn,
    fireAdd,
    addLabel,
    elRef
}) => {
  const {translate} = useContext(AppContext)
    const genders = Genders()
    const positions = EmployeePositions()
    const months = Months()
    const years = []

    const currentYear = new Date().getFullYear()
    for(let i = 0; i < 10; i++) {
        years.push({name: (currentYear - i) ,value: (currentYear - i)})
    }

    const changeGender = gender => setGender(gender)

    const changePosition = position => setPosition(position)

    const changeMonth =  month => setMonth(month)

    const changeYear =  year => setYear(year)

    return (
        <div className="page-section header-box" ref={elRef}>
            <div className="row header">
                <SearchBox searchTrigger={searchTrigger} setKeyword={setKeyword} keyword={keyword}/>
                {
                    employeesFilter ?
                    <>
                        <DropDown
                            placeholder={translate('Gender')}
                            selectedName={selectedGender?.name}
                            textColorBlack={selectedGender?.value}
                            list={genders}
                            itemName="name"
                            select={changeGender} />
                        <DropDown
                            placeholder={translate('Position')}
                            selectedName={selectedPosition?.name}
                            textColorBlack={selectedPosition?.value}
                            list={positions}
                            itemName="name"
                            select={changePosition} />
                    </>
                    : ''
                }
                {
                    historyFilter ?
                    <>
                        <DropDown
                            placeholder={translate('Month')}
                            selectedName={selectedMonth?.name}
                            textColorBlack={selectedMonth?.value}
                            list={months}
                            itemName="name"
                            select={changeMonth} />
                        <DropDown
                            placeholder={translate('Year')}
                            selectedName={selectedYear?.name}
                            textColorBlack={selectedYear?.value}
                            list={years}
                            itemName="name"
                            select={changeYear} />
                    </>
                    : ''
                }
                {
                    withoutAddBtn ? '' :
                    <button className="btn btn-light" onClick={fireAdd}> {addLabel} </button>
                }
            </div>
        </div>
    )
}

export default Filters