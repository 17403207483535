import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../Axios"
import Config from "../../../../../../Config"
import AppContext from "../../../../../partials/AppContext"
import DangerousAlert from "../../../../../Shared/DangerousAlert"

const Checkout = ({cancel ,reloadData ,classId ,id ,name}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/training-classes/members`
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .put(`${entityUrl}/${classId}/${id}`)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }
    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading={translate("CheckoutAlert")}
                message={`${translate("MemberCheckoutConfirm")} "${name}"?`}
                cancel={cancel}
                confirm={saveChanges}
                confirmLabel={translate("YesCheckout")}
                cancelLabel={translate("NoClose")}
            />
        </div>
    )
}

export default Checkout