import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import DropDown from "../../../../FormShared/DropDown"
import FieldError from "../../../../FormShared/FieldError"
import ModalButtons from "../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../FormShared/ModalHeader"
import AppContext from "../../../../partials/AppContext"

const CameThrough = ({
    employeeType,
    chosenSalesman,
    setEmployeeType,
    setChosenSalesman,
    changeEmployee,
    clickNext,
    cancel,
    error
}) => {
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members`
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [salesman ,setSalesman] = useState([{name: translate('Employee') ,value: ''}])
    const [compError ,setError] = useState(false)

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/members/getAllSales` ,{cancelToken: axiosSource.token})
        .then(response => {
            const temp = response?.data && Array.isArray(response?.data) ? response?.data.map(employee => {
                return {
                    name: employee?.name,
                    value: employee?.id
                }
            }) : []
            setSalesman([...temp])
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setSalesman])

    const __changeEmployee = employee => {
        setError(false)
        setChosenSalesman(employee)
        changeEmployee(employee?.value)
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("NewMember")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <div className="row">
                                <h6> {translate("CameThrough")} </h6>
                            </div>
                            <div className="row">
                                <div className="col-md-6 pr-0">
                                    <label className="radio">
                                        <div className="radio-btn">
                                            <input type="radio" checked={employeeType === 'salesman'} onChange={e => {
                                                setEmployeeType('salesman')
                                                setError(false)
                                            }} />
                                            <span className="radio-check-box"></span>
                                        </div>
                                        <h6 className="radio-btn-text">{translate("Salesman")}</h6>
                                    </label>
                                </div>
                                <div className="col-md-6 pl-0">
                                    <label className="radio">
                                        <div className="radio-btn">
                                            <input type="radio" checked={employeeType === 'hostman'} onChange={e => {
                                                setEmployeeType('hostman')
                                                setError(false)
                                            }} />
                                            <span className="radio-check-box"></span>
                                        </div>
                                        <h6 className="radio-btn-text">{translate("Hostman")}</h6>
                                    </label>
                                </div>
                            </div>
                            <DropDown
                                selectedName={chosenSalesman.name}
                                textColorBlack={chosenSalesman.value !== ''}
                                list={salesman}
                                itemName="name"
                                select={__changeEmployee}
                            />
                            {compError ? <FieldError message={translate("SalesmanFirst")}/> : ''}
                            {error ? <FieldError message={error}/> : ''}
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            confirmLabel={translate("Next")}
                            confirm={() => {
                                if (employeeType === 'salesman' && chosenSalesman.value === '') {
                                    setError(true)
                                    return
                                }
                                clickNext()
                            }}
                        />
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default CameThrough