const Table = (props) => {
    return (
        <div className={`table-container ${props.rootClassName ? props.rootClassName : ''}`}>
            <div className="table">
                <table style={props.tableStyle ? props.tableStyle : {}}>
                    {props.children}
                </table>
            </div>              
        </div>
    )
}

export default Table