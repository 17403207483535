import { useContext, useState } from "react"
import SearchBox from '../../Shared/SearchBox'
import DropDown from "../../FormShared/DropDown"
import AppContext from "../../partials/AppContext"
import Months from "../../../constants/Months"

const FilterBox = ({
    loadData,
    keyword,
    setKeyword,
    actions,
    selectedAction,
    changeAction,
    changeMonth,
    changeYear
}) => {
    const {translate} = useContext(AppContext)
    const [selectedMonth ,setSelectedMonth] = useState(translate('All'))
    const [selectedYear ,setSelectedYear] = useState(translate('All'))
    const years = [{name: translate('All') ,value: ''}]
    const months = Months()

    const __changeMonth = (month) => {
        changeMonth(month.value)
        setSelectedMonth(month.name)
    }

    const __changeYear = (year) => {
        changeYear(year.value)
        setSelectedYear(year.name)
    }

    const currentYear = new Date().getFullYear()
    for(let i = 0; i < 10; i++) {
        years.push({name: (currentYear - i) ,value: (currentYear - i)})
    }
    return (
        <div className="row header">
            <SearchBox placeholder={translate("Search")} searchTrigger={loadData} setKeyword={setKeyword} keyword={keyword}/>
            <DropDown
                placeholder={translate('Month')}
                selectedName={selectedMonth}
                textColorBlack={selectedMonth !== translate('All')}
                list={months}
                itemName="name"
                select={__changeMonth} />
            <DropDown
                placeholder={translate('Year')}
                selectedName={selectedYear}
                textColorBlack={selectedYear !== translate('All')}
                list={years}
                itemName="name"
                select={__changeYear} />
            {
                actions ?
                <DropDown
                    placeholder={translate('Action')}
                    selectedName={selectedAction?.name}
                    textColorBlack={selectedAction?.name !== translate('All')}
                    list={actions}
                    itemName="name"
                    select={changeAction} />
                :''
            }
        </div>
    )
}

export default FilterBox