import { useContext, useState } from "react"
import ModalButtons from "../../../../FormShared/ModalButtons"
import PhotoInput from "../../../../FormShared/PhotoInput"
import AppContext from "../../../../partials/AppContext"
import PersonalInputs from "./PersonalInputs"

const Personal = ({
    member, changeMember, setPhoto,
    errors, loadErrors,
    clickNext, clickPrevious,
    gender, setGender,
    defaultImg,
    confirmLabel, cancelLabel,
    disabled,
}) => {
    const {translate} = useContext(AppContext)
    const [compErrors ,setErrors] = useState({
        name: errors?.name,
        national_id: errors?.national_id,
        gender: errors?.gender,
        age: errors?.age,
        height: errors?.height,
        weight: errors?.weight,
        phone_number: errors?.phone_number,
        email: errors?.email,
        photo: errors?.photo
    })

    const updateMemberAttr = (value ,attr) => {
        if (attr === 'photo') setPhoto(value)
        else {
            const __member = {...member}
            __member[attr] = value
            changeMember(__member ,attr)
        }
        const _errors = {...compErrors}
        _errors[attr] = ''
        setErrors(_errors)
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            <PhotoInput defaultImg={defaultImg} updatePhoto={(img) => updateMemberAttr(img ,'photo')} error={compErrors?.photo} />
            <div className="section">
                <PersonalInputs
                    errors={loadErrors ? errors : compErrors}
                    member={member}
                    changeAttr={(value ,attr) => updateMemberAttr(value ,attr)}
                    gender={gender}
                    setGender={setGender}
                />
            </div>
            <ModalButtons
                cancel={clickPrevious}
                confirm={() => {
                    const tempErrors = {...compErrors}
                    let failed = false
                    Object.keys(member).forEach(key => {
                        if (
                            !member[key] && key !== 'email' && key !== 'national_id' &&
                            key !== 'height' && key !== 'weight'
                        ) {
                            tempErrors[key] = translate('RequiredField')
                            failed = true
                        }
                    })
                    if (!failed) clickNext()
                    else setErrors({...tempErrors})
                }}
                confirmLabel={confirmLabel ? confirmLabel : translate("Next")}
                cancelLabel={cancelLabel ? cancelLabel : translate("Previous")}
                disabled={disabled}
            />
        </form>
    )
}

export default Personal