import Input from "../../FormShared/Input"
import FieldError from "../../FormShared/FieldError"
import '../../../css-files/small-form.css'
import { useContext } from "react"
import AppContext from "../../partials/AppContext"
import ModalHeader from "../../FormShared/ModalHeader"
import ModalButtons from "../../FormShared/ModalButtons"

const ChangeModal = ({
    children,
    modalHeader,
    childrenFirst,
    value,
    changeValue,
    placeholder,
    type,
    error,
    disabled,
    cancel,
    confirm
}) => {
    const {translate} = useContext(AppContext)
    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={`${translate("Change")} ${modalHeader}`} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2 flex-gap-5 flex-col">
                            {
                                childrenFirst ? children : ''
                            }
                            <Input
                                value={value}
                                onChange={changeValue}
                                placeholder={placeholder ? placeholder : ''}
                                type={type ? type : 'text'}/>
                            {error ? <FieldError message={error} /> : ''}
                            {
                                !childrenFirst ? children : ''
                            }
                        </div>
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={confirm}
                            confirmLabel={translate("SaveChanges")}/>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangeModal