import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../Axios"
import Config from "../../../Config"
import Input from "../../FormShared/Input"
import AppContext from "../../partials/AppContext"
import DangerousAlert from "../../Shared/DangerousAlert"

const DeleteWorkHours = ({cancel ,reloadData ,id}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [pass ,setPass] = useState('')
    const [disabled ,setDisabled] = useState(false)

    const confirmDelete = () => {
        if (!pass) {
            alert.error(translate('PasswordRequired'))
            return
        }
        const data = {password: pass ,_method: 'DELETE'}
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/branch-setting/${id}` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading=""
                message={`${translate('surePermanentlyDelete')} ${translate('workingHours')}? ${translate('cantUndoAction')}`}
                cancel={cancel}
                cancelLabel={translate("NoCancel")}
                confirm={confirmDelete}
                confirmLabel={translate("YesDelete")}
            >
                <Input type="password" placeholder={translate("Password")} value={pass} onChange={e => setPass(e.target.value)}/>
            </DangerousAlert>
        </div>
    )
}

export default DeleteWorkHours