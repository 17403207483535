import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../Axios"
import Config from "../../../Config"
import AppContext from "../../partials/AppContext"
import PrintButton from "../../Shared/PrintButton"
import Pagination from "../../Shared/Pagination"
import Thead from "../../Shared/Thead"
import Table from "../../Shared/Table"
import Filters from "./Filters"
import AddEmployee from "./AddEmployee"
import EditEmployee from "./EditEmployee"
import Delete from "./Delete"
import AttendanceCheckout from "./AttendanceCheckout"
import AttendanceIn from "./AttendanceIn"

const ListData = ({
    setData,
    data,
    entity,
    entityPlural,
    entitySingle,
    entityUrl,
    filterByGender,
    filterByPosition,
    filterByMonth,
    filterByYear,
    printUrl,
    visible,
    employeesFilter,
    historyFilter,
    withoutAddBtn,
    mapRow,
    headers
}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    
    const [invokeAdd ,setAdd] = useState(undefined)
    const [invokeEdit ,setEdit] = useState(undefined)
    const [invokeDelete ,setDelete] = useState(undefined)
    const [gender ,setGender] = useState({name: translate('All') ,value: ''})
    const [position ,setPosition] = useState({name: translate('All') ,value: ''})
    const [month ,setMonth] = useState({name: translate('All') ,value: ''})
    const [year ,setYear] = useState({name: translate('All') ,value: ''})
    const axiosSource = axios.CancelToken.source()

    const cancelModal = () => {
        setAdd(undefined)
        setEdit(undefined)
        setDelete(undefined)
    }

    const fireAdd = () => {
        cancelModal()
        setAdd(true)
    }

    const fireEdit = (id) => {
        cancelModal()
        setEdit(id)
    }

    const fireDelete = (id ,name) => {
        cancelModal()
        setDelete({id ,name})
    }
    
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    let queryString = `keyword=${keyword}`
    if (filterByGender) queryString += `&gender=${gender.value}`
    if (filterByPosition) queryString += `&position=${position.value}`
    if (filterByMonth) queryString += `&month=${month.value}`
    if (filterByYear) queryString += `&year=${year.value}`

    const loadData = () => {
        axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?${queryString}&limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data, meta = response?.data?.meta
            setData({...data,
                meta: meta,
                headerTitle: `${responseData?.length} ${responseData?.length <= 1 ? translate(entitySingle) : translate(entityPlural)}`,
                list: responseData
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[alert ,gymToken ,clearUserData ,currentPage ,dataPerPage ,gender ,position ,month ,year])

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const setKeyword = (value) => setData({...data, keyword: value})

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    const reloadForDelete = () => {
        if (data.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const printClick = () => Config.printByToken(`${printUrl}?${queryString}` ,true ,gymToken)
    
    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <Filters
                elRef={elRef}
                searchTrigger={triggerSearch}
                setKeyword={setKeyword}
                keyword={keyword}
                selectedGender={gender}
                setGender={setGender}
                selectedPosition={position}
                setPosition={setPosition}
                selectedMonth={month}
                setMonth={setMonth}
                selectedYear={year}
                setYear={setYear}

                employeesFilter={employeesFilter}
                historyFilter={historyFilter}

                fireAdd={fireAdd}
                addLabel={translate(`Add${entitySingle}`)}
                withoutAddBtn={withoutAddBtn}
                />
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={headers}/>
                        <tbody>
                            { dataList?.map(row => mapRow(row ,fireEdit ,fireDelete)) }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n})}/>
                </div>
            </div>
            {invokeAdd && entity === 'employees' ? <AddEmployee loadData={reloadForAdd} cancel={cancelModal}/> : ''}
            {invokeEdit && entity === 'employees' ? <EditEmployee id={invokeEdit} loadData={loadData} cancel={cancelModal}/> : ''}
            {
                invokeDelete ?
                <Delete
                    name={invokeDelete.name}
                    actionUrl={`${entityUrl}/${invokeDelete.id}`}
                    reloadData={reloadForDelete}
                    cancel={cancelModal}/>
                : ''
            }
            {
                invokeEdit && entity === 'attendance' ?
                <AttendanceCheckout
                    id={invokeEdit}
                    reloadData={reloadForDelete}
                    cancel={cancelModal}/>
                : ''
            }
            {invokeAdd && entity === 'attendance' ? <AttendanceIn loadData={reloadForAdd} cancel={cancelModal}/> : ''}
        </div>
    )
}

export default ListData