import { useContext } from "react"
import DateInput from "../../../FormShared/DateInput"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import AppContext from "../../../partials/AppContext"
import ClassTimes from "./ClassTimes"

const styles = {
    classTipMsg: {
        display: 'flex',
        justifyContent: 'left',
        color: 'rgb(128, 127, 127)'
    }
}

const ClassInputs = ({
    classData ,setClassData,
    changeAttr,
    errors,
    changeClassTimes,
    storedStartDate = undefined
}) => {
    const {translate} = useContext(AppContext)
    return (
        <div className="section pb-1">
            <div className="toggle-section">
                <div className="input-label">
                    <label htmlFor="branch-adding">{translate("ClassDetails")}</label>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <Input placeholder={translate("EnglishTitle")} value={classData.title}
                        readOnly={classData?.number_of_members <= classData?.registered_members}
                        onChange={e => changeAttr('title' ,e.target.value)}/>
                    {errors.title ? <FieldError message={errors.title}/> : ''}
                </div>
                <div className="col-sm-6 pr-0">
                    <Input placeholder={translate("ArabicTitle")} value={classData.title_ar}
                        readOnly={classData?.number_of_members <= classData?.registered_members}
                        onChange={e => changeAttr('title_ar' ,e.target.value)}/>
                    {errors.title_ar ? <FieldError message={errors.title_ar}/> : ''}
                </div>
            </div>
            <div style={styles.classTipMsg}>
                {translate("ClassTipMsg")}
            </div>
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <DateInput placeholder={translate("StartDate")} type="date"
                        isDisabled={classData?.number_of_members <= classData?.registered_members}
                        min={storedStartDate ? storedStartDate : new Date().toISOString().split("T")[0]}
                        value={classData.class_start} onChange={e => changeAttr('class_start' ,e.target.value)}/>
                    {errors.class_start ? <FieldError message={errors.class_start}/> : ''}
                </div>
                <div className="col-sm-6 pr-0">
                    <DateInput placeholder={translate("EndDate")} type="date" min={classData.class_start}
                        isDisabled={!classData.class_start || classData?.number_of_members <= classData?.registered_members}
                        value={classData.class_end} onChange={e => changeAttr('class_end' ,e.target.value)}/>
                    {errors.class_end ? <FieldError message={errors.class_end}/> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <Input placeholder={translate("NumberOfMembers")} type="number"
                        value={classData.number_of_members} onChange={e => changeAttr('number_of_members' ,e.target.value)}/>
                    {errors.number_of_members ? <FieldError message={errors.number_of_members}/> : ''}
                </div>
                <div className="col-sm-6 pr-0">
                    <Input placeholder={translate("Price")} readOnly={classData?.registered_members > 0}
                        value={classData.price} onChange={e => changeAttr('price' ,e.target.value)}/>
                    {errors.price ? <FieldError message={errors.price}/> : ''}
                </div>
            </div>
            <div className="toggle-section">
                <div className="input-label">
                    <label htmlFor="branch-adding">{translate("ClassTimes")}</label>
                    {errors.times ? <FieldError message={errors.times}/> : ''}
                </div>
            </div>
            {
                classData?.times?.map(
                    (classTime ,index) => <ClassTimes
                        fullMembers={classData?.number_of_members <= classData?.registered_members}
                        start={classData.class_start}
                        end={classData.class_end}
                        classTime={classTime}
                        changeDay={day => changeClassTimes(index ,'day' ,day)}
                        changeStart={start => changeClassTimes(index ,'start' ,start)}
                        changeEnd={end => changeClassTimes(index ,'end' ,end)}
                        removeDay={() => {
                            if (classData?.number_of_members <= classData?.registered_members) return
                            const tempTimes = classData?.times?.filter((_time ,_index) => _index !== index)
                            setClassData({...classData ,times: tempTimes})
                        }}
                    />
                )
            }
            <button type="button" className="red-btn"
                onClick={() =>
                    setClassData(
                        {
                            ...classData,
                            times: [
                                ...classData.times ,{day: '' ,start: '' ,end: ''}
                            ]
                        }
                    )
                }>
                + {translate("AddClassTime")}
            </button>
        </div>
    )
}

export default ClassInputs