import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../Axios"
import Config from "../../../Config"
import DropDown from "../../FormShared/DropDown"
import FieldError from "../../FormShared/FieldError"
import Input from "../../FormShared/Input"
import AppContext from "../../partials/AppContext"
import GoogleMap from "../../Shared/GoogleMap";

const styles = {
    cameraSpan: {marginLeft: 5 ,color: 'gray'},
    addCameraBtn: {fontSize: 14 ,color: 'rgba(224, 63, 63, 1)'},
    xBtnStyle: {position: "absolute" ,top: 5}
}

const BranchInputs = ({
    branch ,setBranch ,
    errors ,setErrors ,
    cameras ,setCameras,
    cities ,setCities,
    selectedCity ,setSelectedCity ,
    selectedProvince ,setSelectedProvince
}) => {
    let xBtnStyle = {...styles.xBtnStyle}
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate ,systemLang} = useContext(AppContext)
    const [provinces ,setProvinces] = useState([])
    const api = `${Config.apiBaseUrl}/gym-dashboard-services/branches`

    if (systemLang === 'ar') xBtnStyle.left = 5
    else xBtnStyle.right = 5

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${api}/country/getAllProvince` ,{cancelToken: axiosSource.token})
        .then(response => setProvinces(response.data.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setProvinces ,gymToken ,alert ,clearUserData ,api])

    const changeCities = province => {
        setSelectedProvince(province)
        Axios
        .setAuthToken(gymToken)
        .get(`${api}/country/getAllCities?province_id=${province?.id}`)
        .then(response => setCities(response.data.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeCity = city => {
        setErrors({...errors ,city_id: ''})
        setSelectedCity(city)
    }

    const changeCamera = (index ,value) => {
        const camera = {...cameras[index]} ,tempCameras = [...cameras]
        camera.ip = value
        tempCameras[index] = camera
        setCameras(tempCameras)
        setErrors({...errors ,ips: ''})
    }

    return (
        <>
            <div className="row mb-0">
                <h5>{ translate("BranchDetails") }</h5>
            </div>
            <div className="row">
                <div className="col-sm-6 pad0 left-col">
                    <Input
                        placeholder={translate("BranchName")}
                        value={branch?.name}
                        onChange={e => {
                            setErrors({...errors ,name: ''})
                            setBranch({...branch ,name: e.target.value})
                        }}/>
                    {errors.name ? <FieldError message={errors.name}/> : ''}
                </div>
                <div className="col-sm-6 pad0 right-col">
                    <Input
                        placeholder={translate("ArBranchName")}
                        value={branch?.name_ar}
                        isAr={true}
                        onChange={e => {
                            setErrors({...errors ,name_ar: ''})
                            setBranch({...branch ,name_ar: e.target.value})
                        }}/>
                    {errors.name_ar ? <FieldError ar={true} message={errors.name_ar}/> : ''}
                </div>
                <div className="col-sm-6 pad0 left-col">
                    <textarea
                        className='rounded-box'
                        placeholder={translate("EnglishAbout")}
                        value={branch?.description_en}
                        onChange={(e) => {
                            setErrors({...errors ,description_en: ''})
                            setBranch({...branch ,description_en: e.target.value})
                        }}
                    ></textarea>
                    {errors.description_en ? <FieldError message={errors.description_en}/> : ''}
                </div>
                <div className="col-sm-6 pad0 right-col">
                    <textarea
                        className='rounded-box ar'
                        placeholder={translate("ArabicAbout")}
                        value={branch?.description_ar}
                        onChange={(e) => {
                            setErrors({...errors ,description_ar: ''})
                            setBranch({...branch ,description_ar: e.target.value})
                        }}
                    ></textarea>
                    {errors.description_ar ? <FieldError ar={true} message={errors.description_ar}/> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 pad0 left-col">
                    <Input
                        placeholder={translate("Phone")}
                        value={branch?.phone}
                        onChange={e => {
                            setErrors({...errors ,phone: ''})
                            setBranch({...branch ,phone: e.target.value})
                        }}/>
                    {errors.phone ? <FieldError message={errors.phone}/> : ''}
                </div>
                <div className="col-sm-6 pad0 left-col"></div>
                <div className="col-sm-6 pad0 left-col">
                    <Input
                        placeholder={translate("Address")}
                        value={branch?.address}
                        onChange={e => {
                            setErrors({...errors ,address: ''})
                            setBranch({...branch ,address: e.target.value})
                        }}/>
                    {errors.address ? <FieldError message={errors.address}/> : ''}
                </div>
                <div className="col-sm-6 pad0 right-col">
                    <div className="row">
                        <div className="col-sm-6 pad0 left-col">
                            <DropDown
                                selectedName={selectedProvince?.name}
                                textColorBlack={selectedProvince?.id}
                                list={provinces}
                                itemName="name"
                                select={province => changeCities(province)}
                            />
                        </div>
                        <div className="col-sm-6 pad0 right-col">
                            <DropDown
                                selectedName={selectedCity?.name}
                                textColorBlack={selectedCity?.id}
                                list={cities}
                                itemName="name"
                                select={city => changeCity(city)}
                            />
                            {errors.city_id ? <FieldError message={errors.city_id}/> : ''}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 pad0 left-col">
                    <Input
                        placeholder={translate("MapUrl")}
                        value={branch?.map_url}
                        onChange={e => {
                            setErrors({...errors ,map_url: ''})
                            setBranch({...branch ,map_url: e.target.value})
                        }}/>
                    {errors.map_url ? <FieldError message={errors.map_url}/> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 pad0 left-col" style={{display: "flex", height: 350}}>
                    <GoogleMap url={branch.map_url}/>
                </div>
            </div>
            <div className="row mb-0">
                <h5> {translate('CameraIPs')} </h5> <span style={styles.cameraSpan}>({translate('Optional')})</span>
                {errors.ips ? <FieldError message={errors.ips}/> : ''}
            </div>
            {
                cameras?.map((camera ,index) => 
                    <div className="row" key={index}>
                        <div className="col-sm-6 pl-0 rel-position">
                            <Input placeholder={translate("CameraIP")} value={camera?.ip}
                                onChange={(e) => changeCamera(index ,e.target.value)}/>
                            <button onClick={() => setCameras([...cameras].filter?.((camera ,__indx) => __indx !== index))}
                                className="red-text"
                                style={xBtnStyle}
                            > x </button>
                        </div>
                    </div>
                )
            }
            <button type="button" style={styles.addCameraBtn}
                onClick={() => setCameras([...cameras ,{ip: ''}])}>
                + {translate("AddCamera")}
            </button>
        </>
    )
}

export default BranchInputs
