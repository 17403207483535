import { useContext } from "react"
import DropDown from "../../FormShared/DropDown"
import FieldError from "../../FormShared/FieldError"
import Input from "../../FormShared/Input"
import AppContext from "../../partials/AppContext"

const RecordInput = ({
    recordData, setRecordData,
    errors, setErrors,
    changeCashType, changeType,
    types ,cashTypes,
    selectedCashType ,selectedType
}) => {
    const {translate} = useContext(AppContext)
    
    return (
        <div className="section">
            <DropDown
                className="mb-4"
                selectedName={selectedType?.name}
                textColorBlack={selectedType?.value}
                list={types}
                itemName="name"
                select={changeType}
            />
            {errors?.type ? <FieldError message={errors?.type} /> : ''}

            <DropDown
                selectedName={selectedCashType?.name}
                textColorBlack={selectedCashType?.value}
                list={cashTypes}
                itemName="name"
                select={changeCashType}
            />
            {errors?.cash_type ? <FieldError message={errors?.cash_type} /> : ''}

            <Input
                placeholder={translate("Reason")}
                value={recordData?.reason}
                onChange={e => {
                    setErrors({...errors ,reason: ''})
                    setRecordData({...recordData ,reason: e.target.value})
                }}
            />
            {errors?.reason ? <FieldError message={errors?.reason} /> : ''}

            <Input
                placeholder={translate("Amount")}
                value={recordData?.amount}
                onChange={e => {
                    setErrors({...errors ,amount: ''})
                    setRecordData({...recordData ,amount: e.target.value})
                }}
                showSpanHint={`| ${translate('EGP')}`}
            />
            {errors?.amount ? <FieldError message={errors?.amount} /> : ''}
        </div>
    )
}

export default RecordInput