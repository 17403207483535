import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import ModalButtons from "../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../FormShared/ModalHeader"
import PhotoInput from "../../../../FormShared/PhotoInput"
import AppContext from "../../../../partials/AppContext"

const ChangePhotoAttribute = ({id ,cancel ,reloadData}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/employees/update/${id}`

    const [disabled ,setDisabled] = useState(false)
    const [image ,setImage] = useState()
    const [error ,setError] = useState("")

    const saveChanges = () => {
        if (!image) {
            setError(translate("RequiredField"))
            return
        }
        setDisabled(true)
        const data = new FormData()
        data.set('photo' ,image)
        data.set("_method" ,"PUT")
        
        Axios
        .setAuthToken(gymToken)
        .post(entityUrl ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation.photo) {
                setError(validation.photo ?? '')
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={`${translate("Edit")} ${translate("Photo")}`} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                        <PhotoInput
                            updatePhoto={(img) => setImage(img)}
                            error={error}
                        />
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            cancelLabel={translate("Cancel")}
                            disabled={disabled}
                            confirm={saveChanges}
                            confirmLabel={`${translate("Edit")} ${translate("Photo")}`}
                        />
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangePhotoAttribute