import axios from "axios";

const Axios = axios
Axios.defaults.headers = {
    'token-remember': localStorage.getItem('gymToken') ? 'yes' : 'no',
    'accept': 'application/json', // this header to solve 302 redirect when submit any invalid request
    'system-lang': (localStorage.getItem('systemLang') ?? '') === 'ar' ? 'ar' : 'en'
}

Axios.setBranch = bId => {
    Axios.defaults.headers['custom-branch-id'] = bId
}

Axios.removeBranch = () => {
    if (Axios.defaults.headers['custom-branch-id']) {
        delete Axios.defaults.headers['custom-branch-id']
    }
}

Axios.setAuthToken = token => {
    Axios.defaults.headers['authToken'] = token
    return Axios
}

export default Axios