import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import DangerousAlert from "../../../Shared/DangerousAlert"
import SuccessAlert from "../../../Shared/SuccessAlert"

const ChangeStatus = ({equipment ,cancel ,reloadData ,actionFor = 'active'}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/equipment`
    const [disabled ,setDisabled] = useState(false)

    const switchStatus = () => {
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .patch(`${entityUrl}/status/${equipment?.id}`)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return actionFor === 'active' ?
    (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading={translate("PowerOff")}
                message={`${translate('equipmentPowerOff')} "${equipment?.name}"? ${translate('equipmentSwitchLater')}`}
                cancel={cancel}
                confirm={switchStatus}
                confirmLabel={translate("PowerOff")}
            />
        </div>
    )
    :
    (
        <div id="forms">
            <SuccessAlert
                disabled={disabled}
                heading={translate("PowerOn")}
                message={`${translate('equipmentPowerOn')} "${equipment?.name}"? ${translate('equipmentSwitchLater')}`}
                cancel={cancel}
                confirm={switchStatus}
                confirmLabel={translate("PowerOn")}
            />
        </div>
    )
}

export default ChangeStatus