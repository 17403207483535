import { useContext, useEffect } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"

const Attendance = ({visible ,id ,data ,setData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/employees`
    const _data = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle
    const enablePagination = false //to be enabled if enabled from backend

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/attendance/history/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const res = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${res?.length} ${translate("Attendance")}`,
                list: res,
                meta: meta
            })
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[gymToken ,entityUrl ,id ,alert ,clearUserData ,setData])

    const printClick = () => Config.printByToken(`${entityUrl}/attendance/history/print/${id}` ,false ,gymToken)

    return (
        <div className={`page-content display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section content small-page-section member-details-page">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={printClick}>
                                <i className="fas fa-print"></i>
                            </button>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('Date')},
                            {value: translate('Attendance')},
                            {value: translate('Leaving')}
                        ]}/>
                        <tbody>
                            {
                                _data?.map(row => <tr key={row?.id}>
                                    <td> {row?.date} </td>
                                    <td> {row?.in} </td>
                                    <td> {row?.out} </td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                    {
                        enablePagination ?
                        <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                            changeCurrentPage={(page) => setData({...data, currentPage: page})}
                            changeDataPerPage={(n) => setData({...data, dataPerPage: n, currentPage: 1})}/>
                        :''
                    }
                </div>
            </div>
        </div>
    )
}

export default Attendance