import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import DropDown from '../../../FormShared/DropDown'
import '../Classes/ClassTimes.css'
import { useContext } from "react"
import AppContext from "../../../partials/AppContext"

const styles = {
    btnStyle: {fontSize: 14 ,color: 'rgba(224, 63, 63, 1)'}
}

const SubscriptionInputs = ({
    subscriptionData,
    setSubscriptionData,
    errors,
    sessions
}) => {
    const {translate ,systemLang} = useContext(AppContext)
    const changeAttr = (attr ,value) => {
        const temp = {...subscriptionData}
        temp[attr] = value
        setSubscriptionData({...temp})
    }

    return (
        <div className="section pb-1">
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <Input className="rounded-box full-width none-max-width"
                        placeholder={translate("EnglishTitle")} value={subscriptionData.title} onChange={e => changeAttr('title' ,e.target.value)}/>
                    {errors.title ? <FieldError message={errors.title}/> : ''}
                </div>
                <div className="col-sm-6 pr-0">
                    <Input className="rounded-box full-width none-max-width"
                        placeholder={translate("ArabicTitle")} value={subscriptionData.title_ar} onChange={e => changeAttr('title_ar' ,e.target.value)}/>
                    {errors.title_ar ? <FieldError message={errors.title_ar}/> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <Input placeholder={translate("NoOfDays")} type="number"
                        value={subscriptionData.number_of_days} onChange={e => changeAttr('number_of_days' ,e.target.value)}/>
                    {errors.number_of_days ? <FieldError message={errors.number_of_days}/> : ''}
                </div>
                <div className="col-sm-6 pr-0">
                    <Input placeholder={translate("NoOfInvitations")} type="number"
                        value={subscriptionData.number_of_invitations}
                        onChange={e => changeAttr('number_of_invitations' ,e.target.value)}/>
                    {errors.number_of_invitations ? <FieldError message={errors.number_of_invitations}/> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <Input placeholder={translate("FreezingDays")} type="number"
                        value={subscriptionData.freezing_days} onChange={e => changeAttr('freezing_days' ,e.target.value)}/>
                    {errors.freezing_days ? <FieldError message={errors.freezing_days}/> : ''}
                </div>
                <div className="col-sm-6 pr-0">
                    <Input placeholder={translate("Price")}
                        value={subscriptionData.price} onChange={e => changeAttr('price' ,e.target.value)}/>
                    {errors.price ? <FieldError message={errors.price}/> : ''}
                </div>
            </div>
            <div className="row">
                <div className={`col-sm-6 ${systemLang === "ar" ? "pl-0" : "pr-0"}`}>
                    <Input placeholder={translate("VisitsNumber")} type="number"
                        value={subscriptionData.visits_number} onChange={e => changeAttr('visits_number' ,e.target.value)}/>
                    {errors.visits_number ? <FieldError message={errors.visits_number}/> : ''}
                </div>
            </div>
            <div className="toggle-section">
                <div className="input-label">
                    <label htmlFor="branch-adding">{translate("Sessions")}</label>
                    {errors.sessions ? <FieldError message={errors.sessions}/> : ''}
                </div>
            </div>
            {
                subscriptionData?.sessions?.map(
                    (session ,index) => 
                    <div className="row close-row">
                        <div className="col-sm-6 pl-0">
                            <DropDown
                                selectedName={session.real_name}
                                textColorBlack={session.real_name !== translate('Session')}
                                list={sessions}
                                itemName="name"
                                select={({id ,name}) => {
                                    const tempSession = subscriptionData.sessions
                                    tempSession[index].session_id = id
                                    tempSession[index].real_name = name
                                    setSubscriptionData({...subscriptionData ,sessions: [...tempSession]})
                                }} />
                        </div>
                        <div className="col-sm-6 pr-0">
                            <Input placeholder={translate("Quantity")} type="number" value={session.quantity} onChange={e => {
                                const tempSession = subscriptionData.sessions
                                tempSession[index].quantity = e.target.value
                                setSubscriptionData({...subscriptionData ,sessions: [...tempSession]})
                            }}/>
                        </div>
                        <i className="fas fa-times close-btn" onClick={() => {
                            const tempTimes = subscriptionData?.sessions?.filter((session ,_index) => _index !== index)
                            setSubscriptionData({...subscriptionData ,sessions: [...tempTimes]})
                        }}></i>
                    </div>
                )
            }
            <button type="button" style={styles.btnStyle}
                onClick={() =>
                    setSubscriptionData(
                        {
                            ...subscriptionData,
                            sessions: [
                                ...subscriptionData.sessions ,{session_id: '' ,quantity: '' ,real_name: translate('Session')}
                            ]
                        }
                    )
                }>
                + {translate("AddSession")}
            </button>
        </div>
    )
}

export default SubscriptionInputs