const Row = ({data ,showAttendSession}) => {
    return (
        <tr>
            <td> { data?.date } </td>
            <td> { data?.name } </td>
            <td> { data?.id_number } </td>
            <td> { data?.in } </td>
            <td> { data?.out } </td>
            <td> { data?.locker } </td>
            <td className="actions">
                <button onClick={showAttendSession}>
                    <i className="fas fa-hot-tub"></i>
                </button>
            </td>
        </tr>
    )
}

export default Row