const Row = ({row}) => {
    return (
        <tr>
            <td> {row?.day} </td>
            <td> {row?.in} </td>
            <td> {row?.out} </td>
            <td> {row?.locker_number} </td>
        </tr>
    )
}

export default Row