import { useContext } from 'react'
import Config from '../../../Config'
import AppContext from '../../partials/AppContext'
import './style.css'

const BranchStatsCounter = ({data}) => {
    const {systemLang} = useContext(AppContext)

    return (
        <div className='branch-stats-container'>
            <span className='branch-stats-name'> {data?.name} </span>
            {
                data?.counters?.map?.(
                    counter =>
                    <div className='branch-stats-counter-parent'>
                        <div className='branch-stats-counter-container'>
                            <div
                                className={`${systemLang === 'ar' ? 'branch-stats-counter-ar' : 'branch-stats-counter-en'}`}
                                style={{borderColor: counter?.color}}
                            >
                                <span className='branch-stats-title'> {counter?.text} </span>
                                <span className='branch-stats-counter'> {Config.readableStats(counter?.number ,systemLang)} </span>
                                <span className='branch-stats-overview'> {counter?.hint} </span>
                            </div>
                        </div>
                    </div>
                )
            }
            
        </div>
    )
}

export default BranchStatsCounter