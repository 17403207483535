import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Input from '../../../FormShared/Input'
import FieldError from "../../../FormShared/FieldError"
import Axios from "../../../../Axios"
import DropDown from "../../../FormShared/DropDown"

const FoodInput = ({
    foodData,
    setFoodData,
    errors,
    setErrors,
    readOnly = undefined,
    qntReadOnly = undefined
}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/stock/foods/getAllData`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [categories ,setCategories] = useState([])

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(entityUrl ,{cancelToken: axiosSource.token})
        .then(response => setCategories([...response?.data?.categories ,{name_en: translate('AddNew') ,id: '-1'}]))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[gymToken ,entityUrl ,alert ,clearUserData ,setCategories])

    return (
        <>
            <div className="row">
                <div className="col-md-6 pl-0">
                    <Input
                        className="full-max-width"
                        placeholder={translate("ItemName")}
                        value={foodData?.name}
                        onChange={e => {
                            setErrors({...errors ,name: ''})
                            setFoodData({...foodData ,name: e.target.value})
                        }}
                    />
                    {errors?.name ? <FieldError message={errors?.name} /> : ''}
                </div>
                <div className="col-md-6 pr-0">
                    <Input
                        className="full-max-width"
                        placeholder={translate("ItemArName")}
                        value={foodData?.ar_name}
                        onChange={e => {
                            setErrors({...errors ,ar_name: ''})
                            setFoodData({...foodData ,ar_name: e.target.value})
                        }}
                    />
                    {errors?.ar_name ? <FieldError message={errors?.ar_name} /> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 pl-0">
                    <Input
                       showSpanHint={`| ${translate("CAL")}`}
                        placeholder={translate("Calories")}
                        value={foodData?.calories}
                        onChange={e => {
                            setErrors({...errors ,calories: ''})
                            setFoodData({...foodData ,calories: e.target.value})
                        }}
                    />
                    {errors?.calories ? <FieldError message={errors?.calories} /> : ''}
                </div>
                <div className="col-md-6 pr-0">
                    <DropDown
                        selectedName={foodData?.category?.name}
                        textColorBlack={foodData?.category?.id}
                        list={categories}
                        itemName="name_en"
                        select={category => {
                            setErrors({...errors ,category_id: ''})
                            setFoodData({...foodData ,category: {id: category?.id ,name: category?.name_en}})
                        }}
                    />
                    {errors?.category_id ? <FieldError message={errors?.category_id} /> : ''}
                </div>
            </div>
            {
                foodData && foodData.category && foodData.category.id === '-1' ?
                <div className="row">
                    <div className="col-md-6 pl-0">
                        <Input
                            placeholder={translate("NameEnglish")}
                            value={foodData?.newCategory?.name_en}
                            onChange={e => {
                                setErrors({...errors ,name_en: ''})
                                setFoodData({...foodData ,newCategory: {...foodData.newCategory ,name_en: e.target.value}})
                            }}
                        />
                        {errors?.name_en ? <FieldError message={errors?.name_en} /> : ''}
                    </div>
                    <div className="col-md-6 pr-0">
                        <Input
                            placeholder={translate("NameArabic")}
                            value={foodData?.newCategory?.name_ar}
                            onChange={e => {
                                setErrors({...errors ,name_ar: ''})
                                setFoodData({...foodData ,newCategory: {...foodData.newCategory ,name_ar: e.target.value}})
                            }}
                        />
                        {errors?.name_ar ? <FieldError message={errors?.name_ar} /> : ''}
                    </div>
                </div>
                : ''
            }
            <div className="row">
                <div className="col-md-6 pl-0">
                    <textarea className="rounded-box rounded-box-help-center full-max-width"
                        placeholder={translate("Description")}
                        value={foodData?.description}
                        onChange={e => {
                            setErrors({...errors ,description: ''})
                            setFoodData({...foodData ,description: e.target.value})
                        }}></textarea>
                    {errors?.description ? <FieldError message={errors?.description} /> : ''}
                </div>
                <div className="col-md-6 pr-0">
                    <textarea className="rounded-box rounded-box-help-center full-max-width"
                        placeholder={translate("ArabicDescription")}
                        value={foodData?.description_ar}
                        onChange={e => {
                            setErrors({...errors ,description_ar: ''})
                            setFoodData({...foodData ,description_ar: e.target.value})
                        }}></textarea>
                    {errors?.description_ar ? <FieldError message={errors?.description_ar} /> : ''}
                </div>
            </div>
            <h6> {translate("PurchaseDetails")} </h6>
            <div className="row">
                <div className="col-md-6 pl-0">
                    <Input
                        placeholder={translate("Quantity")}
                        showSpanHint={`| ${translate("AvailableQTY")}`}
                        value={foodData?.quantity}
                        onChange={e => {
                            if (isNaN(e.target.value)) {
                                setErrors({...errors ,quantity: translate('validNumber')})
                                return
                            }
                            setErrors({...errors ,quantity: ''})
                            setFoodData({...foodData ,quantity: e.target.value})
                        }}
                        readOnly={qntReadOnly}
                    />
                    {errors?.quantity ? <FieldError message={errors?.quantity} /> : ''}
                </div>
                <div className="col-md-6 pr-0">
                    <Input
                        placeholder={translate("Price")}
                        showSpanHint={`| ${translate("EGP")}`}
                        value={foodData?.purchase_price}
                        onChange={e => {
                            if (isNaN(e.target.value)) {
                                setErrors({...errors ,purchase_price: translate('validNumber')})
                                return
                            }
                            setErrors({...errors ,purchase_price: ''})
                            setFoodData({...foodData ,purchase_price: e.target.value})
                        }}
                        readOnly={readOnly}
                    />
                    {errors?.purchase_price ? <FieldError message={errors?.purchase_price} /> : ''}
                </div>
            </div>
            <h6> {translate("SellingDetails")} </h6>
            <div className="row">
                <div className="col-md-6 pl-0">
                    <Input
                        placeholder={translate("Price")}
                        showSpanHint={`| ${translate("EGP")}`}
                        value={foodData?.selling_price}
                        onChange={e => {
                            if (isNaN(e.target.value)) {
                                setErrors({...errors ,selling_price: translate('validNumber')})
                                return
                            }
                            setErrors({...errors ,selling_price: ''})
                            setFoodData({...foodData ,selling_price: e.target.value})
                        }}
                    />
                    {errors?.selling_price ? <FieldError message={errors?.selling_price} /> : ''}
                </div>
                <div className="col-md-6 pr-0">
                    <Input
                        placeholder={translate("LowQntAlert")}
                        value={foodData?.low_quantity}
                        onChange={e => {
                            if (isNaN(e.target.value)) {
                                setErrors({...errors ,low_quantity: translate('validNumber')})
                                return
                            }
                            setErrors({...errors ,low_quantity: ''})
                            setFoodData({...foodData ,low_quantity: e.target.value})
                        }}
                    />
                    {errors?.low_quantity ? <FieldError message={errors?.low_quantity} /> : ''}
                </div>
            </div>
        </>
    )
}

export default FoodInput