import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import Loading from "../../../Shared/Loading"
import ClassInputs from "./ClassInputs"

const Edit = ({id ,cancel ,reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/training-classes`

    const [classData ,setClassData] = useState({
        title: '' ,title_ar: '' , class_start: '', class_end: '', number_of_members: '', price: '' ,
        times: [
            {day: '' ,start: '' ,end: ''}
        ]
    })
    const [errors ,setErrors] = useState({title: '' ,title_ar: '' ,class_start: '' ,class_end: '' ,number_of_members: '' ,price: '' ,times: ''})
    const [storedStartDate ,setStoredStartDate] = useState(new Date().toISOString().split("T")[0])
    const [loaded ,setLoaded] = useState(false)
    const [disabled ,setDisabled] = useState(false)

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const data = response?.data?.data
            setStoredStartDate(data.class_start)
            setClassData(data)
            setLoaded(true)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[alert ,clearUserData ,gymToken ,setClassData])

    const saveChanges = () => {
        const times = [...classData.times].map(time => {
            let startTime = parseInt(time.start) ,endTime = parseInt(time.end)
            if (time.start.includes('PM')) startTime += 12
            if (time.end.includes('PM')) endTime += 12
            return {
                day: time.day,
                start: startTime < 10 ? `0${startTime}` : `${startTime}`,
                end: endTime < 10 ? `0${endTime}` : `${endTime}`
            }
        })
        setDisabled(true)
        Axios
        .setAuthToken(gymToken)
        .put(`${entityUrl}/${id}` ,{...classData ,times: times})
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.title} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                setErrors(validation)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeAttr = (attr ,value) => {
        const temp = {...classData} ,_errors = {...errors}
        temp[attr] = value
        _errors[attr] = ''
        setClassData(temp)
        setErrors(_errors)
    }

    const changeClassTimes = (index ,attr ,value) => {
        let _value = attr !== 'day' ? value : new Date(value)
        if (attr === 'day') {
            _value = `${_value.toISOString()}`.split('T')[0]
        }

        const temp = [...classData.times]
        temp[index][attr] = _value
        setClassData({...classData ,times: temp})
    }

    return (
        <div id="forms">
            <div id="add-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("UpdateClass")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        {
                            loaded ?
                            <>
                                <ClassInputs
                                    classData={classData}
                                    setClassData={setClassData}
                                    errors={errors}
                                    changeAttr={changeAttr}
                                    changeClassTimes={changeClassTimes}
                                    storedStartDate={storedStartDate}
                                />
                                <div className="col-md-12">
                                    <ModalButtons disabled={disabled} cancel={cancel} confirm={saveChanges} confirmLabel={translate("UpdateClass")} />
                                </div>
                            </>
                            : <Loading/>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Edit