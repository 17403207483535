import { useContext } from "react"
import AppContext from "../components/partials/AppContext"

const HelpCenterTypes = () => {
    const {translate} = useContext(AppContext)

    return [
        {name: translate('Problem') ,value: 'problem'},
        {name: translate('Suggestion') ,value: 'suggestion'},
    ]
}

export default HelpCenterTypes