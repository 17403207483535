import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import AppContext from "../../../partials/AppContext"
import GymExpensesInput from "./GymExpensesInput"

const App = ({entityUrl ,loadData ,cancel ,categories}) => {
    const alert = useAlert()
    const {translate ,gymToken ,clearUserData} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const [expenses ,setExpenses] = useState({
        category: '', sub_category: '', price: ''
    })
    const [receipt ,setReceipt] = useState(undefined)
    const [errors ,setErrors] = useState({
        category: '', sub_category: '', price: '', receipt: '',
    })
    const [selectedCategory ,setSelectedCategory] = useState({name: translate('Category') ,id: undefined})
    const [selectedSubCategory ,setSelectedSubCategory] = useState({name: translate('SubCategory') ,id: undefined})
    const [subCategories ,setSubCategories] = useState([])
    const axiosSource = Axios.CancelToken.source()

    const confirmAddExpenses = () => {
        setDisabled(true)
        const formData = new FormData()
        formData.append('category' ,expenses.category)
        formData.append('sub_category' ,expenses.sub_category)
        formData.append('price' ,expenses.price)
        if (receipt) formData.append('receipt' ,receipt)
        Axios
        .setAuthToken(gymToken)
        .post(entityUrl ,formData ,{cancelToken: axiosSource.token})
        .then(() => {
            setDisabled(false)
            alert.success(`${translate("createdSuccessfully")}`)
            loadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validations = error?.response?.data?.errors
            if (validations) setErrors({...errors ,...validations})
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("AddExpenses")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()} className="flex-col">
                        <GymExpensesInput
                            expenses={expenses}
                            setExpenses={setExpenses}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            categories={categories}
                            subCategories={subCategories}
                            setSubCategories={setSubCategories}
                            selectedSubCategory={selectedSubCategory}
                            setSelectedSubCategory={setSelectedSubCategory}
                            errors={errors}
                            setErrors={setErrors}
                            setReceipt={setReceipt}
                        />
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={confirmAddExpenses}
                            confirmLabel={translate("AddExpenses")}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default App