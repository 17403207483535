import moment from "moment"
import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../Axios"
import Config from "../../../Config"
import WeekDays from "../../../constants/WeekDays"
import FieldError from "../../FormShared/FieldError"
import Input from "../../FormShared/Input"
import ModalButtons from "../../FormShared/ModalButtons"
import ModalHeader from "../../FormShared/ModalHeader"
import AppContext from "../../partials/AppContext"
import TimePicker from "../../Shared/TimePicker"

const styles = {
    errorStyle: {
        fontSize: 15,
        fontStyle: 'italic',
        fontWeight: 'bold',
        letterSpacing: 2
    },
    timeStyle: {
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '1px solid #ededed',
        paddingLeft: 10,
        direction: 'ltr'
    }
}

const WorkingHours = ({reloadData ,cancel ,modalHeader ,defaultData = undefined}) => {
    const o = new Date() ,c = new Date()
    if (defaultData.from) {
        o.setHours(defaultData.from?.split?.(":")[0] ,defaultData.from?.split?.(":")[1])
        c.setHours(defaultData.to?.split?.(":")[0] ,defaultData.to?.split?.(":")[1])
    }
    const alert = useAlert()
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const [pass ,setPass] = useState('')
    const [openTime ,setOpenTime] = useState(moment(o))
    const [closeTime ,setCloseTime] = useState(moment(c))
    const [selectedDays ,setSelectedDays] = useState(defaultData && defaultData.days && Array.isArray(defaultData.days) ? defaultData.days : [])
    const days = WeekDays()
    const [errors ,setErrors] = useState({days: '' ,to: '' ,from: ''})
    const [conflict ,setConflict] = useState('')
    const [disabled ,setDisabled] = useState(false)

    const addDay = day => {
        const tempDays = [...selectedDays]
        if (tempDays.includes(day)) setSelectedDays([...tempDays.filter(_d => _d !== day)])
        else setSelectedDays([...tempDays ,day])
        setConflict('')
    }

    const saveChanges = () => {
        const data = {
            password: pass ,type: defaultData?.type ? defaultData.type : 'male',
            days: selectedDays ,from: openTime?.format?.('HH:mm') ,to: closeTime?.format?.('HH:mm')
        }
        setDisabled(true)
        const url = `${Config.apiBaseUrl}/gym-dashboard-services/branch-setting/work-hours`
        let axioxObj = Axios.setAuthToken(gymToken)
        if (defaultData?.id) {
            axioxObj = axioxObj.put(`${url}/${defaultData.id}` ,data)
        } else {
            axioxObj = axioxObj.post(url ,data)
        }
        axioxObj
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) {
                const validation = error?.response?.data?.errors,
                    toError = validation['to'],
                    fromError = validation['from'],
                    daysError = validation['days']
                const _errors = {...errors}

                if (toError) _errors.to = Array.isArray(toError) ? toError.join(",") : toError
                if (fromError) _errors.from = Array.isArray(fromError) ? fromError.join(",") : fromError
                if (daysError) _errors.days = Array.isArray(daysError) ? daysError.join(",") : daysError
                setErrors(_errors)
            } else if (
                error?.response?.data?.message &&
                error?.response?.status !== Config.workingHourConflictStatus
            ) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.status === Config.workingHourConflictStatus)
                setConflict(error?.response?.data?.message)
        })
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader cancel={cancel} title={modalHeader}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2"> 
                            {conflict ? <FieldError pStyle={styles.errorStyle} message={conflict} /> : ''}
                            <div className="flex-row flex-gap-15 auto-width">
                                <div className="flex-col dir-ltr">
                                    <h4> {translate("OpeningTime")}: </h4>
                                    <TimePicker value={openTime} onChange={value => {
                                        setConflict('')
                                        setOpenTime(value)
                                    }}/>
                                    {errors.from ? <FieldError message={errors.from} /> : ''}
                                </div>
                                <div style={styles.timeStyle}>
                                    <h4> {translate('CloseTime')}: </h4>
                                    <TimePicker value={closeTime} onChange={value => {
                                        setConflict('')
                                        setCloseTime(value)
                                    }}/>
                                    {errors.to ? <FieldError message={errors.to} /> : ''}
                                </div>
                            </div>
                            <h6> {translate("SelectDay")} </h6>
                            {errors.days ? <FieldError message={errors.days} /> : ''}
                            <div className="flex-row-wrap flex-gap-5">
                            {
                                days.map(day =>
                                    <label class="checkbox">
                                        <div class="checkbox-btn">
                                            <input type="checkbox"
                                                checked={selectedDays?.includes(day.key)}
                                                onChange={() => addDay(day.key)}/>
                                            <span class="input-checkbox"></span>
                                        </div>
                                        <h6 class="checkbox-text"> {day.value} </h6>
                                    </label>
                                )
                            }
                            </div>
                            <Input type="password" placeholder={translate("Password")} value={pass} onChange={e => setPass(e.target.value)}/>
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            disabled={disabled}
                            confirmLabel={translate("SaveChanges")}
                            confirm={saveChanges}/>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default WorkingHours