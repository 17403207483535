import { useContext, useState } from "react"
import Axios from "../../../Axios"
import Config from "../../../Config"
import AppContext from "../../partials/AppContext"
import DataLine from "../../Shared/DataLine/DataLine"
import Loading from "../../Shared/Loading"

const styles = {
    coverStyle: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: 290,
    }
}

const GeneralSetting = ({
    branch,
    openModal,
    isVisible,
    openCoverModal,
    openLogoModal,
    changeLocation,
    openLanguageModal,
    openTypeModal,
    openBarModal,
    openCameraModal,
    openLockerModal
}) => {
    const {translate ,setHasLockers ,gymToken ,clearUserData} = useContext(AppContext)
    const [lockerInfoLoader ,setLockerInfoLoader] = useState(false)

    const swithcLockers = () => {
        setLockerInfoLoader(true)
        Axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/branch-setting/lockers` ,{_method: 'PUT'})
        .then(response => {
            setLockerInfoLoader(false)
            setHasLockers(response?.data?.has_lockers)
        })
        .catch(err => {
            setLockerInfoLoader(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const getCoverStyle = () => {
        return {...styles.coverStyle ,background: branch?.cover}
    }

    return (
        <div className={`page-content display-${isVisible ? 'show' : 'hide'}`}>
            <div className="page-section content profile-card-content">
                <div className="details-page-content settings-page">
                    <div className="table-header">
                        <span className="table-header-title"> {translate('GeneralSetting')} </span>
                    </div>
                    <div className="details-page-section rel-position">
                        <div style={getCoverStyle()}>
                            <img src={branch?.cover} alt={branch?.name} className="full-w-h"/>
                            <button className="details-cam-btn en abs-position r-25 t-25">
                                <img alt={branch?.name} src="/assets/svg/img-change.svg" onClick={openCoverModal}/>
                            </button>
                        </div>
                        <div className="details-img-container">
                            <div className="details-img">
                                <img alt={branch?.name} id="gym-logo-img" src={branch?.logo} className="details-full-img"/>
                            </div>
                            <button id="cam-btn" className="details-cam-btn en">
                                <img alt={branch?.name} src="/assets/svg/img-change.svg" onClick={openLogoModal}/>
                            </button>
                        </div>
                        <div className="details-gym-info">
                            <div className="details-info-section">
                                <span className="details-info-title">{translate("MainInfo")}</span>
                                <div className="details-info-data">
                                    <DataLine
                                        title={translate("NameEnglish")}
                                        value={branch?.name}
                                        changeClick={() => openModal({title: translate('ChangeNameEn'), attr: 'name' ,value: branch?.name})}
                                    />
                                    <DataLine
                                        title={translate("NameArabic")}
                                        value={branch?.name_ar}
                                        changeClick={() => openModal({title: translate('ChangeNameAr'), attr: 'name_ar' ,value: branch?.name_ar})}
                                    />
                                    <DataLine
                                        title={translate("Phone")}
                                        value={branch?.phone}
                                        changeClick={() => openModal({title: translate('ChangePhone'), attr: 'phone' ,value: branch?.phone})}
                                    />
                                    <DataLine
                                        title={translate("Address")}
                                        value={branch?.address}
                                        changeClick={() => openModal({title: translate('ChangeAddress'), attr: 'address' ,value: branch?.address})}
                                    />
                                    <DataLine
                                        title={translate("MapLocation")}
                                        value={`${branch?.longitude} , ${branch?.latitude}`}
                                        changeClick={() => changeLocation?.(branch?.latitude ,branch?.longitude)}
                                    />
                                    <DataLine
                                        title={translate("DescriptionEnglish")}
                                        value={`${branch?.description_en?.substring?.(0 ,35)} ...`}
                                        changeClick={() => openModal({
                                            title: translate('ChangeDescriptionEnglish'),
                                            attr: 'description_en',
                                            value: branch?.description_en,
                                            textarea: true
                                        })}
                                    />
                                    <DataLine
                                        title={translate("DescriptionArabic")}
                                        value={`${branch?.description_ar?.substring?.(0 ,35)} ...`}
                                        changeClick={() => openModal({
                                            title: translate('ChangeDescriptionArabic'),
                                            attr: 'description_ar',
                                            value: branch?.description_ar,
                                            textarea: true
                                        })}
                                    />
                                    <DataLine
                                        title={translate("Website")}
                                        value={branch?.website}
                                        changeClick={() => openModal({title: translate('ChangeWebsite'), attr: 'website' ,value: branch?.website})}
                                    />
                                    <DataLine
                                        btnLabel={translate("Update")}
                                        title={translate("Language")}
                                        value={translate(branch?.language)}
                                        changeClick={() => openLanguageModal(branch?.language)}
                                    />
                                    <DataLine
                                        btnLabel={translate("Update")}
                                        title={translate("GymType")}
                                        value={branch?.type?.map?.(t => translate(t))?.join(" ,")}
                                        changeClick={() => openTypeModal(branch?.type)}
                                    />
                                    <DataLine
                                        btnLabel={translate("Update")}
                                        title={translate("Bar")}
                                        value={branch?.bar?.map?.(b => translate(b))?.join(" ,")}
                                        changeClick={() => openBarModal(branch?.bar)}
                                    />
                                </div>
                            </div>

                            <div className="details-info-section">
                                <div className="flex-space-btwn">
                                    <span className="details-info-title">{translate("LockerInfo")}</span>
                                    <button disabled={lockerInfoLoader} onClick={() => swithcLockers()}>
                                        <div className={`toggle ${branch?.has_lockers ? 'active' : ''}`}></div>
                                    </button>
                                </div>
                                {
                                    lockerInfoLoader ?
                                    <Loading/> :
                                    <>
                                        {
                                            branch?.has_lockers ?
                                            <div className="details-info-data">
                                                <DataLine
                                                    title={translate("MaleLockers")}
                                                    value={branch?.male_lockers}
                                                    changeClick={() => openLockerModal('male' ,branch?.lockers?.male_start ,branch?.lockers?.male_lockers)}
                                                />
                                                <DataLine
                                                    title={translate("FemaleLockers")}
                                                    value={branch?.female_lockers}
                                                    changeClick={() => openLockerModal('female' ,branch?.lockers?.female_start ,branch?.lockers?.female_lockers)}
                                                />
                                            </div>
                                            : ''
                                        }
                                    </>
                                }
                            </div>

                            <div className="details-info-section">
                                <span className="details-info-title">{translate("Camera")}</span>
                                <div className="details-info-data">
                                    {
                                        branch?.camera_ip?.map(camera => {
                                            return (
                                                <DataLine key={camera?.id}
                                                    title={translate("IPAddress")}
                                                    value={camera?.ip}
                                                    changeClick={() => openCameraModal?.(camera?.ip ,camera?.id)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            {/* <div className="details-info-section">
                                <span className="details-info-title">{translate("ExtraFiles")}</span>
                                <div className="details-info-data">
                                    {
                                        branch?.extra_files?.map(file => {
                                            return (
                                                <DataLine key={Math.random()}
                                                    title={file.name}
                                                    value={file?.type}
                                                    btnLabel={translate("Download")}
                                                    changeClick={() => window.open(file.link ,'_blank')}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralSetting