import { useContext, useState } from "react"
import AppContext from "../partials/AppContext"
import FieldError from "./FieldError"

const PhotoInput = (props) => {
    const {translate} = useContext(AppContext)
    const [preview ,setPreview] = useState()

    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0]
            props.updatePhoto(img)
            setPreview(URL.createObjectURL(img))
        }
    }

    return (
        <div className={`col-sm-12 pad0 ${props.mb ? props.mb : 'mb-4'}`}>
            <div className="image-upload">
                <div className="img">
                    {
                        preview ? <img style={{width: 200}} alt="Gymme" src={preview} />
                        : <img  style={{width: 200}} src={props.defaultImg ? props.defaultImg : '/assets/svg/upload.svg'} alt="Gymme"/>
                    }
                </div>
                <span className="image-name">{translate('UploadImage')} {props.isOptional ? `(${translate('Optional')})` : ''}</span>
                <p style={{padding: 0 ,marginBottom: 0}} className="description"> {props?.description} </p>
                {props?.error ? <FieldError message={props.error}/> : ''}
                
                <label className="rounded-box">
                    <div className="input-file">
                        <img src="/assets/svg/folder.svg" alt="Gymme"/>
                        <span>{translate("BrowseFiles")}</span>
                        <input onChange={onImageChange} type="file" accept=".png, .jpg, .jpeg"/>
                    </div>
                </label>
            </div>
        </div>
    )
}

export default PhotoInput