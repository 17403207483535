import { useContext } from "react"
import AppContext from "../components/partials/AppContext"

const Genders = () => {
    const {translate} = useContext(AppContext)

    return [
        {name: translate('All') ,value: ''},
        {name: translate('Male') ,value: 'male'},
        {name: translate('Female') ,value: 'female'}
    ]
}

export default Genders