const HistoryRow = ({history}) => {
    const imgStyle = {
        width: 34,
        height: 34,
        borderRadius: '50%'
    },
    dateStyle = {
        textAlign: 'left'
    },
    imgCellStyle = {
        width: 40
    },
    boldText = {
        fontWeight: 'bold'
    }
    
    return (
        <tr>
            <td style={imgCellStyle}>
                <img alt={history?.account?.name} src={history?.account?.avatar} style={imgStyle}/>
            </td>
            <td>
                <span style={boldText}> {history?.account?.name} </span>
                {history?.action} {history?.entity}
                <span style={boldText}> "{history?.entity_value}" </span>
            </td>
            <td style={dateStyle}> {history?.created} </td>
        </tr>
    )
}

export default HistoryRow