import { useAlert } from "react-alert"
import { NavLink } from "react-router-dom"
import ModalHeader from "../../../FormShared/ModalHeader"
import ModalButtons from "../../../FormShared/ModalButtons"
import FieldError from "../../../FormShared/FieldError"
import DateInput from "../../../FormShared/DateInput"
import { useContext, useState } from "react"
import AppContext from "../../../partials/AppContext"
import Config from "../../../../Config"
import Axios from "../../../../Axios"
import SearchBox from "../../../Shared/SearchBox"

const Add = ({cancel ,reloadData}) => {
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/members/booking`
    const [selectMember ,setSelectMember] = useState({name: translate('MemberID') ,id: undefined})
    const [bookDate ,setBookDate] = useState('')
    const [bookTime ,setBookTime] = useState('')
    const [errors ,setErrors] = useState({member_id: '' ,booking_from: ''})
    const [disabled ,setDisabled] = useState(false)
    const [conflict ,setConflict] = useState('')
    const [memberId ,setMemberId] = useState('')

    const changeMember = () => {
        if (!memberId) {
            setErrors({...errors ,member_id: translate('FullMemberID')})
            return
        }
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/getMember/${memberId}`)
        .then(response => setSelectMember({name: response?.data?.name ,id: response?.data?.id}))
        .catch(err => {
            setSelectMember({name: '' ,id: undefined})
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const bookADate = () => {
        setDisabled(true)
        const data = {member_id: selectMember?.id ,booking_from: `${bookDate} ${bookTime}`}
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/add` ,data)
        .then(response => {
            setDisabled(false)
            const res = response?.data?.data
            alert.success(`${res?.name} ${translate("BookingAt")} ${res?.date} ${res?.time} ,${translate("BookedSuccessfully")}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) setErrors({...error?.response?.data?.errors})
            else if (
                error?.response?.data?.message &&
                error?.response?.status !== Config.workingHourConflictStatus
            ) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.status === Config.workingHourConflictStatus)
                setConflict(error?.response?.data?.message)
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("BookDate")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section">
                            {  
                                conflict ?
                                <div className="row">
                                    <div className="col-sm-12 pad0 booking-conflict">
                                        {conflict}
                                        <br></br>
                                        <NavLink className="white-underline" exact to="/settings?tab=hours">
                                            {translate("GoToSettings")}
                                        </NavLink>
                                    </div>
                                </div>
                                : ''
                            }
                            <div className="col-sm-12 pad0 toogle-textfield">
                                <h5> { selectMember?.name } </h5>
                                <SearchBox
                                    extraClass="clear-left-padding"
                                    searchTrigger={changeMember}
                                    setKeyword={value => setMemberId(value)}
                                    keyword={memberId}
                                    placeholder={translate("SearchFullMemberID")}
                                />
                                {errors?.member_id ? <FieldError message={errors?.member_id} /> : ''}
                            </div>
                            <div className="row">
                                <div className="col-sm-6 pl-0 toogle-textfield">
                                    <DateInput
                                        placeholder={translate("Date")}
                                        min={new Date().toISOString().split("T")[0]}
                                        value={bookDate}
                                        onChange={e => {
                                            setErrors({...errors ,booking_from: ''})
                                            setBookDate(e.target.value)
                                        }}/>
                                </div>
                                <div className="col-sm-6 pr-0 toogle-textfield">
                                    <DateInput
                                        type="time"
                                        placeholder={translate("Time")}
                                        value={bookTime}
                                        onChange={e => {
                                            setErrors({...errors ,booking_from: ''})
                                            setBookTime(e.target.value)
                                        }}/>
                                </div>
                                {errors?.booking_from ? <FieldError message={errors?.booking_from} /> : ''}
                            </div>
                        </div>
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={bookADate}
                            confirmLabel={translate("BookDate")}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Add