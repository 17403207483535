import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import Axios from '../../../../Axios'
import Config from '../../../../Config'
import AppContext from '../../../partials/AppContext'
import Loading from '../../../Shared/Loading'
import Table from '../../../Shared/Table'
import Thead from '../../../Shared/Thead'
import './MemberLeftoverStyles.css'

const MemberLeftover = ({memberName ,memberId ,cancel ,reloadData}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/leftover/orders`
    const {clearUserData ,gymToken ,translate ,systemLang} = useContext(AppContext)

    const [details ,setDetails] = useState({orders_list: [] ,total_price: '' ,no_of_orders: ''})

    const [loaded ,setLoaded] = useState(false)
    const [disabled ,setDisabled] = useState(false)

    const [selectedOrders ,setSelectedOrders] = useState([])
    const [selectedCost ,setSelectedCost] = useState(0)

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}/getAllLeftoverOrders/${memberId}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const data = response?.data
            setDetails({
                orders_list: data?.orders_list,
                total_price: data?.total_price,
                no_of_orders: data?.no_of_orders
            })
            setLoaded(true)
            setSelectedOrders([])
            setSelectedCost(0)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,entityUrl ,memberId ,clearUserData ,alert ,setDetails])

    const selectAllOrders = () => {
        if (selectedOrders.length > 0) {
            setSelectedOrders([])
            setSelectedCost(0)
            return
        }
        let total = 0
        const __temp = []
        details.orders_list?.forEach?.(order => {
            __temp.push(order?.order_id)
            total += parseFloat(order?.order_total_price)
        })
        setSelectedOrders(__temp)
        setSelectedCost(total)
    }

    const changeOrderSelect = (id ,cost) => {
        if (selectedOrders.some(o => o === id)) {
            setSelectedOrders([...selectedOrders].filter(o => o !== id))
            setSelectedCost(parseFloat(selectedCost) - parseFloat(cost))
        } else {
            setSelectedCost(parseFloat(selectedCost) + parseFloat(cost))
            setSelectedOrders([...selectedOrders ,id])
        }
    }

    const payClicked = () => {
        if (selectedOrders?.length > 0) {
            if (selectedOrders?.length === details?.orders_list?.length) payAll()
            else paySelected()
        } else {
            alert.error(translate('SelectOrderFirst'))
        }
    }

    const payAll = () => {
        setDisabled(true)
        let axiosSource = axios.CancelToken.source()
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/payAllLeftoverOrders/${memberId}` ,{_method: 'PUT' ,cancelToken: axiosSource.token})
        .then(response => {
            alert.success(`${memberName ? memberName : ''} ${translate("OrdersPaidSuccess")}`)
            setDisabled(false)
            loadData()
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const paySelected = () => {
        setDisabled(true)
        let axiosSource = axios.CancelToken.source()
        const data = {_method: 'PUT' ,cancelToken: axiosSource.token ,orders: []}
        selectedOrders?.forEach?.(o => data.orders.push({id: o}))
        Axios
        .setAuthToken(gymToken)
        .post(`${entityUrl}/paySomeLeftoverOrders/${memberId}` ,data)
        .then(response => {
            alert.success(`${memberName ? memberName : ''} ${translate("SelectedOrderPaid")}`)
            setDisabled(false)
            loadData()
            reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const printClick = () => Config.printByToken(`${entityUrl}/printAllLeftoverOrders/${memberId}` ,false ,gymToken)

    return (
        <div className="leftover-parent">
            <i onClick={cancel} className={`leftover-back-btn clickable fas fa-arrow-${systemLang === 'ar' ? 'right' : 'left'}`}></i>
            {
                loaded ?
                <div className="leftover-content">
                    <div className="leftover-header">
                        <span className="leftover-member">
                            { memberName }
                            <span> { `(${details?.no_of_orders} ${translate("Leftover")})` } </span>
                        </span>
                        <i className="fas fa-print clickable" onClick={printClick}></i>
                    </div>
                    <div className="leftover-cost">
                        <div>
                            <span> {selectedOrders.length} {translate("Orders")} </span>
                            <span> {translate("EGP")} {selectedCost} </span>
                        </div>
                        <button type="button"
                            className="btn btn-light"
                            disabled={disabled}
                            onClick={payClicked}>
                            {translate("Pay")}
                        </button>
                    </div>
                    <div className="page-section content">
                        <div className="table-section">
                            <Table>
                                <Thead headers={[
                                    {value: '' ,loadChildren: true},
                                    {value: translate('DateTime')},
                                    {value: translate('OrderID')},
                                    {value: translate('Barman')},
                                    {value: translate('Cost')}
                                ]}>
                                    <input type="checkbox" onChange={selectAllOrders}
                                        checked={selectedOrders?.length  === details?.orders_list?.length}/>
                                </Thead>
                                <tbody>
                                    {
                                        details?.orders_list?.map?.(
                                            row => <tr>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedOrders?.some?.(o => o === row?.order_id)}
                                                        onChange={() => changeOrderSelect(row?.order_id ,row?.order_total_price)}
                                                    />
                                                </td>
                                                <td> {row?.date_time} </td>
                                                <td> {row?.order_id} </td>
                                                <td> {row?.barman} </td>
                                                <td> {translate("EGP")} {row?.order_total_price} </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                : <Loading/>
            }
        </div>
    )
}

export default MemberLeftover