const words = {
    AddRole: "إضافة وظيفة",
    ArRoleName: "الوظيفة بالعربية",
    AccountRole: "وظيفة الحساب",
    AccountRoles: "وظائف الحساب",
    ArabicRole: "الوظيفة بالعربية",
    AddedDate: "تاريخ الإضافة",
    Actions: "العمليات",
    ADD: "إضافة",
    Account: "الحساب",
    AddAccount: "إضافة حساب",
    AccoDelSuccess: "تم حذف الحساب بنجاح",
    Accounts: "الحسابات",
    AddBranch: "إضافة فرع",
    ArBranchName: "إسم الفرع بالعربية",
    ArabicAbout: "الوصف بالعربية",
    Address: "العنوان",
    AddCamera: "إضافة كاميرا",
    Arabic: "العربية",
    Add: "إضافة",
    AllowMemInGym: "عدد المتدربين المسموح بهم في الجيم",
    AttendNo: "عدد الحاضرين",
    AddEmployee: "إضافة موظف",
    AddAttendance: "إضافة حضور",
    AgeYears: "السن (بالسنين)",
    April: "أبريل",
    August: "أغسطس",
    Attendance: "الحضور",
    Attendances: "الحاضرين",
    AttendnaceTime: "وقت الحضور",
    AddEquipment: "إضافة معدات",
    ArabicEquipment: "المعدات بالعربي",
    AddMaintenance: "إضافة صيانة",
    Action: "العملية",
    AccountAction: "عمليات الحساب",
    AddDrink: "إضافة مشروب",
    AddNew: "إضافة جديد",
    AddItem: "إضافة منتج",
    ArabicDescription: "الوصف بالعربي",
    AddSupplement: "إضافة مكمل",
    AttendedSession: "حضور جلسة",
    AttendanceTime: "وقت الحضور",
    AddMember: "إضافة عضو",
    At: "في",
    AttendSuccessfully: "تم الحضور بنجاح",
    AttendSession: "حضور جلسة",
    Attend: "حضور",
    Age: "السن",
    AddGuest: "إضافة ضيف",
    App: "التطبيق",
    AllowedMemInGym: "الأعضاء المتاحين في الجيم",
    AddPrivateCoach: "إضافة مدرب خاص",
    AddMorSeession: "إضافة جلسات آخري",
    AddSession: "إضافة جلسة",
    ActivateMember: "تفعيل حساب العضو",
    AreYouSure: "هل انت متاكد من انك تريد",
    AddExtra: "إضافة زيادة",
    AttendMemberFirst: "لا يوجد عضو حاضر الآن ,من فضلك سجل حضور واحد عضو علي الأقل لإنشاء طلب",
    ArabicTitle: "العنوان بالعربية",
    AddOffer: "إضافة عرض",
    AddSubscription: "إضافة إشتراك",
    ArabicName: "الإسم بالعربية",
    AddClass: "إضافة فصل",
    AddClassTime: "إضافة وقت للفصل",
    AddMembers: "إضافة أعضاء",
    AttendMember: "حضور عضو",
    ArabicLang: "عربي",
    Active: "فعال",
    AddedBy: "تمت الإضافة بواسطة",
    AddSalary: "إضافة راتب",
    AddExpenses: "إضافة مصروفات",
    AddRecord: "إضافة صف للخزانة",
    Amount: "المبلغ",
    AllMembers: "عدد الأعضاء",
    AddFood: "إضافة طعام",
    AvailableQTY: "الكمية المتاحة",
    All: "الجميع",
}

export default words