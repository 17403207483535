import { useContext } from 'react'
import AppContext from '../../partials/AppContext'
import './DataLine.css'

const DataLine = ({
    title,
    value,
    changeClick,
    _key = undefined,
    withoutBtn = undefined,
    disabled = undefined,
    btnLabel = undefined
}) => {
    const {translate} = useContext(AppContext)
    return (
        <div className="shared-data-line" key={_key ? _key : Math.random()}>
            <span className="shared-data-line-title">{ title } :</span>
            <span className="shared-data-line-data"> { value } </span>
            {
                withoutBtn ?
                '' :
                <button
                    disabled={disabled ? disabled : false}
                    className="shared-data-line-control"
                    onClick={changeClick}>
                    {btnLabel ? btnLabel : translate('Change')}
                </button>
            }
        </div>
    )
}

export default DataLine