import axios from "axios"
import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import Add from "./Add/Add"
import ChangeStatusButtons from "./ChangeStatusButtons"
import DeleteOrder from "./DeleteOrder"
import Edit from "./Edit"
import Row from "./Row"
import Show from './Show'

const Index = ({visible ,data ,setData}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-dashboard-services/orders`
    const {clearUserData ,gymToken ,translate} = useContext(AppContext)
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const [showAction ,setShowAction] = useState(undefined)
    const [addAction ,setAddAction] = useState(undefined)
    const [changeStatusAction ,setChangeStatusAction] = useState(undefined)
    const [deleteAction ,setDeleteAction] = useState(undefined)
    const [editAction ,setEditAction] = useState(undefined)

    const cancelModal = () => {
        setShowAction(undefined)
        setAddAction(undefined)
        setChangeStatusAction(undefined)
        setDeleteAction(undefined)
        setEditAction(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(gymToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${translate(`Order${responseData?.length > 1 ? 's' : ''}`)}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[gymToken ,entityUrl ,dataPerPage ,currentPage ,alert ,clearUserData])

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    const reloadForDelete = () => {
        if (dataList.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,gymToken)

    return (
        <div className={`display-${visible ? 'show' : 'hide'}`}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}/>
                    <button className="btn btn-light" onClick={() => {
                        cancelModal()
                        setAddAction(true)
                    }}>{translate("PlaceOrder")}</button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div className="flex-row flex-gap-20">
                            <button type="button" onClick={triggerSearch}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: translate('OrderID')},
                            {value: translate('OrderTime')},
                            {value: translate('Member')},
                            {value: translate('IDNumber')},
                            {value: translate('Barman')},
                            {value: translate('Cost')},
                            {value: translate('Actions') ,className: 'actions'}
                        ]}/>
                        <tbody>
                            {
                                dataList?.map(
                                    row => <Row key={Math.random()}
                                        row={row}
                                        showRow={() => {
                                            cancelModal()
                                            setShowAction(row?.id)
                                        }}
                                        editRow={() => {
                                            cancelModal()
                                            setEditAction(row?.id)
                                        }}
                                        deleteRow={() => {
                                            cancelModal()
                                            setDeleteAction({id: row?.id ,name: `#${row?.id} ,${row.order_time} ${translate("ForThisMember")} "${row?.member}"`})
                                        }}
                                        changeStatus={() => {
                                            cancelModal()
                                            setChangeStatusAction(row?.id)
                                        }}
                                    />
                                )
                            }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n})}/>
                </div>
            </div>
            {showAction ? <Show id={showAction} cancel={cancelModal} /> : ''}
            {addAction ? <Add cancel={cancelModal} reloadData={reloadForAdd} /> : ''}
            {
                changeStatusAction ?
                <Show id={changeStatusAction} cancel={cancelModal}>
                    <ChangeStatusButtons id={changeStatusAction} cancel={cancelModal} reloadData={reloadForDelete}/>
                </Show>
                : ''
            }
            {deleteAction ? <DeleteOrder id={deleteAction?.id} name={deleteAction?.name} cancel={cancelModal} reloadData={reloadForDelete}/> : ''}
            {editAction ? <Edit id={editAction} cancel={cancelModal} reloadData={loadData}/> : ''}
        </div>
    )
}

export default Index