import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../Axios"
import Config from "../../../Config"
import FieldError from "../../FormShared/FieldError"
import Input from "../../FormShared/Input"
import ModalButtons from "../../FormShared/ModalButtons"
import ModalHeader from "../../FormShared/ModalHeader"
import AppContext from "../../partials/AppContext"

const ChangeBarModal = ({bars ,reloadData ,cancel}) => {
    const alert = useAlert()
    const {clearUserData ,gymToken ,refreshBarSettings ,translate} = useContext(AppContext)
    const [selectedBars ,setBars] = useState([...bars])
    const [pass ,setPass] = useState('')
    const [error ,setError] = useState('')
    const [disabled ,setDisabled] = useState(false)

    const changeType = bar => {
        if (selectedBars.includes(bar)) setBars([...selectedBars.filter(_bar => _bar !== bar)])
        else setBars([...selectedBars ,bar])
    }

    const saveChanges = () => {
        if (selectedBars.length < 1) {
            alert.error('Select item at least')
            return
        }
        setDisabled(true)
        const formData = new FormData
        selectedBars.forEach((bar ,index) => formData.append(`bar[${index}]` ,bar))
        formData.set('_method' ,'PUT')
        formData.set('password' ,pass)
        Axios
        .setAuthToken(gymToken)
        .post(`${Config.apiBaseUrl}/gym-dashboard-services/branch-setting` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} ${translate("updatedSuccessfully")}`)
            reloadData()
            cancel()
            refreshBarSettings()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors?.type) setError(error?.response?.data?.errors?.type)
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader cancel={cancel} title={translate("ChangeBar")}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2">
                            <label class="checkbox">
                                <div class="checkbox-btn">
                                    <input type="checkbox"
                                        checked={selectedBars?.includes('Drinks')}
                                        onChange={() => changeType('Drinks')}/>
                                    <span class="input-checkbox"></span>
                                </div>
                                <h6 class="checkbox-text">{translate("Drinks")}</h6>
                            </label>
                            
                            <label class="checkbox">
                                <div class="checkbox-btn">
                                    <input type="checkbox"
                                        checked={selectedBars?.includes('Food')}
                                        onChange={() => changeType('Food')}/>
                                    <span class="input-checkbox"></span>
                                </div>
                                <h6 class="checkbox-text">{translate("Food")}</h6>
                            </label>

                            <label class="checkbox">
                                <div class="checkbox-btn">
                                    <input type="checkbox"
                                        checked={selectedBars?.includes('Supplement')}
                                        onChange={() => changeType('Supplement')}/>
                                    <span class="input-checkbox"></span>
                                </div>
                                <h6 class="checkbox-text">{translate("Supplement")}</h6>
                            </label>
                            {error ? <FieldError message={error} /> : ''}
                            <Input type="password" placeholder="Password" value={pass} onChange={e => setPass(e.target.value)}/>
                        </div>
                        <ModalButtons
                            cancel={cancel}
                            disabled={disabled}
                            confirmLabel={translate("SaveChanges")}
                            confirm={saveChanges}/>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangeBarModal