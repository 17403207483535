const HistoryRow = ({history}) => {
    return (
        <tr key={ history.id ? history.id : Math.random()}>
            <td> { history?.date } </td>
            <td> { history?.name } </td>
            <td> { history?.id_number } </td>
            <td> { history?.in } </td>
            <td> { history?.out } </td>
        </tr>
    )
}

export default HistoryRow