import { useContext } from "react"
import { useAlert } from "react-alert"
import AppContext from "../partials/AppContext"

const DateInput = props => {
    const {translate} = useContext(AppContext)
    const alert = useAlert()

    const onChange = e => {
        const value = `${e.target.value}`
        const dateReg = /^\d{4}-\d{2}-\d{2}$/
        if (props.type === 'time') props.onChange(e)
        else {
            if (value.length >= 10) {
                if (dateReg.test(value)) {
                    props.onChange(e)
                } else {
                    alert.error(translate("InvalidFormat"))
                }
            }
        }
    }
    return (
        <input className={props.className ? props.className : `rounded-box ${props.isAr ? 'ar' : ''}`}
            placeholder={props.placeholder ? props.placeholder : ''}
            value={props.value ? props.value : ''}
            min={props?.min}
            max={props?.max}
            type={props.type ? props.type : "date"}
            disabled={props.isDisabled ? props.isDisabled : false}
            onChange={onChange}/>
    )
}

export default DateInput