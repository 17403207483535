import { useEffect, useState } from "react"
import Axios from "../../../../../../Axios"
import MemberData from "./MemberData"
import EditMember from '../../Edit'

const General = ({id ,data ,loadData ,visible}) => {
    const [showEdit ,setShowEdit] = useState(undefined)

    const axiosSource = Axios.CancelToken.source()
    
    useEffect(() => {
        loadData(axiosSource.token)
        return () => axiosSource.cancel()
    } ,[])
    
    return (
        <div className={`page-content display-${visible ? 'show' : 'hide'}`}>
            <MemberData showEdit={() => setShowEdit(id)} member={data} reloadData={loadData}/>
            {
                showEdit ?
                <EditMember id={id} cancel={() => setShowEdit(undefined)} reloadData={() => {
                    loadData()
                    setShowEdit(undefined)
                }} />
                : ''
            }
        </div>
    )
}

export default General