import {useContext} from "react"
import AppContext from "../partials/AppContext"

const styles = {
    spanStyle: {
        zIndex: 9,
        fontWeight: 'normal',
        fontSize: 12,
        top: 15
    }
}

const DropDown = (props) => {
    const {systemLang} = useContext(AppContext)
    let spanStyle = {...styles.spanStyle}
    if (systemLang === "ar") {
        spanStyle.left = 15
    } else {
        spanStyle.right = 15
    }
    return (
        <div className={`dropdown form-group custom-dropdown-js ${props?.className} rel-position`}
            style={props.rootStyle ? {...props.rootStyle} : {}}>
            {
                props.placeholder ?
                <span className="abs-position" style={spanStyle}> {props.placeholder} </span>
                : null
            }
            <button className={props.btnClassName ? props.btnClassName : "rounded-box"} style={{...props.styles}}
                type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="dropdown-title" style={{color: props?.textColorBlack ? 'black' : 'rgb(128 127 127)'}}> {props?.selectedName} </span>
                <img alt="Gymme" className="dropdown-icon" src="/assets/svg/chevron-down-solid-dark.svg"/>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {props.children}
                {
                    props?.list?.map((item) => {
                        return props.btnComp ? props.btnComp(item) : (
                            <button key={Math.random()}
                                type="button"
                                className="dropdown-item"
                                onClick={() => props?.select(item)}>
                                {item[props.itemName]}
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DropDown