import { useContext } from "react"
import AppContext from "../../partials/AppContext"

const Row = ({row ,editAction ,deleteAction}) => {
    const {translate} = useContext(AppContext)

    const amountStyle = {
        color: row.amount_color ? row.amount_color : 'black'
    }
    
    return (
        <tr>
            <td> { row?.date } </td>
            <td> { row?.employee } </td>
            <td> { row?.cash_type } </td>
            <td> { row?.type } </td>
            <td> { row?.reason } </td>
            <td style={amountStyle}> { `${row?.amount} ${translate("EGP")}` } </td>
            <td className="actions">
                {
                    row.editable === 1 ?
                    <>
                        <button onClick={editAction}>
                            <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                        </button>
                        <button onClick={deleteAction}>
                            <img alt="Gymme" src="/assets/svg/delete.svg"/>
                        </button>
                    </>
                    : null
                }
            </td>
        </tr>
    )
}

export default Row