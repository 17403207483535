import { useContext } from "react"
import FieldError from "../../FormShared/FieldError"
import Input from "../../FormShared/Input"
import PhotoInput from '../../FormShared/PhotoInput'
import AppContext from "../../partials/AppContext"
import ModalHeader from "../../FormShared/ModalHeader"
import ModalButtons from "../../FormShared/ModalButtons"

const ChangePhoto = ({
    cancel,
    confirm,
    error,
    currentPass,
    setPass,
    passError,
    disabled,
    changePhoto
}) => {
    const {translate} = useContext(AppContext)
    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={translate("ChangePhoto")} cancel={cancel}/>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="section mb-2 flex-gap-5 flex-col">
                            <PhotoInput updatePhoto={(img) => changePhoto(img)} error={error} />
                            <Input
                                value={currentPass}
                                onChange={setPass}
                                placeholder={translate("Password")}
                                type="password"/>
                            {passError ? <FieldError message={passError} /> : ''}
                        </div>
                        <ModalButtons
                            disabled={disabled}
                            cancel={cancel}
                            confirm={confirm}
                            confirmLabel={translate("SaveChanges")}/>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangePhoto