const words = {
    Owner: "المالك",
    Optional: "إختياري",
    OpeningHours: "ساعات العمل",
    OpeningTime: "وقت الافتتاح",
    Other: "آخري",
    October: "أكتوبر",
    OldEquipment: "المعدات القديمة",
    Orders: "الطلبات",
    OrderID: "رقم الطلب",
    OrdersPaidSuccess: "تم دفع الطلب بنجاح",
    OrderNotPaid: "الطلب غير مدفوعة (آجلة)",
    OrdersNotPaid: "الطلب غير مدفوعة (آجلة)",
    OldPassword: "كلمة المرور القديمة",
    Order: "الطلب",
    OrderRevenue: "أرباح الطلب",
    OrderDetails: "تفاصيل الطلب",
    OrderTime: "وقت الطلب",
    Offer: "العرض",
    OfferLimitedNumber: "قم بكتابة الحد الأقصي للعرض او ادخل 0 لعدد غير محدود",
    OfferLimit: "حد العرض",
    OfferCanceledSuccessfully: "تم إلغاء العرض بنجاح",
    Overtime: "وقت إضافي",
    Phone: "الهاتف",
    Password: "كلمة المرور",
    Province: "المحافظة",
    PasswordRequired: "كلمة المرور مطلوبة",
    Position: "الوظيفة",
    PersonalInfo: "المعلومات الشخصية",
    PowerOff: "إغلاق",
    PowerOn: "تفعيل",
    Price: "السعر",
    Problem: "مشكلة",
    Print: "طباعة",
    PersonalProfile: "الملف الشخصي",
    PurchaseDetails: "تفاصيل الشراء",
    PriceEgp: "السعر (بالجنيه)",
    PurchasePrice: "سعر الشراء",
    Purchase: "مشتريات",
    Personal: "شخصي",
    Preview: "السابق",
    ProvinceFirst: "إختر محافظة أولا",
    PrivateCoach: "مدرب خاص",
    PrivateCoachInfo: "معلومات المدرب الخاص",
    Previous: "السابق",
    PayLeftover: "دفع الآجل",
    Paid: "مدفوع",
    PhotoFirst: "إختر صورة أولا",
    Profile: "الملف الشخصي",
    PlanDetails: "تفاصيل الخطة",
    Pay: "دفع",
    PlaceOrder: "إنشاء طلب",
    PriceAfterDiscount: "السعر بعد الخصم",
    PermanentlyCancelOffer: "هل أنت متأكد أنك تريد إلغاء هذا العرض",
    Quantity: "الكمية",
    QTY: "الكمية",
    QntGTEOne: "الكمية لابد ان تساوي او ان تكون أكبر من 1",
    QntGTE: "الكمية لابد ان تساوي او ان تكون أكبر من",
    QNT: "الكمية",
    Overview: "مراجعة",
    Please: "من فضلك",
}

export default words