const words = {
    Owner: "Owner",
    Optional: "Optional",
    OpeningHours: "Opening Hours",
    OpeningTime: "Opening Time",
    Other: "Other",
    October: "October",
    OldEquipment: "Old Equipment",
    Orders: "Orders",
    OrderID: "Order ID",
    OrdersPaidSuccess: "orders have been paid successfully",
    OrderNotPaid: "Order not paid yet (Leftover)",
    OrdersNotPaid: "Orders not paid yet (Leftover)",
    OldPassword: "Old Password",
    Order: "Order",
    OrderRevenue: "Order Revenue",
    OrderDetails: "Order Details",
    OrderTime: "Order Time",
    Offer: "Offer",
    OfferLimitedNumber: "Set the number for limited offer or set to “0” for unlimited Offer",
    OfferLimit: "Offer Limit",
    OfferCanceledSuccessfully: "Offer has been Canceled Successfully",
    Overtime: "Overtime",
    Phone: "Phone",
    Password: "Password",
    Province: "Province",
    PasswordRequired: "Password is required",
    Position: "Position",
    PersonalInfo: "Personal Information",
    PowerOff: "Power Off",
    PowerOn: "Power On",
    Price: "Price",
    Problem: "Problem",
    Print: "Print",
    PersonalProfile: "Personal Profile",
    PurchaseDetails: "Purchase Details",
    PriceEgp: "Price (EGP)",
    PurchasePrice: "Purchase Price",
    Purchase: "Purchase",
    Personal: "Personal",
    Preview: "Preview",
    ProvinceFirst: "Select province first",
    PrivateCoach: "Private Coach",
    PrivateCoachInfo: "Private Coach Information",
    Previous: "Previous",
    PayLeftover: "Pay Leftover",
    Paid: "Paid",
    PhotoFirst: "Select Photo First",
    Profile: "Profile",
    PlanDetails: "Plan Details",
    Pay: "Pay",
    PlaceOrder: "Place Order",
    PriceAfterDiscount: "Price After Discount",
    PermanentlyCancelOffer: "Are you sure you want to permanently cancel this offer",
    Quantity: "Quantity",
    QTY: "QTY",
    QntGTEOne: "Quantity must be greater than or equal to 1",
    QntGTE: "Quantity must be less than or equal to",
    QNT: "QNT",
    Overview: "Overview",
    Please: "Please",
}

export default words